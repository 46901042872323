import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import AdminUsuarios from '../Components/Admin/Usuarios'
import AdminLogo from '../Components/Admin/Logo/AdminLogo'
import AdminFinanceiro from '../Components/Admin/Usuarios'

import ModalNovaLogo from '../Components/Admin/Logo/ModalNovaLogo'

import api from '../api';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '5%',
        position: 'relative',
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
      },
      noMarginNoPadding: {
          padding: '0px',
          margin: '0px',
      },
      LogoBox: {
          marginTop: '2vh'
      }
  }));

export default function Admin() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openLogo, setOpenLogo] = React.useState(false);

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Grid item xs={12} md={12}>
                    <Box display="flex" className={classes.noMarginNoPadding}>
                        <Box flexGrow={1}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',                            
                            }}>
                                <GroupRoundedIcon />
                                <h3 style={{marginLeft: '5px'}}>Usuários</h3>
                            </div>
                        </Box>
                        <Box className={classes.noMarginNoPadding} style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',                 
                            }}>
                                                      
                                <Button        
                                    variant="contained"                            
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<AddCircleIcon />}
                                    size="small"  
                                    onClick={() => setOpen(true)}                                                                     
                                >
                                    Adicionar
                                </Button>
                            
                        </Box>
                        
                    </Box>
                                        
                        <Paper className={classes.paper} elevation={10} style={{height: '35vh', overflow: 'auto'}} >
                                                       
                                
                           
                                                     
                            <AdminUsuarios open={open} setOpen={setOpen} />
                            
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={12} className={classes.LogoBox}>
                    <Box display="flex" className={classes.noMarginNoPadding}>
                        <Box flexGrow={1}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',                            
                            }}>
                                <ImageRoundedIcon />
                                <h3 style={{marginLeft: '5px'}}>Logo</h3>
                            </div>
                        </Box>
                        <Box className={classes.noMarginNoPadding} style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',                 
                            }}>
                                                      
                                <Button        
                                    variant="contained"                            
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<AddCircleIcon />}
                                    size="small"  
                                    onClick={() => setOpenLogo(true)}                                                                     
                                >
                                    Adicionar
                                </Button>
                            
                        </Box>
                        
                    </Box>
                        <Paper className={classes.paper} elevation={10} style={{height: '35vh' , overflow: 'auto'}}>
                            
                            <ModalNovaLogo openLogo={openLogo} setOpenLogo={setOpenLogo} />
                            
                            <AdminLogo />
                        </Paper>
                    </Grid>
                    
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <MonetizationOnIcon />
                        <h3 style={{marginLeft: '5px'}}>Financeiro</h3>
                    </div>
                    <Paper className={classes.paper} elevation={10} style={{height: '78vh'}}>
                        
                        {/* <AdminFinanceiro /> */}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}