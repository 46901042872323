import React, { useState, useEffect } from 'react';
import '../css/login.css';
import history from '../history';
import api from '../api';
import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';


import { Context } from '../Context/AuthContext';


function Copyright() {
  return (
    <Typography variant="body2" color="textPrimary" align="center">
      {'Copyright © '}{' '}{new Date().getFullYear()}{' '}
      <Link color="inherit" href="https://geotaker.com.br/">
        Geotaker. Todos os direitos reservados.
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '100px',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  informacao: {
    marginTop: '20px',
  },
  senhasDesiguais: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    color:'red',
  }
}));

export default function RecuperarSenha() {
  const classes = useStyles();
  const [user, setUser] = useState({ email: "" });
  const [loading, setLoading] = useState(false)
  const [loadingConfirm, setLoadingConfirm] = useState(false)
  const [loadingNewPass, setLoadingNewPass] = useState(false)

  const [recuperandoSenha, setRecuperandoSenha] = useState(true)
  
  const [codigo, setCodigo] = useState({ codigo: ""})
  const [codigoRec, setCodigoRec] = useState();
  const [codAguardando, setCodAguardando] = useState(true);

  const [novaSenha, setNovaSenha] = useState({ newpass: "", renewpass: "" })

  // useEffect(()=>{console.log(novaSenha)}, [novaSenha])

  const handleRecupPass = async () => {
    setLoading(true)
    await api.post('/login/recuperarsenha', { user })
    .then(function (response) {
      // handle success
      
      const datajson = response.data
      // console.log(datajson)
        
    // setCodigoRec(datajson.codigo)  

    setLoading(false)

    if (datajson.codigo !== null) {
      setCodAguardando(false)
      toast.success("Código enviado para o email. Confirme o código.");
    } else {
      toast.error("Email não cadastrado!");
    }   
    
    
    })
    .catch(function (error) {
      // handle error
      toast.error("Algum erro ocorreu! Informe o suporte.");
      setLoading(false) 
      console.log(error);
    })
    .then(function () {
      // always executed
     
    });
  }

  const handleConfirmCode = async () => {
    setLoadingConfirm(true)
    await api.post('/login/confirmarcodigorecuperarsenha', { user, codigo })
    .then(function (response) {
      // handle success
      
      const datajson = response.data
      // console.log(datajson)
      setLoadingConfirm(false)

      if(datajson.message === "authorized") {
        toast.success("Código confirmado! Cadastre a nova senha.");
        setRecuperandoSenha(false)
      }
      if(datajson.message === "unauthorized") {
        toast.error("Código inválido!");
      }
      if(datajson.message === "expired") {
        toast.error("Esse código está expirado!");
      }
    })
    .catch(function (error) {
      // handle error
      toast.error("Ocorreu algum erro! Informe o suporte.");
      setLoadingConfirm(false)
      console.log(error);
    })
    .then(function () {
      // always executed     
    });
  }


  
  const handleNewPass = async () => {    
    setLoadingNewPass(true)      
      await api.post('/login/salvarsenha', { user, novaSenha })
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        console.log(datajson)
        setLoadingNewPass(false)

        if(datajson.message === "updated") {
          toast.success("Nova senha cadastrada com sucesso! Faça login.");  
          history.push('/login');   
        } else {
          toast.error("Ocorreu algum erro! Informe o suporte.")
        }
        
      })
      .catch(function (error) {
        // handle error
        toast.error("Ocorreu algum erro! Informe o suporte.");
        setLoadingNewPass(false)
        console.log(error);
      })
      .then(function () {
        // always executed     
      });  
   
  }

  const handleChangeInput = e => {
		setUser({ ...user, [e.target.name]: e.target.value });   
	};

  const handleChangeCodigo = e => {
    setCodigo({ ...codigo, [e.target.name]: e.target.value });
  }  

  const handleChangeNovaSenha = e => {
    setNovaSenha({ ...novaSenha, [e.target.name]: e.target.value });
  }  

  const handlSubmit = e => {   
		e.preventDefault();    
		handleRecupPass(user);
	};

  const handlSubmitCode = e => {   
		e.preventDefault();    
		handleConfirmCode(codigo);
	};

  const handlSubmitNewPass= e => {   
		e.preventDefault();    
		handleNewPass(novaSenha);
	};
  
  return (
    <>
    <div className="bg"></div>
    <div className="bg bg2"></div>
    <div className="bg bg3"></div>
    {recuperandoSenha ? ( 
      <>        
        <Container component="main" maxWidth="xs">
          {/* <CssBaseline /> */}
          <Paper className={classes.paper} elevation={10}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            
            
            {codAguardando ? (
            <>
                <Typography component="h1" variant="h5">
                Recuperar Senha
                </Typography>
                <Typography variant="caption" className={classes.informacao}>
                Digite o email de cadastro. Você receberá um código por email.
                </Typography>
                <form className={classes.form} noValidate onSubmit={handlSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    onChange={handleChangeInput}
                    autoFocus
                />
                <div className={classes.wrapper}>
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading || (user.email === "") }
                    className={classes.submit}            
                    >
                    Recuperar
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>   
                <Grid container>
                  <Grid item xs>
                    <Link href="/login" variant="body2">
                      Voltar para login
                    </Link>
                  </Grid>            
                </Grid>           
                </form>
            </>
            ) : (
            <>
                <Typography component="h1" variant="h5">
                Confirmar Código
                </Typography>
                <Typography variant="caption" className={classes.informacao}>
                Digite o código que foi enviado para o email.
                </Typography>
                <form className={classes.form} noValidate onSubmit={handlSubmitCode}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="codigo"
                    label="Codigo"
                    name="codigo"
                    value={codigo.codigo}
                    onChange={handleChangeCodigo}
                    autoFocus
                />
                <div className={classes.wrapper}>
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loadingConfirm || (codigo.codigo === "")}
                    className={classes.submit}            
                    >
                    Confirmar Código
                    </Button>
                    {loadingConfirm && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>     
                <Grid container>
                  <Grid item xs>
                    <Link href="/login" variant="body2">
                      Voltar para login
                    </Link>
                  </Grid>            
                </Grid>     
                </form>
            </>
            )}
            
          </Paper>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </>
      ) : ( 
      <>  
        <Container component="main" maxWidth="xs">        
          <Paper className={classes.paper} elevation={10}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Nova Senha
            </Typography>
            <form className={classes.form} noValidate onSubmit={handlSubmitNewPass}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="newpass"
                label="Nova Senha"
                type="password"
                name="newpass"               
                onChange={handleChangeNovaSenha}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="renewpass"
                label="Repetir Senha"
                type="password"
                id="renewpass"
                onChange={handleChangeNovaSenha}               
              />
              {(novaSenha.newpass !== novaSenha.renewpass) ? <p className={classes.senhasDesiguais}>* As senhas devem ser iguais.</p> : <p></p>}
              <div className={classes.wrapper}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loadingNewPass || (novaSenha.newpass === "") ||(novaSenha.newpass !== novaSenha.renewpass)}
                  className={classes.submit}            
                >
                  Salvar
                </Button>
                {loadingNewPass && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>     
              <Grid container>
                  <Grid item xs>
                    <Link href="/login" variant="body2">
                      Voltar para login
                    </Link>
                  </Grid>            
                </Grid>           
            </form>
          </Paper>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </>
      )}

    </>
  );

 

  
}