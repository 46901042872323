import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../Context/AuthContext';
import { AdminContext } from '../../Context/AdminContext';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import AppsIcon from '@material-ui/icons/Apps';
import SettingsIcon from '@material-ui/icons/Settings';

import Menu from '@material-ui/core/Menu';

import AccountCircle from '@material-ui/icons/AccountCircle';

import MeetingRoomRoundedIcon from '@material-ui/icons/MeetingRoomRounded';
import AssignmentIndRoundedIcon from '@material-ui/icons/AssignmentIndRounded';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import DarkModeToggle from "react-dark-mode-toggle";
import ExploreIcon from '@material-ui/icons/Explore';

const imageLogo = require(`../../images/logo/geotaker-favicon-white.png`)

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  
  
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  btnDarkMode: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  btnMobile: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: '0px',
    marginBottom: '0px',
  }
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {      
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        // color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function PrimaryAppBar({
  isDarkMode, 
  setIsDarkMode,
  children
}) {
  const classes = useStyles();
  const { authenticated, handleLogout, dataUser } = useContext(Context);
  const { isAdmin } = useContext(AdminContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElItem, setAnchorElItem] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorElNotif, setAnchorElNotif] = useState(null);
 
  const isMenuOpen = Boolean(anchorEl);
  const isMenuItemOpen = Boolean(anchorElItem);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItensMenuOpen = (event) => {
    setAnchorElItem(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuItemClose = () => {
    setAnchorElItem(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function logOut () {
    handleMenuClose()
    handleLogout()
  }

  const handleClickNotif = (event) => {
    setAnchorElNotif(event.currentTarget);
  };

  const handleCloseNotif = () => {
    setAnchorElNotif(null);
  };

  const menuId = 'primary-account-menu';

  const renderMenuItem = [
    <Menu
      key={"menu10"}
      anchorEl={anchorElItem}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuItemOpen}
      onClose={handleMenuItemClose}
      style={{zIndex:10000}}
    >
      <Link href="/dashboard" onClick={handleMenuItemClose} style={{textDecoration: 'none'}} color="inherit">
        <StyledMenuItem>        
          <ListItemIcon style={{minWidth: '10px'}}>
            <AssessmentIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Dashboard" style={{marginLeft: '10px'}} />
        </StyledMenuItem>
      </Link>
      <Link href="/explorar" onClick={handleMenuItemClose} style={{textDecoration: 'none'}} color="inherit">
        <StyledMenuItem>        
          <ListItemIcon style={{minWidth: '10px'}}>
            <ExploreIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Explorar" style={{marginLeft: '10px'}} />
        </StyledMenuItem>
      </Link>
      {/* <Link href="/analise" onClick={handleMenuItemClose} style={{textDecoration: 'none'}} color="inherit">
        <StyledMenuItem>        
          <ListItemIcon style={{minWidth: '10px'}}>
            <AddCircleRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Estudo" style={{marginLeft: '10px'}} />
        </StyledMenuItem>        
      </Link> */}
      
    </Menu>
  ];

  const renderMenu = [
    <Menu
      key={"menu1"}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{zIndex:10000}}
    >
      <Link href="/profile" onClick={handleMenuClose} style={{textDecoration: 'none'}} color="inherit">
        <StyledMenuItem>        
          <ListItemIcon style={{minWidth: '10px'}}>
            <AssignmentIndRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Meu Perfil" style={{marginLeft: '10px'}} />
        </StyledMenuItem>
      </Link>
      {isAdmin ? (
        <Link href="/administracao" onClick={handleMenuClose} style={{textDecoration: 'none'}} color="inherit">
        <StyledMenuItem>        
          <ListItemIcon style={{minWidth: '10px'}}>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Administração" style={{marginLeft: '10px'}} />
        </StyledMenuItem>
      </Link>
      ) : (
        <div />        
      )}
      <Divider />
      <StyledMenuItem onClick={logOut}>
          <ListItemIcon style={{minWidth: '10px'}}>
            <MeetingRoomRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sair" style={{marginLeft: '10px'}} />
      </StyledMenuItem>
      {/* <MenuItem onClick={logOut}>Sair</MenuItem> */}
    </Menu>
  ];

  const menuNotif = [
    <Menu
        key="menu-notif"
        anchorEl={anchorElNotif}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElNotif)}
        onClose={handleCloseNotif}
        style={{zIndex:20000}}
      >
        <div style={{paddingRight:'20px', paddingLeft:'20px', zIndex: 20000}}>
          <p>Não há notificações.</p>
        </div>        
      </Menu>
  ]
  

  const mobileMenuId = 'primary-account-menu-mobile';
  const mobileMenuIdAuth = 'primary-account-menu-mobile-auth';

  const renderMobileMenuAuth = [   
    <Menu
      key={"menu2"}
      anchorEl={mobileMoreAnchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={mobileMenuIdAuth}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>      
      <DarkModeToggle
          className={classes.btnMobile}
          onChange={setIsDarkMode}
          checked={isDarkMode}
          size={60}
        />
        <p style={{margin: '0px 0px 0px 5px', fontSize: '18px'}}>Modo Escuro</p>
        </MenuItem>
        
          <MenuItem
          onClick={handleClickNotif}
          >
            <IconButton 
            aria-label="Mostrar Notificações" 
            color="inherit" 
            className={classes.btnMobile}            
            >
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
              <p style={{margin: '0px 0px 0px 5px', fontSize: '18px'}}>Notificações</p>
            </IconButton>
            
          </MenuItem>
          <MenuItem onClick={handleItensMenuOpen}>
            <IconButton
              aria-label="Opções"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              className={classes.btnMobile}
            >
              <AppsIcon /><p style={{margin: '0px 0px 0px 5px', fontSize: '18px'}}>Opções</p>
            </IconButton>
    
          </MenuItem>
          <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
              aria-label="Perfil do usuário"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              className={classes.btnMobile}
            >
              <AccountCircle /><p style={{margin: '0px 0px 0px 5px', fontSize: '18px'}}>{authenticated? dataUser.firstName : ''}</p>
            </IconButton>
    
          </MenuItem>
        
        
      </Menu>
  ];

  const renderMobileMenuNoAuth = [   
    <Menu
      key={"menu3"}
      anchorEl={mobileMoreAnchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>      
      <DarkModeToggle
          className={classes.btnMobile}
          onChange={setIsDarkMode}
          checked={isDarkMode}
          size={60}
        />
        <p> Dark Mode</p>
        </MenuItem>
        
      </Menu>
  ];

  return (
    <>
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
        {authenticated ? (
          <Typography variant="body2" noWrap>
            {/* <img src={imageLogo} height="20"/> */}
             <a href='/dashboard' style={{textDecoration: 'none', color:'white', fontSize: '18px', fontWeight: '900'}}>Geotaker</a> 
          </Typography>
        ) : (
          <Typography variant="body2" noWrap style={{textDecoration: 'none', color:'white', fontSize: '18px', fontWeight: '900'}}>
            {/* <img src={imageLogo} height="20"/> */}
            Geotaker 
          </Typography>
        )}
          
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <DarkModeToggle                         
              onChange={setIsDarkMode}
              checked={isDarkMode}
              size={60}
            />
          </div>
          <div className={classes.sectionDesktop}>    
          {authenticated && (
            
            <>    
              <IconButton 
              aria-label="Notificações" 
              color="inherit"
              onClick={handleClickNotif}
              style={{marginLeft: '15px'}}
              >
                <Badge badgeContent={0} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton 
              aria-label="Opções" 
              color="inherit"
              onClick={handleItensMenuOpen}
              >
                <Badge badgeContent={0} color="secondary">
                  <AppsIcon />
                </Badge>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle /><p style={{margin: '0px 0px 0px 5px', fontSize: '18px'}}>{dataUser.firstName}</p>
              </IconButton>            
           </>
            
           )} 
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuIdAuth}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
          

        </Toolbar>
      </AppBar>
      {authenticated ? renderMobileMenuAuth : renderMobileMenuNoAuth}
      {renderMenuItem}
      {renderMenu}
      {menuNotif}
      { children }
    </div>
    </>
  );
}