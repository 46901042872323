import L from "leaflet";

const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://geotaker.com.br/images/marker-icon.png",
  shadowUrl: "https://geotaker.com.br/images/marker-shadow.png"
});

const icon1 = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://geotaker.com.br/images/markers/clientes.png",
  shadowUrl: "https://geotaker.com.br/images/marker-shadow.png"
});

const icon2 = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://geotaker.com.br/images/markers/concorrentes.png",
  shadowUrl: "https://geotaker.com.br/images/marker-shadow.png"
});

const icon3 = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://geotaker.com.br/images/markers/estudos.png",
  shadowUrl: "https://geotaker.com.br/images/marker-shadow.png"
});

const icon4 = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://geotaker.com.br/images/markers/fornercedores.png",
  shadowUrl: "https://geotaker.com.br/images/marker-shadow.png"
});

const icon5 = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://geotaker.com.br/images/markers/franquias.png",
  shadowUrl: "https://geotaker.com.br/images/marker-shadow.png"
});

const icon6 = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://geotaker.com.br/images/markers/unidade.png",
  shadowUrl: "https://geotaker.com.br/images/marker-shadow.png"
});

const icon7 = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://geotaker.com.br/images/markers/parceiros.png",
  shadowUrl: "https://geotaker.com.br/images/marker-shadow.png"
});

const icon8 = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://geotaker.com.br/images/markers/geral.png",
  shadowUrl: "https://geotaker.com.br/images/marker-shadow.png"
});


export { icon, icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8 }
