import React, { useState, useEffect, useContext } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import NaturePeopleRoundedIcon from '@material-ui/icons/NaturePeopleRounded';

import api from '../../../api';

const useStyles = makeStyles((theme) => ({
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
}))

export default function IDH(props) {
    const classes = useStyles();
    const [idhm, setIdhm] = useState();
    const [idhmRenda, setIdhmRenda] = useState();
    const [idhmLong, setIdhmLong] = useState();
    const [idhmEduc, setIdhmEduc] = useState();
    const codCidade = props.codCidade   

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
        
          await api.post('/consulta/cidade/idh', {cidade:codCidade})
          .then(function (response) {
            // handle success
            //console.log(response.data)
            const datajson = response.data[0];
            
            setIdhm(datajson.idhm_2010)
            setIdhmRenda(datajson.idhm_renda)
            setIdhmLong(datajson.idhm_longevidade)
            setIdhmEduc(datajson.idhm_educacao)

            setLoading(false)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          
          });
    
    
        })();
      }, []);

      return (
        <>
        {loading ? (
                        <Skeleton style={{ width: '60%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
            
            <div>
              
              
                             
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <NaturePeopleRoundedIcon color="secondary"/>
                    <p style={{marginLeft: '5px'}} className={classes.textSecond}>Índice de Desenvolvimento Humano (IDH): <span style={{fontSize: '20px'}} className={classes.textPrimary}>{idhm}</span></p>
                </div>
              <Divider /> 
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <NaturePeopleRoundedIcon color="secondary"/>
                    <p style={{marginLeft: '5px'}} className={classes.textSecond}>IDH - Renda: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{idhmRenda}</span></p>
                </div>
              <Divider /> 
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <NaturePeopleRoundedIcon color="secondary"/>
                    <p style={{marginLeft: '5px'}} className={classes.textSecond}>IDH - Longevidade: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{idhmLong}</span></p>
                </div>
              <Divider /> 
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <NaturePeopleRoundedIcon color="secondary"/>
                    <p style={{marginLeft: '5px'}} className={classes.textSecond}>IDH - Educação: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{idhmEduc}</span></p>
                </div>

            </div>
            

                      )}
        </>
      );

}