import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';
import Chart from "react-apexcharts";

import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import api from '../../../api';

const useStyles = makeStyles((theme) => ({
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
}))

export default function Populacao(props) {
    const classes = useStyles();      
    const { isDarkModeCont } = useContext(DarkContext);
    const [dadosSexo, setDadosSexo] = useState([0, 0]);  
    const [popTotal, setPopTotal] = useState();
    const [popHomem, setPopHomem] = useState();
    const [popMulher, setPopMulher] = useState();
    const codBairro = props.codBairro   
    const distrito = props.distrito;
   

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
        
          await api.post('/consulta/bairro/populacaoestimada', {bairro:codBairro, distrito: distrito})
          .then(function (response) {
            // handle success
           
            const datajson = response.data    

            const popTotalValorReal = Number(datajson[1].V)
            const popTotalValorCorrigido = Math.round(popTotalValorReal + (popTotalValorReal*0.10))
            
            setPopTotal(popTotalValorCorrigido.toLocaleString('pt-BR'))

            const popHomValorReal = Number(datajson[2].V)
            const popHomValorCorrigido = Math.round(popHomValorReal + (popHomValorReal*0.10))

            setPopHomem(popHomValorCorrigido.toLocaleString('pt-BR'))

            const popMulValorReal = Number(datajson[3].V)
            const popMulValorCorrigido = Math.round(popMulValorReal + (popMulValorReal*0.10))

            setPopMulher(popMulValorCorrigido.toLocaleString('pt-BR'))

            setDadosSexo([Math.round(Number(datajson[5].V)), Math.round(Number(datajson[6].V))])

            setLoading(false)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          
          });
    
    
        })();
      }, []);



      
       const options = {
         chart: { 
             background: 'transparent',           
         defaultLocale: 'pt-br',
         locales: [{
             name: 'pt-br',
             options: {
             months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
             shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
             days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
             shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
             toolbar: {
                 download: 'Download SVG',
                 selection: 'Seleção',
                 selectionZoom: 'Selecionar Zoom',
                 zoomIn: 'Mais Zoom',
                 zoomOut: 'Menos Zoom',
                 pan: 'Mover',
                 reset: 'Reiniciar Zoom',
             }
             }
         }],
         type: 'donut',     
         // dropShadow: {
         // enabled: true,
         // color: '#000',
         // top: 3,
         // left: 3,
         // blur: 3,
         // opacity: 0.2
         // },
         // height: 150,
         stacked: true,
         
         },
         theme: {
             mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
         },
         colors: ['#264aff', '#ff33cf'],
         // fill: {
         //   type: "gradient",
         //   gradient: {
         //     shade: "dark",
         //     type: "horizontal",
         //     shadeIntensity: 0.5,
         //     inverseColors: true,
         //     opacityFrom: 1,
         //     opacityTo: 1,
         //     stops: [0, 100]
         //   }
         // },
         dataLabels: {
             enabled: false
           },
           stroke: {
             curve: 'smooth'
           },
           
           legend: {
             show: true,
             position: "bottom",
             // offsetX: -30,
             // offsetY: -10,
             formatter: function (val, opts) {
               return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
             }
           },
           labels: ['Homens', 'Mulheres'],
       }
      
       const series = dadosSexo
      console.log(series)
      
      
      
     

      return (
        <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <p style={{textAlign: 'left'}}>População Bairro</p>
                <Divider />
                    {loading ? (
                        <Skeleton style={{ width: '60%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                        <div style={{textAlign: 'left'}}>
                            <p style={{marginLeft: '15px'}} className={classes.textSecond}>População Total: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{popTotal} pessoas</span></p>
                        </div>
                      )}

                <Divider />
                    {loading ? (
                        <Skeleton style={{ width: '60%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                        <div style={{textAlign: 'left'}}>
                            <p style={{marginLeft: '15px'}} className={classes.textSecond}>População Homem: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{popHomem} pessoas</span></p>
                        </div>
                      )}

                <Divider />
                    {loading ? (
                        <Skeleton style={{ width: '60%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                        <div style={{textAlign: 'left'}}>
                            <p style={{marginLeft: '15px'}} className={classes.textSecond}>População Mulher: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{popMulher} pessoas</span></p>
                        </div>
                      )}
                </Grid>
                <Grid item xs={12} md={6}>
                <p style={{textAlign: 'left'}}>População Homem x Mulher</p>
                <Divider />
                

                <div className="app">
                    <div className="row">
                        <div className="mixed-chart">
                        <Chart
                            options={options}
                            series={series}
                            type="pie"
                            width="100%"
                            height="250vh"
                        />
                        </div>
                    </div>
                </div>


                </Grid>
            </Grid>
        </>
      );

}