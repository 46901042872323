import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';

import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Chart from "react-apexcharts";

import LocationCityRoundedIcon from '@material-ui/icons/LocationCityRounded';

import api from '../../../api';

const useStyles = makeStyles((theme) => ({
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
}))

export default function EmpresasDetalhadas(props) {
    const classes = useStyles();
    const { isDarkModeCont } = useContext(DarkContext);

    const [ativaMatriz, setAtivaMatriz] = useState();
    const [ativaFilial, setAtivaFilial] = useState();
    const [dadosSeriesAtiva, setDadosSeriesAtiva] = useState([0, 0]);

    const [baixadaMatriz, setBaixadaMatriz] = useState();
    const [baixadaFilial, setBaixadaFilial] = useState();
    const [dadosSeriesBaixada, setDadosSeriesBaixada] = useState([0, 0]);

    const [outrasMatriz, setOutrasMatriz] = useState();
    const [outrasFilial, setOutrasFilial] = useState();
    const [dadosSeriesOutras, setDadosSeriesOutras] = useState([0, 0]);

    const [totalMatriz, setTotalMatriz] = useState();
    const [totalFilial, setTotalFilial] = useState();
    const [dadosSeriesTotal, setDadosSeriesTotal] = useState([0, 0]);

    const codCidade = props.codCidade   

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
        
          await api.post('/consulta/cidade/empresas', {cidade:codCidade})
          .then(function (response) {
            // handle success
            //console.log(response.data[0].idh)
            // console.log(response.data)
            const datajson = response.data

            setAtivaMatriz(Number(response.data[0].ativa_matriz).toLocaleString('pt-BR'))
            setAtivaFilial(Number(response.data[0].ativa_filial).toLocaleString('pt-BR'))
            setDadosSeriesAtiva([Number(response.data[0].ativa_matriz), Number(response.data[0].ativa_filial)])

            setBaixadaMatriz(Number(response.data[0].baixada_matriz).toLocaleString('pt-BR'))
            setBaixadaFilial(Number(response.data[0].baixada_filial).toLocaleString('pt-BR'))
            setDadosSeriesBaixada([Number(response.data[0].baixada_matriz), Number(response.data[0].baixada_filial)])

            setOutrasMatriz(Number(response.data[0].outras_matriz).toLocaleString('pt-BR'))
            setOutrasFilial(Number(response.data[0].outras_filial).toLocaleString('pt-BR'))
            setDadosSeriesOutras([Number(response.data[0].outras_matriz), Number(response.data[0].outras_filial)])

            setTotalMatriz(Number(response.data[0].total_matriz).toLocaleString('pt-BR'))
            setTotalFilial(Number(response.data[0].total_filial).toLocaleString('pt-BR'))
            setDadosSeriesTotal([Number(response.data[0].total_matriz), Number(response.data[0].total_filial)])
           
            
            setLoading(false)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          
          });
    
    
        })();
      }, []);



      const optionsChart = {
        chart: {
            background: 'transparent', 
            height: 200,
            type: 'bar',
            events: {
              click: function(chart, w, e) {
                // console.log(chart, w, e)
              }
            },
            toolbar: {
                show: false
              },
          },
          //colors: ['#264aff', '#ff33cf'],
          theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: ["Matriz", "Filial"],
            labels: {
              style: {
              // colors: ['#264aff', '#ff33cf'],
                fontSize: '8px'
              }
            }
          },       
          yaxis: {
            title: {
              text: 'Empresas',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('pt-BR');
              }
            },
          },
    }

    const seriesBaixadas = [{
      name: 'Empresas',
        data: dadosSeriesBaixada
      }]

    const seriesAtivas = [{
    name: 'Empresas',
        data: dadosSeriesAtiva
    }]  

    const seriesOutras = [{
        name: 'Empresas',
        data: dadosSeriesOutras
    }] 
    
    const seriesTotal = [{
        name: 'Empresas',
        data: dadosSeriesTotal
    }]  



      return (
        <>
        <Grid container spacing={3}>
             
              <Grid item xs={12} md={6}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingBottom: '0px',
                    marginBottom: '0px',
                }}>
                <LocationCityRoundedIcon color="secondary"/>
                  <p style={{marginLeft: '5px'}} className={classes.textPrimary}>Empresas Ativas</p>
                  </div>
                  <Divider />
                  <Grid container spacing={3}>
                     <Grid item xs={12} md={6}>
                        {loading ? (
                            <div>
                                <Skeleton style={{ width: '100%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                                <Skeleton style={{ width: '100%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                            </div>
                            
                        ) : (
                            <div>
                                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Matriz: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{ativaMatriz}</span> unidades</p>
                                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Filial: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{ativaFilial}</span> unidades</p>
                            </div>
                
                        )}
                        </Grid>
                            <Grid item xs={12} md={5}>
                            <div className="app">
                                <div className="row">
                                <div className="mixed-chart">
                                    <Chart
                                    options={optionsChart}
                                    series={seriesAtivas}
                                    type="bar"
                                    width="100%"
                                    height="200vh"
                                    />
                                </div>
                                </div>
                            </div>                
                        </Grid>
                    </Grid>

              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        paddingBottom: '0px',
                        marginBottom: '0px',
                    }}>
                        <LocationCityRoundedIcon color="secondary"/>
                        <p style={{marginLeft: '5px'}} className={classes.textPrimary}>Empresas Baixadas</p>
                  </div>
                  <Divider />
                  <Grid container spacing={3}>
                     <Grid item xs={12} md={6}>
                        {loading ? (
                            <div>
                                <Skeleton style={{ width: '100%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                                <Skeleton style={{ width: '100%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                            </div>
                            
                        ) : (
                            <div>
                                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Matriz: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{baixadaMatriz}</span> unidades</p>
                                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Filial: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{baixadaFilial}</span> unidades</p>
                            </div>
                
                        )}
                    </Grid>
                        <Grid item xs={12} md={5}>
                        <div className="app">
                            <div className="row">
                            <div className="mixed-chart">
                                <Chart
                                options={optionsChart}
                                series={seriesBaixadas}
                                type="bar"
                                width="100%"
                                height="200vh"
                                />
                            </div>
                            </div>
                        </div>                
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        paddingBottom: '0px',
                        marginBottom: '0px',
                }}>
                    <LocationCityRoundedIcon color="secondary"/>
                    <p style={{marginLeft: '5px'}} className={classes.textPrimary}>Outras Empresas</p>
                  </div>
                  <Divider />
                  <Grid container spacing={3}>
                     <Grid item xs={12} md={6}>
                        {loading ? (
                            <div>
                                <Skeleton style={{ width: '100%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                                <Skeleton style={{ width: '100%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                            </div>
                            
                        ) : (
                            <div>
                                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Matriz: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{outrasMatriz}</span> unidades</p>
                                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Filial: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{outrasFilial}</span> unidades</p>
                            </div>
                
                        )}
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <div className="app">
                                <div className="row">
                                <div className="mixed-chart">
                                    <Chart
                                    options={optionsChart}
                                    series={seriesOutras}
                                    type="bar"
                                    width="100%"
                                    height="200vh"
                                    />
                                </div>
                                </div>
                            </div>                
                        </Grid>
                    </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        paddingBottom: '0px',
                        marginBottom: '0px',
                    }}>
                    <LocationCityRoundedIcon color="secondary"/>
                    <p style={{marginLeft: '5px'}} className={classes.textPrimary}>Total Empresas</p>
                </div>
                  <Divider />
                  <Grid container spacing={3}>
                     <Grid item xs={12} md={6}>
                        {loading ? (
                            <div>
                                <Skeleton style={{ width: '100%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                                <Skeleton style={{ width: '100%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                            </div>
                            
                        ) : (
                            <div>
                                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Matriz: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{totalMatriz}</span> unidades</p>
                                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Filial: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{totalFilial}</span> unidades</p>
                            </div>
                
                        )}
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <div className="app">
                                <div className="row">
                                <div className="mixed-chart">
                                    <Chart
                                    options={optionsChart}
                                    series={seriesTotal}
                                    type="bar"
                                    width="100%"
                                    height="200vh"
                                    />
                                </div>
                                </div>
                            </div>                
                            </Grid>
                        </Grid>
              </Grid>
        </Grid>

        
        </>
      );

}