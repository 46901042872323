import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Context } from '../Context/AuthContext';
import { AdminContext } from '../Context/AdminContext';
import { FlyProvider } from '../Context/FlyContext';

import Login from '../pages/Login';
import RecuperarSenha from '../pages/RecuperarSenha';
import NovaSenha from '../pages/NovaSenha';
import Users from '../pages/Users';
import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile'
// import Analise from '../pages/Analise'
// import Explorar from '../pages/Explorar'
import Allinone from '../pages/Allinone'
import Estudo from '../pages/Estudos/Estudo'
import EstudoExterno from '../pages/Estudos/EstudoExterno'
import Cliente from '../pages/Estudos/Cliente'
import Admin from '../pages/Admin'

import Teste from '../pages/Teste'

function CustomRoute({ isPrivate, ...rest }) {
  const { loading, authenticated } = useContext(Context);
  
  if (loading) {
    return <CircularProgress />;
  }

  if (isPrivate && !authenticated) {
    return <Redirect to="/login" />
  }

  return <Route {...rest} />;
}

// function AdminRoute({ isPrivate, ...rest }) {  
//   const { loading, authenticated } = useContext(Context);
//   const { isAdmin } = useContext(AdminContext);
  
//   if (loading) {
//     return <CircularProgress />;
//   }
 
//   if ( (isPrivate && !authenticated) || (isPrivate && !isAdmin) ) {
//     return <Redirect to="/login" />
//   } else {
//     return <Route {...rest} />;
//   }

// }

export default function Routes() {
  return (  
    <>      
    <Switch>
    <Route exact path="/cliente" component={Cliente} />
    <Route exact path="/teste" component={Teste} />
    <Route exact path="/cliente/estudo" component={EstudoExterno} />
    <CustomRoute exact path="/login/recuperar-senha" component={RecuperarSenha} />
    <CustomRoute exact path="/login/nova-senha" component={NovaSenha} />
      <FlyProvider>
        <CustomRoute exact isPrivate path="/" component={Dashboard} />
        <CustomRoute exact path="/login" component={Login} />
        <CustomRoute isPrivate exact path="/users" component={Users} />
        <CustomRoute isPrivate exact path="/dashboard" component={Dashboard} />
        <CustomRoute isPrivate exact path="/profile" component={Profile} />
        {/* <CustomRoute isPrivate exact path="/analise" component={Analise} />
        <CustomRoute isPrivate exact path="/explorando" component={Explorar} /> */}
        <CustomRoute isPrivate exact path="/explorar" component={Allinone} />
        <CustomRoute isPrivate exact path="/estudo" component={Estudo} />
        <CustomRoute isPrivate exact path="/administracao" component={Admin} />
        

      </FlyProvider>
    </Switch>
    </>    
  );
}