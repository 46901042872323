import React, {useState, useEffect} from 'react';
import api from '../../../api';
import Skeleton from '@material-ui/lab/Skeleton';

export default function Bairro(props) {
    const [Bairro, setBairro] = useState();
    const [Cidade, setCidade] = useState();
    const [Estado, setEstado] = useState();
   
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            const tokenEstudo = props.tokenEstudo;
            const codBairro = props.cod;

          await api.post('/cliente/bairro', { tokenEstudo, codBairro } )
          .then(function (response) {
            // handle success
            const datajson = response.data
           // console.log("DADOS: "+JSON.stringify(datajson));            
           // console.log(datajson[0].companyLogo)
            // console.log(datajson)
            setBairro(datajson[0].bairro)
            setCidade(datajson[0].cidade)
            setEstado(datajson[0].estado)
            

           setLoading(false)
          })
          .catch(function (error) {
            // handle error
            setLoading(false)
            console.log(error);
          })
          .then(function () {
            // always executed
           
          });
     
     
        })();
      }, []);


    return(
      <div style={{textAlign: 'center',}}>
      {loading ? (
        <Skeleton animation="wave" style={{maxWidth: '40%', marginLeft: '30%', marginRight: '30%' }}/> 
    ) : ( 
        <>{Bairro} - {Cidade} - {Estado}</>
        )}
    </div>
    )

}