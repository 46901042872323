import React, { useState, useEffect, useContext } from 'react';
import './css/noprint.css';
import './css/html.css';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Router } from 'react-router-dom';

import Routes from './routes/';
import history from './history';

import { DarkContext } from './Context/DarkContext';

import MenuAppBar from './Components/MenuBar';



var statusLocalStorageTheme = localStorage.getItem('theme')
if (statusLocalStorageTheme === "true") { statusLocalStorageTheme = true} else { statusLocalStorageTheme = false}

function App() {
  
  const { isDarkModeCont, changeThemeMode } = useContext(DarkContext);
  
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          background: {
            default: isDarkModeCont ? '#4f4f4f' : '#e6e6e6',
          },
          type: isDarkModeCont ? 'dark' : 'light',
          primary: {            
            main: '#1266F1',
          },
          secondary: {           
            main: isDarkModeCont ? '#A3E8E8' : '#6520AD',
          }
        },
        typography: {
          fontFamily: 'Open Sans',
          fontWeightLight: 400,
          fontWeightRegular: 500,
          fontWeightMedium: 600,
          fontWeightBold: 700,
        },
        overrides: {
          MuiPaper: {
            rounded: {
              borderRadius: '14px',
            },
          },
        },
      }, ptBR),
      
    [isDarkModeCont],
  );
  
  

  return (       
      <ThemeProvider theme={theme}>    
      <CssBaseline/>       
      <ToastContainer />
              <MenuAppBar isDarkMode={isDarkModeCont} setIsDarkMode={changeThemeMode}>
                <Router history={history}>
                  <Routes />
                </Router>
              </MenuAppBar>
      </ThemeProvider>    
  );
}

export default App;
