import React, { useState, useEffect, createContext } from 'react';

//import useDarkMode from './hooks/useDarkMode';

const AdminContext = createContext();

function AdminProvider({ children }) {
 
  const [isAdmin, setIsAdmin] = useState(false);

  // useEffect(() => {
  //   console.log("Administrador: "+isAdmin)
  // },[isAdmin])

//   function changeTheAdmin(value) {
//     setIsAdmin(value)
//   }
     
      return (
        <AdminContext.Provider value={{ isAdmin, setIsAdmin }}>
          {children}
        </AdminContext.Provider>
      );
}

export { AdminContext, AdminProvider };
