import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';

import Chart from "react-apexcharts";

import api from '../../../api';


export default function PopCrescAnos(props) {
    const [dados, setDados] = useState([]);
    const [series, setSeries] = useState([{
        name: 'Habitantes',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }])
    const [categories, setCategories] = useState([])
    const codCidade = props.codCidade
    const { isDarkModeCont } = useContext(DarkContext);


    const options = {
        chart: { 
            background: 'transparent',           
        defaultLocale: 'pt-br',
        locales: [{
            name: 'pt-br',
            options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            toolbar: {
                download: 'Download SVG',
                selection: 'Seleção',
                selectionZoom: 'Selecionar Zoom',
                zoomIn: 'Mais Zoom',
                zoomOut: 'Menos Zoom',
                pan: 'Mover',
                reset: 'Reiniciar Zoom',
            }
            }
        }],
        type: 'line',     
        // dropShadow: {
        // enabled: true,
        // color: '#000',
        // top: 3,
        // left: 3,
        // blur: 3,
        // opacity: 0.2
        // },
        // height: 150,
        stacked: true,
        toolbar: {
            show: false
          },
        },
        theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
        //colors: ['#264aff', '#ff33cf'],
        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shade: "dark",
        //     type: "horizontal",
        //     shadeIntensity: 0.5,
        //     inverseColors: true,
        //     opacityFrom: 1,
        //     opacityTo: 1,
        //     stops: [0, 100]
        //   }
        // },
        dataLabels: {
            enabled: false
          },
          stroke: {
            width: 2,
            curve: 'smooth'
          },
          xaxis: {
            categories: categories,
            title: {
              text: 'Ano'
            },
          },
          yaxis: {
            title: {
              text: 'População',
            },
            labels: {
                formatter: function (value) {
                  return value.toLocaleString('pt-BR');
                }
              },
          },
          
        
        
      }
    
    //   const series = [{
    //     name: 'Habitantes',
    //     data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //   }]


       
   useEffect(() => {
    (async () => {
      
      await api.post('/consulta/cidade/populacaoestimativa', {cidade:codCidade})
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        // console.log(JSON.stringify(datajson));
        // console.log(datajson[1].D3C)
        // setDados(datajson)
        
        setCategories([datajson[1].D3C, datajson[2].D3C, datajson[3].D3C, datajson[4].D3C, datajson[5].D3C, datajson[6].D3C, datajson[7].D3C, datajson[8].D3C, datajson[9].D3C, datajson[10].D3C])

        setSeries([{
            name: 'População',
            data: [datajson[1].V, datajson[2].V, datajson[3].V, datajson[4].V, datajson[5].V, datajson[6].V, datajson[7].V, datajson[8].V, datajson[9].V, datajson[10].V]
          }])
        

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });


      // const { data } = console.log(await api.get('/consulta/ibge/teste'));
      // console.log(data)
      // setDados(data);
    })();
  }, []);

//   useEffect(() => {console.log(dados[2])}, [dados])


  
  return (
    <div className="app">
         <div className="row">
           <div className="mixed-chart">
             <Chart
               options={options}
               series={series}
               type="area"
               //width="100%"
               height="400vh"
             />
           </div>
         </div>
       </div>
  );
}
