import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../api';
import Popper from '@material-ui/core/Popper';
import { FlyContext } from '../../../Context/FlyContext';


// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }



export default function ComboBoxCidades({setCodCidadeBox, setLatLngEstudo, voarPara, voarParaLocal}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { setFlyToMap, setFlyToMapInitial, setMalhaState } = useContext(FlyContext);
  const loading = open && options.length === 0;
  
  const PopperMy = function (props) {
    return (<Popper {...props} style={{ zIndex: 10000 }} placement='bottom-start' />)
  }
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const { data } = await api.get('/municipios');
      // console.log(data)
    //   await sleep(1e3); // For demo purposes.
      const countries = await JSON.parse(JSON.stringify(data));
      
      // console.log(countries)
        //console.log((countries.data).map((key) => { return key} ))
     
        if (active) {
            setOptions(countries);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

//   React.useEffect(() => {
//     if (!open) {
//       setOptions([]);
//     }
//   }, [open]);

  return (
    <Autocomplete    
    PopperComponent={PopperMy}
      id="asynchronous-demo"
      style={{ zIndex: 1000000000000000000000000000000000000 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
     // getOptionSelected={(option, value) => console.log( value.id)}
      getOptionLabel={(option) => option.nome_municipio + ' - ' + option.abrev_uf}
      options={options}
      loading={loading}
      onChange={(e, value) => {
          if(value !== null){
            // console.log('do the types match?', value === value.nome_municipio);
            // console.log('do the objects match?', value === value.nome_municipio);
            // console.log('the objects in question', value, value.nome_municipio);
           //console.log("Cidade: "+JSON.stringify(value))
           setCodCidadeBox(value.codigo_municipio_completo)
           setLatLngEstudo([value.latitude, value.longitude])
          //  setFlyToMap([value.latitude, value.longitude])
          voarParaLocal([value.latitude,value.longitude], 10, value.codigo_municipio_completo)
          } else {
            setCodCidadeBox()
            setLatLngEstudo()
            setMalhaState(true)
            voarPara([-12.7187523,-67.3257076], 5)
          }
       
    
      }}
      renderInput={(params) => (
        <TextField
        style={{zIndex: 1000000000000000000000000000000000}}
       
          {...params}
          label="Selecione..."
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment style={{backgroundColor: 'red'}}>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}