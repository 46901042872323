import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Sumario from './Sumario/Sumario';
import api from '../../api';
import CircularProgress from '@material-ui/core/CircularProgress';
import Populacao from '../../Components/Ibge/Bairro/Populacao'
import PopIdade from '../../Components/Ibge/Bairro/PopIdade'
import PopRendimento from '../../Components/Ibge/Bairro/PopRendimento'
import Consumo from '../../Components/Ibge/Bairro/Consumo'

import purple from '@material-ui/core/colors/purple';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '5%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  paperNiveis: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: purple[900],
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  
  imgresponsive: {
    width: '100px',
    height: 'auto',
  },
  mapresponsive: {
    width: '80%',
    height: 'auto',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fontBold: {
    fontWeight: 900,
  },
}));


export default function Bairro(props) {
  const classes = useStyles();
    
  const codBairro = props.cod;
  const distrito = props.distrito;

  const [nomeBairro, setNomeBairro] = useState();
  const [nomeCidade, setNomeCidade] = useState();
  const [nomeEstado, setNomeEstado] = useState();

  
  useEffect(() => {
    (async () => {
      
      await api.post('/consulta/bairro/nomebairro', {bairro:codBairro, distrito})
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        // console.log(datajson)
        setNomeBairro(datajson[0].bairro)
        setNomeCidade(datajson[0].cidade)
        setNomeEstado(datajson[0].estado)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });


    })();
  }, []);

  return (
    <>
    <Accordion style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <Typography className={classes.heading}>Bairro: {nomeBairro ? nomeBairro : <CircularProgress style={{color: 'green'}} size={15} />}</Typography>
                </AccordionSummary>
                <AccordionDetails>

    <div className={classes.root}>
      
      <Grid container spacing={3}>
     
        <Grid item xs={12}>       
          <Paper className={classes.paperNiveis} elevation={16}>
            <h1>Estudo Geográfico, Social e Econômico</h1>
            <h2>Bairro</h2>
            <h1 className={classes.fontBold}>{nomeBairro}</h1>
            <h3>{nomeCidade} - {nomeEstado}</h3>
          </Paper>
        </Grid>
      

        <Sumario estado={null} cidade={null} bairro={codBairro} shopping={null} />
        

        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionpopulacaobairro">
          <Paper className={classes.paper} elevation={16}>

            <h2>População</h2>

            <Populacao codBairro={codBairro} distrito={distrito} />    

          </Paper>
        </Grid>



        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionidadebairro">
          <Paper className={classes.paper} elevation={16}>
            <h2>Idade</h2>
            <PopIdade codBairro={codBairro} distrito={distrito} />
          </Paper>
        </Grid>



        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionrendimentobairro">
          <Paper className={classes.paper} elevation={16}>
            <h2>Rendimento</h2>
            <PopRendimento codBairro={codBairro} distrito={distrito} />
          </Paper>
        </Grid>


        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionconsumobairro">
          <Paper className={classes.paper} elevation={16}>
            <h2>Consumo</h2>
            <Consumo codBairro={codBairro} distrito={distrito} />
          </Paper>
        </Grid>

   


      </Grid>
    
            
    </div>
    </AccordionDetails>
            </Accordion>
            </>
  );
}
