import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ShareIcon from '@material-ui/icons/Share';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import LinkIcon from '@material-ui/icons/Link';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import {
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";

  
  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";

  const useStyles = makeStyles((theme) => ({
    root: {
        margin: '5%',
      },
     
    rootAbas: {
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '100px'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    },
    fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    },
    paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    
    },
    modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',  
    margin: theme.spacing(3),
    padding: theme.spacing(3),    
    },
    iconMargin: {
        marginRight: '5px',
        marginLeft: '5px'
    },
    noMargin: {
        margin: '0px',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    btnAbrirEstudo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',  
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    backgroundColor: '#8702a8',  
    float: 'left',     
    marginLeft: '5px',             
    marginRight: '5px',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    cursor: 'pointer',
    },
    btnLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',  
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    backgroundColor: '#2756A3',  
    float: 'left',     
    marginLeft: '5px',             
    marginRight: '5px',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    cursor: 'pointer',
    },
    titulo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',  
    },
    tabsEstudo: {
        marginTop: '2px',
    },
    tabsGeral: {
        paddingBottom: '30px',
    },
    popoverText: {
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '5px',
        marginBottom: '5px',
        // backgroundColor: '#212121',
        // color: 'white',
        // overflow: 'no'
    }
      
}));


export default function EstudoShare(props) {
    const classes = useStyles();
    
    const urlInterno = 'https://sistema.geotaker.com.br/estudo/?PIEBkUYblOLjgtdYbnmhTDG='+props.tokenShare
    const urlExterno = 'https://sistema.geotaker.com.br/cliente/'+"?HdsfgFdfgaseFoiUudtgfdHdsfgFdfgaseFoiUudtgfd="+props.tokenShare;
    
    const copyLink = () => {
        navigator.clipboard.writeText(urlExterno)
        toast.success("Link copiado!");
    }  

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => { setAnchorEl(event.currentTarget); };
    const handlePopoverClose = () => { setAnchorEl(null); };
    const open = Boolean(anchorEl);

    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const handlePopoverOpen1 = (event) => { setAnchorEl1(event.currentTarget); };
    const handlePopoverClose1 = () => { setAnchorEl1(null); };
    const open1 = Boolean(anchorEl1);

    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const handlePopoverOpen2 = (event) => { setAnchorEl2(event.currentTarget); };
    const handlePopoverClose2 = () => { setAnchorEl2(null); };
    const open2 = Boolean(anchorEl2);

    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const handlePopoverOpen3 = (event) => { setAnchorEl3(event.currentTarget); };
    const handlePopoverClose3 = () => { setAnchorEl3(null); };
    const open3 = Boolean(anchorEl3);

    const [anchorEl4, setAnchorEl4] = React.useState(null);
    const handlePopoverOpen4 = (event) => { setAnchorEl4(event.currentTarget); };
    const handlePopoverClose4 = () => { setAnchorEl4(null); };
    const open4 = Boolean(anchorEl4);

    const [anchorEl5, setAnchorEl5] = React.useState(null);
    const handlePopoverOpen5 = (event) => { setAnchorEl5(event.currentTarget); };
    const handlePopoverClose5 = () => { setAnchorEl5(null); };
    const open5 = Boolean(anchorEl5);

    const [anchorEl6, setAnchorEl6] = React.useState(null);
    const handlePopoverOpen6 = (event) => { setAnchorEl6(event.currentTarget); };
    const handlePopoverClose6 = () => { setAnchorEl6(null); };
    const open6 = Boolean(anchorEl6);

    const [anchorEl7, setAnchorEl7] = React.useState(null);
    const handlePopoverOpen7 = (event) => { setAnchorEl7(event.currentTarget); };
    const handlePopoverClose7 = () => { setAnchorEl7(null); };
    const open7 = Boolean(anchorEl7);

    const [anchorEl8, setAnchorEl8] = React.useState(null);
    const handlePopoverOpen8 = (event) => { setAnchorEl8(event.currentTarget); };
    const handlePopoverClose8 = () => { setAnchorEl8(null); };
    const open8 = Boolean(anchorEl8);

    const [anchorEl9, setAnchorEl9] = React.useState(null);
    const handlePopoverOpen9 = (event) => { setAnchorEl9(event.currentTarget); };
    const handlePopoverClose9 = () => { setAnchorEl9(null); };
    const open9 = Boolean(anchorEl9);

    



    return (
        <>

        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openShare}
        onClose={props.handleCloseShare}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openShare}>
        
            <Paper className={classes.paper} elevation={10}>
            
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    >   

                    <div style={{ width: '100%', margin: 0 }}>
                        <Box display="flex" p={1}>
                            <Box flexGrow={1} >
                            <div  style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        margin: '0px'
                                    }}
                                    className={classes.noMargin}
                                    > 
                        
                                        <ShareIcon size="small" />
                                        <h3 style={{marginLeft: '10px'}} className={classes.noMargin}>Abrir ou Compartilhar</h3>
                                    
                            </div>
                            </Box>
                            <Box>
                                <CloseRoundedIcon onClick={props.handleCloseShare} style={{cursor: 'pointer'}}/>
                            </Box>
                            
                        </Box>
                        <Divider />
                    </div>

                
                </Grid>
                <Grid>
                <div>
      
    </div>
                <div 
                    className={classes.btnAbrirEstudo}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                        <OpenInBrowserIcon 
                            onClick={() => window.open(urlInterno, '_blank')} 
                            style={{color: 'white'}}     
                                                
                        />
                       
                       <Popover
                            style={{ pointerEvents: 'none', marginTop: '5px'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Abrir estudo em outra aba</small>
                        </Popover>
                    </div>
                    
                    <div 
                        className={classes.btnLink}
                        aria-owns={open1 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen1}
                        onMouseLeave={handlePopoverClose1}
                        >
                        <LinkIcon 
                            onClick={() => copyLink()} 
                            style={{color: 'white', transform: 'rotate(135deg)'}} 
                        />
                        <Popover
                            style={{ pointerEvents: 'none', marginTop: '5px'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open1}
                            anchorEl={anchorEl1}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose1}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Copiar link para compartilhamento</small>
                        </Popover>
                    </div>
                    
                    <WhatsappShareButton 
                        url={urlExterno} 
                        className={classes.iconMargin}
                        aria-owns={open2 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen2}
                        onMouseLeave={handlePopoverClose2}
                        >
                        <WhatsappIcon size={40} round={true} />
                        <Popover
                            style={{ pointerEvents: 'none'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open2}
                            anchorEl={anchorEl2}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose2}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Compartilhar via WhatsApp</small>
                        </Popover>
                    </WhatsappShareButton>
                    <TelegramShareButton 
                        url={urlExterno} 
                        className={classes.iconMargin}
                        aria-owns={open3 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen3}
                        onMouseLeave={handlePopoverClose3}
                    >
                        <TelegramIcon size={40} round={true} />
                        <Popover
                            style={{ pointerEvents: 'none'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open3}
                            anchorEl={anchorEl3}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose3}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Compartilhar via Telegram</small>
                        </Popover>
                    </TelegramShareButton>
                    <EmailShareButton
                        url={urlExterno} 
                        className={classes.iconMargin}
                        aria-owns={open4 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen4}
                        onMouseLeave={handlePopoverClose4}
                    >
                        <EmailIcon size={40} round={true} />
                        <Popover
                            style={{ pointerEvents: 'none'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open4}
                            anchorEl={anchorEl4}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose4}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Compartilhar via Email</small>
                        </Popover>
                    </EmailShareButton>
                    <FacebookShareButton
                        url={urlExterno} 
                        className={classes.iconMargin}
                        aria-owns={open5 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen5}
                        onMouseLeave={handlePopoverClose5}
                    >
                        <FacebookIcon size={40} round={true} />
                        <Popover
                            style={{ pointerEvents: 'none'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open5}
                            anchorEl={anchorEl5}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose5}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Compartilhar via Facebook</small>
                        </Popover>
                    </FacebookShareButton>
                    <FacebookMessengerShareButton
                        url={urlExterno} 
                        className={classes.iconMargin}
                        aria-owns={open6 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen6}
                        onMouseLeave={handlePopoverClose6}
                    >
                        <FacebookMessengerIcon size={40} round={true} />
                        <Popover
                            style={{ pointerEvents: 'none'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open6}
                            anchorEl={anchorEl6}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose6}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Compartilhar via Facebook Messenger</small>
                        </Popover>
                    </FacebookMessengerShareButton>
                    <LinkedinShareButton
                        url={urlExterno} 
                        className={classes.iconMargin}
                        aria-owns={open7 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen7}
                        onMouseLeave={handlePopoverClose7}
                    >
                        <LinkedinIcon size={40} round={true} />
                        <Popover
                            style={{ pointerEvents: 'none'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open7}
                            anchorEl={anchorEl7}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose7}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Compartilhar via LinkedIn</small>
                        </Popover>
                    </LinkedinShareButton>
                    <TwitterShareButton
                        url={urlExterno} 
                        className={classes.iconMargin}
                        aria-owns={open8 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen8}
                        onMouseLeave={handlePopoverClose8}
                    >
                        <TwitterIcon size={40} round={true} />
                        <Popover
                            style={{ pointerEvents: 'none'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open8}
                            anchorEl={anchorEl8}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose8}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Compartilhar via Twitter</small>
                        </Popover>
                    </TwitterShareButton>
                    {/* <PinterestShareButton
                        url={urlExterno} 
                        className={classes.iconMargin}
                        aria-owns={open9 ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen9}
                        onMouseLeave={handlePopoverClose9}
                    >
                        <PinterestIcon size={40} round={true} />
                        <Popover
                            style={{ pointerEvents: 'none'}}
                            id="mouse-over-popover"
                            sx={{
                            pointerEvents: 'none',
                            }}
                            open={open9}
                            anchorEl={anchorEl9}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            onClose={handlePopoverClose9}
                            disableRestoreFocus
                        >
                            <small className={classes.popoverText}>Compartilhar via Pinterest</small>
                        </Popover>
                    </PinterestShareButton> */}
                </Grid>
                
                
              
                
            </Paper>
         
        </Fade>
        
      </Modal>
        
      
    </>
    )

}