import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../api';
import Popper from '@material-ui/core/Popper';
import { FlyContext } from '../../../Context/FlyContext';


// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }
const options2 = [{
    'title':'Option 1',
    'value':'10'
}, {
    'title':'Option 2',
    'value':'20'
}];



export default function ComboBoxShoppings({setCodShoppingBox, setButtonCheckShoppingBairro, setStateBairroShop, stateBairroShop, stateBairroShopAtual, setLatLngEstudo, voarPara }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { flyToMap, setFlyToMap } = useContext(FlyContext);
  const loading = open && options.length === 0;
  
  const PopperMy = function (props) {
    return (<Popper {...props} style={{ zIndex: 10000 }} placement='bottom-start' />)
  }
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const { data } = await api.get('/shoppings');
      // console.log(data)
    //   await sleep(1e3); // For demo purposes.
      const dataShoppings = await JSON.parse(JSON.stringify(data));
      
    //   console.log(dataShoppings)
        //console.log((dataShoppings.data).map((key) => { return key} ))
     
        if (active) {
            setOptions(dataShoppings);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

//   React.useEffect(() => {
//     if (!open) {
//       setOptions([]);
//     }
//   }, [open]);

  return (
    <Autocomplete    
    PopperComponent={PopperMy}
      id="asynchronous-demo"
      style={{ zIndex: 1000000000000000000000000000000000000 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
     // getOptionSelected={(option, value) => console.log( value.id)}
      getOptionLabel={(option) => option.nome + ': '+  option.cidade + ' - ' + option.uf}
      options={options}
      loading={loading}
      onChange={(e, value) => {
          if(value !== null){
            // console.log('do the types match?', value === value.nome_municipio);
            // console.log('do the objects match?', value === value.nome_municipio);
            // console.log('the objects in question', value, value.nome_municipio);
            // console.log('the objects in question', value)
            setCodShoppingBox({
                id: value.id,
                cod_ibge: value.cod_ibge,
                cod_ibge_bairro: value.cod_ibge_bairro
            })

            if(value.cod_ibge_bairro === "") {
                setStateBairroShop(false)
                setButtonCheckShoppingBairro(true)
            }

            if(value.cod_ibge_bairro !== "") {
                setStateBairroShop(stateBairroShopAtual)
                setButtonCheckShoppingBairro(false)
            }

            setLatLngEstudo([value.latitude, value.longitude])
            
          //  setFlyToMap([value.latitude, value.longitude])
            voarPara([value.latitude,value.longitude], 17, true)
          } else {
            setCodShoppingBox()
            setLatLngEstudo()
            setStateBairroShop(stateBairroShopAtual)
            setButtonCheckShoppingBairro(false)
            voarPara([-15.2703821,-55.3690471], 5)
          }
       
    
      }}
      renderInput={(params) => (
        <TextField
        style={{zIndex: 1000000000000000000000000000000000}}
       
          {...params}
          label="Selecione..."
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment style={{backgroundColor: 'red'}}>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}