import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';


import Chart from "react-apexcharts";

import api from '../../../api';

const useStyles = makeStyles((theme) => ({
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
}))

export default function MoradoresDomicilio(props) {
    const classes = useStyles();
    const [dadosLabel, setDadosLabel] = useState([]);
    const [dadosSeries, setDadosSeries] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const codEstado = props.codEstado
    const { isDarkModeCont } = useContext(DarkContext);

    const [loading, setLoading] = useState(true)

    const [umMorador, setUmMorador] = useState();
    const [doisMoradores, setDoisMoradores] = useState();
    const [tresMoradores, setTresMoradores] = useState();
    const [quatroMoradores, setQuatroMoradores] = useState();
    const [cincoMoradores, setCincoMoradores] = useState();
    const [seisMoradores, setSeisMoradores] = useState();
    const [seteMoradores, setSeteMoradores] = useState();
    const [oitoMoradores, setOitoMoradores] = useState();


    useEffect(() => {
      (async () => {
      
        await api.post('/consulta/estado/moradores', {estado:codEstado})
        .then(function (response) {
          // handle success
          
          const datajson = response.data
          setDadosLabel([datajson[2].D5N, datajson[3].D5N, datajson[4].D5N, datajson[5].D5N, datajson[6].D5N, datajson[7].D5N, datajson[8].D5N, datajson[9].D5N])
          setDadosSeries([Number(datajson[2].V), Number(datajson[3].V), Number(datajson[4].V), Number(datajson[5].V), Number(datajson[6].V), Number(datajson[7].V), Number(datajson[8].V), Number(datajson[9].V)])
          
          setUmMorador(Number(datajson[2].V).toLocaleString('pt-BR'));
          setDoisMoradores(Number(datajson[3].V).toLocaleString('pt-BR'));
          setTresMoradores(Number(datajson[4].V).toLocaleString('pt-BR'));
          setQuatroMoradores(Number(datajson[5].V).toLocaleString('pt-BR'));
          setCincoMoradores(Number(datajson[6].V).toLocaleString('pt-BR'));
          setSeisMoradores(Number(datajson[7].V).toLocaleString('pt-BR'));
          setSeteMoradores(Number(datajson[8].V).toLocaleString('pt-BR'));
          setOitoMoradores(Number(datajson[9].V).toLocaleString('pt-BR'));
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        
        });
  
  
      })();
    }, []);
  

    const options = {
        chart: {
            background: 'transparent', 
            height: 350,
            type: 'bar',
            events: {
              click: function(chart, w, e) {
                // console.log(chart, w, e)
              }
            },
            toolbar: {
                show: false
              },
          },
          //colors: ['#264aff', '#ff33cf'],
          theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: dadosLabel,
            labels: {
              style: {
              // colors: ['#264aff', '#ff33cf'],
                fontSize: '12px'
              }
            }
          },       
          yaxis: {
            title: {
              text: 'Domicílios',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('pt-BR');
              }
            },
          },
    }

    const series = [{
      name: 'Domicílios',
        data: dadosSeries
      }]

    return (
      <>

<Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <div style={{textAlign: 'left'}}>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                  }}>
                      <PersonRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>01 Morador: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{umMorador}</span> domicílios</p>                      
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <PeopleAltRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>02 Moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{doisMoradores}</span> domicílios</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <PeopleAltRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>03 moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{tresMoradores}</span> domicílios</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                      <PeopleAltRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>04 Moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{quatroMoradores}</span> domicílios</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <PeopleAltRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>05 Moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{cincoMoradores}</span> domicílios</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <PeopleAltRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>06 Moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{seisMoradores}</span> domicílios</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <PeopleAltRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>07 Moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{seteMoradores}</span> domicílios</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <PeopleAltRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>08 Moradores ou mais: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{oitoMoradores}</span> domicílios</p>
                      )}
                  </div>
                </div>
                
              </Grid>
              <Grid item xs={12} md={7}>
                <div className="app">
                  <div className="row">
                    <div className="mixed-chart">
                      <Chart
                        options={options}
                        series={series}
                        type="bar"
                        width="100%"
                        height="500vh"
                      />
                    </div>
                  </div>
                </div>                
              </Grid>

            </Grid> 

        
      </>     
      );

}