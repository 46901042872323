import React, {useContext} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../api';
import Popper from '@material-ui/core/Popper';
import { FlyContext } from '../../../Context/FlyContext';




export default function ComboBoxBairros({setCodBairroBox, setBairroDistrito, setLatLngEstudo, voarPara}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { flyToMap, setFlyToMap } = useContext(FlyContext);
  const loading = open && options.length === 0;
  
  const PopperMy = function (props) {
    return (<Popper {...props} style={{ zIndex: 10000 }} placement='bottom-start' />)
  }
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const { data } = await api.get('/bairros');
      // console.log(data)
    
      const bairros = await JSON.parse(JSON.stringify(data));
      
      // console.log(countries)
        //console.log((countries.data).map((key) => { return key} ))
     
        if (active) {
            setOptions(bairros);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

//   React.useEffect(() => {
//     if (!open) {
//       setOptions([]);
//     }
//   }, [open]);

  return (
    <Autocomplete    
    PopperComponent={PopperMy}
      id="asynchronous-demo"
      style={{ zIndex: 1000000000000000000000000000000000000 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
     // getOptionSelected={(option, value) => console.log( value.id)}
      getOptionLabel={(option) => option.cidade + ' - ' + option.estado + ': ' + option.bairro}
      options={options}
      loading={loading}
      onChange={(e, value) => {
          if(value !== null){
            console.log(value)
            // console.log('do the types match?', value === value.nome_municipio);
            // console.log('do the objects match?', value === value.nome_municipio);
            // console.log('the objects in question', value, value.nome_municipio);
          //  console.log(JSON.stringify(value))
           setCodBairroBox(value.codigo)
           setBairroDistrito(value.distrito)
           setLatLngEstudo([value.latitude, value.longitude])
          //  setFlyToMap([value.latitude, value.longitude])
            voarPara([value.latitude,value.longitude], 15, true)
          } else {
            setCodBairroBox()
            setLatLngEstudo()
            setBairroDistrito()
            voarPara([-12.7187523,-67.3257076], 5)
          }
       
    
      }}
      renderInput={(params) => (
        <TextField
        style={{zIndex: 1000000000000000000000000000000000}}
       
          {...params}
          label="Selecione..."
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment style={{backgroundColor: 'red'}}>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}