import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../Context/DarkContext';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chart from "react-apexcharts";
import Paper from '@material-ui/core/Paper';
import Sumario from './Sumario/Sumario';
import api from '../../api';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

import Populacao from '../../Components/Ibge/Bairro/Populacao'
import PopIdade from '../../Components/Ibge/Bairro/PopIdade'
import PopRendimento from '../../Components/Ibge/Bairro/PopRendimento'
import Consumo from '../../Components/Ibge/Bairro/Consumo'
import nomeBairro from '../../Components/Ibge/Setor/nomeBairro'
import PopulacaoResidenteCorRaca from '../../Components/Ibge/Setor/PopulacaoResidenteCorRaca'
import PopIdadeSetor from '../../Components/Ibge/Setor/PopIdadeSetor'
import PopRendimentoSetor from '../../Components/Ibge/Setor/PopRendimentoSetor'
import ConsumoSetor from '../../Components/Ibge/Setor/ConsumoSetor'
import EstudantesSetor from '../../Components/Ibge/Setor/EstudantesSetor'
import ImoveisSetor from '../../Components/Ibge/Setor/ImoveisSetor'

import purple from '@material-ui/core/colors/purple';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '5%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  paperNiveis: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: purple[900],
  },
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  
  imgresponsive: {
    width: '100px',
    height: 'auto',
  },
  mapresponsive: {
    width: '80%',
    height: 'auto',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fontBold: {
    fontWeight: 900,
  },
}));


export default function Setor(props) {
  const classes = useStyles();
  const { isDarkModeCont } = useContext(DarkContext);
    
  const codSetor = props.cod;
 
  const [nomeBairro, setNomeBairro] = useState();
  const [nomeCidade, setNomeCidade] = useState();
  const [nomeEstado, setNomeEstado] = useState();
  const [codBairro, setCodBairro] = useState();
  const [descSetor, setDescSetor] = useState();
  const [descSetorMais, setDescSetorMais] = useState();
  const [popTotal, setPopTotal] = useState();
  const [popHomem, setPopHomem] = useState();
  const [popMulher, setPopMulher] = useState();
  const [dadosSexo, setDadosSexo] = useState([0, 0]); 
  const [densDemografica, setDensDemografica] = useState();
  
  var codEst = {
    '11': "RO",
    '12': "AC",
    '13': "AM",
    '14': "RR",
    '15': "PA",
    '16': "AP",
    '17': "TO",
    '21': "MA",
    '22': "PI",
    '23': "CE",
    '24': "RN",
    '25': "PB",
    '26': "PE",
    '27': "AL",
    '28': "SE",
    '29': "BA",
    '31': "MG",
    '32': "ES",
    '33': "RJ",
    '35': "SP",
    '41': "PR",
    '42': "SC",
    '43': "RS",
    '50': "MS",
    '51': "MT",
    '52': "GO",
    '53': "DF",
  }
  

  useEffect(() => {
    (async () => {
      
      await api.post('/consulta/setor', {setor:codSetor})
      .then(function (response) {
        // handle success
              
        const datajson = response.data      
        
        var nomeCid = (datajson.data[0][0]).toLowerCase()
        var nomeCidCaptalize = nomeCid.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
       
        setNomeCidade(nomeCidCaptalize)
        setNomeEstado(codEst[(codSetor).substring(0,2)])
        setDescSetor(datajson.data[2][0])
        setDescSetorMais(datajson.data[2][1])
        setPopTotal(datajson.data[2][3])
        setPopHomem(datajson.data[2][4].toLocaleString('pt-BR'))
        setPopMulher(datajson.data[2][5].toLocaleString('pt-BR'))
        setDadosSexo([Math.round(Number((datajson.data[2][4]/datajson.data[2][3])*100)), Math.round(Number((datajson.data[2][5]/datajson.data[2][3])*100))])
        

        setDensDemografica(Math.round(datajson.data[2][3]/(datajson.data[2][6]/1000000)*100)/100)
        const dataBairro = datajson.data[4][1]
        
        setCodBairro(dataBairro)
      
        const verifyBairro = dataBairro.substr(7,9)
        if(verifyBairro != '000') {
            api.post('/consulta/bairro/nomebairro', {bairro: dataBairro})
            .then(function (response) {
              // handle success
             
              const datajson = response.data
              // console.log(datajson)
              setNomeBairro(datajson[0].bairro)
              
             
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
             
            });
          } 
          
   


      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });


    })();
  }, []);



  const options = {
    chart: { 
        background: 'transparent',
        type: 'donut',     
        stacked: true,    
    },
    theme: {
        mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
    },
    colors: ['#264aff', '#ff33cf'],  
    dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      
      legend: {
        show: true,
        position: "bottom",     
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
        }
      },
      labels: ['Homens', 'Mulheres'],
  }
 
  const series = dadosSexo



  return (
    <>
    <Accordion style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <Typography className={classes.heading} style={{fontWeight: 900}}>Setor </Typography>
                {nomeCidade ? (
                  <Typography style={{marginLeft: '20px', fontSize: '13px', marginTop: '3px'}}>
                    Cidade: <span style={{fontWeight: 600}}>{nomeCidade} - {nomeEstado}</span>
                  </Typography>
                ) : (
                  <CircularProgress style={{color: 'green', marginLeft: '20px', marginTop: '3px'}} size={15} />
                )}

                {nomeBairro ? (
                  <Typography style={{marginLeft: '20px', fontSize: '13px', marginTop: '3px'}}>
                    Bairro: <span style={{fontWeight: 600}}>{nomeBairro}</span>
                  </Typography>
                ) : (
                  null
                )}  
                </AccordionSummary>
                <AccordionDetails>

    <div className={classes.root}>
      
      <Grid container spacing={3}>
     
        <Grid item xs={12}>       
          <Paper className={classes.paperNiveis} elevation={16}>
            <h1>Estudo Geográfico, Social e Econômico</h1>
            <h2>Setor</h2>
            <h2 className={classes.fontBold}>{nomeCidade} - {nomeEstado}</h2>
            <h3>{nomeBairro}</h3>
          </Paper>
        </Grid>
      

        <Sumario estado={null} cidade={null} bairro={null} shopping={null} setor={true} />
        

        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionsobresetor">
          <Paper className={classes.paper} elevation={16}>

            <h2>Sobre</h2>
           
            <p className={classes.textPrimary} style={{textAlign: 'left'}}>Descrição do Setor</p>
            <p className={classes.textSecond} style={{textAlign: 'left', marginBlockStart: 0, marginBlockEnd: 0}}>Localizado na cidade {nomeCidade} - {nomeEstado} {nomeBairro ? "e no bairro " + nomeBairro : null}.</p>
            <p className={classes.textSecond} style={{textAlign: 'left', marginBlockStart: 0, marginBlockEnd: 0}}>{descSetor}</p>
            <p className={classes.textSecond} style={{textAlign: 'left', marginBlockStart: 0, marginBlockEnd: 0}}>{descSetorMais}</p>
          </Paper>
        </Grid>

        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionpopulacaosetor">
          <Paper className={classes.paper} elevation={16}>

            <h2>População</h2>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Setor</p>
                <Divider style={{marginBottom: '30px'}} />
                  
                        <div style={{textAlign: 'left'}}>
                            <p style={{marginLeft: '15px'}} className={classes.textSecond}>População Total: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{popTotal} pessoas</span></p>
                        </div>
                     

                <Divider />
                   
                        <div style={{textAlign: 'left'}}>
                            <p style={{marginLeft: '15px'}} className={classes.textSecond}>População Homem: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{popHomem} pessoas</span></p>
                        </div>
                     

                <Divider />
                  
                        <div style={{textAlign: 'left'}}>
                            <p style={{marginLeft: '15px'}} className={classes.textSecond}>População Mulher: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{popMulher} pessoas</span></p>
                        </div>

                <Divider />
                  
                        <div style={{textAlign: 'left'}}>
                            <p style={{marginLeft: '15px'}} className={classes.textSecond}>Estudantes: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{popTotal ? <EstudantesSetor codSetor={codSetor} popSetor={popTotal} /> : <CircularProgress style={{color: 'green', marginLeft: '20px', marginTop: '3px'}} size={15} /> } pessoas</span></p>
                        </div>

                <Divider />
                  
                        <div style={{textAlign: 'left'}}>
                            <p style={{marginLeft: '15px'}} className={classes.textSecond}>Densidade Demográfica: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{densDemografica} Hab/km²</span></p>
                        </div>

                
                    
                </Grid>
                <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Homem x Mulher</p>
                <Divider style={{marginBottom: '30px'}} />
                

                <div className="app">
                    <div className="row">
                        <div className="mixed-chart">
                        <Chart
                            options={options}
                            series={series}
                            type="pie"
                            width="100%"
                            height="250vh"
                        />
                        </div>
                    </div>
                </div>


                </Grid>

                <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População por Cor ou Raça</p>
                <Divider style={{marginBottom: '30px'}} />
                  <PopulacaoResidenteCorRaca codSetor={codSetor} />
                </Grid>
            </Grid>



          </Paper>
        </Grid>

        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionidadesetor">
          <Paper className={classes.paper} elevation={16}>
            <h2>Idade</h2>
            <PopIdadeSetor codSetor={codSetor} />
          </Paper>
        </Grid>



        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionrendimentosetor">
          <Paper className={classes.paper} elevation={16}>
            <h2>Rendimento</h2>
            {codBairro ? (
              <PopRendimentoSetor codBairro={codBairro} />
            ) : null}
            
          </Paper>
        </Grid>


        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionconsumosetor">
          <Paper className={classes.paper} elevation={16}>
            <h2>Consumo</h2>
            {popTotal ? <ConsumoSetor populacaoSetor={popTotal} /> : null}
          </Paper>
        </Grid>





        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionimoveissetor">
          <Paper className={classes.paper} elevation={16}>

            <h2>Imóveis</h2>

                  
                        
                  <ImoveisSetor codSetor={codSetor} />
                
              


          </Paper>
        </Grid>

   


      </Grid>
    
            
    </div>
    </AccordionDetails>
            </Accordion>
            </>
  );
}
