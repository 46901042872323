import React, { useState, useEffect, useContext } from 'react';
import api from '../../../api';


export default function EstudantesSetor(props) {
    const [estudantes, setEstudantes] = useState();
   

    const codSetor = props.codSetor
    const popSetor = props.popSetor

    useEffect(() => {
        (async () => {
          
          await api.post('/consulta/setor/estudantes', {setor:codSetor, popSetor: popSetor})
          .then(function (response) {
            // handle success
          
            const datajson = response.data
           
            setEstudantes(datajson)
         
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
           
          });
    
    
        })();
      }, []);

    return (
        <>
        {estudantes}
        </>
    )
}