import React, {useMemo, useState, useEffect, useContext} from 'react';
import {useDropzone} from 'react-dropzone';
import { LogoContext } from '../../../../Context/LogoContext';
import api from '../../../../api';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

import { DarkContext } from '../../../../Context/DarkContext';




const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const thumbsContainer = {
  
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', 
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  // width: '50vh',
  maxWidth: '500px',
  // height: 'auto',
  maxHeight: '500px',
  padding: 4,
  boxSizing: 'border-box'
};
const thumbSalvar = {
  paddingTop: 20,
  paddingBottom: 50,
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: '100%',
  height: '100%'
};  




export default function StyledDropzone({ setOpenLogo }) {
  const { LogoCompany, setLogoCompany } = useContext(LogoContext);
  const { isDarkModeCont } = useContext(DarkContext);
  const [files, setFiles] = useState([]);



  function EnviarImagem() {

    let file = files[0]
    let formdata = new FormData()
    const token = localStorage.getItem('token');

    formdata.append('avatar', file)
    formdata.append('tokenUser', token)

    // console.log(file)

    api.post('/administracao/logo/adicionar', formdata, {
      onUploadProgress: (event) => {
        let progress = Math.round(
          (event.loaded * 100) / event.total
        );

        // console.log(`A imagem está ${progress}% carregada... `);  
      }, 
    })
      .then(function (response) {
        // handle success
       
        const datajson = response.data  
        setLogoCompany(datajson.fileNameSaved)
        setOpenLogo(false)
        toast.success("Logo salva com sucesso!");
        
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });
  }

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: isDarkModeCont ? '#fafafa' : '#e3e3e3',
    borderStyle: 'dashed',
    backgroundColor: isDarkModeCont ? '#424242' : '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    maxFiles:1,
    accept: 'image/*',
    maxSize	: 2 * 1024 * 1024,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    },
    onDropRejected: () => {toast.error("O arquivo deve ser uma imagem e ter no máximo 2 MB.");}
  });

  const thumbs = files.map(file => (
    <div key={'divComum'} style={thumbSalvar}>
    <div style={thumb} key={file.name}>
        <div style={thumbInner}>
            <img
            src={file.preview}
            style={img}
            />
        </div>
        </div>
        <div>  
          <Button        
              variant="contained"                            
              color="primary"                            
              startIcon={<SaveRoundedIcon />}
              size="small"  
              style={{marginRight: '10px'}} 
              onClick={() => EnviarImagem()}                                                                   
          >
              Salvar
          </Button>
          
        </div>
    </div>
    ));

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach(file => URL.revokeObjectURL(file.preview));
      }, [files]);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);


  // useEffect(() => {console.log(files)}, [files])

  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Arraste e solte a imagem aqui ou clique para selecionar o arquivo.</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </div>
  );
}