import axios from 'axios';

const client = sessionStorage.getItem('client');
const token = localStorage.getItem('token');

var tokengeral = '';

if(client) {
  var tokengeral = { 'Authorization': `Bearer ${JSON.parse(client)}` };
} 

if(token) {
  var tokengeral = { 'Authorization': `Bearer ${JSON.parse(token)}` };
} 

export default axios.create({
  baseURL: 'https://api.geotaker.com.br',
  headers: tokengeral
});