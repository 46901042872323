import React, { useContext, useState, useEffect } from 'react';
import { DarkContext } from '../../../Context/DarkContext';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import icon from "../constants";
import '../../../js/leaflet/leaflet.css'
import '../../../js/leaflet/leaflet.js'
import '../../../js/sinopsesetores/Control.Geocoder.css'
import '../../../js/sinopsesetores/Control.Geocoder.js'
import '../../../js/sinopsesetores/jquery-1.6.2.js'
import '../../../js/sinopsesetores/jquery-ui-1.8.14.custom.min.js'


var shapes = new Array();
var map;

export default function MapaEstudo(props) {
    const { isDarkModeCont } = useContext(DarkContext);
    const [cartoDecod, setCartoDecod] = useState();

    var codSetor = props.codSetor;

    useEffect(()=> {
        if(cartoDecod != null) {
            // console.log(cartoDecod)
            cartograma.desenha(cartoDecod)
        }
        
        }, [cartoDecod])



        function initmap() {
            var padrao   = L.tileLayer('https://mt1.google.com/vt/yrs=y&x=1325&y=3143&z=cn&x={x}&y={y}&z={z}'),
            satelite  = L.tileLayer('https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'),
            hibrido = L.tileLayer('https://www.google.cn/maps/vt?lyrs=y&x=1325&y=3143&z=cn&x={x}&y={y}&z={z}'),
            transito = L.tileLayer('https://mt0.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}'),
            bicicleta = L.tileLayer('http://mt1.google.com/vt/lyrs=m@121,bike&hl=pt&x={x}&y={y}&z={z}'),
            transpublico = L.tileLayer('http://mt1.google.com/vt/lyrs=m@121,transit|vm:1&hl=en&amp;opts=r&x={x}&y={y}&z={z}'),
            relevo = L.tileLayer('http://mt0.google.com/vt/lyrs=p&hl=pt&x={x}&y={y}&z={z}'),
            relevomais = L.tileLayer('http://mt0.google.com/vt/lyrs=t&hl=pt&x={x}&y={y}&z={z}'),
            cartografico = L.tileLayer('https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'),
            pretoebranco = L.tileLayer('https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png'),
            dark = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png')
            
        
            
            map = new L.Map("map_geotaker", {
                center: props.cordenadas.coord,
                zoom: props.cordenadas.zoom,
                layers: [padrao],
                zoomControl: false,
                attributionControl: false,
                scrollWheelZoom: false
            });
            
            
            //OPEN STREET MAPS
            var osmUrl='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
            var osmAttrib='Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> ALOW contributors';
            //var layer = new L.TileLayer(osmUrl, {minZoom: 4, attribution: osmAttrib,opacity: 0.5});		
            // var layer = new L.tileLayer('https://mt1.google.com/vt/yrs=y&x=1325&y=3143&z=cn&x={x}&y={y}&z={z}', {
            // 	attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ALOOOWW contributors'
            // }).addTo(map);
                
            var baseLayers = {
                "Padrão": padrao,
                "Satélite": satelite,
                "Hibrido": hibrido,
                "Trânsito": transito,
                "Bicicleta": bicicleta,
                "Transporte Público": transpublico,
                "Relevo": relevo,
                "Relevo+": relevomais,
                "Cartográfico": cartografico,
                "Preto e Branco": pretoebranco,
                "Escuro": dark
            };
            L.control.layers(baseLayers, null, {position: 'bottomright'}).addTo(map);
            new L.Control.Zoom({ position: 'bottomleft' }).addTo(map);
            // map.setView(new L.LatLng(-15, -52),5);	
            
            // setMapCriado(L.stamp(layer))
            // setMapCriado(map)
            L.marker(props.cordenadas.coord, {icon: icon}).addTo(map)
            
        };
        
        useEffect(() => {
            initmap();
          }, []);



        function alterarLayerLight() {
            var layerControlElement = document.getElementsByClassName('leaflet-control-layers')[0];
                layerControlElement.getElementsByTagName('input')[0].click();
        }
        
        function alterarLayerDark() {
            var layerControlElement = document.getElementsByClassName('leaflet-control-layers')[0];
                layerControlElement.getElementsByTagName('input')[10].click();
        }

        


        useEffect(()=> {
            if(map) {
            if(isDarkModeCont == true) {
                alterarLayerDark()
            }
            if(isDarkModeCont == false) {
                alterarLayerLight()
            }
        }
        }, [isDarkModeCont])




        
        function smalldecode(point){
            var prefix = '';
            switch(point.charAt(0)) {
                case '!': prefix = '-1'; break;
                case '@': prefix = '-2'; break;
                case '#': prefix = '-3'; break;
                case '$': prefix = '-4'; break;
                case '%': prefix = '-5'; break;
                case '?': prefix = '-6'; break;
                case '&': prefix = '-7'; break;
                case '*': prefix = '-8'; break;
                case '(': prefix = '-9'; break;
                default: prefix = ''; break;
            }
            
            var myCharCode = point.charCodeAt(0);
            
            if((myCharCode > 64) && (myCharCode <  91)){
                prefix = '-' + point.charAt(0).toLowerCase();
            }
            
            if (prefix.length > 0) {
                return prefix + point.substr(1) + ''; 
            }
            else {
                return point;
            }
        }

        

        function NewML() {

            //boundaries
            var fatherId;
            var west;
            var east;
            var north;
            var south;
            var ready;
            var exponent;
            var precisionNumber;
            var maxNumDigits;
            var obj = this;
            
            //array of items
            var units = new Array();
            
            //array of lengths
            var n = new Array();
            n['fatherIdLen']	= 	2;
            n['exponent']		= 	1;
            n['maxNumDigits']	= 	1;
            n['idlength'] 		=	2;
            n['situation'] 		=	1; 
            n['polycntlen']		= 	2;
            n['lonnumdigits']	=	1;
            n['latnumdigits']	=	1;
            n['polylenlen']		= 	2;
            
            this.getData = function(address) {
                var ajaxRequest;  // The variable that makes Ajax possible!
            
                var data = null;
                
                ready = false;
            
                try{
                    // Opera 8.0+, Firefox, Safari
                    ajaxRequest = new XMLHttpRequest();
                } catch (e){
                    // Internet Explorer Browsers
                    try{
                        ajaxRequest = new ActiveXObject("Msxml2.XMLHTTP");
                    } catch (e) {
                        try {
                            ajaxRequest = new ActiveXObject("Microsoft.XMLHTTP");
                        } catch (e) {
                            // Something went wrong
                            alert("Your browser broke!");
                            return false;
                        }
                    }
                }
                
                thisObj = this;
                
                // Create a function that will receive data sent from the server
                ajaxRequest.onreadystatechange = function(){
                    if(ajaxRequest.readyState == 4){
                        data = ajaxRequest.responseText;
                        obj.parse(data);
                        obj.ready = true;
                    }
                }
                
                ajaxRequest.open("GET", address, false);
                ajaxRequest.send(null);
            }
            
            this.parse = function(data){
            // console.log(data)
                
                //index;
                var index = 0;
                
                var fatherIdLen = String(data).substr(index, n['fatherIdLen']) * 1;
                index += n['fatherIdLen'];
                
                fatherId = String(data).substr(index, fatherIdLen);
                index += fatherIdLen;
                
                exponent = String(data).substr(index, n['exponent']) * 1;
                index += n['exponent'];
                
                precisionNumber = Math.pow(10, exponent);
                
                maxNumDigits = String(data).substr(index, n['maxNumDigits']) * 1;
                index += n['maxNumDigits']; 
                
                west = parseInt(smalldecode(String(data).substr(index, maxNumDigits)), 36)/(precisionNumber * (-1));
                index += maxNumDigits;
                
                east = parseInt(smalldecode(String(data).substr(index, maxNumDigits)), 36)/(precisionNumber * (-1));
                index += maxNumDigits;
                
                north = parseInt(smalldecode(String(data).substr(index, maxNumDigits)), 36)/(precisionNumber * (-1));
                index += maxNumDigits;
                
                south = parseInt(smalldecode(String(data).substr(index, maxNumDigits)), 36)/(precisionNumber * (-1));
                index += maxNumDigits;
                
                while (index < String(data).length) {
                    var idlength = String(data).substr(index, n['idlength']) * 1;
                    index += n['idlength'];
                    
                    var id = String(data).substr(index, idlength);
                    index += idlength;
                    
                    var situation = String(data).substr(index, n['situation']) * 1;
                    index += n['situation'];
                    
                    var polycntlen = String(data).substr(index, n['polycntlen']) * 1;
                    index += n['polycntlen'];			
                    
                    var polycnt = String(data).substr(index, polycntlen) * 1;
                    index += polycntlen;
                    
                    var xcenter = String(data).substr(index, maxNumDigits);
                    index += maxNumDigits;
                    
                    var ycenter = String(data).substr(index, maxNumDigits);
                    index += maxNumDigits;
                    
                    var lonlen = String(data).substr(index, n['lonnumdigits']) * 1;
                    index += n['lonnumdigits'];
                    
                    var latlen = String(data).substr(index, n['latnumdigits']) * 1;
                    index += n['latnumdigits'];
                    
                    var latlonlen = lonlen + latlen;
                    
                    var u = new Unit(id, situation);
                    
                    //array of polygons
                    var polygons = new Array();
                    
                    for (var i = 0; i < polycnt; i++) {
                        var p = new Polygon(xcenter, ycenter, exponent, precisionNumber);
                        
                        var polylenlen = String(data).substr(index, n['polylenlen']) * 1;
                        index += n['polylenlen'];
                        
                        var polylen = String(data).substr(index, polylenlen) * 1;
                        index += polylenlen;
                        
                        var coordcnt = Math.round(polylen / latlonlen);
                        
                var j
                        for (j = 0; j < coordcnt; j++) {
                            var lon = String(data).substr(index, lonlen);
                            index += lonlen;
                            
                            var lat = String(data).substr(index, latlen);
                            index += latlen;
                            p.addPoint(lon, lat);
                        }
                        u.addPolygon(p);
                    }
                    
                    units.push(u);
                    // console.log(u)
                }
                ready = true;
                // console.log(units)
            }
            
            this.isReady = function() {
                return ready;
            }
            
            this.getId = function() {
                return fatherId;
            }
            
            this.getWest = function() {
                return west;
            }
            
            this.getEast = function() {
                return east;
            }
            
            this.getNorth = function() {
                return north;
            }
            
            this.getSouth = function() {
                return south;
            }
            
            this.getLatCenter = function() {
                return (north + south)/2;
            }
            
            this.getLonCenter = function() {
                return (west + east)/2;
            }
            
            this.isEmpty = function() {
                return (units.length == 0);
            }
            
            this.googleExport = function() {
                if (units.length > 0) {
                    return units.shift();
                }
                else {
                    return null;
                }
            }
        }

        function Cartograma()
        {
            
            var marker = L.marker([], {icon: icon}).bindPopup("",{minWidth:'100%'});
            
            this.desenha = function(nml)
            {
                // console.log(nml)
                var nml_polygon = nml.googleExport();
                // console.log(nml_polygon)
            var options = {
                    weight:2,
                    opacity:1,
                    fill:true,
                    color: "#9f27b8"
                };          
            
                var group = L.featureGroup();
                while (nml_polygon != null) { 
                    var id = nml_polygon.getId();
            //   console.log(id)
                    var situacao = "0";
                    var paths = nml_polygon.getMyPaths()
                    options.id = id;                    
                    options.setVisible = function (v)
                    {
                        this.visible = v;
                        if (!v)
                        {
                            options.fillColor  = "#"+params.getCorBase();
                            options.fillOpacity = 0;
                            options.opacity  = 0;
                        }
                        else
                        {
                            options.fillOpacity = params.getOpContorno()
                            options.opacity = params.getEspContorno();
                        }
                        dados.removeDado((params.getVariavel()[0]=='cidadesat'?this.id.substr(0,6):this.id));
                    }
        
                    var polyCoords = [];

                    if(id == codSetor) {      
                      for (var i in paths) {
                        polyCoords.push(paths[i]);
                      }
                    }
        
                    // for (var i in paths) {
                    // //   console.log(paths[i])
                    // polyCoords.push(paths[i]);
                    // }               
                    
                    
                    var poly = new L.polygon(polyCoords,options);
                    shapes[id] = poly;
                    var geoJsonGroup = L.layerGroup([poly]) 
                    geoJsonGroup.addTo(map);                      
                    group.addLayer(poly);                                        
                    nml_polygon = nml.googleExport();
                }

                map.fitBounds(group.getBounds())    
                
                // group.on('click',function(e){
                //      map.fitBounds(this.getBounds());
                //      var container = $('<div />');
                //      container.html(`<iframe id="infoWindowChrat" name="infoWindowChrat" style="width:620px;height:480px;border:0px;" src="https://geotaker.com.br/tooltips/tooltip.htm?codigo=${e.layer.options.id}" frameborder="0" scrolling="no"></iframe>`)
                //      container.append('<style>.buttonGerarEstudo:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
                //      container.append($('<button class="buttonGerarEstudo" id="buttonGerarEstudo" style="margin-left: 235px; background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 150px; padding: 8px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/AssessmentTwoToneIcon20.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">GERAR ESTUDO</span></button>').click(function() {
                        
                    
                //     })[0])        
                //      marker.setLatLng(e.latlng).bindPopup(container[0]).addTo(map).openPopup();  
                //  });
                 
               
              
        
                // this.centraliza();
            }
            
            this.centraliza = function()
            {
                // var bounds = new google.maps.LatLngBounds();
                // for (var id in shapes)
                // {
                // 	if (shapes[id].visible == true)
                // 	{
                // 		bounds.union(shapes[id].getBounds());
                // 	}
                // }
                // map.fitBounds(bounds);
                
                // map.fitBounds(group.getBounds(), {padding:[20,20]});
            }
            
            this.getShapes = function()
            {
                // console.log(shapes)
                return shapes;
            }
        }
        
        var cartograma = new Cartograma();

        function Unit(id, situation) {
            var _googleMultiPolygonPath = new Array();
            var _situation;
            var _id;
            
            this.setId = function(id) {
                _id = id;
            }
            
            this.setSituation = function(situation) {
                _situation = situation;
            }
            
            this.setId(id);
            this.setSituation( ( !situation ? "0" : situation ) );
        
            this.getId = function() {
                return _id;
            }	
            
            this.getSituation = function() {
                return _situation;
            }
            
            this.getMyPaths = function () {
                return _googleMultiPolygonPath;
            }
            
            this.addPolygon = function (p) {
            // console.log("veio aqui no ADDPOLYGON!!!")
                _googleMultiPolygonPath.push(p.getMyPath());
            // setPolygons(oldArray => [...oldArray, p.getMyPath()])
            // console.log(p.getMyPath())
            }
        }


        function Polygon(xcenter, ycenter, exponent, precisionNumber) {

            var _xcenter;
            var _ycenter;
            //var _googlePolygonPath = new google.maps.MVCArray();
            var _polyCoords = []
            
            this.setXCenter = function(xcenter) {
                _xcenter = parseInt(smalldecode(xcenter), 36)/(precisionNumber * (-1));
            }
            
            this.setYCenter = function(ycenter) {
                _ycenter = parseInt(smalldecode(ycenter), 36)/(precisionNumber * (-1));
            }
            
            this.setXCenter(xcenter);
            this.setYCenter(ycenter);
        
            var old_lon = "...";
            var old_lonpt;
            var old_lat = "...";
            var old_latpt;
            
            this.addPoint = function(lon, lat){
                if (lat == undefined || lon == undefined) {
                    var x = 1;
                }
                
                var lonpt = (old_lon == lon ? old_lonpt : (_xcenter + parseInt(smalldecode(lon), 36)/precisionNumber).toFixed(exponent)) * 1;
                var latpt = (old_lat == lat ? old_latpt : (_ycenter + parseInt(smalldecode(lat), 36)/precisionNumber).toFixed(exponent)) * 1;
                
                old_lon = lon;
                old_lat = lat;
                old_lonpt = lonpt;
                old_latpt = latpt;
                
                
                //var myLatLng = new google.maps.LatLng(latpt, lonpt);
        
                var myLatLng = L.latLng(latpt, lonpt);
        
                //console.log(feature.getGeometry().getCoordinates());
                // _googlePolygonPath.push(myLatLng);
        
                _polyCoords.push(myLatLng);
                
            }
            
            this.getMyPath = function() {
                return _polyCoords;
            }
        }


useEffect(()=> {

    if(props.setor == true) {
        
    var ufCod = codSetor.substring(0,2)
    var codCidade = codSetor.substring(0,7)
    var myHeaders = new Headers();
    var myInit = { method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default' };
    fetch(
        `https://servicodados.ibge.gov.br/Malha/${ufCod}/${codCidade}.js`,
        myInit
    )
        .then((res) => res.text())
        .then((kmlText) => {
        var kmltextCortada = kmlText.substring(17, (kmlText.length)-3)
        // console.log(kmltextCortada)
        var myNewml = [kmltextCortada].shift();     
        var newml_decoder = new NewML();
        newml_decoder.parse(myNewml)
        //   console.log(newml_decoder);
        setCartoDecod(newml_decoder)
        // cartograma.desenha(newml_decoder);
        
        })
    }
}, [])
    


    return (
        <div>
            <div id="map_geotaker" style={{height: "250px", borderRadius: "25px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}></div>
            {/* <MapContainer 
            center={props.cordenadas.coord} 
            zoom={props.cordenadas.zoom} scrollWheelZoom={true} 
            style={{ height: "250px", borderRadius: "25px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}
            zoomControl={false}
            attributionControl={false}
            draggable={false}
            >
                <TileLayer
                    url="https://mt1.google.com/vt/yrs=y&x=1325&y=3143&z=cn&x={x}&y={y}&z={z}"
                />
                <Marker position={props.cordenadas.coord} icon={icon}></Marker>
            </MapContainer> */}
        </div>

    )

}