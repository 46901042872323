import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-scroll";
import Divider from '@material-ui/core/Divider';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '5%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  sumarioTopico: {
    fontSize: '20px',
    textAlign: 'Left',
  },
  sumarioSubTopico: {
    paddingLeft: '20px',
    textAlign: 'Left',
  },
}))

export default function SumarioShopping(props) {
    const classes = useStyles();
   

    

      return (

    <>

        <div style={{textAlign: 'Left'}}>
            <h2>Sumário Shopping</h2>
        </div>

        <Divider />
      
        <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                
                
                <p className={classes.sumarioTopico}><Link
                activeClass="active"
                to="dadoscadastraisshopping"              
                smooth={true}              
                offset={-50}
                duration={500} 
                className={classes.cursorPointer}     
                >
                  Dados Cadastrais
                </Link></p>

                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="dadoscadastraisshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Endereço
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="dadoscadastraisshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Número
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="dadoscadastraisshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Bairro
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="dadoscadastraisshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      CEP
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="dadoscadastraisshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Cidade
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="dadoscadastraisshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Estado
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="dadoscadastraisshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Região
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="dadoscadastraisshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Telefone
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="dadoscadastraisshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Website
                    </Link></p>
                    

                <p className={classes.sumarioTopico}><Link
                activeClass="active"
                to="perfilfrequentadoresshopping"              
                smooth={true}              
                offset={-50}
                duration={500} 
                className={classes.cursorPointer}     
                >
                  Perfil dos Frequentadores
                </Link></p>

                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="perfilfrequentadoresshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Classe A
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="perfilfrequentadoresshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Classe B
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="perfilfrequentadoresshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Classe C
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="perfilfrequentadoresshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Classe D
                    </Link></p>
                
                

                

            </Grid>
            <Grid item xs={12} md={4}>

                <p className={classes.sumarioTopico}><Link
                activeClass="active"
                to="informacoesshopping"              
                smooth={true}              
                offset={-50}
                duration={500} 
                className={classes.cursorPointer}     
                >
                  Informações
                </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="informacoesshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Data de Inauguração
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="informacoesshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Tipo de Shopping
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="informacoesshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Pisos do Shopping
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="informacoesshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Área Bruta Locável
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="informacoesshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Área Construída
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="informacoesshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Área do Terreno
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="informacoesshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Estacionamento
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="informacoesshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Administração
                    </Link></p>
                    



            </Grid>
            <Grid item xs={12} md={4}>

                
            <p className={classes.sumarioTopico}><Link
                activeClass="active"
                to="lojasshopping"              
                smooth={true}              
                offset={-50}
                duration={500} 
                className={classes.cursorPointer}     
                >
                  Lojas
                </Link></p>                   
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="lojasshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Total de Lojas
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="lojasshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Lojas Âncoras
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="lojasshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Megalojas
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="lojasshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Satélites
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="lojasshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Conveniência e Serviços
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="lojasshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Alimentação
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="lojasshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Entretenimento
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="lojasshopping"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Cinema
                    </Link></p>

              </Grid>
            </Grid>

        </>   
      );

}