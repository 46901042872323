import React, { Component, useContext, useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { DarkContext } from '../../../Context/DarkContext';
import api from '../../../api';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

export default function EstadosMes(props) {
    const tokenUser = localStorage.getItem('token')
    const { isDarkModeCont } = useContext(DarkContext);
    const [quantidade, setQuantidade] = useState([]);
    const [estados, setEstados] = useState([]);
    const [loading, setLoading] = useState(true)

    const nomeEstados = {
      '12': 'Acre',
      '27': 'Alagoas',
      '16': 'Amapá',
      '13': 'Amazonas',
      '29': 'Bahia',
      '23': 'Ceará',
      '53': 'Distrito Federal',
      '32': 'Espírito Santo',
      '52': 'Goiás',
      '21': 'Maranhão',
      '51': 'Mato Grosso',
      '50': 'Mato Grosso do Sul',
      '31': 'Minas Gerais',
      '15': 'Pará',
      '25': 'Paraíba',
      '41': 'Paraná',
      '26': 'Pernambuco',
      '22': 'Piauí',
      '33': 'Rio de Janeiro',
      '24': 'Rio Grande do Norte',
      '43': 'Rio Grande do Sul',
      '11': 'Rondônia',
      '14': 'Roraima',
      '42': 'Santa Catarina',
      '35': 'São Paulo',
      '28': 'Sergipe',
      '17': 'Tocantins'     
    }

    // console.log(nomeEstados[29])

    useEffect(() => {
      (async () => {
        
        await api.post('/dashboard/estadosalta', { tokenUser })
        .then(function (response) {
          // handle success
          
          const datajson = response.data
          // console.log(datajson)
  
          datajson.map((item) => { 
            var nomeDoEstado = nomeEstados[item.estado]
            // console.log(item) 
            setQuantidade(quantidade => [...quantidade, item.quantidade])
            setEstados(estados => [...estados, nomeDoEstado])            
            
          })
  
          // console.log(datajson[0].NC);
          // setDados(datajson)
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          setLoading(false)
          console.log(error);
        })
        .then(function () {
          // always executed
         
        });
  
      })();
    }, []);



    const series = quantidade
    
    const options = {
      chart: {
        type: 'donut',
        background: 'transparent',
      },
      theme: {
        mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
    },
    labels: estados,
      responsive: [{
        breakpoint: 1000,
        options: {
          chart: {
            width: "100%"
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }


      return(
        <>
        {loading ? (
          <Box display="flex" justifyContent="center" style={{marginTop: '20px'}}>
            <Skeleton variant="circle" width={'13em'} height={'13em'} animation="wave" />
          </Box>
          ) : (
              <div className="app">
                <div className="row">
                  <div className="mixed-chart">
                    <Chart
                      options={options}
                      series={series}
                      type="pie"
                      width="100%"
                      height="200vh"
                    />
                  </div>
                </div>
              </div>
            )}
            </> 
      )
}