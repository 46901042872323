import React, { useState, useEffect, useContext } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


import api from '../../../api';




const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
          borderBottom: 'unset',
        },
      },
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
}))


  
 

export default function Consumo(props) {
    const classes = useStyles();
    const [dados, setDados] = useState();
    const codEstado = props.codEstado 
    
    const [openModule, setOpenModule] = React.useState(false);
    const [openModuleHabitacao, setOpenModuleHabitacao] = React.useState(false);
    const [openModuleVestuario, setOpenModuleVestuario] = React.useState(false);
    const [openModuleTransporte, setOpenModuleTransporte] = React.useState(false);
    const [openModuleHigiene, setOpenModuleHigiene] = React.useState(false);
    const [openModuleSaude, setOpenModuleSaude] = React.useState(false);
    const [openModuleEducacao, setOpenModuleEducacao] = React.useState(false);
    const [openModuleCultura, setOpenModuleCultura] = React.useState(false);
    const [openModulePessoais, setOpenModulePessoais] = React.useState(false);
    const [openModuleDespesas, setOpenModuleDespesas] = React.useState(false);
    const [openModuleOutrasDespesas, setOpenModuleOutrasDespesas] = React.useState(false);
    const [openModuleAumentoAtivo, setOpenModuleAumentoAtivo] = React.useState(false);
    const [openModuleDiminuicaoPassivo, setOpenModuleDiminuicaoPassivo] = React.useState(false);

    const [openFunctionAlimenForaDom, setOpenFunctionAlimenForaDom] = React.useState(false);
    const [openFunction, setOpenFunction] = React.useState(false);
    const [openFunctionAlimDomSal, setOpenFunctionAlimDomSal] = React.useState(false);
    const [openFunctionAlimDomBeb, setOpenFunctionAlimDomBeb] = React.useState(false);
    const [openFunctionAlimDomOleo, setOpenFunctionAlimDomOleo] = React.useState(false);
    const [openFunctionAlimDomPan, setOpenFunctionAlimDomPan] = React.useState(false);
    const [openFunctionAlimDomLeite, setOpenFunctionAlimDomLeite] = React.useState(false);
    const [openFunctionAlimDomAves, setOpenFunctionAlimDomAves] = React.useState(false);
    const [openFunctionAlimDomCarne, setOpenFunctionAlimDomCarne] = React.useState(false);
    const [openFunctionAlimDomFrutas, setOpenFunctionAlimDomFrutas] = React.useState(false);
    const [openFunctionAlimDomLeg, setOpenFunctionAlimDomLeg] = React.useState(false);
    const [openFunctionAlimDomAcucar, setOpenFunctionAlimDomAcucar] = React.useState(false);
    const [openFunctionAlimDomTuber, setOpenFunctionAlimDomTuber] = React.useState(false);
    const [openFunctionAlimDomFarinha, setOpenFunctionAlimDomFarinha] = React.useState(false);
    const [openFunctionAlimDomCer, setOpenFunctionAlimDomCer] = React.useState(false);
    const [openFunctionHabServicos, setOpenFunctionHabServicos] = React.useState(false);
    const [openFunctionHabServAgua, setOpenFunctionHabServAgua] = React.useState(false);
    const [openFunctionHabServAluguel, setOpenFunctionHabServAluguel] = React.useState(false);

    const [loading, setLoading] = useState(false)

    const [totalPopulacao, setTotalPopulacao] = useState();

    useEffect(() => {
        (async () => {
        
          await api.post('/consumo/estado', {estado:codEstado})
          .then(function (response) {
            // handle success
            
            const datajson = response.data
            // console.log(datajson)
            setTotalPopulacao(Number(datajson[1].V))

            // setDados(Number(datajson[1].V).toLocaleString('pt-BR'))
            // setLoading(false)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          
          });
    
    
        })();
      }, []);

      function converterReal(valor) {
          return Number(valor).toLocaleString('pt-BR')
      }


    // useEffect(() => {
    //     (async () => {
        
    //       await api.post('/consulta/estado/populacaoestimada', {estado:codEstado})
    //       .then(function (response) {
    //         // handle success
            
    //         const datajson = response.data
          
    //         setDados(Number(datajson[1].V).toLocaleString('pt-BR'))
    //         setLoading(false)
    //       })
    //       .catch(function (error) {
    //         // handle error
    //         console.log(error);
    //       })
    //       .then(function () {
    //         // always executed
          
    //       });
    
    
    //     })();
    //   }, []);

      return (
        <>
        {loading ? (
                        <Skeleton style={{ width: '60%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                          <p></p>
            // <p style={{marginLeft: '5px'}} className={classes.textSecond}>População: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{dados}</span> habitantes.</p>
                      )}

        <p style={{marginLeft: '5px'}} className={classes.textSecond}>Categorias e consumo mensal da população total e por classes sociais</p>
        
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead></TableHead>
                <TableBody>
                
                


                <React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModule(!openModule)}
                            style={{ color: "white" }}
                            >
                            {openModule ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Alimentação</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*658.23)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModule} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>



                                <React.Fragment>                                
                                    <TableRow key={'asfsafsdf'}>
                                        <TableCell style={{ width: "62px" }}>
                                            <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpenFunctionAlimenForaDom(!openFunctionAlimenForaDom)}
                                            >
                                            {openFunctionAlimenForaDom ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            Alimentação Fora do Domicílio
                                        </TableCell>
                                        <TableCell component="th" scope="row">R$ {converterReal((totalPopulacao/3)*215.96)}</TableCell>
                                       
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                            colSpan={6}
                                        >
                                        <Collapse
                                        in={openFunctionAlimenForaDom}
                                        timeout="auto"
                                        unmountOnExit
                                        >
                                        <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>



                                                

                                                <TableRow key={'alfd2'}>
                                                    <TableCell component="th" scope="row">
                                                    Alimentação light e diet
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1.89)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1.02)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*0.16)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*0.42)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.10)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.09)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.08)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.02)}</TableCell>                          
                                                </TableRow>

                                                <TableRow key={'alfd3'}>
                                                    <TableCell component="th" scope="row">
                                                    Cervejas, chopes e outras bebidas alcoólicas
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*93.79)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*22.70)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*14.55)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*19.38)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*15.00)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*10.26)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*7.03)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.87)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'alfd4'}>
                                                    <TableCell component="th" scope="row">
                                                    Lanches
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*178.96)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*65.09)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*34.88)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*24.84)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*20.89)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*15.74)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*11.06)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.46)}</TableCell>                       
                                                </TableRow>

                                                <TableRow key={'alfd5'}>
                                                    <TableCell component="th" scope="row">
                                                    Refrigerantes e outras bebidas não alcoólicas
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*66.30)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*14.77)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*11.75)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*12.19)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*11.59)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*7.45)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.05)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.50)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'alfd6'}>
                                                    <TableCell component="th" scope="row">
                                                    Sanduíches e salgados
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*200.59)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*56.72)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*41.40)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*39.32)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*28.89)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*17.90)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*10.24)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.12)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'alfd7'}>
                                                    <TableCell component="th" scope="row">
                                                    Café, leite, café/leite e chocolate
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*30.03)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*10.57)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*5.85)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*5.21)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*3.90)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.21)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.49)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.80)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'alfd8'}>
                                                    <TableCell component="th" scope="row">
                                                    Almoço e jantar
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*2071.42)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*839.36)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*488.60)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*308.60)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*208.68)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*119.35)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*67.38)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*39.45)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'alfd1'}>
                                                    <TableCell component="th" scope="row">
                                                    Outras
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*146.44)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*37.40)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*27.35)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*20.68)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*23.47)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*17.69)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*12.98)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.87)}</TableCell>                       
                                                </TableRow>


                                            </TableBody>
                                            </Table>
                                        </Box>
                                        </Collapse>
                                    </TableCell>
                                    </TableRow>
                                </React.Fragment>

                                <React.Fragment>
                                    <TableRow key={'asfsafsdf'}>
                                        <TableCell style={{ width: "62px" }}>
                                            <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpenFunction(!openFunction)}
                                            >
                                            {openFunction ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            Alimentação no Domicílio
                                        </TableCell>
                                        <TableCell component="th" scope="row">R$ {converterReal((totalPopulacao/3)*442.27)}</TableCell>
                                       
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                            colSpan={6}
                                        >
                                        <Collapse
                                        in={openFunction}
                                        timeout="auto"
                                        unmountOnExit
                                        >
                                        <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>

 

                                                <TableRow key={'alnd2'}>
                                                    <TableCell component="th" scope="row">
                                                    Alimentos preparados
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*183.50)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*62.58)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*44.07)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*27.76)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*22.86)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*13.79)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*8.02)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.42)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'alnd3'}>
                                                    <TableCell component="th" scope="row">
                                                    Enlatados e conservas
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*45.51)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*14.37)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*10.14)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*8.04)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*5.52)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*3.38)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*2.41)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.65)}</TableCell>                       
                                                </TableRow>

                                                <TableRow key={'alnd1'}>
                                                    <TableCell component="th" scope="row">
                                                    Outros alimentos
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*381.23)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*110.31)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*68.01)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*63.18)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*48.23)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*41.84)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*27.42)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*22.24)}</TableCell>                        
                                                </TableRow>




                                                <React.Fragment>
                                                    <TableRow key={'alndsc0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomSal(!openFunctionAlimDomSal)}
                                                            >
                                                            {openFunctionAlimDomSal ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Sal e condimentos
                                                        </TableCell>
                                                        <TableCell component="th" scope="row"  style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*96.08)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*22.72)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*19.61)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*16.77)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*13.21)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*10.55)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*7.70)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.52)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomSal}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndsc1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Sal refinado
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*3.59)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*0.64)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*0.51)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*0.47)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.57)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.53)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.44)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.43)}</TableCell>                        
                                                                </TableRow>

                                                                <TableRow key={'alndsc2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Maionese
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*10.07)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*2.36)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1.98)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*1.98)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*1.70)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.05)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.66)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.34)}</TableCell>                         
                                                                </TableRow>

                                                                <TableRow key={'alndsc3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Massa de tomate
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*5.87)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*0.82)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1.17)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*0.95)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.95)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.88)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.63)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.47)}</TableCell>                         
                                                                </TableRow>
                                                                

                                                                <TableRow key={'alndsc5'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*76.53)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*18.90)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*15.95)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*13.38)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*9.99)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*8.08)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.96)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.27)}</TableCell>                         
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>



                                                <React.Fragment>
                                                    <TableRow key={'alndbeb0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomBeb(!openFunctionAlimDomBeb)}
                                                            >
                                                            {openFunctionAlimDomBeb ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Bebidas e infusões
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*503.42)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*138.81)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*109.98)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*93.64)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*64.05)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*45.56)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*29.88)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*21.50)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomBeb}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndbeb1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Cervejas e chopes
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*120.52)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*39.65)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*23.72)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*24.34)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*15.94)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*9.67)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*4.67)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.53)}</TableCell>                         
                                                                </TableRow>

                                                                <TableRow key={'alndbeb2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Bebidas não alcoólicas light e diet
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*7.23)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*3.81)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1.58)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*1.04)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.49)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.19)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.05)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.07)}</TableCell>                         
                                                                </TableRow>

                                                                <TableRow key={'alndbeb3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Refrigerantes
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*114.75)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*23.33)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*24.15)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*23.59)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*17.74)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*12.50)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*8.16)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.28)}</TableCell>                         
                                                                </TableRow>

                                                                <TableRow key={'alndbeb4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Café moído
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*76.23)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*13.38)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*12.93)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*11.12)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*11.25)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*10.50)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*9.18)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*7.87)}</TableCell>                         
                                                                </TableRow>

                                                                <TableRow key={'alndbeb5'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outras bebidas alcoólicas
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*61.82)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*20.73)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*20.78)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*12.22)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*3.51)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.51)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.24)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.83)}</TableCell>                   
                                                                </TableRow>

                                                                <TableRow key={'alndbeb6'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outras
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*122.84)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*37.90)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*26.81)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*21.33)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*15.12)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*10.19)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.57)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.92)}</TableCell>               
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>



                                                <React.Fragment>
                                                    <TableRow key={'alndsc1'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomOleo(!openFunctionAlimDomOleo)}
                                                            >
                                                            {openFunctionAlimDomOleo ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Óleos e gorduras
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*71.87)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*19.45)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*14.18)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*11.71)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*8.92)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*7.20)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.67)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.74)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomOleo}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndoleo1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Azeite de oliva
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*29.18)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*11.20)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*7.44)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*5.27)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*2.81)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.38)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.78)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.30)}</TableCell>                        
                                                                </TableRow>

                                                                <TableRow key={'alndoleo2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Óleo de soja
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*28.61)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*3.38)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*3.36)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*4.07)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*4.64)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*4.93)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*4.30)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.93)}</TableCell>                   
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndoleo3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*14.10)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*4.87)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*3.39)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*2.37)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*1.47)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.89)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.60)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.51)}</TableCell>                  
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>




                                                <React.Fragment>
                                                    <TableRow key={'alndpan0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomPan(!openFunctionAlimDomPan)}
                                                            >
                                                            {openFunctionAlimDomPan ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Panificados
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*408.71)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*89.52)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*81.30)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*70.23)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*58.22)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*47.37)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*35.11)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*26.96)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomPan}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndpan1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Light e diet
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*3.44)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1.29)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1.07)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*0.54)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.31)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.10)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.09)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.04)}</TableCell>                  
                                                                </TableRow>

                                                                <TableRow key={'alndpan2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Biscoito
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*100.75)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*20.95)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*19.64)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*15.15)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*14.22)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*12.50)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*9.93)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*8.36)}</TableCell>                 
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndpan3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Pão francês
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*142.08)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*22.92)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*23.14)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*22.55)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*23.66)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*20.50)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*16.05)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*13.26)}</TableCell>                     
                                                                </TableRow>

                                                                <TableRow key={'alndpan4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros panificados
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*162.43)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*44.36)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*37.44)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*32.00)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*20.02)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*14.27)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*9.04)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.30)}</TableCell>              
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>




                                                <React.Fragment>
                                                    <TableRow key={'alndleite0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomLeite(!openFunctionAlimDomLeite)}
                                                            >
                                                            {openFunctionAlimDomLeite ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Leites e derivados
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*484.72)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*125.12)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*109.11)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*86.34)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*62.90)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*45.25)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*32.49)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*23.51)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomLeite}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndleite1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Orgânicos
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*2.72)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1.93)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*0.07)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*0.43)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.16)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.06)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.03)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.04)}</TableCell>               
                                                                </TableRow>

                                                                <TableRow key={'alndleite2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Light e diet
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*14.24)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*7.52)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*3.11)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*1.81)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*1.04)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.46)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.20)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.10)}</TableCell>       
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndleite3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Queijos
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*166.32)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*52.38)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*41.82)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*32.71)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*18.96)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*10.67)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.20)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.58)}</TableCell>                
                                                                </TableRow>

                                                                <TableRow key={'alndleite4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Leite em pó
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*43.30)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*7.38)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*8.15)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*6.13)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*5.55)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*5.13)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.70)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.26)}</TableCell>                     
                                                                </TableRow>

                                                                <TableRow key={'alndleite5'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Leite de vaca
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*121.06)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*22.74)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*22.50)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*20.79)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*18.45)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*15.89)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*12.17)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*8.52)}</TableCell>             
                                                                </TableRow>

                                                                <TableRow key={'alndleite6'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*137.10)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*33.17)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*33.46)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*24.48)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*18.74)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*13.04)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*8.19)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.02)}</TableCell>      
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>





                                                <React.Fragment>
                                                    <TableRow key={'alndaves0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomAves(!openFunctionAlimDomAves)}
                                                            >
                                                            {openFunctionAlimDomAves ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Aves e ovos
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*269.66)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*52.23)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*48.30)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*40.73)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*35.79)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*34.30)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*30.55)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*27.76)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomAves}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndaves1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Orgânicos
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*2.34)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1.33)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*0.62)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*0.16)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.13)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.04)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.04)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.02)}</TableCell>                    
                                                                </TableRow>

                                                                <TableRow key={'alndaves2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Ovo de galinha
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*61.14)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*12.68)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*10.88)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*10.55)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*8.21)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*7.11)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.40)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.31)}</TableCell>                   
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndaves3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Frango
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*187.42)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*32.19)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*32.77)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*26.32)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*25.10)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*25.89)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*22.26)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*21.89)}</TableCell>                     
                                                                </TableRow>

                                                                <TableRow key={'alndaves4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*18.73)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*6.03)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*4.02)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*3.69)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*2.34)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.25)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.85)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.55)}</TableCell>                     
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>





                                                <React.Fragment>
                                                    <TableRow key={'alndcarne0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomCarne(!openFunctionAlimDomCarne)}
                                                            >
                                                            {openFunctionAlimDomCarne ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Carnes, vísceras e pescados
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*791.96)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*176.68)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*150.91)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*128.09)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*115.45)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*91.70)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*72.65)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*56.48)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomCarne}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndcarne1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Pescados frescos
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*57.17)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*13.03)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*8.01)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*8.45)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*7.18)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*6.16)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*7.08)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*7.26)}</TableCell>            
                                                                </TableRow>

                                                                <TableRow key={'alndcarne2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Carnes e peixes industrializados
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*171.97)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*37.26)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*33.47)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*28.49)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*26.27)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*20.34)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*14.63)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*11.51)}</TableCell>                 
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndcarne3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Carne de suíno
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*62.31)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*13.79)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*9.28)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*9.88)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*9.40)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*8.35)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.87)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.74)}</TableCell>                     
                                                                </TableRow>

                                                                <TableRow key={'alndcarne4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Carne de boi de segunda
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*119.36)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*16.13)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*17.94)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*20.45)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*19.40)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*18.18)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*15.21)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*12.05)}</TableCell>            
                                                                </TableRow>

                                                                <TableRow key={'alndcarne5'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Carne de boi de primeira
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*275.16)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*81.42)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*64.78)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*46.35)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*34.84)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*23.94)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*14.61)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*9.22)}</TableCell>               
                                                                </TableRow>

                                                                <TableRow key={'alndcarne6'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*105.98)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*15.06)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*17.44)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*14.47)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*18.34)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*14.72)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*14.24)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*11.71)}</TableCell>                   
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>





                                                <React.Fragment>
                                                    <TableRow key={'alndfrutas0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomFrutas(!openFunctionAlimDomFrutas)}
                                                            >
                                                            {openFunctionAlimDomFrutas ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Frutas
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*249.92)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*69.80)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*55.01)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*46.61)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*30.66)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*21.71)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*15.41)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*10.72)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomFrutas}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndfrutas1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Maçã
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*22.50)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*4.99)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*4.71)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*4.23)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*3.41)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.39)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.64)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.13)}</TableCell>                     
                                                                </TableRow>

                                                                <TableRow key={'alndfrutas2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Laranja
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*24.26)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*6.19)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*5.18)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*4.71)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*3.07)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.37)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.63)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.11)}</TableCell>                 
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndfrutas3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Banana
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*56.90)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*13.58)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*11.52)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*10.32)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*7.42)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*5.70)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*4.94)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.42)}</TableCell>                  
                                                                </TableRow>                                                               

                                                                <TableRow key={'alndfrutas4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outras frutas
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*146.24)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*45.03)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*33.59)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*27.35)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*16.76)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*11.25)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*7.20)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.06)}</TableCell>                      
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>



                                                <React.Fragment>
                                                    <TableRow key={'alndleg0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomLeg(!openFunctionAlimDomLeg)}
                                                            >
                                                            {openFunctionAlimDomLeg ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Legumes e verduras
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*165.56)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*45.57)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*36.06)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*28.33)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*19.46)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*15.54)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*11.79)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*8.81)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomLeg}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndleg1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Alface
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*19.20)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*6.25)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*3.78)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*3.10)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*2.26)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.88)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.16)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.77)}</TableCell>  
                                                                </TableRow>

                                                                <TableRow key={'alndleg2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Cebola
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*18.53)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*3.83)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*3.69)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*3.22)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*2.57)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.09)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.68)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.45)}</TableCell>               
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndleg3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Tomate
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*47.36)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*10.43)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*10.71)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*8.45)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*6.00)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*4.82)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*3.92)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.03)}</TableCell>       
                                                                </TableRow>                                                               

                                                                <TableRow key={'alndleg4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*80.47)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*25.06)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*17.88)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*13.57)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*8.63)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*6.75)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.03)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.55)}</TableCell>                
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>



                                            
                                                <React.Fragment>
                                                    <TableRow key={'alndacucar0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomAcucar(!openFunctionAlimDomAcucar)}
                                                            >
                                                            {openFunctionAlimDomAcucar ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Açúcares e derivados
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*205.65)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*58.63)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*40.55)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*36.65)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*26.66)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*19.71)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*13.38)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*10.07)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomAcucar}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndacucar1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Light e diet
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*3.50)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1.78)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*0.93)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*0.39)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.18)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.13)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.03)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.06)}</TableCell>         
                                                                </TableRow>

                                                                <TableRow key={'alndacucar2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Açúcar cristal
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*16.99)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1.39)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1.80)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*2.05)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*2.67)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*3.20)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*3.02)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.86)}</TableCell>        
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndacucar3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Açúcar refinado
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*14.99)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*2.15)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*2.30)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*2.30)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*2.11)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.31)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.93)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.89)}</TableCell>               
                                                                </TableRow>                                                               

                                                                <TableRow key={'alndacucar4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*170.12)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*53.30)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*35.51)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*31.91)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*21.69)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*14.07)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*8.38)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.26)}</TableCell>                   
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>





                                                <React.Fragment>
                                                    <TableRow key={'alndtuber0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomTuber(!openFunctionAlimDomTuber)}
                                                            >
                                                            {openFunctionAlimDomTuber ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Tubérculos e raízes
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*64.78)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*13.56)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*14.53)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*10.29)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*8.86)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*7.56)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.78)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.20)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomTuber}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndtuber1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Mandioca
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*7.28)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1.18)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1.13)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*1.11)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*1.17)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.17)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.85)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.67)}</TableCell>   
                                                                </TableRow>

                                                                <TableRow key={'alndtuber2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Cenoura
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*11.17)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*2.79)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*2.33)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*1.81)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*1.45)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.19)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.93)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.67)}</TableCell>         
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndtuber3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Batata inglesa
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*24.06)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*4.19)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*5.78)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*3.61)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*3.59)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.89)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*2.29)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.71)}</TableCell>          
                                                                </TableRow>                                                               

                                                                <TableRow key={'alndtuber4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*22.30)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*5.41)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*5.28)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*3.76)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*2.65)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.32)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.72)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.16)}</TableCell>                
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>





                                                <React.Fragment>
                                                    <TableRow key={'alndfar0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomFarinha(!openFunctionAlimDomFarinha)}
                                                            >
                                                            {openFunctionAlimDomFarinha ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Farinhas, féculas e massas
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*133.17)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*28.43)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*24.56)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*19.62)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*17.79)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*15.73)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*14.28)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*12.76)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomFarinha}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndfar1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Farinha de mandioca
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*14.06)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*0.98)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1.62)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*1.42)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*1.62)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.10)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*2.99)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.33)}</TableCell>                
                                                                </TableRow>

                                                                <TableRow key={'alndfar2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Farinha de trigo
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*9.93)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1.36)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1.91)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*1.67)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*1.62)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.55)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.05)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.77)}</TableCell>       
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndfar3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Macarrão
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*33.30)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*5.06)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*6.21)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*4.65)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*4.80)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*4.78)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*4.07)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.73)}</TableCell>                
                                                                </TableRow>                                                               

                                                                <TableRow key={'alndfar4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outras
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*75.87)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*21.03)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*14.82)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*11.88)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*9.75)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*7.30)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.16)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.93)}</TableCell>                 
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>




                                                <React.Fragment>
                                                    <TableRow key={'alndcer0'}>
                                                        <TableCell component="th" scope="row">
                                                            <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => setOpenFunctionAlimDomCer(!openFunctionAlimDomCer)}
                                                            >
                                                            {openFunctionAlimDomCer ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                            
                                                            </IconButton>
                                                            Cereais, leguminosas e oleaginosas
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                            R$ {converterReal((totalPopulacao/3)*169.40)}
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*30.50)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*27.84)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*25.19)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*21.59)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*22.54)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*21.35)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*20.39)}</TableCell>
                                                       
                                                    
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                                            colSpan={6}
                                                        >
                                                        <Collapse
                                                        in={openFunctionAlimDomCer}
                                                        timeout="auto"
                                                        unmountOnExit
                                                        >
                                                        <Box margin={1}>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Categoria</TableCell>                                                    
                                                                        <TableCell>Total</TableCell>                                                    
                                                                        <TableCell>A</TableCell>
                                                                        <TableCell>A1</TableCell>
                                                                        <TableCell>B</TableCell>
                                                                        <TableCell>B1</TableCell>
                                                                        <TableCell>C</TableCell>
                                                                        <TableCell>D</TableCell>
                                                                        <TableCell>E</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                            <TableBody>



                                                                <TableRow key={'alndcer1'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Orgânicos
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*0.35)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*0.08)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*0.09)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*0.05)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.06)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.02)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.00)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.05)}</TableCell> 
                                                                </TableRow>

                                                                <TableRow key={'alndcer2'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Feijão
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*39.44)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*4.44)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*5.23)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*6.07)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*5.58)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*6.13)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.02)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.97)}</TableCell>                    
                                                                </TableRow>                                                             

                                                                <TableRow key={'alndcer3'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Arroz
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*83.55)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*7.92)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*11.98)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*12.49)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*11.52)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*13.59)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*13.09)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*12.96)}</TableCell>      
                                                                </TableRow>                                                               

                                                                <TableRow key={'alndcer4'}>
                                                                    <TableCell component="th" scope="row">
                                                                    Outros
                                                                    </TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*46.05)}</TableCell>                                                    
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*18.06)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*10.53)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*6.58)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*4.44)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.80)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*2.23)}</TableCell>
                                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.41)}</TableCell>           
                                                                </TableRow>


                                                            </TableBody>
                                                            </Table>
                                                        </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                    </TableRow>
                                                </React.Fragment>















                                            </TableBody>
                                            </Table>
                                        </Box>
                                        </Collapse>
                                    </TableCell>
                                    </TableRow>
                                </React.Fragment>






                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>




            {/* Habitação */}

            <React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleHabitacao(!openModuleHabitacao)}
                            style={{ color: "white" }}
                            >
                            {openModuleHabitacao ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Habitação</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*17076.81)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleHabitacao} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>

                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>



                                    <React.Fragment>
                                        <TableRow key={'asfsafsdf'}>
                                            <TableCell component="th" scope="row">
                                                <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setOpenFunctionHabServAluguel(!openFunctionHabServAluguel)}
                                                >
                                                {openFunctionHabServAluguel ? (
                                                    <KeyboardArrowUpIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}
                                                </IconButton>
                                                Aluguel
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                                R$ {converterReal((totalPopulacao/3)*8379.03)}
                                            </TableCell>                                                   
                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*2970.61)}</TableCell>
                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1831.36)}</TableCell>
                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*1270.64)}</TableCell>
                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*914.82)}</TableCell>
                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*626.87)}</TableCell>
                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*456.75)}</TableCell>
                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*307.98)}</TableCell> 
                                        
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                                colSpan={6}
                                            >
                                            <Collapse
                                            in={openFunctionHabServAluguel}
                                            timeout="auto"
                                            unmountOnExit
                                            >
                                            <Box margin={1}>
                                                <Table size="small" aria-label="purchases">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Categoria</TableCell>                                                    
                                                            <TableCell>Total</TableCell>                                                    
                                                            <TableCell>A</TableCell>
                                                            <TableCell>A1</TableCell>
                                                            <TableCell>B</TableCell>
                                                            <TableCell>B1</TableCell>
                                                            <TableCell>C</TableCell>
                                                            <TableCell>D</TableCell>
                                                            <TableCell>E</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                <TableBody>



                                                    <TableRow key={'teste11'}>
                                                            <TableCell component="th" scope="row">
                                                            Aluguel monetário
                                                            </TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*871.71)}</TableCell>                                                    
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*165.66)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*213.99)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*143.77)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*118.57)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*85.80)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*80.02)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*63.90)}</TableCell>                         
                                                        </TableRow>

                                                        <TableRow key={'teste11'}>
                                                            <TableCell component="th" scope="row">
                                                            Aluguel não monetário
                                                            </TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*7507.34)}</TableCell>                                                    
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*2804.96)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1617.38)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*1126.87)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*796.25)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*541.06)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*376.73)}</TableCell>
                                                            <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*244.09)}</TableCell>                        
                                                        </TableRow>                                                                      
                                                        

                                                </TableBody>
                                                </Table>
                                            </Box>
                                            </Collapse>
                                        </TableCell>
                                        </TableRow>
                                    </React.Fragment>

                                    <TableRow key={'teste11'}>
                                        <TableCell component="th" scope="row">
                                        Condomínio
                                        </TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1203.05)}</TableCell>                                                    
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*668.71)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*294.15)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*145.77)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*64.27)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*18.57)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*8.58)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.00)}</TableCell>                        
                                    </TableRow>


                                    <TableRow key={'teste11'}>
                                        <TableCell component="th" scope="row">
                                        Consertos artigos do lar
                                        </TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*57.37)}</TableCell>                                                    
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*25.81)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*12.09)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*7.42)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*5.07)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*3.36)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*2.19)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.43)}</TableCell>                         
                                    </TableRow>

                                    <TableRow key={'teste11'}>
                                        <TableCell component="th" scope="row">
                                        Eletrodomésticos
                                        </TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*626.04)}</TableCell>                                                    
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*174.13)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*127.31)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*100.10)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*85.29)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*59.40)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*45.41)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*34.40)}</TableCell>                        
                                    </TableRow>

                                    <TableRow key={'teste11'}>
                                        <TableCell component="th" scope="row">
                                        Mobiliários e artigos do lar
                                        </TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*794.00)}</TableCell>                                                    
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*291.72)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*166.82)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*112.31)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*88.32)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*61.71)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*41.96)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*31.16)}</TableCell>                       
                                    </TableRow>

                                    <TableRow key={'teste11'}>
                                        <TableCell component="th" scope="row">
                                        Artigos de limpeza
                                        </TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*227.65)}</TableCell>                                                    
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*59.79)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*49.62)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*39.49)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*28.66)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*22.11)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*16.84)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*11.14)}</TableCell>                        
                                    </TableRow>

                                    <TableRow key={'teste11'}>
                                        <TableCell component="th" scope="row">
                                        Manutenção do lar
                                        </TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*2268.66)}</TableCell>                                                    
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1157.16)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*549.05)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*284.46)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*128.81)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*71.36)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*48.16)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*29.66)}</TableCell>                         
                                    </TableRow>
                                
                                




                                <React.Fragment>
                                    <TableRow key={'asfsafsdfdds'}>
                                        <TableCell component="th" scope="row">
                                            <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpenFunctionHabServicos(!openFunctionHabServicos)}
                                            >
                                            {openFunctionHabServicos ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                            </IconButton>
                                            Serviços e taxas
                                        </TableCell>
                                        <TableCell component="th" scope="row" style={{fontSize: '10px'}}>
                                            R$ {converterReal((totalPopulacao/3)*3521.01)}
                                        </TableCell>                                                   
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*964.07)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*730.74)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*602.30)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*476.89)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*339.79)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*240.08)}</TableCell>
                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*167.14)}</TableCell> 
                                        
                                        
                                       
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{ paddingBottom: 0, paddingTop: 0 }}
                                            colSpan={6}
                                        >
                                        <Collapse
                                        in={openFunctionHabServicos}
                                        timeout="auto"
                                        unmountOnExit
                                        >
                                        <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>   

                                                <TableRow key={'teste11'}>
                                                        <TableCell component="th" scope="row">
                                                        Água e esgoto
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*395.85)}</TableCell>                                                    
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*75.27)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*70.42)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*68.01)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*64.10)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*50.81)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*39.12)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*28.12)}</TableCell>                        
                                                    </TableRow>


                                                    <TableRow key={'teste11'}>
                                                        <TableCell component="th" scope="row">
                                                        Gás doméstico
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*293.44)}</TableCell>                                                    
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*58.19)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*43.85)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*42.96)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*39.25)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*39.21)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*37.15)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*32.83)}</TableCell>                        
                                                    </TableRow>                                                    


                                                    <TableRow key={'teste11'}>
                                                        <TableCell component="th" scope="row">
                                                        Pacote de telefone, TV e Internet
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*714.87)}</TableCell>                                                    
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*252.33)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*183.16)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*129.40)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*83.56)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*40.47)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*18.11)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*7.84)}</TableCell>                          
                                                    </TableRow>
                                                    
                                                    <TableRow key={'teste11'}>
                                                        <TableCell component="th" scope="row">
                                                        Telefone Celular
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*601.55)}</TableCell>                                                    
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*216.03)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*124.23)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*97.00)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*74.76)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*45.01)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*28.17)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*16.35)}</TableCell>                          
                                                    </TableRow>

                                                    <TableRow key={'teste11'}>
                                                        <TableCell component="th" scope="row">
                                                        Telefone fixo
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*71.69)}</TableCell>                                                    
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*16.79)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*13.88)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*12.80)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*10.40)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*8.76)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.04)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.02)}</TableCell>                          
                                                    </TableRow>

                                                    <TableRow key={'teste11'}>
                                                        <TableCell component="th" scope="row">
                                                        Energia elétrica
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1077.09)}</TableCell>                                                    
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*260.14)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*208.94)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*183.86)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*152.75)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*117.64)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*88.14)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*65.62)}</TableCell>              
                                                    </TableRow>

                                                    {/* <TableRow key={'teste11'}>
                                                        <TableCell component="th" scope="row">
                                                        Condomínio
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*46)}</TableCell>                                                    
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*06)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*53)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*58)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*44)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*80)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*23)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*41)}</TableCell>                         
                                                    </TableRow> */}

                                                    <TableRow key={'teste11'}>
                                                        <TableCell component="th" scope="row">
                                                        Outros
                                                        </TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*366.52)}</TableCell>                                                    
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*85.32)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*86.26)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*68.27)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*52.07)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*37.88)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*23.36)}</TableCell>
                                                        <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*13.36)}</TableCell>                        
                                                    </TableRow>


                                            </TableBody>
                                            </Table>
                                        </Box>
                                        </Collapse>
                                    </TableCell>
                                    </TableRow>
                                </React.Fragment>


                                </TableBody>
                                </Table>
                            </Box>





                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>






                {/* Vestuário */}

            <React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleVestuario(!openModuleVestuario)}
                            style={{ color: "white" }}
                            >
                            {openModuleVestuario ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Vestuário</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*1882.39)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleVestuario} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>

                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Tecidos e armarinhos
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*21.79)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*7.08)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*5.09)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*3.68)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*2.35)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.71)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.54)}</TableCell>                         
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Jóias e bijuterias
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*109.12)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*53.97)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*24.19)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*13.22)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*9.01)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*4.70)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*2.59)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.44)}</TableCell>                    
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Calçados e apetrechos
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*466.23)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*138.90)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*99.72)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*78.57)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*63.20)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*42.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*25.39)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*18.11)}</TableCell>                  
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Roupa de criança
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*228.58)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*73.70)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*44.29)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*34.17)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*30.40)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*21.59)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*14.17)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*10.26)}</TableCell>                       
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Roupa de mulher
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*604.03)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*226.61)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*124.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*93.35)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*71.87)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*43.67)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*26.26)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*17.93)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Roupa de homem
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*452.63)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*155.70)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*93.92)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*73.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*56.20)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*36.44)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*21.85)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*15.18)}</TableCell>                      
                                                </TableRow>
                                                
                                                

                                                </TableBody>
                                            </Table>
                                        </Box>


                               






                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>






                 {/* Transporte */}

            <React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleTransporte(!openModuleTransporte)}
                            style={{ color: "white" }}
                            >
                            {openModuleTransporte ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Transporte</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*10036.22)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleTransporte} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                




                                    <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Viagens esporádicas
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1296.98)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*657.56)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*318.44)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*156.56)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*95.83)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*38.48)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*20.27)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*9.84)}</TableCell>                    
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Aquisição de veículos
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*4345.39)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*2033.04)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1040.38)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*570.72)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*416.47)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*170.52)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*78.37)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*35.89)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Manutenção e acessórios
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*950.96)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*293.88)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*226.07)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*166.66)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*130.15)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*75.03)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*38.47)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*20.70)}</TableCell>        
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Álcool - veículo próprio
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*227.24)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*59.68)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*55.54)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*54.82)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*33.47)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*16.38)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.30)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.05)}</TableCell>                      
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Gasolina - veículo próprio
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1801.41)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*610.04)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*424.15)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*311.69)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*230.26)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*127.13)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*62.20)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*35.94)}</TableCell>             
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Urbano
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*520.49)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*103.51)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*98.67)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*88.03)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*84.15)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*66.71)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*47.72)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*31.70)}</TableCell>                   
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Outras
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*893.75)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*378.38)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*233.33)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*145.00)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*84.49)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*32.85)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*13.59)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.11)}</TableCell>                 
                                                </TableRow>
                                                
                                                

                                                </TableBody>
                                            </Table>
                                        </Box>






                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>






                {/* Higiene */}

            <React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleHigiene(!openModuleHigiene)}
                            style={{ color: "white" }}
                            >
                            {openModuleHigiene ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Higiene e Cuidados Pessoais</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*1254.43)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleHigiene} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                




                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Instrumentos e produtos de uso pessoal
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*694.15)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*172.73)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*135.81)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*115.83)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*105.40)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*74.72)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*50.95)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*38.71)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Sabonete
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*69.46)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*12.07)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*12.22)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*12.07)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*10.92)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*9.03)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*7.33)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.82)}</TableCell>                  
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Produtos para cabelo
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*155.64)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*33.36)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*28.07)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*27.09)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*25.22)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*18.49)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*13.82)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*9.59)}</TableCell>                   
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Perfume
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*335.19)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*70.13)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*66.86)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*57.25)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*50.74)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*39.53)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*29.40)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*21.28)}</TableCell>                    
                                                </TableRow>
                                                

                                                </TableBody>
                                            </Table>
                                        </Box>







                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>





{/* Saúde */}

<React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleSaude(!openModuleSaude)}
                            style={{ color: "white" }}
                            >
                            {openModuleSaude ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Assistência a Saúde</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*4122.97)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleSaude} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                




                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Material de tratamento
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*162.18)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*55.83)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*38.29)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*25.49)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*17.78)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*11.86)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*8.33)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.60)}</TableCell>                       
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Exames diversos
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*77.97)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*20.02)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*9.97)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*13.51)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*12.42)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*9.61)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*7.93)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.51)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Hospitalização
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*14.40)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*4.20)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*2.09)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*3.35)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*3.30)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.80)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.42)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.24)}</TableCell>                      
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Serviços de cirurgia
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*170.19)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*75.15)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*40.84)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*25.81)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*14.46)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*6.56)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.31)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.06)}</TableCell>                      
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Tratamento médico e ambulatorial
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*80.33)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*36.25)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*23.42)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*10.39)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*5.10)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.44)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.71)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.02)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Consulta médica
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*184.88)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*59.60)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*42.05)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*31.46)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*20.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*14.47)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*11.12)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.84)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Consulta e tratamento dentário
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*208.15)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*70.66)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*49.26)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*46.35)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*22.62)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*10.56)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.41)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.29)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Plano/Seguro saúde
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1803.97)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*831.13)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*472.96)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*284.06)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*144.21)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*47.83)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*17.76)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.02)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Remédios
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1385.35)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*398.90)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*282.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*221.11)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*183.23)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*134.50)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*102.87)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*62.40)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Outras
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*35.55)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*11.67)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*6.84)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*8.57)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*3.45)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*2.21)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*2.13)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.68)}</TableCell>                      
                                                </TableRow>
                                                

                                                </TableBody>
                                            </Table>
                                        </Box>







                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>






{/* Educação */}

<React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleEducacao(!openModuleEducacao)}
                            style={{ color: "white" }}
                            >
                            {openModuleEducacao ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Educação</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*2976.50)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleEducacao} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                




                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Artigos escolares
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*85.15)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*26.67)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*17.72)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*11.54)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*12.05)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*7.62)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.45)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.10)}</TableCell>                       
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Livros didáticos e revistas técnicas
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*133.37)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*37.62)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*32.40)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*19.64)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*17.30)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*12.70)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*7.79)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.92)}</TableCell>                    
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Outros cursos e atividades
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*688.10)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*303.92)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*199.16)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*89.77)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*55.46)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*25.98)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*9.29)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.52)}</TableCell>                       
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Cursos superiores
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*711.45)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*284.63)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*147.96)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*133.53)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*93.70)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*33.08)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*13.05)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*5.50)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Cursos regulares
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1163.36)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*677.77)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*242.25)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*133.66)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*62.89)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*26.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*14.24)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.21)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Outras
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*195.06)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*87.81)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*43.19)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*25.26)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*19.60)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*10.03)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.40)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.77)}</TableCell>                        
                                                </TableRow>

                                                </TableBody>
                                            </Table>
                                        </Box>








                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>







{/* Cultura */}

<React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleCultura(!openModuleCultura)}
                            style={{ color: "white" }}
                            >
                            {openModuleCultura ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Recreação e Cultura</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*1451.25)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleCultura} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                


                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Recreações e esportes
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*352.35)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*155.75)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*87.96)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*49.75)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*34.11)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*14.41)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*6.43)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*3.94)}</TableCell>                      
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Periódicos, livros e revistas não didáticos
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*106.03)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*50.71)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*25.46)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*14.95)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*8.66)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*3.87)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.57)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.81)}</TableCell>                      
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Celular e acessórios
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*440.42)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*125.49)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*100.16)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*79.73)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*56.54)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*37.48)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*24.79)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*16.23)}</TableCell>                  
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Brinquedos e jogos
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*105.22)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*42.78)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*23.07)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*14.86)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*12.62)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*6.17)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*3.45)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.27)}</TableCell>                   
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Fumo
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*144.62)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*25.08)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*30.44)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*22.88)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*22.30)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*19.47)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*14.08)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*10.37)}</TableCell>                        
                                                </TableRow>  

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Outras
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*447.26)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*267.48)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*87.84)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*47.60)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*27.12)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*10.16)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*4.52)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.54)}</TableCell>                        
                                                </TableRow>                                                

                                                </TableBody>
                                            </Table>
                                        </Box>



                                
                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>











{/* Pessoais */}

<React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModulePessoais(!openModulePessoais)}
                            style={{ color: "white" }}
                            >
                            {openModulePessoais ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Serviços Pessoais</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*630.90)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModulePessoais} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                




                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Consertos de artigos pessoais
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*18.43)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*5.03)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*3.76)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*2.59)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*3.07)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.94)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.36)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.68)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Manicuro e pedicuro
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*128.22)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*46.45)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*31.53)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*22.80)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*14.85)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*7.31)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*3.55)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.73)}</TableCell>                      
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Cabeleireiro
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*330.39)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*102.71)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*72.00)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*56.66)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*43.11)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*28.16)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*16.93)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*10.82)}</TableCell>                     
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Outras
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*153.84)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*77.01)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*35.56)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*21.53)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*11.28)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*5.09)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*2.18)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*1.19)}</TableCell>                        
                                                </TableRow>
                                                

                                                </TableBody>
                                            </Table>
                                        </Box>






                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>









{/* Despesas */}

<React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleDespesas(!openModuleDespesas)}
                            style={{ color: "white" }}
                            >
                            {openModuleDespesas ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Despesas Diversas</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*1927.23)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleDespesas} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                




                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Imóveis de uso ocasional
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*533.16)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*313.38)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*123.68)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*44.12)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*31.38)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*11.93)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.78)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.89)}</TableCell>                
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Serviços profissionais
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*647.71)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*385.22)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*109.75)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*62.38)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*50.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*20.60)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*13.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.08)}</TableCell>                       
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Cerimônias e festas
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*292.11)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*144.31)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*60.88)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*48.32)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*22.72)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*9.17)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*4.19)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.52)}</TableCell>                    
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Comunicação
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*31.90)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*10.56)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*8.44)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*5.46)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*3.58)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.99)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*1.13)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.74)}</TableCell>                      
                                                </TableRow>
                                                
                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Jogos e apostas
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*109.19)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*27.91)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*29.36)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*16.80)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*15.18)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*9.80)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*5.88)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.26)}</TableCell>                   
                                                </TableRow>
                                                
                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Outras
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*313.17)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*104.72)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*81.08)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*51.51)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*35.92)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*20.48)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*12.75)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.71)}</TableCell>                
                                                </TableRow>
                                                

                                                </TableBody>
                                            </Table>
                                        </Box>






                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>





                {/* Outras Despesas Correntes */}

                <React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleOutrasDespesas(!openModuleOutrasDespesas)}
                            style={{ color: "white" }}
                            >
                            {openModuleOutrasDespesas ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Outras Despesas Correntes</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*10854.62)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleOutrasDespesas} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                




                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Impostos
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*4895.27)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*2869.86)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1087.56)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*558.14)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*237.90)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*82.22)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*39.65)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*19.94)}</TableCell>                
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Contribuições Trabalhistas
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*2613.87)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*1139.20)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*607.30)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*433.93)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*250.24)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*117.60)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*48.20)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*17.40)}</TableCell>                        
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Serviços Bancários
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*802.39)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*430.92)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*145.49)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*104.90)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*66.57)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*31.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*15.13)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*8.04)}</TableCell>                   
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Pensões, mesadas e doações
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*632.59)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*277.09)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*155.62)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*91.69)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*51.83)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*27.86)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*18.04)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*10.46)}</TableCell>                     
                                                </TableRow>
                                                
                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Previdência Privada
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*317.18)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*202.05)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*79.79)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*23.81)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*9.31)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*1.65)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.50)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.07)}</TableCell> 
                                                </TableRow>
                                                
                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Outras
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1593.31)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*952.27)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*358.74)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*167.79)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*71.58)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*25.84)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*12.36)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*4.73)}</TableCell>   
                                                </TableRow>
                                                

                                                </TableBody>
                                            </Table>
                                        </Box>






                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>






                {/* Aumento do Ativo */}

                <React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleAumentoAtivo(!openModuleAumentoAtivo)}
                            style={{ color: "white" }}
                            >
                            {openModuleAumentoAtivo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Aumento do Ativo</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*4373.63)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleAumentoAtivo} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                




                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Imóvel (Aquisição)
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*3545.79)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*2386.34)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*863.93)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*173.74)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*67.23)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*34.15)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*13.41)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*6.99)}</TableCell>                
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Imóvel (Reforma)
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*824.54)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*315.64)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*172.51)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*141.11)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*100.48)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*54.98)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*25.85)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*13.97)}</TableCell>                       
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Outros Investimentos
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*3.28)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*0.42)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*1.67)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*0.75)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*0.28)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*0.04)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*0.09)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*0.03)}</TableCell>           
                                                </TableRow>                                               
                                                

                                                </TableBody>
                                            </Table>
                                        </Box>





                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>






                {/* Diminuição do Passivo */}

                <React.Fragment>
                    <TableRow className={classes.root} style={{ background: "#3f51b5" }}>
                        <TableCell style={{ width: "62px" }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModuleDiminuicaoPassivo(!openModuleDiminuicaoPassivo)}
                            style={{ color: "white" }}
                            >
                            {openModuleDiminuicaoPassivo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    <TableCell style={{ color: "white" }}>Diminuição do Passivo</TableCell>
                    <TableCell style={{ color: "white" }}>R$ {converterReal((totalPopulacao/3)*2278.24)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openModuleDiminuicaoPassivo} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                <TableBody>
                                
                                




                                <Box margin={1}>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Categoria</TableCell>                                                    
                                                        <TableCell>Total</TableCell>                                                    
                                                        <TableCell>A</TableCell>
                                                        <TableCell>A1</TableCell>
                                                        <TableCell>B</TableCell>
                                                        <TableCell>B1</TableCell>
                                                        <TableCell>C</TableCell>
                                                        <TableCell>D</TableCell>
                                                        <TableCell>E</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            <TableBody>


                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Empréstimo
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*1501.56)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*507.70)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*409.44)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*248.08)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*169.71)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*83.39)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*55.19)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*28.05)}</TableCell>       
                                                </TableRow>

                                                <TableRow key={'teste11'}>
                                                    <TableCell component="th" scope="row">
                                                    Prestação de Imóvel
                                                    </TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3)*776.69)}</TableCell>                                                    
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.07)*420.73)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.06)*157.59)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.15)*104.21)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.27)*55.74)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/3.16)*24.66)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.83)*11.00)}</TableCell>
                                                    <TableCell style={{fontSize: '10px'}}>R$ {converterReal((totalPopulacao/2.72)*2.76)}</TableCell>                    
                                                </TableRow>

                                               
                                                </TableBody>
                                            </Table>
                                        </Box>





                                </TableBody>
                                </Table>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>














                </TableBody>
            </Table>
        </TableContainer>
        
        </>
      );

}