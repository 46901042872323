import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';

import Chart from "react-apexcharts";

import api from '../../../api';


export default function PopIdade(props) {
    const [dadosLabel, setDadosLabel] = useState([]);
    const [dadosSeries, setDadosSeries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [dadosLabelHomens, setDadosLabelHomens] = useState([]);
    const [dadosSeriesHomens, setDadosSeriesHomens] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [dadosLabelMulheres, setDadosLabelMulheres] = useState([]);
    const [dadosSeriesMulheres, setDadosSeriesMulheres] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const codCidade = props.codCidade
    const { isDarkModeCont } = useContext(DarkContext);


    const options = {
        chart: { 
            background: 'transparent',           
        defaultLocale: 'pt-br',
        locales: [{
            name: 'pt-br',
            options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            toolbar: {
                download: 'Download SVG',
                selection: 'Seleção',
                selectionZoom: 'Selecionar Zoom',
                zoomIn: 'Mais Zoom',
                zoomOut: 'Menos Zoom',
                pan: 'Mover',
                reset: 'Reiniciar Zoom',
            }
            }
        }],
        type: 'area',     
        // dropShadow: {
        // enabled: true,
        // color: '#000',
        // top: 3,
        // left: 3,
        // blur: 3,
        // opacity: 0.2
        // },
        // height: 150,
        stacked: false,
        toolbar: {
            show: false
          },
        },
        theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
        colors: ['#1fff80', '#4d8eff', '#ff78f6'], 
        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shadeIntensity: 1,
        //     inverseColors: true,
        //     gradientToColors: ["#DB162F"],
        //     opacityFrom: 1,
        //     opacityTo: 1,
        //     type: "vertical",
        //     stops: [0, 30]
        //   }
        // },
        dataLabels: {
            enabled: false
          },
          stroke: {
            width: 2,
            curve: 'smooth'
          },
          xaxis: {
            categories: dadosLabel,
            title: {
              text: 'Idade'
            }
          },
          yaxis: {
            title: {
              text: 'População',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('pt-BR');
              }
            },
          },
          
        
        
      }
    
      const series = [{
        name: 'População Total',
        data: dadosSeries
      },{
        name: 'População Homens',
        data: dadosSeriesHomens
      },{
        name: 'População Mulheres',
        data: dadosSeriesMulheres
      }, ]


       
   useEffect(() => {
    (async () => {
      
      api.post('/consulta/cidade/populacaoidade', {cidade:codCidade})
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        // console.log(datajson)
        // console.log(datajson[0].NC);
        setDadosLabel([datajson[3].D6N, datajson[4].D6N, datajson[5].D6N, datajson[20].D6N, datajson[21].D6N, datajson[6].D6N, datajson[7].D6N, datajson[8].D6N, datajson[9].D6N, datajson[10].D6N, datajson[11].D6N, datajson[12].D6N, datajson[13].D6N, datajson[14].D6N, datajson[15].D6N, datajson[16].D6N, datajson[17].D6N, datajson[18].D6N, datajson[19].D6N, datajson[2].D6N])
        setDadosSeries([datajson[3].V, datajson[4].V, datajson[5].V, datajson[20].V, datajson[21].V, datajson[6].V, datajson[7].V, datajson[8].V, datajson[9].V, datajson[10].V, datajson[11].V, datajson[12].V, datajson[13].V, datajson[14].V, datajson[15].V, datajson[16].V, datajson[17].V, datajson[18].V, datajson[19].V, datajson[2].V])
        
        setDadosSeriesHomens([datajson[24].V, datajson[25].V, datajson[26].V, datajson[41].V, datajson[42].V, datajson[27].V, datajson[28].V, datajson[29].V, datajson[30].V, datajson[31].V, datajson[32].V, datajson[33].V, datajson[34].V, datajson[35].V, datajson[36].V, datajson[37].V, datajson[38].V, datajson[39].V, datajson[40].V, datajson[23].V])
        
        setDadosSeriesMulheres([datajson[45].V, datajson[46].V, datajson[47].V, datajson[62].V, datajson[63].V, datajson[48].V, datajson[49].V, datajson[50].V, datajson[51].V, datajson[52].V, datajson[53].V, datajson[54].V, datajson[55].V, datajson[56].V, datajson[57].V, datajson[58].V, datajson[59].V, datajson[60].V, datajson[61].V, datajson[44].V])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });


      // const { data } = console.log(await api.get('/consulta/ibge/teste'));
      // console.log(data)
      // setDados(data);
    })();
  }, []);

  // useEffect(() => {console.log(dados)}, [dados])


  
  return (
    <div className="app">
         <div className="row">
           <div className="mixed-chart">
             <Chart
               options={options}
               series={series}
               type="area"
               //width="100%"
               height="400vh"
             />
           </div>
         </div>
       </div>
  );
}
