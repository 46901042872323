import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';

import DriveEtaRoundedIcon from '@material-ui/icons/DriveEtaRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import AirportShuttleRoundedIcon from '@material-ui/icons/AirportShuttleRounded';
import MotorcycleRoundedIcon from '@material-ui/icons/MotorcycleRounded';
import DirectionsBusRoundedIcon from '@material-ui/icons/DirectionsBusRounded';
import EmojiTransportationRoundedIcon from '@material-ui/icons/EmojiTransportationRounded';
import CommuteRoundedIcon from '@material-ui/icons/CommuteRounded';
import DirectionsSubwayRoundedIcon from '@material-ui/icons/DirectionsSubwayRounded';
import EventSeatRoundedIcon from '@material-ui/icons/EventSeatRounded';
import TwoWheelerRoundedIcon from '@material-ui/icons/TwoWheelerRounded';
import SingleBedRoundedIcon from '@material-ui/icons/SingleBedRounded';

import Chart from "react-apexcharts";

import api from '../../../api';

const useStyles = makeStyles((theme) => ({
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
}))

export default function Veiculos(props) {
    const classes = useStyles();
    const [dadosLabel, setDadosLabel] = useState([]);
    const [dadosSeries, setDadosSeries] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const codEstado = props.codEstado
    const { isDarkModeCont } = useContext(DarkContext);

    const [loading, setLoading] = useState(true)

    const [automovel, setAutomovel] = useState();
    const [bonde, setBonde] = useState();
    const [caminhao, setCaminhao] = useState();
    const [caminhaoTrator, setCaminhaoTrator] = useState();
    const [caminhonete, setCaminhonete] = useState();
    const [camioneta, setCamioneta] = useState();
    const [chassiplataf, setChassiPlataf] = useState();
    const [ciclomotor, setCiclomotor] = useState();
    const [microonibus, setMicroonibus] = useState();
    const [motocicleta, setMotocicleta] = useState();
    const [motoneta, setMotoneta] = useState();
    const [onibus, setOnibus] = useState();
    const [outros, setOutros] = useState();
    const [quadriciclo, setQuadriciclo] = useState();
    const [reboque, setReboque] = useState();
    const [semiReboque, setSemiReboque] = useState();
    const [sidecar, setSidecar] = useState();
    const [total, setTotal] = useState();
    const [tratorEsteira, setTratorEsteira] = useState();
    const [tratorRodas, setTratorRodas] = useState();
    const [triciclo, setTriciclo] = useState();
    const [utilitarios, setUtilitarios] = useState();


    useEffect(() => {
      (async () => {
      
        await api.post('/consulta/estado/veiculos', {estado:codEstado})
        .then(function (response) {
          // handle success
          //console.log("DADOS: "+JSON.stringify(response) )

         // console.log(response.data)
          const datajson = response.data
          
         // console.log(datajson[0].total)
        
        setDadosLabel(["Automóvel", "Bonde", "Caminhão", "Caminhão Trator", "Caminhonete", "Camioneta", "Chassi Plataforma", "Ciclomotor", "Micro-ônibus", "Motocicleta", "Motoneta", "Ônibus", "Outros", "Quadriciclo", "Reboque", "Semi Reboque", "Sidecar", "Trator Esteira", "Trator Rodas", "Triciclo", "Utilitarios"])
        setDadosSeries([Number(datajson[0].automovel), Number(datajson[0].bonde), Number(datajson[0].caminhao), Number(datajson[0].caminhao_trator), Number(datajson[0].caminhonete), Number(datajson[0].camioneta), Number(datajson[0].chassi_plataf), Number(datajson[0].ciclomotor), Number(datajson[0].microonibus), Number(datajson[0].motocicleta), Number(datajson[0].motoneta), Number(datajson[0].onibus), Number(datajson[0].outros), Number(datajson[0].quadriciclo), Number(datajson[0].reboque), Number(datajson[0].semi_reboque), Number(datajson[0].sidecar), Number(datajson[0].trator_esteira), Number(datajson[0].trator_rodas), Number(datajson[0].triciclo), Number(datajson[0].utilitarios)])
          
        
        setAutomovel(Number(datajson[0].automovel).toLocaleString('pt-BR'));
        setBonde(Number(datajson[0].bonde).toLocaleString('pt-BR'));
        setCaminhao(Number(datajson[0].caminhao).toLocaleString('pt-BR'));
        setCaminhaoTrator(Number(datajson[0].caminhao_trator).toLocaleString('pt-BR'));
        setCaminhonete(Number(datajson[0].caminhonete).toLocaleString('pt-BR'));
        setCamioneta(Number(datajson[0].camioneta).toLocaleString('pt-BR'));
        setChassiPlataf(Number(datajson[0].chassi_plataf).toLocaleString('pt-BR'));
        setCiclomotor(Number(datajson[0].ciclomotor).toLocaleString('pt-BR'));
        setMicroonibus(Number(datajson[0].microonibus).toLocaleString('pt-BR'));
        setMotocicleta(Number(datajson[0].motocicleta).toLocaleString('pt-BR'));
        setMotoneta(Number(datajson[0].motoneta).toLocaleString('pt-BR'));
        setOnibus(Number(datajson[0].onibus).toLocaleString('pt-BR'));
        setOutros(Number(datajson[0].outros).toLocaleString('pt-BR'));
        setQuadriciclo(Number(datajson[0].quadriciclo).toLocaleString('pt-BR'));
        setReboque(Number(datajson[0].reboque).toLocaleString('pt-BR'));
        setSemiReboque(Number(datajson[0].semi_reboque).toLocaleString('pt-BR'));
        setSidecar(Number(datajson[0].sidecar).toLocaleString('pt-BR'));
        setTotal(Number(datajson[0].total).toLocaleString('pt-BR'));
        setTratorEsteira(Number(datajson[0].trator_esteira).toLocaleString('pt-BR'));
        setTratorRodas(Number(datajson[0].trator_rodas).toLocaleString('pt-BR'));
        setTriciclo(Number(datajson[0].triciclo).toLocaleString('pt-BR'));
        setUtilitarios(Number(datajson[0].utilitarios).toLocaleString('pt-BR'));
       
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        
        });
  
  
      })();
    }, []);
  

    const options = {
        chart: {
            background: 'transparent', 
            height: 350,
            type: 'bar',
            events: {
              click: function(chart, w, e) {
                // console.log(chart, w, e)
              }
            },
            toolbar: {
                show: false
              },
          },
          //colors: ['#264aff', '#ff33cf'],
          theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: dadosLabel,
            labels: {
              style: {
              // colors: ['#264aff', '#ff33cf'],
                fontSize: '12px'
              }
            }
          },       
          yaxis: {
            title: {
              text: 'Unidades',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('pt-BR');
              }
            },
          },
    }

    const series = [{
      name: 'Unidades',
        data: dadosSeries
      }]

    return (
      <>

<Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <div style={{textAlign: 'left'}}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                  }}>
                      <EmojiTransportationRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Total de Veículos: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{total}</span> unidades</p>                      
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                  }}>
                      <DriveEtaRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Automóvel: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{automovel}</span> unidades</p>                      
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <DirectionsSubwayRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Bonde: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{bonde}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <LocalShippingRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Caminhão: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{caminhao}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                      <LocalShippingRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Caminhão Trator: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{caminhaoTrator}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <LocalShippingRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Caminhonete: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{caminhonete}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <LocalShippingRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Camioneta: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{camioneta}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <DirectionsBusRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Chassi Plataforma: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{chassiplataf}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <MotorcycleRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Ciclomotor: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{ciclomotor}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <AirportShuttleRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Micro-ônibus: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{microonibus}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <MotorcycleRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Motocicleta: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{motocicleta}</span> unidades</p>
                      )}
                  </div>
                  
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                <div style={{textAlign: 'left'}}>
                
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <MotorcycleRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Motoneta: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{motoneta}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <AirportShuttleRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Ônibus: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{onibus}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <CommuteRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Outros: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{outros}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <SingleBedRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Quadriciclo: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{quadriciclo}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <DirectionsBusRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Reboque: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{reboque}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <DirectionsBusRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Semi Reboque: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{semiReboque}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <TwoWheelerRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Sidecar: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{sidecar}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <EventSeatRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Trator Esteira: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{tratorEsteira}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <EventSeatRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Trator Rodas: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{tratorRodas}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <TwoWheelerRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Triciclo: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{triciclo}</span> unidades</p>
                      )}
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <AirportShuttleRoundedIcon color="secondary"/>
                      {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                      <p style={{marginLeft: '5px'}} className={classes.textSecond}>Utilitarios: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{utilitarios}</span> unidades</p>
                      )}
                  </div>
                  
                </div>
                
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="app">
                  <div className="row">
                    <div className="mixed-chart">
                      <Chart
                        options={options}
                        series={series}
                        type="bar"
                        width="100%"
                        height="500vh"
                      />
                    </div>
                  </div>
                </div>                
              </Grid>

            </Grid> 

        
      </>     
      );

}