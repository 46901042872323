import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import api from '../../api';
import CircularProgress from '@material-ui/core/CircularProgress';
import Sumario from './Sumario/Sumario';
import SobreEstado from '../../Components/Ibge/Estado/SobreEstado'
import PopulacaoEstimada from '../../Components/Ibge/Estado/PopEstimada'
import AreaTerritorial from '../../Components/Ibge/Estado/AreaTerritorial'
import DensidadeDemografica from '../../Components/Ibge/Estado/DensidadeDemografica'
import IDH from '../../Components/Ibge/Estado/IDH'
import ReceitaOrcamentaria from '../../Components/Ibge/Estado/ReceitaOrcamentaria'
import Empresas from '../../Components/Ibge/Estado/Empresas'
import SalariosMinimos from '../../Components/Ibge/Estado/SalariosMinimos'
import PIB from '../../Components/Ibge/Estado/PIB'
import PopCrescAnos from '../../Components/Ibge/Estado/PopCrescAnos'
import { PopPaisEstado, PopUrbanoEstado, PopRuralEstado, PopSexoEstado, PopSexoEstadoUrbano, PopSexoEstadoRural } from '../../Components/Ibge/Estado/PopDadosEstado'
import PopIdade from '../../Components/Ibge/Estado/PopIdade'
import PopRendimento from '../../Components/Ibge/Estado/PopRendimento'
import Consumo from '../../Components/Ibge/Estado/Consumo'
import ImoveisTipos from '../../Components/Ibge/Estado/ImoveisTipos'
import MoradoresDomicilio from '../../Components/Ibge/Estado/MoradoresDomicilio'
import PessoalOcupadoTotal from '../../Components/Ibge/Estado/PessoalOcupadoTotal'
import PessoalOcupadoAssalariado from '../../Components/Ibge/Estado/PessoalOcupadoAssalariado'
import NiveisInstrucaoEscolar from '../../Components/Ibge/Estado/NiveisInstrucaoEscolar'
import Alfabetizacao from '../../Components/Ibge/Estado/Alfabetizacao'
import Analfabetizacao from '../../Components/Ibge/Estado/Analfabetizacao'
import Veiculos from '../../Components/Ibge/Estado/Veiculos'
import TesouroReceita from '../../Components/Ibge/Estado/TesouroReceita'

import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import MapIcon from '@material-ui/icons/Map';
import PersonPinRoundedIcon from '@material-ui/icons/PersonPinRounded';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import NaturePeopleRoundedIcon from '@material-ui/icons/NaturePeopleRounded';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';

import purple from '@material-ui/core/colors/purple';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '5%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  paperNiveis: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: purple[900],
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backflag: {
    // padding:0,
    // margin:0,
    top: 0,
    minHeight: '100vh',
    backgroundImage: `url(${'http://geotaker.com.br/assets/images/saopaulo.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundsize: 'cover',  
  },

  imgresponsive: {
    width: '100px',
    height: 'auto',
  },
  mapresponsive: {
    width: '80%',
    height: 'auto',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fontBold: {
    fontWeight: 900,
  },
}));


export default function Estado(props) {
  const classes = useStyles();
  const codEstado = props.cod;
  const imageBandeira = require(`../../images/bandeiras/${codEstado}.png`)
  const imageMapa = require(`../../images/mapas/${codEstado}.png`)
  const [nomeEstado, setNomeEstado] = useState();
  
  useEffect(() => {
    (async () => {
      
      await api.post('/consulta/estado/nomeestado', {estado:codEstado})
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        
        setNomeEstado(datajson[0].estado)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });


    })();
  }, []);

  return (
<>
    <Accordion style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Estado: {nomeEstado ? nomeEstado : <CircularProgress style={{color: 'green'}} size={15} />}</Typography>
                </AccordionSummary>
                <AccordionDetails>

    <div className={classes.root}>
      
      <Grid container spacing={3}>
     
        <Grid item xs={12}>       
          <Paper className={classes.paperNiveis} elevation={16}>
            <h1>Estudo Geográfico, Social e Econômico</h1>
            <h2>Estado</h2>
            <h1 className={classes.fontBold}>{nomeEstado}</h1>
          </Paper>
          <div className={classes.paper}>
            
              <br />
              {/* <img src="https://www.geotaker.com.br/assets/images/deskbrasil.png" className={classes.imgresponsive} />  */}
              {/* <img src="https://www.geotaker.com.br/assets/images/desksaopauloreduz.png" className={classes.imgresponsive} />  */}
              <img src={imageBandeira} />
        </div>
        </Grid>
        {/* <Grid item xs={12} style={{marginTop: '20px'}}>
          <Paper className={classes.paper} elevation={16}>

            <h2>Sumário</h2>

            
            <Sumario/>

          </Paper>
        </Grid> */}

<Sumario estado={codEstado} cidade={null} bairro={null} shopping={null} />


        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionsobre">
          <Paper className={classes.paper} elevation={16}>

            <h2>Sobre</h2>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                {/* <img src="https://www.geotaker.com.br/assets/images/mapas/estados/saopaulo.png" className={classes.mapresponsive} />  */}
                <img style={{width: '100%'}} src={imageMapa} />
              </Grid>
              <Grid item xs={12} md={8}>
                <SobreEstado codEstado={codEstado} />  
              </Grid>

            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} style={{marginTop: '20px'}} id="sectioninformacoes">
          <Paper className={classes.paper} elevation={16}>

            <h2>Informações</h2>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <div style={{textAlign: 'left'}}>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                  }}>
                      <PeopleAltRoundedIcon color="secondary"/>
                      <PopulacaoEstimada codEstado={codEstado} />
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <MapIcon color="secondary"/>
                      <AreaTerritorial codEstado={codEstado} />
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <PersonPinRoundedIcon color="secondary"/>
                      <DensidadeDemografica codEstado={codEstado} />
                  </div>
                  <Divider />                  
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                      <AccountBalanceWalletRoundedIcon color="secondary"/>
                      <PessoalOcupadoTotal codEstado={codEstado} />
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <NaturePeopleRoundedIcon color="secondary"/>
                      <PessoalOcupadoAssalariado codEstado={codEstado} />
                  </div>
                </div>
                
              </Grid>
              <Grid item xs={12} md={6}>
              <div style={{textAlign: 'left'}}>
                <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <NaturePeopleRoundedIcon color="secondary"/>
                      <IDH codEstado={codEstado} />
                  </div>
                <Divider />                
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <BusinessRoundedIcon color="secondary"/>
                    <Empresas codEstado={codEstado} />
                </div>
                <Divider />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <AccountBalanceWalletRoundedIcon color="secondary"/>
                    <ReceitaOrcamentaria codEstado={codEstado} />
                </div>                
                <Divider />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <MonetizationOnRoundedIcon color="secondary"/>
                    <SalariosMinimos codEstado={codEstado} />
                </div>
                <Divider />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <AccountBalanceTwoToneIcon color="secondary"/>
                    <PIB codEstado={codEstado} />
                </div>
                
                </div>
              </Grid>

            </Grid>           

          </Paper>
        </Grid>





        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionreceitaanual">
          <Paper className={classes.paper} elevation={16}>

            <h2>Receita Anual</h2>

            <TesouroReceita codEstado={codEstado} />          

          </Paper>
        </Grid>




        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionpopulacao">
          <Paper className={classes.paper} elevation={16}>

            <h2>População</h2>

            <Grid container spacing={3}>            
            <Grid item xs={12} md={12}>
                <p style={{textAlign: 'left'}}>Crescimento Populacional</p>
                <Divider />
                <PopCrescAnos codEstado={codEstado} />
                
              </Grid>

              </Grid>

            <Grid container spacing={3}>            
            
              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Estado x País</p>
                <Divider />
                <PopPaisEstado codEstado={codEstado} />
                
              </Grid>
              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Urbana</p>
                <Divider />
                <PopUrbanoEstado codEstado={codEstado} />
              </Grid>

              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Rural</p>
                <Divider />
                <PopRuralEstado codEstado={codEstado} />
              </Grid>

            </Grid>

            <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Total - Sexo</p>
                <Divider />
                <PopSexoEstado codEstado={codEstado} />
              </Grid>
              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Urbana - Sexo</p>
                <Divider />
                <PopSexoEstadoUrbano codEstado={codEstado} />
              </Grid>
              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Rural - Sexo</p>
                <Divider />
                <PopSexoEstadoRural codEstado={codEstado} />
              </Grid>

            </Grid>

          </Paper>
        </Grid>



        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionidade">
          <Paper className={classes.paper} elevation={16}>
            <h2>Idade</h2>
            <PopIdade codEstado={codEstado} />
          </Paper>
        </Grid>
        

        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionrendimento">
          <Paper className={classes.paper} elevation={16}>
            <h2>Rendimento</h2>
            <PopRendimento codEstado={codEstado} />
          </Paper>
        </Grid>


        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionconsumoestado">
          <Paper className={classes.paper} elevation={16}>
            <h2>Consumo</h2>
            <Consumo codEstado={codEstado} />
          </Paper>
        </Grid>


        <Grid item xs={12} style={{marginTop: '20px'}} id="sectioneducacao">
          <Paper className={classes.paper} elevation={16}>

            <h2>Educação</h2>
            
            <Grid container spacing={3}>
              
              <Grid item xs={12} md={6}>
                <p style={{textAlign: 'left'}}>Taxa de Alfabetização</p>
                <Divider />
                <Alfabetizacao codEstado={codEstado} />
              </Grid>
              <Grid item xs={12} md={6}>
                <p style={{textAlign: 'left'}}>Taxa de Analfabetismo</p>
                <Divider />
                <Analfabetizacao codEstado={codEstado} />
              </Grid>

            </Grid>

             

            <NiveisInstrucaoEscolar codEstado={codEstado} />         

          </Paper>
        </Grid>



        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionimoveis">
          <Paper className={classes.paper} elevation={16}>

            <h2>Imóveis</h2>

            <ImoveisTipos codEstado={codEstado} />

          </Paper>
        </Grid>



        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionmoradorespordomicilio">
          <Paper className={classes.paper} elevation={16}>

            <h2>Moradores por domicílio</h2>

            <MoradoresDomicilio codEstado={codEstado} />        

          </Paper>
        </Grid>



        

        
        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionveiculos">
          <Paper className={classes.paper} elevation={16}>

            <h2>Veículos</h2>

            <Veiculos codEstado={codEstado} />           

          </Paper>
        </Grid>


        


      </Grid>
    
      
        
    </div>

    </AccordionDetails>
            </Accordion>
            </>
  );
}
