import React, {useState, useEffect} from 'react';
import api from '../../../api';
import Skeleton from '@material-ui/lab/Skeleton';

export default function Estado(props) {
    const [Estado, setEstado] = useState();
    const [UF, setUF] = useState();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            const tokenEstudo = props.tokenEstudo;
            const codEstado = props.cod;

          await api.post('/cliente/estado', { tokenEstudo, codEstado } )
          .then(function (response) {
            // handle success
            const datajson = response.data
           // console.log("DADOS: "+JSON.stringify(datajson));            
           // console.log(datajson[0].companyLogo)
            // console.log(datajson)
            setEstado(datajson[0].estado)
            setUF(datajson[0].UF)
           setLoading(false)
          })
          .catch(function (error) {
            // handle error
            setLoading(false)
            console.log(error);
          })
          .then(function () {
            // always executed
           
          });
     
     
        })();
      }, []);


    return(
      <div style={{textAlign: 'center',}}>
      {loading ? (
        <Skeleton animation="wave" style={{maxWidth: '40%', marginLeft: '30%', marginRight: '30%' }}/> 
    ) : ( 
        <>{Estado} ({UF})</>
        )}
    </div>
    )

}