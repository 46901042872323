import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import HouseRoundedIcon from '@material-ui/icons/HouseRounded';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';

import Chart from "react-apexcharts";

import api from '../../../api';

const useStyles = makeStyles((theme) => ({
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
}))

function verifIsNan(value) {
  return isNaN(value) ? 0 : value
}

export default function ImoveisTipos(props) {
  const classes = useStyles();
  const [dadosLabel, setDadosLabel] = useState([]);
  const [dadosSeries, setDadosSeries] = useState([0, 0, 0, 0, 0]);
  const codEstado = props.codEstado
  const { isDarkModeCont } = useContext(DarkContext);

  const [loading, setLoading] = useState(true)

  const [totalImoveis, setTotalImoveis] = useState();
  const [casa, setCasa] = useState();
  const [condominio, setCondominio] = useState();
  const [apartamento, setApartamento] = useState();
  const [comodo, setComodo] = useState();
  const [maloca, setMaloca] = useState();
  

  // console.log(props)

   useEffect(() => {
    (async () => {
    
      await api.post('/consulta/estado/imoveis', {estado:codEstado})
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        setDadosLabel([datajson[8].D4N, datajson[9].D4N, datajson[10].D4N, datajson[11].D4N, datajson[12].D4N])
        setDadosSeries([verifIsNan(Number(datajson[8].V)), verifIsNan(Number(datajson[9].V)), verifIsNan(Number(datajson[10].V)), verifIsNan(Number(datajson[11].V)), verifIsNan(Number(datajson[12].V))])
        
        setTotalImoveis(verifIsNan(Number(datajson[1].V)).toLocaleString('pt-BR'))
        setCasa(verifIsNan(Number(datajson[2].V)).toLocaleString('pt-BR'))
        setCondominio(verifIsNan(Number(datajson[3].V)).toLocaleString('pt-BR'))
        setApartamento(verifIsNan(Number(datajson[4].V)).toLocaleString('pt-BR'))
        setComodo(verifIsNan(Number(datajson[5].V)).toLocaleString('pt-BR'))
        setMaloca(verifIsNan(Number(datajson[6].V)).toLocaleString('pt-BR'))
        setLoading(false)
        
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false)
      })
      .then(function () {
        // always executed
      
      });


      // const { data } = console.log(await api.get('/consulta/ibge/teste'));
      // console.log(data)
      // setDados(data);
    })();
  }, []);

  // useEffect(() => {console.log(dados)}, [dados])


  const options = {
    chart: { 
        background: 'transparent',           
    defaultLocale: 'pt-br',
    locales: [{
        name: 'pt-br',
        options: {
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
        shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        toolbar: {
            download: 'Download SVG',
            selection: 'Seleção',
            selectionZoom: 'Selecionar Zoom',
            zoomIn: 'Mais Zoom',
            zoomOut: 'Menos Zoom',
            pan: 'Mover',
            reset: 'Reiniciar Zoom',
        }
        }
    }],
    type: 'donut',     
    // dropShadow: {
    // enabled: true,
    // color: '#000',
    // top: 3,
    // left: 3,
    // blur: 3,
    // opacity: 0.2
    // },
    //height: 150,
    stacked: true,
    toolbar: {
        show: false
      },
    
    },
    theme: {
        mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
    },
    //colors: ['#669DB5', '#E5C6A0', '#669DB5', '#94A74A'],
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "dark",
    //     type: "horizontal",
    //     shadeIntensity: 0.5,
    //     inverseColors: true,
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 100]
    //   }
    // },
    dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      
      legend: {
        show: true,
        position: "left",
        offsetX: -30,
        offsetY: -10,
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
        }
      },
      labels: dadosLabel,
  }

  const series = dadosSeries
  
  return (
    <>

      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <div style={{textAlign: 'left'}}>
          
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                <HomeWorkRoundedIcon color="secondary"/>
                {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Total Imóveis: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{totalImoveis}</span> unidades</p>
                      )}
            </div>
            <Divider />
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                paddingBottom: '0px',
                marginBottom: '0px',
            }}>
                <HomeRoundedIcon color="secondary"/>
                {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Casa: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{casa}</span> unidades</p>
                 )}                      
            </div>
            <Divider />
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                <HouseRoundedIcon color="secondary"/>
                {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Casa de Vila/Condomínio: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{condominio}</span> unidades</p>
                )}
            </div>
            <Divider />
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                <ApartmentRoundedIcon color="secondary"/>
                {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Apartamento: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{apartamento}</span> unidades</p>
                )}
            </div>
            <Divider />
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
                <HomeRoundedIcon color="secondary"/>
                {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Cômodo ou cortiço: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{comodo}</span> unidades</p>
                )}
            </div>
            <Divider />
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}>
                <HomeRoundedIcon color="secondary"/>
                {loading ? (
                        <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
                <p style={{marginLeft: '5px'}} className={classes.textSecond}>Oca ou maloca: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{maloca}</span> unidades</p>
                )}
            </div>
            
            
          </div>
          
        </Grid>
        <Grid item xs={12} md={7}>
          {loading ? (
            <Box display="flex" justifyContent="center">
              <Skeleton variant="circle" width={'20em'} height={'20em'} animation="wave" />
            </Box>
            ) : (
          <div className="app">
            <div className="row">
              <div className="mixed-chart">
                <Chart
                  options={options}
                  series={series}
                  type="pie"
                  width="100%"
                  height="300vh"
                />
              </div>
            </div>
          </div>
            )}
        </Grid>

      </Grid> 

        

    </>
  );
}
