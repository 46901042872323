import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../../api';
import { DataGrid, GridToolbar, GridRowsProp, GridColDef } from '@material-ui/data-grid';
import Tooltip from '@material-ui/core/Tooltip';


import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';


const columns = [
  { field: 'id', headerName: 'ID', flex: 0.2 },
  { field: 'conta', headerName: 'Conta', flex: 1 },
  { field: 'valorReal', headerName: 'Valor', flex: 1 },
];


const useStyles = makeStyles((theme) => ({
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
  root: {
    '& .super-app-theme--header': {
      backgroundColor: `${
        theme.palette.type === 'light' ? '#ffcc40' : 'rgb(67, 67, 67)'
      }` ,
    },
    '& .MuiDataGrid-root': {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.04)',
    },
  },
}))

export default function TesouroReceita(props) {
    const classes = useStyles();
    const [dados, setDados] = useState();
    const [receitaExcIntrOrc, setReceitaExcIntrOrc] = useState();
    const [receitaIntrOrc, setReceitaIntrOrc] = useState();
    const [receitaTotal, setReceitaTotal] = useState();
    const [tableData, setTableData] = useState([])
    const codEstado = props.codEstado   

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
        
          await api.post('/consulta/estado/receitasorcamentarias', {estado:codEstado})
          .then(function (response) {
            // handle success
            
            const datajson = response.data
            // console.log(datajson.items.length)
            // console.log(datajson.items)

            const receitasExcetoIntraOrcamentarias = datajson.items.filter(function(item){
                return (item.cod_conta == 'ReceitasExcetoIntraOrcamentarias');
            });
            const receitasIntraOrcamentarias = datajson.items.filter(function(item){
                return (item.cod_conta == 'ReceitasIntraOrcamentarias');
            });
            const receitaTotal = datajson.items.filter(function(item){
                return (item.cod_conta == 'TotalReceitas');
            });

            // console.log('Receita exceto intra orçamentarias (I): ', (receitasExcetoIntraOrcamentarias[0].valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}));
            // console.log('Receita intra orçamentarias (II): ', (receitasIntraOrcamentarias[0].valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}));
            // console.log('Receita Total (III) = (I + II): ', (receitaTotal[0].valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}));
            
            setReceitaExcIntrOrc((receitasExcetoIntraOrcamentarias[0].valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))
            setReceitaIntrOrc((receitasIntraOrcamentarias[0].valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))
            setReceitaTotal((receitaTotal[0].valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))
          
                  
            const arrayData = [];

            for(var n in datajson.items){
              //console.log(datajson.items[n])
              arrayData.push({id: n, valorReal: (datajson.items[n].valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),  ...datajson.items[n]})
            }
            // console.log(arrayData)

            setTableData(arrayData)          
          
            setLoading(false)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          
          });
    
    
        })();
      }, []);

      return (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
            <p style={{textAlign: 'left'}}>Receitas resumidas</p>
                <Divider />
              <div style={{textAlign: 'left'}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingBottom: '0px',
                    marginBottom: '0px',
                }}>
                    <AttachMoneyRoundedIcon color="secondary"/>
                    {loading ? (
                      <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                    ) : (
                    <p style={{marginLeft: '5px'}} className={classes.textSecond}>Receita Exceto Intra Orçamentarias (I): <span style={{fontSize: '20px'}} className={classes.textPrimary}>{receitaExcIntrOrc}</span></p>                      
                    )}
                </div>
                <Divider />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <MonetizationOnRoundedIcon color="secondary"/>
                    {loading ? (
                      <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                    ) : (
                    <p style={{marginLeft: '5px'}} className={classes.textSecond}>Receita Intra Orçamentarias (II): <span style={{fontSize: '20px'}} className={classes.textPrimary}>{receitaIntrOrc}</span></p>
                    )}
                </div>
                <Divider />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <AccountBalanceRoundedIcon color="secondary"/>
                    {loading ? (
                      <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                    ) : (
                    <p style={{marginLeft: '5px', fontWeight: '600'}} className={classes.textSecond}>Receita Total (III) = (I + II): <span style={{fontSize: '20px'}} className={classes.textPrimary}>{receitaTotal}</span></p>
                    )}
                </div>
                
              </div>
              
            </Grid>
           
            
          </Grid>
          <Grid container spacing={3} style={{marginTop: '40px'}}>
          
              <Grid item xs={12} md={12}>
              <p style={{textAlign: 'left'}}>Receitas detalhadas</p>
              <Divider />
              <div style={{ height: 500, width: '100%', marginTop: '15px' }} className={classes.root}>
                <DataGrid 
                  rows={tableData} 
                  columns={columns} 
                  localeText={
                    {
                      // Root
                      noRowsLabel: 'Nenhuma linha',
                      noResultsOverlayLabel: 'Nenhum resultado encontrado.',
                      errorOverlayDefaultLabel: 'Ocorreu um erro.',
                    
                      // Density selector toolbar button text
                      toolbarDensity: 'Densidade',
                      toolbarDensityLabel: 'Densidade',
                      toolbarDensityCompact: 'Compacto',
                      toolbarDensityStandard: 'Padrão',
                      toolbarDensityComfortable: 'Confortável',
                    
                      // Columns selector toolbar button text
                      toolbarColumns: 'Colunas',
                      toolbarColumnsLabel: 'Exibir seletor de colunas',
                    
                      // Filters toolbar button text
                      toolbarFilters: 'Filtros',
                      toolbarFiltersLabel: 'Exibir filtros',
                      toolbarFiltersTooltipHide: 'Ocultar filtros',
                      toolbarFiltersTooltipShow: 'Exibir filtros',
                      toolbarFiltersTooltipActive: (count) =>
                        `${count} ${count !== 1 ? 'filtros' : 'filtro'} ${count !== 1 ? 'ativos' : 'ativo'}`,
                    
                      // Export selector toolbar button text
                      toolbarExport: 'Exportar',
                      toolbarExportLabel: 'Exportar',
                      toolbarExportCSV: 'Baixar como CSV',
                    
                      // Columns panel text
                      columnsPanelTextFieldLabel: 'Localizar coluna',
                      columnsPanelTextFieldPlaceholder: 'Título da coluna',
                      columnsPanelDragIconLabel: 'Reordenar Coluna',
                      columnsPanelShowAllButton: 'Mostrar todas',
                      columnsPanelHideAllButton: 'Ocultar todas',
                    
                      // Filter panel text
                      filterPanelAddFilter: 'Adicionar filtro',
                      filterPanelDeleteIconLabel: 'Excluir',
                      filterPanelOperators: 'Operadores',
                      filterPanelOperatorAnd: 'E',
                      filterPanelOperatorOr: 'Ou',
                      filterPanelColumns: 'Colunas',
                      filterPanelInputLabel: 'Valor',
                      filterPanelInputPlaceholder: 'Filtrar valor',
                    
                      // Filter operators text
                      filterOperatorContains: 'contém',
                      filterOperatorEquals: 'é igual a',
                      filterOperatorStartsWith: 'começa com',
                      filterOperatorEndsWith: 'termina com',
                      filterOperatorIs: 'é',
                      filterOperatorNot: 'não é',
                      filterOperatorOnOrAfter: 'em ou após',
                      filterOperatorBefore: 'antes de',
                      filterOperatorOnOrBefore: 'em ou antes de',
                      filterOperatorAfter: 'após',
                    
                      // Column menu text
                      columnMenuLabel: 'Menu',
                      columnMenuShowColumns: 'Exibir colunas',
                      columnMenuFilter: 'Filtrar',
                      columnMenuHideColumn: 'Ocultar',
                      columnMenuUnsort: 'Desfazer ordenação',
                      columnMenuSortAsc: 'Ordenar do menor para o maior',
                      columnMenuSortDesc: 'Ordenar do maior para o menor',
                    
                      // Column header text
                      columnHeaderFiltersTooltipActive: (count) =>
                        `${count} ${count !== 1 ? 'filtros' : 'filtro'} ${count !== 1 ? 'ativos' : 'ativo'}`,
                      columnHeaderFiltersLabel: 'Exibir Filtros',
                      columnHeaderSortIconLabel: 'Ordenar',
                    
                      // Rows selected footer text
                      footerRowSelected: (count) =>
                        count !== 1
                          ? `${count.toLocaleString()} linhas selecionadas`
                          : `${count.toLocaleString()} linha selecionada`,
                    
                      // Total rows footer text
                      footerTotalRows: 'Total de linhas:',
                    
                      // Total visible rows footer text
                      footerTotalVisibleRows: (visibleCount, totalCount) =>
                        `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,
                    
                      // Checkbox selection text
                      checkboxSelectionHeaderName: 'Seleção',
                    
                      // Boolean cell text
                      booleanCellTrueLabel: 'sim',
                      booleanCellFalseLabel: 'não',
                    }
                  }
                />
              </div>
              </Grid>
            </Grid>


        </>
      );

}