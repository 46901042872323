import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Context } from '../Context/AuthContext';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import ConsultasMes from '../Components/Charts/ConsultasMes'
import EstadosMes from '../Components/Charts/EstadosMes'
import Atualizacoes from '../Components/Charts/Atualizacoes'

import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import MapTwoToneIcon from '@material-ui/icons/MapTwoTone';
import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ExploreIcon from '@material-ui/icons/Explore';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: '5%',
      marginTop: '100px'
    },
    paper: {
      padding: theme.spacing(2),
    //   textAlign: 'center',
      color: theme.palette.text.primary,
    },
    PaperAltura: {
        padding: theme.spacing(2),
    //   textAlign: 'center',
      color: theme.palette.text.primary,
        height: 287.75
    }
  }));

export default function Dashboard() {
    const classes = useStyles();
    const { dataUser } = useContext(Context);
    const [height, setHeight] = useState(null);

    const divAltura = useCallback(node => {
        if (node !== null) {
          setHeight(node.getBoundingClientRect().height);
         
        }
      }, []);

      const arrayMes = {
      0: "Janeiro",
      1: "Fevereiro",
      2: "Março",
      3: "Abril",
      4: "Maio",
      5: "Junho",
      6: "Julho",
      7: "Agosto",
      8: "Setembro",
      9: "Outubro",
      10: "Novembro",
      11: "Dezembro",
      }   
      
      var dataAtual = new Date();
      var mes = dataAtual.getMonth();

    
    return (
        <div className={classes.root}>
            <h1>Olá, {dataUser.firstName}.</h1>
            <Box display="flex" justifyContent="flex-end">
                <Box style={{paddingBottom: '15px'}}>
                    <Button                    
                        variant="contained"
                        color="primary"
                        size="large"
                        href="/explorar"
                        className={classes.button}
                        startIcon={<ExploreIcon />}
                    >
                        Explorar
                    </Button>

                </Box>
                {/* <Box style={{paddingBottom: '15px', paddingLeft: '15px'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        href="/analise"
                        className={classes.button}
                        startIcon={<AddCircleRoundedIcon />}
                    >
                        Estudo
                    </Button>

                </Box>             */}
            </Box>
            
           
            
            
            <Grid container spacing={3}>
                <Grid item md={12} sm={12}>
                    <Paper className={classes.paper} elevation={10}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <AssessmentRoundedIcon/>
                            <h3 style={{marginLeft: '5px'}}>Estudos de {arrayMes[mes]}</h3>
                        </div>
                        <ConsultasMes />
                    </Paper>
                </Grid>
                
                <Grid item md={6} sm={12} className={classes.Altura}>
                    <Paper className={classes.paper} elevation={10} ref={divAltura}>
                    <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <MapRoundedIcon/>                           
                            <h3 style={{marginLeft: '5px'}}>Estados em alta</h3>
                        </div>
                        <EstadosMes />
                    </Paper>
                </Grid>
                <Grid item md={6} sm={12} style={{width: '100%'}}>
                    <Paper className={classes.paper} elevation={10} style={{width: '100%', height: height}}>
                    <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <UpdateRoundedIcon/>
                            <h3 style={{marginLeft: '5px'}}>Atualizações</h3>
                        </div>
                    <div style={{height: height-80, overflow: 'auto'}}>
                    
                        <Atualizacoes />
                        </div>
                    </Paper>
                </Grid>
            </Grid>        
        
        </div>
    )
}