import React, { useState, useEffect, createContext } from 'react';

//import useDarkMode from './hooks/useDarkMode';

const FlyContext = createContext();

function FlyProvider({ children }) {
    const [flyToMap, setFlyToMap] = useState({});
    const [flyToMapExplorar, setFlyToMapExplorar] = useState({});
    const [flyToMapInitial, setFlyToMapInitial] = useState({});
    const [malhaState, setMalhaState] = useState(false);
    const [malhaIbge, setMalhaIbge] = useState();
    const [cepLatLng, setCepLatLng] = useState();
    const [pointerView, setPointerView] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState([0,0]);
    const [loadingSelect, setLoadingSelect] = useState(false);

    // useEffect(() => {
    //     console.log(flyToMap)
    // }, [flyToMap])

    return (
        <FlyContext.Provider value={{ flyToMap, setFlyToMap, flyToMapInitial, setFlyToMapInitial, malhaState, setMalhaState, cepLatLng, setCepLatLng, selectedPosition, setSelectedPosition, pointerView, setPointerView, loadingSelect, setLoadingSelect, flyToMapExplorar, setFlyToMapExplorar, malhaIbge, setMalhaIbge }}>
          {children}
        </FlyContext.Provider>
      );
}

export { FlyContext, FlyProvider };
