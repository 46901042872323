import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import api from '../api';
import { Context } from '../Context/AuthContext';
import { AdminContext } from '../Context/AdminContext';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: '5%',
    },
    paper: {
      padding: theme.spacing(2),
    //   textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
  }));


export default function Profile() {
  const { dataUser, setDataUser } = useContext(Context);
  const { isAdmin } = useContext(AdminContext);
  const classes = useStyles();  
  const [loadUserData, setLoadUserData] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [companyUser, setCompanyUser] = useState('');
  const [passUser, setPassUser] = useState('');
  const [rePassUser, setRePassUser] = useState('');
  
  useEffect(() => {
    
        setLoadUserData(false)
      const data = api.get('/profile/infos/'+dataUser.id)
      .then(function (response) {    
        
        setFirstName(response.data[0].firstName)
        setSecondName(response.data[0].secondName)
        setEmailUser(response.data[0].email)
        setCompanyUser(response.data[0].company) 
        setLoadUserData(true)  
      })
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleChangeSecondName = (event) => {
    setSecondName(event.target.value);
  };

  const handleChangeEmailUser = (event) => {
    setEmailUser(event.target.value);
  };

  const handleChangeCompanyUser = (event) => {
    setCompanyUser(event.target.value);
  };

  const handleChangePassUser = (event) => {
    setPassUser(event.target.value);
  };

  const handleChangeRePassUser = (event) => {
    setRePassUser(event.target.value);
  };

  async function handleSaveChanges() {

    const datasUserChange = { 'id': dataUser.id, firstName, secondName, emailUser, companyUser }    
    await api.post('/profile/update/data', datasUserChange)
    .then(function (response) {

      localStorage.setItem('token', JSON.stringify(response.data.token));

      const datasUserUpdated = {
        'id': dataUser.id,
        'firstName': firstName,
        'secondName': secondName,
        'email': emailUser,
        'company': companyUser,
      }     
      setDataUser(datasUserUpdated)     
      toast.success("Atualização salva com sucesso!");
      
    })
    .catch(function (error) {
      console.log(error);
      toast.error("Algo deu errado! Informe o suporte.");
    });

  }


  function handleSaveChangesPass() {
    if(passUser !== rePassUser) {
      toast.error("Os campos de senhas devem ser iguais.");
    } else {
      
      const datasUserPassChange = { 'id': dataUser.id, passUser, rePassUser }    
       api.post('/profile/update/pass', datasUserPassChange)
      .then(function (response) {
        console.log(response)
        toast.success("Senha atualizada com sucesso!");
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Algo deu errado! Informe o suporte.");
      });

    } 
    
  }

 
  return (
    loadUserData?  
    <div className={classes.root}>
        <h1>{dataUser.firstName}, esse é seu perfil.</h1>        
        <Paper className={classes.paper} elevation={10}>
            <Grid container spacing={3}>               
                <Grid item md={4} xs={12}>
                    <TextField id="firstName" label="Nome" variant="outlined" value={firstName} onChange={handleChangeFirstName} fullWidth />                
                </Grid>
                <Grid item md={8} xs={12}>
                    <TextField id="secondName" label="Sobrenome" variant="outlined" value={secondName} onChange={handleChangeSecondName} fullWidth />                
                </Grid>
                <Grid item md={8} xs={12}>
                    <TextField id="emailUser" label="Email" variant="outlined" value={emailUser} onChange={handleChangeEmailUser} fullWidth />                
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField id="companyUser" label="Empresa" variant="outlined" value={companyUser} onChange={handleChangeCompanyUser} disabled={!isAdmin} fullWidth />                
                </Grid>
            </Grid>            
        </Paper>
        <Grid
        container        
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{marginTop: '20px'}}
        >
            <Button variant="contained" color="secondary" style={{marginRight: '10px'}} onClick={handleOpen}>
                Alterar senha
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleSaveChanges()}>
                Salvar alterações
            </Button>
            
        </Grid>

        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        
            <Paper className={classes.paper} elevation={10}>
            <Grid
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginTop: '20px'}}
                    >    
                <div  m={1} style={{
                            display: 'flex',
                            alignItems: 'center',
                            
                        }}> 
               
                            <LockTwoToneIcon/>
                            <h2 style={{marginLeft: '5px'}}>Alterar senha</h2>
                        
                </div>
                </Grid>
                
                <form className={classes.root} noValidate autoComplete="off">   
                    <Grid
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginTop: '20px'}}
                    >           
                        <TextField id="newPass" label="Nova senha *" variant="outlined" type="password" style={{marginBottom: '10px'}} onChange={handleChangePassUser} />
                        <TextField id="reNewPass" label="Confirmar Senha *" variant="outlined" type="password" onChange={handleChangeRePassUser} />

                        
                    </Grid> 
                    <Grid
                    container   
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginTop: '20px'}}
                    >    
                        <Button variant="contained" color="primary" style={{marginRight: '10px'}} onClick={() => handleSaveChangesPass()}>
                          Salvar
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleClose}>
                          Cancelar
                        </Button>
                    </Grid>
                </form>
            </Paper>
         
        </Fade>
      </Modal>
    </div>
    
    
    :


    <div className={classes.root}>
        <h1>{dataUser.firstName}, esse é seu perfil.</h1>        
        <Paper className={classes.paper} elevation={10}>
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    <Skeleton animation="wave" height={50} style={{margin: '0px'}}/>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Skeleton animation="wave" height={50} style={{margin: '0px'}} />
                </Grid>
                <Grid item md={8} xs={12}>
                    <Skeleton animation="wave" height={50} style={{margin: '0px'}} />
                </Grid>
                <Grid item md={4} xs={12}>
                    <Skeleton animation="wave" height={50} style={{margin: '0px'}} />
                </Grid>
            </Grid>            
        </Paper>
        <Grid
        container        
        direction="row"
        justify="flex-end"
        alignItems="center"
        style={{marginTop: '20px'}}
        >
            <Skeleton animation="wave" width={150} height={50} style={{marginRight: '10px'}} />
            <Skeleton animation="wave" width={190} height={50} />
            
        </Grid>

    </div>
  );
}
