import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';
import api from '../../../api';
import Chart from "react-apexcharts";

export default function PopulacaoResidenteCorRaca(props) {   
  const { isDarkModeCont } = useContext(DarkContext);
  const [dadosCorRaca, setDadosCorRaca] = useState([0, 0, 0, 0, 0]); 
  const [dadosLabel, setDadosLabel] = useState(["","","","",""])
    const codSetor = props.codSetor

    useEffect(() => {
        (async () => {
          
          await api.post('/consulta/setor/populacaoresidenteracorraca', {setor:codSetor})
          .then(function (response) {
            // handle success
           
            const datajson = response.data
            // console.log(datajson)
            // console.log(datajson.data[1].V)
            // const totalPessoas = (Number(datajson.data[1].V)+Number(datajson.data[2].V)+Number(datajson.data[3].V)+Number(datajson.data[4].V)+Number(datajson.data[5].V))

            var corRaca = {
              '1642': "Branca",
              '1643': "Preta",
              '1644': "Amarela",
              '1645': "Parda",
              '1646': "Indígena"
            }

   
        var chavesAscendentes = Object.keys(datajson.data).sort( function(keyA, keyB) {
          return Number(datajson.data[keyA].VAR) - Number(datajson.data[keyB].VAR);
        });     
            

            setDadosCorRaca([Number(datajson.data[chavesAscendentes[0]].V), Number(datajson.data[chavesAscendentes[1]].V), Number(datajson.data[chavesAscendentes[2]].V), Number(datajson.data[chavesAscendentes[3]].V), Number(datajson.data[chavesAscendentes[4]].V)])
            setDadosLabel([corRaca[Number(datajson.data[chavesAscendentes[0]].VAR)], corRaca[Number(datajson.data[chavesAscendentes[1]].VAR)], corRaca[Number(datajson.data[chavesAscendentes[2]].VAR)], corRaca[Number(datajson.data[chavesAscendentes[3]].VAR)], corRaca[Number(datajson.data[chavesAscendentes[4]].VAR)]])
            // setValorPopulacaoTotal(datajson.data[1].V)
           
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
           
          });
    
    
        })();
      }, []);



      const options = {
        chart: {
            background: 'transparent', 
            height: 200,
            type: 'bar',
            events: {
              click: function(chart, w, e) {
                // console.log(chart, w, e)
              }
            },
            toolbar: {
                show: false
              },
          },
          //colors: ['#264aff', '#ff33cf'],
          theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: dadosLabel,
            labels: {
              style: {
              // colors: ['#264aff', '#ff33cf'],
                fontSize: '8px'
              }
            }
          },       
          yaxis: {
            title: {
              text: 'População',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('pt-BR');
              }
            },
          },
    }

    const series = [{
      name: 'População',
        data: dadosCorRaca
      }]

    return (
      <div className="app">
          <div className="row">
              <div className="mixed-chart">
              <Chart
                  options={options}
                  series={series}
                  type="bar"
                  width="100%"
                  height="250vh"
              />
              </div>
          </div>
      </div>
    )
}