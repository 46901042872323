import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

import Chart from "react-apexcharts";

import api from '../../../api';


export default function Alfabetizacao(props) {
   
    const [dadosLabel, setDadosLabel] = useState([]);
    const [dadosSeries, setDadosSeries] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const codEstado = props.codEstado
    const { isDarkModeCont } = useContext(DarkContext);

    const [loading, setLoading] = useState(true)

     
    useEffect(() => {
        (async () => {
          
          await api.post('/consulta/estado/alfabetizacao', {estado:codEstado})
          .then(function (response) {
            // handle success
            const datajson = response.data
           // console.log("DADOS: "+JSON.stringify(datajson));
            
           setDadosSeries(Math.round(datajson[1].V))
           setLoading(false)
          })
          .catch(function (error) {
            // handle error
            setLoading(false)
            console.log(error);
          })
          .then(function () {
            // always executed
           
          });
     
     
        })();
      }, []);
  

    const options = {
        chart: { 
            background: 'transparent',   
            height: 350,        
        defaultLocale: 'pt-br',
        locales: [{
            name: 'pt-br',
            options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            toolbar: {
                download: 'Download SVG',
                selection: 'Seleção',
                selectionZoom: 'Selecionar Zoom',
                zoomIn: 'Mais Zoom',
                zoomOut: 'Menos Zoom',
                pan: 'Mover',
                reset: 'Reiniciar Zoom',
            }
            }
        }],
        type: 'donut',     
       //  dropShadow: {
       //  enabled: true,
       //  color: '#000',
       //  top: 3,
       //  left: 3,
       //  blur: 3,
       //  opacity: 0.2
       //  },
       //  height: 150,
        stacked: true,
        
        },
        theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
        //colors: ['#264aff', '#ff33cf'],
        fill: {
         type: 'gradient',
         gradient: {
           shade: 'dark',
           type: 'horizontal',
           shadeIntensity: 0.5,
           gradientToColors: ['#ABE5A1'],
           inverseColors: true,
           opacityFrom: 1,
           opacityTo: 1,
           stops: [0, 100]
         }
       },
       
       plotOptions: {
         radialBar: {
           startAngle: -135,
           endAngle: 225,
            hollow: {
             margin: 0,
             size: '70%',
             background: isDarkModeCont ? '#ababab' : '#fff',
             image: undefined,
             imageOffsetX: 0,
             imageOffsetY: 0,
             position: 'front',
             dropShadow: {
               enabled: true,
               top: 3,
               left: 0,
               blur: 4,
               opacity: 0.24
             }
           },
           track: {
             background: '#fff',
             strokeWidth: '67%',
             margin: 0, // margin is in pixels
             dropShadow: {
               enabled: true,
               top: -3,
               left: 0,
               blur: 4,
               opacity: 0.35
             }
           },
       
           dataLabels: {
             show: true,
             name: {
               offsetY: -10,
               show: true,
               color: '#888',
               fontSize: '17px'
             },
             value: {
               formatter: function(val) {
                 return parseInt(val);
               },
               color: '#111',
               fontSize: '36px',
               show: true,
             }
           }
         }
       },
       stroke: {
         lineCap: 'round'
       },
          
         //  legend: {
         //    show: false,
         //    position: "top",
         //    offsetX: -30,
         //    offsetY: -10,
         //    formatter: function (val, opts) {
         //      return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
         //    }
         //  },
          labels: ['%'],
      }
     
      const series = [dadosSeries]

      return (
          <>
          {loading ? (
            <Box display="flex" justifyContent="center" style={{marginTop: '25px'}}>
              <Skeleton variant="circle" width={'16em'} height={'16em'} animation="wave" />
            </Box>
            ) : (
                <div className="app">
                    <div className="row">
                        <div className="mixed-chart">
                        <Chart
                            options={options}
                            series={series}
                            type="radialBar"
                            width="100%"
                            height="300vh"
                        />
                        </div>
                    </div>
                </div>  
            )}
        </> 
        );

}