import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import './index.css';
import { AuthProvider } from './Context/AuthContext';
import { DarkProvider } from './Context/DarkContext';
import { AdminProvider } from './Context/AdminContext';
import { LogoProvider } from './Context/LogoContext';

import App from './App';

ReactDOM.render(
  // <React.StrictMode>
  <AdminProvider>
    <AuthProvider>
      <DarkProvider>  
        <LogoProvider>
          <App />
        </LogoProvider>                          
      </DarkProvider>
    </AuthProvider>
  </AdminProvider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);
