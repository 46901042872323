import React, { useState, useEffect, createContext } from 'react';

//import useDarkMode from './hooks/useDarkMode';

const DarkContext = createContext();

function DarkProvider({ children }) {
 
  const [isDarkModeCont, setIsDarkModeCont] = useState(false);

    var statusLocalStorageThemeCont = localStorage.getItem('theme')
    if (statusLocalStorageThemeCont === "true") { statusLocalStorageThemeCont = true} else { statusLocalStorageThemeCont = false}
    
    useEffect(() => {
        setIsDarkModeCont(statusLocalStorageThemeCont)    
      },[])

    useEffect(() => {
      localStorage.setItem('theme', isDarkModeCont);
    }, [isDarkModeCont])

    function changeThemeMode(theme) {
      setIsDarkModeCont(theme)
    }
    
     
      return (
        <DarkContext.Provider value={{ isDarkModeCont, changeThemeMode }}>
          {children}
        </DarkContext.Provider>
      );
}

export { DarkContext, DarkProvider };
