import React, { Component, useContext, useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { DarkContext } from '../../../Context/DarkContext';
import api from '../../../api';


function generateDayWiseTimeSeries(baseval, count, yrange) {

    var i = 0;
    var series = [];
    while (i < count) {
      var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
  
      series.push([baseval, y]);
      baseval += 86400000;
      i++;
    }
    return series;
  }
  

export default function ConsultasMes(props) {

  const { isDarkModeCont } = useContext(DarkContext);
  const tokenUser = localStorage.getItem('token')
  const [dados, setDados] = useState()
  const [datas, setDatas] = useState([])
  const [estados, setEstados] = useState([])
  const [cidades, setCidades] = useState([])
  const [bairros, setBairros] = useState([])
  const [shoppings, setShoppings] = useState([])
  const [setores, setSetores] = useState([])
  
  
  
  useEffect(() => {
    (async () => {
      
      await api.post('/dashboard/estudosmes', { tokenUser })
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        // console.log(datajson)

        datajson.map((item) => { 
          // console.log(item) 
          setDatas(datas => [...datas, item.data])
          setEstados(estados => [...estados, item.estados])
          setCidades(cidades => [...cidades, item.cidades])
          setBairros(bairros => [...bairros, item.bairros])
          setShoppings(shoppings => [...shoppings, item.shoppings])
          setSetores(setores => [...setores, item.setores])
          
        })

        // console.log(datajson[0].NC);
        setDados(datajson)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });

    })();
  }, []);
  

  const options = {
    chart: { 
        background: 'transparent',           
    defaultLocale: 'pt-br',
    locales: [{
        name: 'pt-br',
        options: {
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
        shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        toolbar: {
            download: 'Download SVG',
            selection: 'Seleção',
            selectionZoom: 'Selecionar Zoom',
            zoomIn: 'Mais Zoom',
            zoomOut: 'Menos Zoom',
            pan: 'Mover',
            reset: 'Reiniciar Zoom',
        }
        }
    }],
    type: 'line',     
    dropShadow: {
    enabled: true,
    color: '#000',
    top: 18,
    left: 7,
    blur: 10,
    opacity: 0.2
    },
    height: 150,
    stacked: false,
    events: {
    selection: function (chart, e) {
        console.log(new Date(e.xaxis.min))
        }
    }
    },
    theme: {
        mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
    },
    // colors: ['#673AB7', '#00E396', '#1E88E5', '#fcba03'],
    dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1,
        curve: 'smooth'
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [20, 100, 100, 100]
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      },
      xaxis: {
        type: 'datetime',
        // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        categories: datas
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy'
        },
      },
  }
  
  const series = [{
    name: 'Estado',
    data: estados
  }, {
    name: 'Cidade',
    data: cidades
  }, {
    name: 'Bairro',
    data: bairros
  }, {
    name: 'Shopping',
    data: shoppings
  }, {
    name: 'Setor',
    data: setores
  }]

  

  return(
    <div className="app">
         <div className="row">
           <div className="mixed-chart">
             <Chart
               options={options}
               series={series}
               type="area"
               width="100%"
               height="200vh"
             />
           </div>
         </div>
       </div>
  )
}

