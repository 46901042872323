import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';

import Chart from "react-apexcharts";

import api from '../../../api';

function verifIsNan(value) {
  return isNaN(value) ? 0 : value
}

export function PopCidadeEstado(props) {
  const [dadoPaisEstado, setDadoPaisEstado] = useState(0);
  const codCidade = props.codCidade
  const codEstado = props.codEstado
  const { isDarkModeCont } = useContext(DarkContext);

 const options = {
   chart: { 
       background: 'transparent',   
       height: 350,        
   defaultLocale: 'pt-br',
   locales: [{
       name: 'pt-br',
       options: {
       months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
       shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
       days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
       shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
       toolbar: {
           download: 'Download SVG',
           selection: 'Seleção',
           selectionZoom: 'Selecionar Zoom',
           zoomIn: 'Mais Zoom',
           zoomOut: 'Menos Zoom',
           pan: 'Mover',
           reset: 'Reiniciar Zoom',
       }
       }
   }],
   type: 'donut',     
  //  dropShadow: {
  //  enabled: true,
  //  color: '#000',
  //  top: 3,
  //  left: 3,
  //  blur: 3,
  //  opacity: 0.2
  //  },
  //  height: 150,
   stacked: true,
   
   },
   theme: {
       mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
   },
   //colors: ['#264aff', '#ff33cf'],
   fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      shadeIntensity: 0.5,
      gradientToColors: ['#ABE5A1'],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
       hollow: {
        margin: 0,
        size: '70%',
        background: isDarkModeCont ? '#ababab' : '#fff',
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: 'front',
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: '#fff',
        strokeWidth: '67%',
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },
  
      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: true,
          color: '#888',
          fontSize: '17px'
        },
        value: {
          formatter: function(val) {
            return parseInt(val);
          },
          color: '#111',
          fontSize: '36px',
          show: true,
        }
      }
    }
  },
  stroke: {
    lineCap: 'round'
  },
     
    //  legend: {
    //    show: false,
    //    position: "top",
    //    offsetX: -30,
    //    offsetY: -10,
    //    formatter: function (val, opts) {
    //      return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
    //    }
    //  },
     labels: ['%'],
 }

 const series = [dadoPaisEstado]


 
  useEffect(() => {
   (async () => {
     
     await api.post('/consulta/cidade/populacaocidadeestado', {cidade:codCidade, estado:codEstado})
     .then(function (response) {
       // handle success
       const datajson = response.data
      // console.log("DADOS: "+JSON.stringify(datajson));
       let popEstado = datajson[1].V;
       let popCidade = datajson[2].V;
       let popCidadeXeStado = (popCidade/popEstado)*100;
      setDadoPaisEstado(Math.round(popCidadeXeStado))
     })
     .catch(function (error) {
       // handle error
       console.log(error);
     })
     .then(function () {
       // always executed
      
     });


   })();
 }, []);

//  useEffect(() => {console.log(dadosUrbRur)}, [dadosUrbRur])


 
 return (
   <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="radialBar"
              width="100%"
              height="250vh"
            />
          </div>
        </div>
      </div>
 );
}


export function PopUrbanoEstado(props) {
  const [dadoUrbano, setDadoUrbano] = useState(0);
  const codCidade = props.codCidade
  const { isDarkModeCont } = useContext(DarkContext);

 const options = {
   chart: { 
       background: 'transparent',   
       height: 350,        
   defaultLocale: 'pt-br',
   locales: [{
       name: 'pt-br',
       options: {
       months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
       shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
       days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
       shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
       toolbar: {
           download: 'Download SVG',
           selection: 'Seleção',
           selectionZoom: 'Selecionar Zoom',
           zoomIn: 'Mais Zoom',
           zoomOut: 'Menos Zoom',
           pan: 'Mover',
           reset: 'Reiniciar Zoom',
       }
       }
   }],
   type: 'donut',     
  //  dropShadow: {
  //  enabled: true,
  //  color: '#000',
  //  top: 3,
  //  left: 3,
  //  blur: 3,
  //  opacity: 0.2
  //  },
  //  height: 150,
   stacked: true,
   
   },
   theme: {
       mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
   },
   //colors: ['#264aff', '#ff33cf'],
   fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      shadeIntensity: 0.5,
      gradientToColors: ['#ABE5A1'],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
       hollow: {
        margin: 0,
        size: '70%',
        background: isDarkModeCont ? '#ababab' : '#fff',
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: 'front',
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: '#fff',
        strokeWidth: '67%',
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },
  
      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: true,
          color: '#888',
          fontSize: '17px'
        },
        value: {
          formatter: function(val) {
            return parseInt(val);
          },
          color: '#111',
          fontSize: '36px',
          show: true,
        }
      }
    }
  },
  stroke: {
    lineCap: 'round'
  },
     
    //  legend: {
    //    show: false,
    //    position: "top",
    //    offsetX: -30,
    //    offsetY: -10,
    //    formatter: function (val, opts) {
    //      return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
    //    }
    //  },
     labels: ['%'],
 }

 const series = [dadoUrbano]


 
  useEffect(() => {
   (async () => {
     
     await api.post('/consulta/cidade/populacaoprocentagem', {cidade:codCidade})
     .then(function (response) {
       // handle success
       const datajson = response.data
      // console.log("DADOS: "+JSON.stringify(datajson));
       
      setDadoUrbano(Math.round(datajson[2].V))
     })
     .catch(function (error) {
       // handle error
       console.log(error);
     })
     .then(function () {
       // always executed
      
     });


   })();
 }, []);

//  useEffect(() => {console.log(dadosUrbRur)}, [dadosUrbRur])


 
 return (
   <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="radialBar"
              width="100%"
              height="250vh"
            />
          </div>
        </div>
      </div>
 );
}


export function PopRuralEstado(props) {
  const [dadoRural, setDadoRural] = useState(0);
  const codCidade = props.codCidade
  const { isDarkModeCont } = useContext(DarkContext);

 const options = {
   chart: { 
       background: 'transparent',   
       height: 350,        
   defaultLocale: 'pt-br',
   locales: [{
       name: 'pt-br',
       options: {
       months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
       shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
       days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
       shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
       toolbar: {
           download: 'Download SVG',
           selection: 'Seleção',
           selectionZoom: 'Selecionar Zoom',
           zoomIn: 'Mais Zoom',
           zoomOut: 'Menos Zoom',
           pan: 'Mover',
           reset: 'Reiniciar Zoom',
       }
       }
   }],
   type: 'donut',     
  //  dropShadow: {
  //  enabled: true,
  //  color: '#000',
  //  top: 3,
  //  left: 3,
  //  blur: 3,
  //  opacity: 0.2
  //  },
  //  height: 150,
   stacked: true,
   
   },
   theme: {
       mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
   },
   //colors: ['#264aff', '#ff33cf'],
   fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'horizontal',
      shadeIntensity: 0.5,
      gradientToColors: ['#ABE5A1'],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
       hollow: {
        margin: 0,
        size: '70%',
        background: isDarkModeCont ? '#ababab' : '#fff',
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: 'front',
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: '#fff',
        strokeWidth: '67%',
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },
  
      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: true,
          color: '#888',
          fontSize: '17px'
        },
        value: {
          formatter: function(val) {
            return parseInt(val);
          },
          color: '#111',
          fontSize: '36px',
          show: true,
        }
      }
    }
  },
  stroke: {
    lineCap: 'round'
  },
     
    //  legend: {
    //    show: false,
    //    position: "top",
    //    offsetX: -30,
    //    offsetY: -10,
    //    formatter: function (val, opts) {
    //      return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
    //    }
    //  },
     labels: ['%'],
 }

 const series = [dadoRural]


 
  useEffect(() => {
   (async () => {
     
     await api.post('/consulta/cidade/populacaoprocentagem', {cidade:codCidade})
     .then(function (response) {
       // handle success
       const datajson = response.data
      // console.log("DADOS: "+JSON.stringify(datajson));
       
      setDadoRural(Math.round(verifIsNan(Number(datajson[3].V))))
     })
     .catch(function (error) {
       // handle error
       console.log(error);
     })
     .then(function () {
       // always executed
      
     });

   })();
 }, []);



 
 return (
   <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="radialBar"
              width="100%"
              height="250vh"
            />
          </div>
        </div>
      </div>
 );
}


export function PopSexoEstado(props) {
  const [dadosSexoEstado, setDadosSexoEstado] = useState([0, 0]);
  const codCidade = props.codCidade
  const { isDarkModeCont } = useContext(DarkContext);

 const options = {
   chart: { 
       background: 'transparent',           
   defaultLocale: 'pt-br',
   locales: [{
       name: 'pt-br',
       options: {
       months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
       shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
       days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
       shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
       toolbar: {
           download: 'Download SVG',
           selection: 'Seleção',
           selectionZoom: 'Selecionar Zoom',
           zoomIn: 'Mais Zoom',
           zoomOut: 'Menos Zoom',
           pan: 'Mover',
           reset: 'Reiniciar Zoom',
       }
       }
   }],
   type: 'donut',     
   // dropShadow: {
   // enabled: true,
   // color: '#000',
   // top: 3,
   // left: 3,
   // blur: 3,
   // opacity: 0.2
   // },
   // height: 150,
   stacked: true,
   
   },
   theme: {
       mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
   },
   colors: ['#264aff', '#ff33cf'],
   // fill: {
   //   type: "gradient",
   //   gradient: {
   //     shade: "dark",
   //     type: "horizontal",
   //     shadeIntensity: 0.5,
   //     inverseColors: true,
   //     opacityFrom: 1,
   //     opacityTo: 1,
   //     stops: [0, 100]
   //   }
   // },
   dataLabels: {
       enabled: false
     },
     stroke: {
       curve: 'smooth'
     },
     
     legend: {
       show: true,
       position: "bottom",
       // offsetX: -30,
       // offsetY: -10,
       formatter: function (val, opts) {
         return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
       }
     },
     labels: ['Homens', 'Mulheres'],
 }

 const series = dadosSexoEstado


 
  useEffect(() => {
   (async () => {
     
     await api.post('/consulta/cidade/populacaosexo', {cidade:codCidade})
     .then(function (response) {
       // handle success
       
       const datajson = response.data
       // console.log(datajson[0].NC);
       setDadosSexoEstado([Math.round(Number(datajson[2].V)), Math.round(Number(datajson[3].V))])
     })
     .catch(function (error) {
       // handle error
       console.log(error);
     })
     .then(function () {
       // always executed
      
     });


     // const { data } = console.log(await api.get('/consulta/ibge/teste'));
     // console.log(data)
     // setDados(data);
   })();
 }, []);

 // useEffect(() => {console.log(dados)}, [dados])


 
 return (
   <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="pie"
              width="100%"
              height="250vh"
            />
          </div>
        </div>
      </div>
 );
}


export function PopSexoEstadoUrbano(props) {
  const [dadosSexoEstadoUrb, setDadosSexoEstadoUrb] = useState([0, 0]);
  const codCidade = props.codCidade
  const { isDarkModeCont } = useContext(DarkContext);

 const options = {
   chart: { 
       background: 'transparent',           
   defaultLocale: 'pt-br',
   locales: [{
       name: 'pt-br',
       options: {
       months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
       shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
       days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
       shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
       toolbar: {
           download: 'Download SVG',
           selection: 'Seleção',
           selectionZoom: 'Selecionar Zoom',
           zoomIn: 'Mais Zoom',
           zoomOut: 'Menos Zoom',
           pan: 'Mover',
           reset: 'Reiniciar Zoom',
       }
       }
   }],
   type: 'donut',     
   // dropShadow: {
   // enabled: true,
   // color: '#000',
   // top: 3,
   // left: 3,
   // blur: 3,
   // opacity: 0.2
   // },
   // height: 150,
   stacked: true,
   
   },
   theme: {
       mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
   },
   colors: ['#264aff', '#ff33cf'],
   // fill: {
   //   type: "gradient",
   //   gradient: {
   //     shade: "dark",
   //     type: "horizontal",
   //     shadeIntensity: 0.5,
   //     inverseColors: true,
   //     opacityFrom: 1,
   //     opacityTo: 1,
   //     stops: [0, 100]
   //   }
   // },
   dataLabels: {
       enabled: false
     },
     stroke: {
       curve: 'smooth'
     },
     
     legend: {
       show: true,
       position: "bottom",
       // offsetX: -30,
       // offsetY: -10,
       formatter: function (val, opts) {
         return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
       }
     },
     labels: ['Homens', 'Mulheres'],
 }

 const series = dadosSexoEstadoUrb


 
  useEffect(() => {
   (async () => {
     
     await api.post('/consulta/cidade/populacaosexo', {cidade:codCidade})
     .then(function (response) {
       // handle success
       
       const datajson = response.data

       let totalUrb = datajson[4].V;
       let totalUrbHom = datajson[5].V;
       let totalUrbMul = datajson[6].V;

       let valorTotalUrbHom = (totalUrbHom * 100) / totalUrb;
       let valorTotalUrbMul = (totalUrbMul * 100) / totalUrb;


       setDadosSexoEstadoUrb([Math.round(Number(valorTotalUrbHom)), Math.round(Number(valorTotalUrbMul))])
     })
     .catch(function (error) {
       // handle error
       console.log(error);
     })
     .then(function () {
       // always executed
      
     });


     // const { data } = console.log(await api.get('/consulta/ibge/teste'));
     // console.log(data)
     // setDados(data);
   })();
 }, []);

 // useEffect(() => {console.log(dados)}, [dados])


 
 return (
   <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="pie"
              width="100%"
              height="250vh"
            />
          </div>
        </div>
      </div>
 );
}


export function PopSexoEstadoRural(props) {
  const [dadosSexoEstadoRur, setDadosSexoEstadoRur] = useState([0, 0]);
  const codCidade = props.codCidade
  const { isDarkModeCont } = useContext(DarkContext);

 const options = {
   chart: { 
       background: 'transparent',           
   defaultLocale: 'pt-br',
   locales: [{
       name: 'pt-br',
       options: {
       months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
       shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
       days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
       shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
       toolbar: {
           download: 'Download SVG',
           selection: 'Seleção',
           selectionZoom: 'Selecionar Zoom',
           zoomIn: 'Mais Zoom',
           zoomOut: 'Menos Zoom',
           pan: 'Mover',
           reset: 'Reiniciar Zoom',
       }
       }
   }],
   type: 'donut',     
   // dropShadow: {
   // enabled: true,
   // color: '#000',
   // top: 3,
   // left: 3,
   // blur: 3,
   // opacity: 0.2
   // },
   // height: 150,
   stacked: true,
   
   },
   theme: {
       mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
   },
   colors: ['#264aff', '#ff33cf'],
   // fill: {
   //   type: "gradient",
   //   gradient: {
   //     shade: "dark",
   //     type: "horizontal",
   //     shadeIntensity: 0.5,
   //     inverseColors: true,
   //     opacityFrom: 1,
   //     opacityTo: 1,
   //     stops: [0, 100]
   //   }
   // },
   dataLabels: {
       enabled: false
     },
     stroke: {
       curve: 'smooth'
     },
     
     legend: {
       show: true,
       position: "bottom",
       // offsetX: -30,
       // offsetY: -10,
       formatter: function (val, opts) {
         return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
       }
     },
     labels: ['Homens', 'Mulheres'],
 }

 const series = dadosSexoEstadoRur


 
  useEffect(() => {
   (async () => {
     
     await api.post('/consulta/cidade/populacaosexo', {cidade:codCidade})
     .then(function (response) {
       // handle success
       
       const datajson = response.data

       let totalRur = datajson[7].V;
       let totalRurHom = datajson[8].V;
       let totalRurMul = datajson[9].V;

       let valorTotalRurHom = (totalRurHom * 100) / totalRur;
       let valorTotalRurMul = (totalRurMul * 100) / totalRur;


       setDadosSexoEstadoRur([Math.round(verifIsNan(Number(valorTotalRurHom))), Math.round(verifIsNan(Number(valorTotalRurMul)))])
     })
     .catch(function (error) {
       // handle error
       console.log(error);
     })
     .then(function () {
       // always executed
      
     });


     // const { data } = console.log(await api.get('/consulta/ibge/teste'));
     // console.log(data)
     // setDados(data);
   })();
 }, []);

 // useEffect(() => {console.log(dados)}, [dados])


 
 return (
   <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={options}
              series={series}
              type="pie"
              width="100%"
              height="250vh"
            />
          </div>
        </div>
      </div>
 );
}