import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';

import Chart from "react-apexcharts";

import api from '../../../api';


export default function PopRendimento(props) {
    const [dadosLabel, setDadosLabel] = useState([]);
    const [dadosSeries, setDadosSeries] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const codCidade = props.codCidade
    const { isDarkModeCont } = useContext(DarkContext);


    const options = {
        chart: { 
            background: 'transparent',           
        defaultLocale: 'pt-br',
        locales: [{
            name: 'pt-br',
            options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            toolbar: {
                download: 'Download SVG',
                selection: 'Seleção',
                selectionZoom: 'Selecionar Zoom',
                zoomIn: 'Mais Zoom',
                zoomOut: 'Menos Zoom',
                pan: 'Mover',
                reset: 'Reiniciar Zoom',
            }
            }
        }],
        type: 'line',     
        // dropShadow: {
        // enabled: true,
        // color: '#000',
        // top: 3,
        // left: 3,
        // blur: 3,
        // opacity: 0.2
        // },
        height: 150,
        stacked: true,
        toolbar: {
            show: false
          },
        },
        plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
        theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
        //colors: ['#264aff', '#ff33cf'],
        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shade: "dark",
        //     type: "horizontal",
        //     shadeIntensity: 0.5,
        //     inverseColors: true,
        //     opacityFrom: 1,
        //     opacityTo: 1,
        //     stops: [0, 100]
        //   }
        // },
        dataLabels: {
            enabled: true
          },
        //   stroke: {
        //     curve: 'smooth'
        //   },
          xaxis: {
            categories: dadosLabel,
            title: {
              text: '% da população'
            }
          },
          // yaxis: {
          //   title: {
          //     text: '% da População',
          //   },
          // },
          yaxis: {
            
            labels: {
              formatter: function (value) {
                return value.toLocaleString('pt-BR') + "%";
              }
            },
          },
          
        
        
      }
    
      const series = [{
        name: 'População',
        data: dadosSeries
      }]


       
   useEffect(() => {
    (async () => {
      
      await api.post('/consulta/cidade/populacaorendimento', {cidade:codCidade})
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        // console.log(datajson[0].NC);
        setDadosLabel([datajson[9].D4N, datajson[2].D4N, datajson[3].D4N, datajson[4].D4N, datajson[5].D4N, datajson[6].D4N, datajson[7].D4N, datajson[8].D4N])
        setDadosSeries([Number(datajson[9].V), Number(datajson[2].V), Number(datajson[3].V), Number(datajson[4].V), Number(datajson[5].V), Number(datajson[6].V), Number(datajson[7].V), Number(datajson[8].V)])
    
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });


      // const { data } = console.log(await api.get('/consulta/ibge/teste'));
      // console.log(data)
      // setDados(data);
    })();
  }, []);

  // useEffect(() => {console.log(dados)}, [dados])


  
  return (
    <div className="app">
         <div className="row">
           <div className="mixed-chart">
             <Chart
               options={options}
               series={series}
               type="bar"
               //width="100%"
               height="300vh"
             />
           </div>
         </div>
       </div>
  );
}
