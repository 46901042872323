import React, { useContext, useState, useEffect } from 'react';
import api from '../../api';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { LogoContext } from '../../Context/LogoContext';

const useStyles = makeStyles((theme) => ({
    logo: {
        padding: theme.spacing(2),
        textAlign: 'center',
        marginTop: '100px',
      },
}));

export default function Logo() {
    const classes = useStyles();
    const { LogoCompany, setLogoCompany } = useContext(LogoContext);
    const [loading, setLoading] = useState(true)
    const [logoComp, setLogoComp] = useState()
   

    useEffect(() => {
        (async () => {
            const tokenUser = localStorage.getItem('token');

          await api.post('/administracao/logo/consultarlogoid', { tokenUser } )
          .then(function (response) {
            // handle success
            const datajson = response.data
           // console.log("DADOS: "+JSON.stringify(datajson));            
           // console.log(datajson[0].companyLogo)

           setLogoCompany(datajson[0].companyLogo)
           setLoading(false)
          })
          .catch(function (error) {
            // handle error
            setLoading(false)
            console.log(error);
          })
          .then(function () {
            // always executed
           
          });
     
     
        })();
      }, []);

      
return (
    <div className={classes.logo}>       
        {loading ? (
            <Skeleton animation="wave" variant="rect" width={'100%'} height={118} /> 
        ) : (       
            <img src={`https://api.geotaker.com.br/logos/${LogoCompany}`}  style={{maxWidth: '80%'}}></img>
        )}
    </div>
    )
}