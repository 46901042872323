import React, { useContext, useState, useEffect } from 'react';
import api from '../api';
import { Context } from '../Context/AuthContext';
import { FlyContext } from '../Context/FlyContext';
import { DarkContext } from '../Context/DarkContext';
import history from '../history';
const axios = require('axios');
import '../css/leaflet.css';

import ComboBoxEstados from '../Components/ComboBoxs/Estados';
import ComboBoxCidades from '../Components/ComboBoxs/Cidades';
import ComboBoxBairros from '../Components/ComboBoxs/Bairros';
import ComboBoxShoppings from '../Components/ComboBoxs/Shoppings';

import EstudoShare from '../Components/EstudoShare';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';
import Popper from '@material-ui/core/Popper';
import Swal from 'sweetalert2'
import Skeleton from '@material-ui/lab/Skeleton';
// import Drawer from '@mui/material/Drawer';
import { icon, icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8 } from "../js/leaflet/constants";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import GroupIcon from '@material-ui/icons/Group';
import WcIcon from '@material-ui/icons/Wc';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SearchIcon from '@material-ui/icons/Search';
import ExploreIcon from '@material-ui/icons/Explore';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse';
import red from '@material-ui/core/colors/red';
import { HuePicker } from 'react-color';

import LocationCityIcon from '@material-ui/icons/LocationCity';
import MapIcon from '@material-ui/icons/Map';
import StreetviewIcon from '@material-ui/icons/Streetview';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import CropIcon from '@material-ui/icons/Crop';

import '../js/leaflet/leaflet.css'
import '../js/leaflet/leaflet.js'
import '../js/sinopsesetores/Control.Geocoder.css'
import '../js/sinopsesetores/Control.Geocoder.js'
import '../js/sinopsesetores/jquery-1.6.2.js'
import '../js/sinopsesetores/jquery-ui-1.8.14.custom.min.js'
import '../js/ControlLayersTree/L.Control.Layers.Tree.css'
import '../js/ControlLayersTree/L.Control.Layers.Tree.js'

import '../js/leafletDraw/Leaflet.draw.js'
import '../js/leafletDraw/Leaflet.Draw.Event.js'
import '../js/leafletDraw/leaflet.draw.css'

import '../js/leafletDraw/Toolbar.js'
import '../js/leafletDraw/Tooltip.js'


import '../js/leafletDraw/GeometryUtil.js'
import '../js/leafletDraw/LatLngUtil.js'
import '../js/leafletDraw/LineUtil.Intersect.js'
import '../js/leafletDraw/Polygon.Intersect.js'
import '../js/leafletDraw/Polyline.Intersect.js'
import '../js/leafletDraw/TouchEvents.js'

import '../js/leafletDraw/DrawToolbar.js'
import '../js/leafletDraw/Draw.Feature.js'
import '../js/leafletDraw/Draw.SimpleShape.js'
import '../js/leafletDraw/Draw.Polyline.js'
import '../js/leafletDraw/Draw.Marker.js'
import '../js/leafletDraw/Draw.CircleMarker.js'
import '../js/leafletDraw/Draw.Circle.js'
import '../js/leafletDraw/Draw.Polygon.js'
import '../js/leafletDraw/Draw.Rectangle.js'


import '../js/leafletDraw/EditToolbar.js'
import '../js/leafletDraw/EditToolbar.Edit.js'
import '../js/leafletDraw/EditToolbar.Delete.js'


import '../js/leafletDraw/Control.Draw.js'

import '../js/leafletDraw/Edit.Poly.js'
import '../js/leafletDraw/Edit.SimpleShape.js'
import '../js/leafletDraw/Edit.Marker.js'
import '../js/leafletDraw/Edit.CircleMarker.js'
import '../js/leafletDraw/Edit.Circle.js'
import '../js/leafletDraw/Edit.Rectangle.js'


// import '../js/sinopsesetores/jSonpLoad.js'
// import '../js/sinopsesetores/newml.js'
// import '../js/sinopsesetores/params.js'
// import '../js/sinopsesetores/cartograma.js'
// import '../js/sinopsesetores/dados.js'
// import '../js/sinopsesetores/cargaDados.js'
// import '../js/sinopsesetores/main.js'

var newml_data = new Array()
var objDataTmp = new Array();
var shapes = new Array();

var map;
var groupLayers = [];
var drawnItems

var markerBusca = L.marker([],{draggable:true}).on('dragend',function(e){
	reverseGeocode(e.target.getLatLng());
}).bindPopup("");


	
var layer;












// function getUrlPontos(id)
//     {
// 		var strFolder = "SP";
// 		var arrNiveis = new Array(2,7,9,11);
// 		if (id.length > 2)
// 		{
// 			var i = 0;
// 			while (arrNiveis[i] != id.length)
// 			{
// 				strFolder += id.substr(0,arrNiveis[i]) + "/";
// 				i++;
// 			}
// 		}
// 		return "https://servicodados.ibge.gov.br/Malha/" + strFolder + id + ".js";
//     }



// getUrlPontos(3500501)

// console.log(newml_data)








const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: '3%',
      zIndex: '1000',    
    },
    paper: {
      padding: theme.spacing(1),
    //   textAlign: 'center',
      color: theme.palette.text.primary,
    },
    slider: {    
        // padding: '20px',
        // maxWidth: '90%'
    },
    panel: {      
    //   position: 'relative',
        // backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'rgba(100, 100, 111, 0.8) 0px 10px 20px 0px',     
        overflow: 'auto',
        width: 'auto',
		    borderRadius: '0 0 20px 20px'
    },
    panelEstudo: {
        boxShadow: 'rgba(100, 100, 111, 0.8) 0px 10px 20px 0px',   
        overflow: 'auto',
        width: 'auto',  
        borderRadius: '0 0 20px 20px',
        paddingBottom: '5px'
    },
    panelInside: {
      position: 'relative',
      maxHeight: '25em', 
      borderRadius: '0 0 20px 20px'
    },
    wrapper: {    
        position: 'relative',     
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    wrapper2: {    
		position: 'relative',     
		display: 'flex',
		paddingBottom: '20px',
		alignItems: 'center',
		justifyContent: 'center',
    },
	wrapper3: {    
		position: 'relative',     
		display: 'flex',
		paddingBottom: '10px',
		paddingTop: '5px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	wrapper4: {    	
		paddingTop: '15px',		
	},
  wrapper5: {    
		position: 'relative',     
		display: 'flex',	
    paddingBottom: '10px',	
		alignItems: 'center',
		justifyContent: 'center',
    },
    buttonWrapper: {    
      position: 'relative',     
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '22px',
      // paddingBottom: '2px',
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonExcluir: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[700],
      '&:hover': {
        backgroundColor: red[900],
      },
    },
    buttonSalvar: {
      color: 'white',
      backgroundColor: '#448746',
      '&:hover': {
        backgroundColor: '#3a663b',
      },
    },
    buttonsEspacados: {
      marginTop: 10,
      marginBottom: 10,
      position: 'relative',     
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonSuccess: {
    margin: theme.spacing(1),
    backgroundColor: 'green',
    },
    title: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    indicator: {
        backgroundColor: "#ff002b"
      },    
    modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    },
    buttonProgress: {
        color: green[500],     
    },
    customizedButton: {
      position: 'absolute',
      left: '90%',
      top: '5%',
      // backgroundColor: 'lightgray',
      color: 'gray',
    },
    customizedButtonDetalhes: {
      position: 'absolute',
      left: '85%',
      top: '0%',
      // backgroundColor: 'lightgray',
      // color: 'gray',
    },
    formControl: {
		// position: 'absolute',
      	margin: theme.spacing(1),	  
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 120,
		width: '100%'
    },   
	sliders: {
		width: '100%'
	},
	toolbarCidade: {
	
		minHeight: 0,
		width: '100%'
	},
	carregandoMalha: {
		position: 'absolute',
		marginLeft: '45%',
		top: '45%',
      	zIndex: '1001',
	},
	legenda: {
		// height: '20px',
		borderRadius: '10px',
		backgroundColor: 'red',
		backgroundImage: 'linear-gradient(to right, #FFFB0B , #E6D506 , #CCAA01 , #B48000 , #995500 , #802B00 , #660100 )',
    },
    formControlLabel:{
        fontSize: '13px'
    }
  }));


  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={2}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
 
  const followersMarks = [
    {
      value: 0,
      scaledValue: 0,
      label: "0"
    },
    {
      value: 25,
      scaledValue: 200000,
      label: "200k"
    },
    {
      value: 50,
      scaledValue: 500000,
      label: "500k"
    },
    {
      value: 75,
      scaledValue: 1000000,
      label: "1M"
    },
    {
      value: 100,
      scaledValue: 2000000,
      label: "2M"
    },
    {
      value: 125,
      scaledValue: 5000000,
      label: "5M"
    },
    {
      value: 150,
      scaledValue: 7500000,
      label: "7.5M"
    },
    {
      value: 175,
      scaledValue: 10000000,
      label: "10M"
    },
    {
      value: 200,
      scaledValue: 13000000,
      label: "13M"
    }
  ];


  const followersMarksSalario = [
    {
      value: 0,
      scaledValue: 0,
      label: "0"
    },
    {
      value: 1,
      scaledValue: 1,
      label: "1"
    },
    {
      value: 2,
      scaledValue: 2,
      label: "2"
    },
    {
      value: 3,
      scaledValue: 3,
      label: "3"
    },
    {
      value: 4,
      scaledValue: 4,
      label: "4"
    },
    {
      value: 5,
      scaledValue: 5,
      label: "5"
    },
    {
      value: 6,
      scaledValue: 6,
      label: "6"
    },
    {
      value: 7,
      scaledValue: 7,
      label: "7"
    },
    {
      value: 8,
      scaledValue: 8,
      label: "8"
    },
    {
      value: 9,
      scaledValue: 9,
      label: "9"
    },
    {
      value: 10,
      scaledValue: 10,
      label: "10"
    }
  ];

  const scaleValues = (valueArray) => {
    return [scale(valueArray[0]), scale(valueArray[1])];
  };
  const scale = (value) => {
    if (value === undefined) {
      return undefined;
    }
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = followersMarks[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark.scaledValue;
    }
    const nextMark = followersMarks[previousMarkIndex + 1];
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
    return remainder * increment + previousMark.scaledValue;
  };
  
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  }

  
  

export default function Allinone() {
const classes = useStyles();
const { dataUser } = useContext(Context);
const { isDarkModeCont } = useContext(DarkContext);
const { setFlyToMapExplorar, setMalhaIbge } = useContext(FlyContext);
const [malha, setMalha] = useState();
const [valueTab, setValueTab] = React.useState(0);
const [minMaxTab, setMinMaxTab] = useState(true);
const [minMaxTabInfo, setMinMaxTabInfo] = useState(true);
const [openTabs, setOpenTabs] = useState(true);
const [expanded, setExpanded] = React.useState(false);
const [openDetalhes, setOpenDetalhes] = useState(false);
const [value, setValue] = React.useState([0, 200]);
const [valueSliderSalario, setValueSliderSalario] = React.useState([0, 7]);
const [buscaSucesso, setBuscaSucesso] = useState(false);
const [sucessoBusca, setSucessoBusca] = useState(false);
const [stateMenu, setStateMenu] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  

const [cartoDecod, setCartoDecod] = useState();
const [polygonos, setPolygons] = useState([]);
const [nmlPoly, setNmlPoly] = useState([]);
const [shapesSaved, setShapesSaves] = useState([])

const [mapCriado, setMapCriado] = useState();
const [idLayer, setIdLayer] = useState();
const [liberaPinMapa, setLiberaPinMapa] = useState(true)
// useEffect(()=> {alert(liberaPinMapa)}, [liberaPinMapa])
const [razaoSexo, setRazaoSexo] = useState(false)

const [loadingBusca, setLoadingBusca] = useState(false);
const [openModal, setOpenModal] = useState(false);
const [cidadesReceb, setCidadesReceb] = useState([])
const [qtdadeCidadesReceb, setqtdadeCidadesReceb] = useState();
const [codCidade, setCodCidade] = useState();
const [codCidadeAnt, setCodCidadeAnt] = useState();

const [popCidade, setPopCidade] = useState();
const [loadingVerCidade, setLoadingVerCidade] = useState(false);
const [loadingMalhaCidade, setLoadingMalhaCidade] = useState(false);

const [nomeMunicDetalhes, setNomeMunicDetalhes] = useState();
const [nomeUfDetalhes, setNomeUfDetalhes] = useState();
const [nomeEstDetalhes, setNomeEstDetalhes] = useState();
const [nomeMesorregDetalhes, setNomeMesorregDetalhes] = useState();
const [nomeMicrorregDetalhes, setNomeMicrorregDetalhes] = useState();

const [loadingEmpresasCidade, setLoadingEmpresasCidade] = useState(false);
const [dadosEmpresasCidade, setDadosEmpresasCidade] = useState();
const [loadingSalarioMedio, setLoadingSalarioMedio] = useState(false);
const [dadosSalarioMedio, setDadosSalarioMedio] = useState();
const [loadingDensDemog, setLoadingDensDemog] = useState(false);
const [dadosDensDemog, setDadosDensDemog] = useState();
const [loadingPib, setLoadingPib] = useState(false);
const [dadosPib, setDadosPib] = useState();

const [stateSexo, setStateSexo] = React.useState(0);

const [loadingEstudo, setLoadingEstudo] = useState(false);
const [successoEstudo, setSuccessoEstudo] = useState(false);

const [loadingEstudoCidade, setLoadingEstudoCidade] = useState(false);
const [successoEstudoCidade, setSuccessoEstudoCidade] = useState(false);

const [loadingEstudoBairro, setLoadingEstudoBairro] = useState(false);
const [successoEstudoBairro, setSuccessoEstudoBairro] = useState(false);

const [loadingEstudoShopping, setLoadingEstudoShopping] = useState(false);
const [successoEstudoShopping, setSuccessoEstudoShopping] = useState(false);
const [buttonCheckShoppingBairro, setButtonCheckShoppingBairro] = useState(false);

var pontos = L.layerGroup();
var layerAreas = L.layerGroup();

const [openModalPesqNeg, setOpenModalPesqNeg] = useState(false);
const [valueLatLngPesqNeg, setValueLatLngPesqNeg] = useState({})
const [valuePesqNeg, setValuePesqNeg] = useState()
const [loadingPesqNeg, setLoadingPesqNeg] = useState(false);
const handleOpenModalPesqNeg = () => {   
  setOpenModalPesqNeg(true);
};
const handleCloseModalPesqNeg = () => {   
  setOpenModalPesqNeg(false);
};


const [colorNewArea, setColorNewArea] = useState('#5DA4DB');
const handleChangeColorNewArea = color => setColorNewArea(color.hex);

useEffect(()=>{ 
  valueLayerNewArea && !verifyTypeIsMarker ?  valueLayerNewArea.setStyle({color: colorNewArea, fillColor: colorNewArea}) : null
  
},[colorNewArea] )

const [colorEditArea, setColorEditArea] = useState();
const handleChangeColorEditArea = color => setColorEditArea(color.hex);




  const [openModalEditarArea, setOpenModalEditarArea] = useState(false);
  const [loadingDadosArea, setLoadingDadosArea] = useState(false)
  const [valueEditIdArea, setValueEditIdArea] = useState()
  const [valueEditTypeArea, setValueEditTypeArea] = useState()
  const [valueEditNomeArea, setValueEditNomeArea] = useState()
  const [valueEditCategoriaArea, setValueEditCategoriaArea] = useState()
  const [valueEditObsArea, setValueEditObsArea] = useState()
  
  const handleOpenModalEditarArea = (idArea) => {
    document.getElementById("buttonDetalhesArea").disabled = true;
    document.getElementById("buttonDetalhesArea").style.backgroundColor = "#E0E0E0";
    document.getElementById("buttonDetalhesArea").style.color = "#BDBDBD";
    document.getElementById("buttonDetalhesArea").style.boxShadow = "1px 1px 5px grey";
    document.getElementById("buttonDetalhesArea").innerHTML = '<img src="https://geotaker.com.br/images/loading.gif" style="position: absolute;left: 10px; top:3px;" width="25"> <span style="padding-left: 20px; font-size: 12px;">AGUARDE</span>'
    setLoadingDadosArea(true)  
    
    api.post('/explorar/areas/detalhes', {idArea: idArea})
          .then(function (response) {
            // handle success
            
            const datajson = response.data
            
          //  console.log(datajson) 
           setValueEditIdArea(datajson.id)
           setValueEditTypeArea(datajson.type)
           setValueEditNomeArea(datajson.nome)    
           setValueEditCategoriaArea(datajson.categoria)
           setValueEditObsArea(datajson.observacoes)
           setColorEditArea(datajson.cor)
           
           if(datajson.type === "marker") {
            setVerifyTypeIsMarker(true)
           } else {
            setVerifyTypeIsMarker(false)
           }

           setOpenModalEditarArea(true);
           setLoadingDadosArea(false)
           
  
            document.getElementById("buttonDetalhesArea").disabled = false;
            document.getElementById("buttonDetalhesArea").style.backgroundColor = "#2A5CF1";
            document.getElementById("buttonDetalhesArea").style.color = "white";
            document.getElementById("buttonDetalhesArea").style.boxShadow = "0px 0px 0px";
            document.getElementById("buttonDetalhesArea").innerHTML = '<img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">EDITAR</span>'
  
          })
          .catch(function (error) {
            // handle error
            setLoadingDadosArea(false)
            console.log(error);
          })
  
  };
  const handleCloseModalEditarArea = () => {   
    setOpenModalEditarArea(false);
    setValueEditIdArea()
    setValueEditTypeArea()
    setValueEditNomeArea()    
    setValueEditCategoriaArea()
    setValueEditObsArea()
    // setColorEditArea()
  };


  const [salvandoEditarArea, setSalvandoEditarArea] = useState(false)
  const salvarEditarArea = (idArea) => {
    setSalvandoEditarArea(true)
    api.post('/explorar/areas/salvareditar', 
    {
      idArea: idArea,
      nome: valueEditNomeArea,
      categoria: valueEditCategoriaArea,
      observacoes: valueEditObsArea,
      cor: colorEditArea
    })
    .then(function (response) {
             
            var nomeCategoria
            switch (valueEditCategoriaArea) {
              case 1:
                var nomeCategoria = "Clientes"
                break;
              case 2:
                var nomeCategoria = "Concorrentes"
                break;
              case 3:
                var nomeCategoria = "Estudos"
                break;
              case 4:
                var nomeCategoria = "Fornecedores"
                break;
              case 5:
                var nomeCategoria = "Unidades Franqueadas"
                break;
              case 6:
                var nomeCategoria = "Minhas Unidades"
                break;
              case 7:
                var nomeCategoria = "Parceiros"
                break;
              case 8:
                var nomeCategoria = "Geral"
                break;
            }
    
      var containerEditArea = $('<div />');             

      containerEditArea.html(`<h3 style="word-wrap: break-word; margin-block-end: 0;">${valueEditNomeArea}</h3>`)
      containerEditArea.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
      containerEditArea.append(`<p style="word-wrap: break-word; margin-block-start: 0; font-size: 10px">${valueEditObsArea}</p>`)
      containerEditArea.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
      containerEditArea.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesArea" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 100px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">EDITAR</span></button>').click(function() {
          
              handleOpenModalEditarArea(idArea)
              })[0])
              containerEditArea.append('</div>')

              // console.log(valueEditTypeArea)
      
      if(valueEditTypeArea === "marker") {
        var marker = map.getMarkerById(idArea)
        marker.setPopupContent(containerEditArea[0])        
      } else if (valueEditTypeArea === "polygon" || valueEditTypeArea === "rectangle") {
        var marker = map.getPolygonById(idArea)
        marker.setPopupContent(containerEditArea[0])
        marker.setStyle({color: colorEditArea, fillColor: colorEditArea});
      } else if (valueEditTypeArea === "polyline") {
        var marker = map.getPolylineById(idArea)
        marker.setPopupContent(containerEditArea[0])
        marker.setStyle({color: colorEditArea, fillColor: colorEditArea});
      } else if (valueEditTypeArea === "circle" || valueEditTypeArea === "circlemarker") {
        var marker = map.getCircleById(idArea)
        marker.setPopupContent(containerEditArea[0])
        marker.setStyle({color: colorEditArea, fillColor: colorEditArea});
      }
      
      
      

      handleCloseModalEditarArea()
      toast.success("Alterações salvas com sucesso!")
      setSalvandoEditarArea(false)
    })
    .catch(function (error) {
      // handle error
      toast.error("Ocorreu algum erro e a alteração da área não foi salva. Se persistir informe o suporte.")
      setSalvandoEditarArea(false)
      console.log(error);
    })
  }


  function removeMeLayerGroup(id) {
    map.eachLayer(function (layer) {
      console.log(layer)
		if (layer._leaflet_id === id){
      console.log(layer)
			map.removeLayer(layer)
		}
	});	
}


  const removerArea = (idArea) => {
  // console.log(map.getMarkerById(idPonto))

  Swal.fire({
    title: 'Confirma a exclusão da área?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    confirmButtonColor: '#448746',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#B71C1C',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {

            api.post('/explorar/areas/excluir', 
            {
              idArea: idArea    
            })
            .then(function (response) {
             
              if(valueEditTypeArea === "marker") {
                var marker = map.getMarkerById(idArea)
                map.removeLayer(marker);       
              } else if (valueEditTypeArea === "polygon" || valueEditTypeArea === "rectangle") {
                var marker = map.getPolygonById(idArea)
                // console.log(marker._leaflet_id)
                map.removeLayer(marker);
              } else if (valueEditTypeArea === "polyline") {
                var marker = map.getPolylineById(idArea)
                map.removeLayer(marker);
              } else if (valueEditTypeArea === "circle" || valueEditTypeArea === "circlemarker") {
                var marker = map.getCircleById(idArea)
                map.removeLayer(marker);
              }

              handleCloseModalEditarArea()
              toast.success("Área excluída com sucesso!")
            })
            .catch(function (error) {
              // handle error
              toast.error("Ocorreu algum erro e a exclusão do ponto não foi realizada. Se persistir informe o suporte.")
              console.log(error);
            })

        } 
  })

}











const [openModalEditarPonto, setOpenModalEditarPonto] = useState(false);
const [loadingDadosPonto, setLoadingDadosPonto] = useState(false)
const [valueEditIdPonto, setValueEditIdPonto] = useState()
const [valueLatLngEditPonto, setValueLatLngEditPonto] = useState()
const [valueCodSetorEditPonto, setValueCodSetorEditPonto] = useState()
const [valueEditNomePonto, setValueEditNomePonto] = useState()
const [valueEditCategoriaPonto, setValueEditCategoriaPonto] = useState()
const [valueEditObsPonto, setValueEditObsPonto] = useState()

const handleOpenModalEditarPonto = (idPonto) => {
  document.getElementById("buttonDetalhesPonto").disabled = true;
  document.getElementById("buttonDetalhesPonto").style.backgroundColor = "#E0E0E0";
  document.getElementById("buttonDetalhesPonto").style.color = "#BDBDBD";
  document.getElementById("buttonDetalhesPonto").style.boxShadow = "1px 1px 5px grey";
  document.getElementById("buttonDetalhesPonto").innerHTML = '<img src="https://geotaker.com.br/images/loading.gif" style="position: absolute;left: 10px; top:3px;" width="25"> <span style="padding-left: 20px; font-size: 12px;">CARREGANDO...</span>'
  setLoadingDadosPonto(true)  
  setOpenModalEditarPonto(true);
  api.post('/explorar/pontos/detalhes', {idPonto: idPonto})
        .then(function (response) {
          // handle success
          
          const datajson = response.data
          
         console.log(datajson) 
         setValueEditIdPonto(datajson.id)
         setValueLatLngEditPonto({lat: datajson.latitude, lng: datajson.longitude})
         setValueCodSetorEditPonto(datajson.codSetor)
         setValueEditNomePonto(datajson.nome)    
         setValueEditCategoriaPonto(datajson.categoria)
         setValueEditObsPonto(datajson.observacoes)

         setLoadingDadosPonto(false)
         

          document.getElementById("buttonDetalhesPonto").disabled = false;
          document.getElementById("buttonDetalhesPonto").style.backgroundColor = "#2A5CF1";
          document.getElementById("buttonDetalhesPonto").style.color = "white";
          document.getElementById("buttonDetalhesPonto").style.boxShadow = "0px 0px 0px";
          document.getElementById("buttonDetalhesPonto").innerHTML = '<img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">VER DETALHES</span>'

        })
        .catch(function (error) {
          // handle error
          setLoadingDadosPonto(false)
          console.log(error);
        })

};
const handleCloseModalEditarPonto = () => {   
  setOpenModalEditarPonto(false);
  setValueEditIdPonto()
  setValueLatLngEditPonto()
  setValueCodSetorEditPonto()
  setValueEditNomePonto()    
  setValueEditCategoriaPonto()
  setValueEditObsPonto()
};

const [salvandoEditarPonto, setSalvandoEditarPonto] = useState(false)
const salvarEditarPonto = (idPonto) => {
  setSalvandoEditarPonto(true)
  api.post('/explorar/pontos/salvareditar', 
  {
    idPonto: idPonto,
    nome: valueEditNomePonto,
    categoria: valueEditCategoriaPonto,
    observacoes: valueEditObsPonto
  })
  .then(function (response) {

          var iconCategoria
          var nomeCategoria
          switch (valueEditCategoriaPonto) {
            case 1:
              var iconCategoria = icon1
              var nomeCategoria = "Clientes"
              break;
            case 2:
              var iconCategoria = icon2
              var nomeCategoria = "Concorrentes"
              break;
            case 3:
              var iconCategoria = icon3
              var nomeCategoria = "Estudos"
              break;
            case 4:
              var iconCategoria = icon4
              var nomeCategoria = "Fornecedores"
              break;
            case 5:
              var iconCategoria = icon5
              var nomeCategoria = "Unidades Franqueadas"
              break;
            case 6:
              var iconCategoria = icon6
              var nomeCategoria = "Minhas Unidades"
              break;
            case 7:
              var iconCategoria = icon7
              var nomeCategoria = "Parceiros"
              break;
            case 8:
              var iconCategoria = icon8
              var nomeCategoria = "Geral"
              break;
          }
  
    var containerEditPonto = $('<div />');        
    containerEditPonto.html(`<h3 style="width: 150px; word-wrap: break-word; margin-block-end: 0;">${valueEditNomePonto}</h3>`)
    containerEditPonto.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
    containerEditPonto.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
    containerEditPonto.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesPonto" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 150px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">VER DETALHES</span></button>').click(function() {                
            handleOpenModalEditarPonto(idPonto)
            })[0])
    containerEditPonto.append('</div>')
    
    var marker = map.getMarkerById(idPonto)
    marker.setIcon(iconCategoria)
    marker.setPopupContent(containerEditPonto[0])

    handleCloseModalEditarPonto()
    toast.success("Alterações salvas com sucesso!")
    setSalvandoEditarPonto(false)
  })
  .catch(function (error) {
    // handle error
    toast.error("Ocorreu algum erro e a alteração do ponto não foi salva. Se persistir informe o suporte.")
    setSalvandoEditarPonto(false)
    console.log(error);
  })
}

const removerPonto = (idPonto) => {
  // console.log(map.getMarkerById(idPonto))

  Swal.fire({
    title: 'Confirma a exclusão do ponto?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    confirmButtonColor: '#448746',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#B71C1C',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {

            api.post('/explorar/pontos/excluir', 
            {
              idPonto: idPonto    
            })
            .then(function (response) {
              var marker = map.getMarkerById(idPonto)
              map.removeLayer(marker);
              handleCloseModalEditarPonto()
              toast.success("Ponto excluído com sucesso!")
            })
            .catch(function (error) {
              // handle error
              toast.error("Ocorreu algum erro e a exclusão do ponto não foi realizada. Se persistir informe o suporte.")
              console.log(error);
            })

        } 
  })

}



const [openModalMarcarPonto, setOpenModalMarcarPonto] = useState(false);
const [valueLatLngMarcarPonto, setValueLatLngMarcarPonto] = useState()
const [valueCodSetorMarcarPonto, setValueCodSetorMarcarPonto] = useState()
const [optionsCategoriasPonto, setOptionsCategoriasPonto] = React.useState([]);
const [openCategoriasPonto, setOpenCategoriasPonto] = React.useState(false);
const [loadingBuscaMarcarPonto, setLoadingBuscaMarcarPonto] = useState(false);
const loadingCategoriasPonto = openCategoriasPonto && optionsCategoriasPonto.length === 0;
const [valueNomePonto, setValueNomePonto] = useState()
const [valueCategoriaPonto, setValueCategoriaPonto] = useState()
const [valueObsPonto, setValueObsPonto] = useState()

const [valueLayerNewArea, setValueLayerNewArea] = useState()
const [openModalMarcarArea, setOpenModalMarcarArea] = useState(false);
const [valueNomeArea, setValueNomeArea] = useState()
const [valueCategoriaArea, setValueCategoriaArea] = useState()
const [valueObsArea, setValueObsArea] = useState()
const [valueCoordenadasArea, setValueCoordenadasArea] = useState()
const [valueTypeArea, setValueTypeArea] = useState()
const [verifyTypeIsMarker, setVerifyTypeIsMarker] = useState(false)
const [valueCenterArea, setValueCenterArea] = useState()
const [valueRadiusArea, setValueRadiusArea] = useState()


// useEffect(()=>{ console.log(valueLatLngMarcarPonto) }, [valueLatLngMarcarPonto])

const PopperCategoriasPonto = function (props) {
    return (<Popper {...props} style={{ zIndex: 10000 }} placement='bottom-start' />)
  }
const handleOpenModalMarcarPonto = () => {   
  setOpenModalMarcarPonto(true);
};
const handleCloseModalMarcarPonto = () => {   
  setOpenModalMarcarPonto(false);
};

const handleOpenModalMarcarArea = (layer) => {   
  setOpenModalMarcarArea(true);
  var leafletId = layer._leaflet_id 
  // console.log(layer.target)
  // drawnItems.removeLayer(layer.layer)
  // console.log(map.eachLayer(2))
  // map.removeLayer(layer._leaflet_id)
};
const handleCloseModalMarcarArea = () => {   
  setOpenModalMarcarArea(false);
};

useEffect(() => {
  
   

    let active = true;  

    (async () => {
      const { data } = await api.get('/explorar/pontos/categorias');
      const categorias = await JSON.parse(JSON.stringify(data));

      if (active) {
        setOptionsCategoriasPonto(categorias);     
      }
    })();

    return () => {
      active = false;
    };
    
  }, []);

 

const salvarNovoPonto = async () => {

  api.post('/explorar/pontos/novo', 
      {
        companyId: dataUser.companyId,
        userId: dataUser.id,
        nome: valueNomePonto,
        categoria: valueCategoriaPonto, 
        observacoes: valueObsPonto,
        codSetor: valueCodSetorMarcarPonto,
        latitude: valueLatLngMarcarPonto.lat,
        longitude: valueLatLngMarcarPonto.lng
      })
        .then(function (response) {
          // handle success          
          const data = response.data
          const idNovo = response.data.idNovo
          console.log(data)        
          handleCloseModalMarcarPonto()

          var iconCategoria
          var nomeCategoria
          switch (valueCategoriaPonto) {
            case 1:
              var iconCategoria = icon1
              var nomeCategoria = "Clientes"
              break;
            case 2:
              var iconCategoria = icon2
              var nomeCategoria = "Concorrentes"
              break;
            case 3:
              var iconCategoria = icon3
              var nomeCategoria = "Estudos"
              break;
            case 4:
              var iconCategoria = icon4
              var nomeCategoria = "Fornecedores"
              break;
            case 5:
              var iconCategoria = icon5
              var nomeCategoria = "Unidades Franqueadas"
              break;
            case 6:
              var iconCategoria = icon6
              var nomeCategoria = "Minhas Unidades"
              break;
            case 7:
              var iconCategoria = icon7
              var nomeCategoria = "Parceiros"
              break;
            case 8:
              var iconCategoria = icon8
              var nomeCategoria = "Geral"
              break;
          }

          var containerNovoPonto = $('<div />');
        
          containerNovoPonto.html(`<h3 style="width: 150px; word-wrap: break-word; margin-block-end: 0;">${valueNomePonto}</h3>`)
          containerNovoPonto.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
          containerNovoPonto.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
          containerNovoPonto.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesPonto" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 150px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">VER DETALHES</span></button>').click(function() {
                
            handleOpenModalEditarPonto(idNovo)
            })[0])            
           

            containerNovoPonto.append('</div>')

           L.marker([valueLatLngMarcarPonto.lat,valueLatLngMarcarPonto.lng], {id: idNovo, icon: iconCategoria}).bindPopup(containerNovoPonto[0]).addTo(map)

          // var newMarker = new L.marker([valueLatLngMarcarPonto.lat,valueLatLngMarcarPonto.lng]).addTo(map);
          toast.success("Novo ponto salvo com sucesso!")
        })
        .catch(function (error) {
          // handle error         
            toast.error("Ocoreu algum erro e o novo ponto não foi salvo. Tente novamente mais tarde e se persistir informe o suporte.");
            console.log(error);
        })
        .then(function () {
          // always executed
        
        });

}


const limparDadosNovaArea = () => {
  setValueNomeArea()
  setValueCategoriaArea()
  setValueObsArea()
  setValueCoordenadasArea()
  setValueTypeArea()
  setValueCenterArea()
  setValueRadiusArea()
}

const salvarNovaArea = async () => {

  api.post('/explorar/areas/novo', 
      { 
        type: valueTypeArea,
        coordinates: valueCoordenadasArea,
        center: valueCenterArea,
        radius: valueRadiusArea,
        nome: valueNomeArea,
        categoria: valueCategoriaArea, 
        observacoes: valueObsArea,       
        cor: colorNewArea ,
        companyId: dataUser.companyId,
        userId: dataUser.id,
      })
        .then(function (response) {
          // console.log(response)
          

          let idNovaArea = response.data.idNovo
          let categoriaNovaArea = response.data.data.categoria
          let nomeNovaArea = response.data.data.nome
          let obsNovaArea = response.data.data.observacoes
          let corNovaArea = response.data.data.cor

          valueLayerNewArea.options = {
            id: idNovaArea,
            color: corNovaArea,
            fillColor: corNovaArea,
            fillOpacity: 0.3,
            clickable: true,        
            fill: true,
            opacity: 0.5,
            showArea: true,
            stroke: true,
            weight: 4,
          }

          setValueEditTypeArea(response.data.data.type)
          if(response.data.data.type === "marker") {
            setVerifyTypeIsMarker(true)
           } else {
            setVerifyTypeIsMarker(false)
           }

          var nomeCategoria
            switch (categoriaNovaArea) {
              case 1:
                var nomeCategoria = "Clientes"
                break;
              case 2:
                var nomeCategoria = "Concorrentes"
                break;
              case 3:
                var nomeCategoria = "Estudos"
                break;
              case 4:
                var nomeCategoria = "Fornecedores"
                break;
              case 5:
                var nomeCategoria = "Unidades Franqueadas"
                break;
              case 6:
                var nomeCategoria = "Minhas Unidades"
                break;
              case 7:
                var nomeCategoria = "Parceiros"
                break;
              case 8:
                var nomeCategoria = "Geral"
                break;
            }
    
              var containerNovaArea = $('<div />');             

              containerNovaArea.html(`<h3 style="word-wrap: break-word; margin-block-end: 0;">${nomeNovaArea}</h3>`)
              containerNovaArea.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
              containerNovaArea.append(`<p style="word-wrap: break-word; margin-block-start: 0; font-size: 10px">${obsNovaArea}</p>`)
              containerNovaArea.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
              containerNovaArea.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesArea" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 100px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">EDITAR</span></button>').click(function() {
          
              handleOpenModalEditarArea(idNovaArea)
              })[0])
              containerNovaArea.append('</div>')

              valueLayerNewArea.setPopupContent(containerNovaArea[0])

              // console.log(valueLayerNewArea)

          handleCloseModalMarcarArea()
          limparDadosNovaArea()
          toast.success("Nova área cadastrada!")
        })

    }

  


const [state, setState] = React.useState({
    checkedA: true,
    checkedCidade: true,
    checkedBairroEstado: true,
    checkedBairroCidade: true,
    checkedShoppingEstado: true,
    checkedShoppingCidade: true,
    checkedShoppingBairro: true,
  });
  const [stateBairroShop, setStateBairroShop] = useState(true)
  const [stateBairroShopAtual, setStateBairroShopAtual] = useState(true)

const [codEstadoBox, setCodEstadoBox] = useState();
const [codCidadeBox, setCodCidadeBox] = useState();
const [codBairroBox, setCodBairroBox] = useState();
const [codShoppingBox, setCodShoppingBox] = useState();
const [bairroDistrito, setBairroDistrito] = useState();
const [latLngEstudo, setLatLngEstudo] = useState();

const [openShare, setOpenShare] = useState(false);
const [tokenShare, setTokenShare] = useState()

const handleOpenShare = () => {
  setOpenShare(true);
};

const handleCloseShare = () => {
  setOpenShare(false);
};

const handleChangeMinMax = () => {
	setMinMaxTab((prev) => !prev);
};
const handleChangeMinMaxInfo = () => {
	setMinMaxTabInfo((prev) => !prev);
};

const handleChangeSliderSalario = (event, newValue) => {
  setValueSliderSalario(newValue);
};

const verificarCidades = () => {
    
    setLoadingBusca(true)
  
    api.post('/explorar/cidades/consulta', {habMin: Number(JSON.stringify(scaleValues(value)[0])), habMax: Number(JSON.stringify(scaleValues(value)[1])), sexo: stateSexo, salMin: valueSliderSalario[0], salMax: valueSliderSalario[1]})
        .then(function (response) {
          // handle success
          
          const datajson = response.data
          setqtdadeCidadesReceb(datajson.length)
          setCidadesReceb(datajson)
          handleOpenModal()
          
          setLoadingBusca(false)
         
        })
        .catch(function (error) {
          // handle error
          setLoadingBusca(false)
          console.log(error);
        })
        .then(function () {
          // always executed
         
        });
   
  }



  const verificarCategoriasPonto = () => {
    
    setLoadingBuscaMarcarPonto(true)
  
    api.post('/explorar/pontos/categorias')
        .then(function (response) {
          // handle success
          
          const datajson = response.data
          setqtdadeCidadesReceb(datajson.length)
          setCidadesReceb(datajson)
          handleOpenModal()
          
          setLoadingBusca(false)
         
        })
        .catch(function (error) {
          // handle error
          setLoadingBusca(false)
          console.log(error);
        })
        .then(function () {
          // always executed
         
        });
   
  }


// var arrayPontos = []
const [arrayPontos, setArrayPontos] = useState()


var arrayPontosObj = [{
  label: 'Pontos',
  selectAllCheckbox: true,
  children: [
      /* start aiports from http://www.partow.net/miscellaneous/airportdatabase/#Download */
      {label: 'Clientes',
          selectAllCheckbox: true,
          children: [
            {label: 'ALICANTE - ALC', layer: L.marker([38.282000, -0.558000])},
            {label: 'ALMERIA - LEI', layer: L.marker([36.844000, -2.370000])},
            {label: 'AVILES - OVD', layer: L.marker([43.563000, -6.034000])},
            {label: 'BADAJOZ - BJZ', layer: L.marker([38.891000, -6.821000])},                    
        ]
      },
      {label: 'SPAIN',
          selectAllCheckbox: true,
          children: [
              {label: 'ALICANTE - ALC', layer: L.marker([38.282000, -0.558000])},
              {label: 'ALMERIA - LEI', layer: L.marker([36.844000, -2.370000])},
              {label: 'AVILES - OVD', layer: L.marker([43.563000, -6.034000])},
              {label: 'BADAJOZ - BJZ', layer: L.marker([38.891000, -6.821000])},                    
          ]
      },
      {label: 'FRANCE',
          selectAllCheckbox: true,
          children: [
              {label: 'AGEN - AGF', layer: L.marker([44.175000, 0.591000])},
              {label: 'AIX-LES-MILLES - QXB', layer: L.marker([43.505000, 5.368000])},
              {label: 'ALBI - LBI', layer: L.marker([43.914000, 2.113000])},
              {label: 'ANGOULEME - ANG', layer: L.marker([45.729000, 0.221000])},                    
          ]
      }
  ]
}]

// console.log(arrayPontosObj)



useEffect(() => {
  // console.log("ID COMPANY: "+dataUser.companyId)
    api.post('/explorar/pontos/listagem', { companyId: dataUser.companyId })
    .then(function (response) {
      // handle success
      
      const datajson = response.data
      // console.log(datajson)    
      for (const i in datajson) {

        var iconCategoria
        var nomeCategoria
        const tipoCategoria = datajson[i].categoria;
        switch (tipoCategoria) {
          case 1:
            var iconCategoria = icon1
            var nomeCategoria = "Clientes"
            break;
          case 2:
            var iconCategoria = icon2
            var nomeCategoria = "Concorrentes"
            break;
          case 3:
            var iconCategoria = icon3
            var nomeCategoria = "Estudos"
            break;
          case 4:
            var iconCategoria = icon4
            var nomeCategoria = "Fornecedores"
            break;
          case 5:
            var iconCategoria = icon5
            var nomeCategoria = "Unidades Franqueadas"
            break;
          case 6:
            var iconCategoria = icon6
            var nomeCategoria = "Minhas Unidades"
            break;
          case 7:
            var iconCategoria = icon7
            var nomeCategoria = "Parceiros"
            break;
          case 8:
            var iconCategoria = icon8
            var nomeCategoria = "Geral"
            break;
        }

    //     var popupPonto = L.popup()
    // // .setContent('<button class="buttonGerarEstudo" id="buttonGerarEstudo" style="margin-left: 53px; background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 150px; padding: 8px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">GERAR ESTUDO</span></button>').click(function() {
                
    // //   alert("OKKK!")
    // //  })[0])
    
        
    //  .setContent('<h3>'+datajson[i].nome+'</h3><div><style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style><button class="buttonDetalhesPonto" id="buttonDetalhesPonto" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 150px; padding: 6px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">VER DETALHES</span></button>');
    

            var containerPonto = $('<div />');
        
            containerPonto.html(`<h3 style="width: 150px; word-wrap: break-word; margin-block-end: 0;">${datajson[i].nome}</h3>`)
            containerPonto.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
            containerPonto.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
            containerPonto.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesPonto" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 150px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">VER DETALHES</span></button>').click(function() {
                
            handleOpenModalEditarPonto(datajson[i].id)
            })[0])            

            containerPonto.append('</div>')

        L.marker([datajson[i].latitude, datajson[i].longitude], {id: datajson[i].id, icon: iconCategoria}).bindPopup(containerPonto[0]).addTo(pontos)
        
      }
     
    })
    .catch(function (error) {
      // handle error    
      console.log(error);
    })
  
  }, [])


  
  

  var redPinMarker = L.icon({
    iconUrl: 'https://geotaker.com.br/images/pin_red.png',
    shadowUrl: false,

    iconSize:     [41, 47], // size of the icon    
    iconAnchor:   [22, 22], // point of the icon which will correspond to marker's location   
    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
});

  useEffect(() => {
    // console.log("ID COMPANY: "+dataUser.companyId)
      api.post('/explorar/areas/listagem', { companyId: dataUser.companyId })
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        
        for (const i in datajson) {    
          
          var nomeCategoria
          const tipoCategoria = datajson[i].categoria;
          switch (tipoCategoria) {
            case 1:             
              var nomeCategoria = "Clientes"
              break;
            case 2:
              var nomeCategoria = "Concorrentes"
              break;
            case 3:
              var nomeCategoria = "Estudos"
              break;
            case 4:
              var nomeCategoria = "Fornecedores"
              break;
            case 5:
              var nomeCategoria = "Unidades Franqueadas"
              break;
            case 6:
              var nomeCategoria = "Minhas Unidades"
              break;
            case 7:
              var nomeCategoria = "Parceiros"
              break;
            case 8:
              var nomeCategoria = "Geral"
              break;
          }


       
          const tipoArea = datajson[i].type;

          switch (tipoArea) {

            case "rectangle":
                var containerArea = $('<div />');
          
                containerArea.html(`<h3 style="word-wrap: break-word; margin-block-end: 0;">${datajson[i].nome}</h3>`)
                containerArea.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
                containerArea.append(`<p style="word-wrap: break-word; margin-block-start: 0; font-size: 10px">${datajson[i].observacoes}</p>`)
                containerArea.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
                containerArea.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesArea" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 100px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">EDITAR</span></button>').click(function() {
                  
                handleOpenModalEditarArea(datajson[i].id)
              })[0])            
  
              containerArea.append('</div>')

              L.rectangle(JSON.parse("[" +  datajson[i].coordinates + "]"), {
                id: datajson[i].id,
                color: datajson[i].cor,
                fillColor: datajson[i].cor,
                fillOpacity: 0.3,
              }).bindPopup(containerArea[0]).addTo(layerAreas);
              break;

            case "polygon":

                var containerArea = $('<div />');
          
                containerArea.html(`<h3 style="word-wrap: break-word; margin-block-end: 0;">${datajson[i].nome}</h3>`)
                containerArea.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
                containerArea.append(`<p style="word-wrap: break-word; margin-block-start: 0; font-size: 10px">${datajson[i].observacoes}</p>`)
                containerArea.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
                containerArea.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesArea" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 100px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">EDITAR</span></button>').click(function() {
                  
                handleOpenModalEditarArea(datajson[i].id)
              })[0])            
  
              containerArea.append('</div>')


              L.polygon(JSON.parse("[" +  datajson[i].coordinates + "]"), {
                id: datajson[i].id,
                color: datajson[i].cor,
                fillColor: datajson[i].cor,
                fillOpacity: 0.3,
              }).bindPopup(containerArea[0]).addTo(layerAreas);              
              break;

            case "circle":

                var containerArea = $('<div />');
            
                containerArea.html(`<h3 style="word-wrap: break-word; margin-block-end: 0;">${datajson[i].nome}</h3>`)
                containerArea.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
                containerArea.append(`<p style="word-wrap: break-word; margin-block-start: 0; font-size: 10px">${datajson[i].observacoes}</p>`)
                containerArea.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
                containerArea.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesArea" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 100px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">EDITAR</span></button>').click(function() {
                  
                handleOpenModalEditarArea(datajson[i].id)
              })[0])            

              containerArea.append('</div>')


              L.circle(JSON.parse(datajson[i].center), {
                id: datajson[i].id,
                color: datajson[i].cor,
                fillColor: datajson[i].cor,
                fillOpacity: 0.3,
                radius: Number(datajson[i].radius)
              }).bindPopup(containerArea[0]).addTo(layerAreas);              
              break;

            case "polyline":

              var containerArea = $('<div />');
          
                containerArea.html(`<h3 style="word-wrap: break-word; margin-block-end: 0;">${datajson[i].nome}</h3>`)
                containerArea.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
                containerArea.append(`<p style="word-wrap: break-word; margin-block-start: 0; font-size: 10px">${datajson[i].observacoes}</p>`)
                containerArea.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
                containerArea.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesArea" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 100px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">EDITAR</span></button>').click(function() {
                  
                handleOpenModalEditarArea(datajson[i].id)
              })[0])            

              containerArea.append('</div>')


              L.polyline(JSON.parse("[" +  datajson[i].coordinates + "]"), {
                id: datajson[i].id,
                color: datajson[i].cor,
                fillColor: datajson[i].cor,
                fillOpacity: 0.3,
              }).bindPopup(containerArea[0]).addTo(layerAreas);            
              break;
            
            case "circlemarker":

              var containerArea = $('<div />');
          
                containerArea.html(`<h3 style="word-wrap: break-word; margin-block-end: 0;">${datajson[i].nome}</h3>`)
                containerArea.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
                containerArea.append(`<p style="word-wrap: break-word; margin-block-start: 0; font-size: 10px">${datajson[i].observacoes}</p>`)
                containerArea.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
                containerArea.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesArea" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 100px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">EDITAR</span></button>').click(function() {
                  
                handleOpenModalEditarArea(datajson[i].id)
              })[0])            
  
              containerArea.append('</div>')

              L.circle(JSON.parse(datajson[i].center), {
                id: datajson[i].id,
                color: 'red',
                fillColor: datajson[i].cor,
                fillOpacity: 0.3,
                radius: Number(datajson[i].radius)
            }).bindPopup(containerArea[0]).addTo(layerAreas);              
              break;

            case "marker":

              var containerArea = $('<div />');
          
                containerArea.html(`<h3 style="word-wrap: break-word; margin-block-end: 0;">${datajson[i].nome}</h3>`)
                containerArea.append(`<p style="margin-block-start: 0; font-size: 10px">Categoria: ${nomeCategoria}</p>`)
                containerArea.append(`<p style="word-wrap: break-word; margin-block-start: 0; font-size: 10px">${datajson[i].observacoes}</p>`)
                containerArea.append('<style>.buttonDetalhesPonto:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
                containerArea.append($('<button class="buttonDetalhesPonto" id="buttonDetalhesArea" style="background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 100px; padding: 7px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">EDITAR</span></button>').click(function() {
                  
                handleOpenModalEditarArea(datajson[i].id)
              })[0])            
  
              containerArea.append('</div>')

              L.marker(JSON.parse(datajson[i].center), {
                id: datajson[i].id,
                icon: redPinMarker
              }).bindPopup(containerArea[0]).addTo(layerAreas);
              break;      
          }
  
   
              
          
        }
       
      })
      .catch(function (error) {
        // handle error    
        console.log(error);
      })
    
    }, [])





// useEffect(()=>{console.log(arrayPontos.children[1].children), [arrayPontos]})

var airportsEurope =[]


var baseLayers;
function initmap() {
	var padrao   = L.tileLayer('https://mt1.google.com/vt/yrs=y&x=1325&y=3143&z=cn&x={x}&y={y}&z={z}'),
	satelite  = L.tileLayer('https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}'),
	hibrido = L.tileLayer('https://www.google.cn/maps/vt?lyrs=y&x=1325&y=3143&z=cn&x={x}&y={y}&z={z}'),
	transito = L.tileLayer('https://mt0.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}'),
	bicicleta = L.tileLayer('http://mt1.google.com/vt/lyrs=m@121,bike&hl=pt&x={x}&y={y}&z={z}'),
	transpublico = L.tileLayer('http://mt1.google.com/vt/lyrs=m@121,transit|vm:1&hl=en&amp;opts=r&x={x}&y={y}&z={z}'),
	relevo = L.tileLayer('http://mt0.google.com/vt/lyrs=p&hl=pt&x={x}&y={y}&z={z}'),
	terrain = L.tileLayer('https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg'),
	cartografico = L.tileLayer('https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'),
	pretoebranco = L.tileLayer('https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png'),
	dark = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'),
	watercolor = L.tileLayer('https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg')

  // var cities = L.layerGroup();

	// L.marker([39.61, -105.02], {icon: icon}).bindPopup('This is Littleton, CO.').addTo(cities)
	// L.marker([39.74, -104.99], {icon: icon}).bindPopup('This is Denver, CO.').addTo(cities)
	// L.marker([39.73, -104.8], {icon: icon}).bindPopup('This is Aurora, CO.').addTo(cities)
	// L.marker([39.77, -105.23], {icon: icon}).bindPopup('This is Golden, CO.').addTo(cities)

  L.Map.include({
    getMarkerById: function (id) {
        var marker = null;
        this.eachLayer(function (layer) {
            if (layer instanceof L.Marker) {
                if (layer.options.id === id) {
                    marker = layer;
                }
            }
        });
        return marker;
    }
});

L.Map.include({
  getPolygonById: function (id) {
      var marker = null;
      this.eachLayer(function (layer) {       
          if (layer instanceof L.Polygon) {           
              if (layer.options.id === id) {                
                  marker = layer;
              }
          }
      });
      return marker;
  }
});

L.Map.include({
  getCircleById: function (id) {
      var marker = null;
      this.eachLayer(function (layer) {       
          if (layer instanceof L.Circle) {           
              if (layer.options.id === id) {                
                  marker = layer;
              }
          }
      });
      return marker;
  }
});


L.Map.include({
  getPolylineById: function (id) {
      var marker = null;
      this.eachLayer(function (layer) {       
          if (layer instanceof L.Polyline) {           
              if (layer.options.id === id) {                
                  marker = layer;
              }
          }
      });
      return marker;
  }
});



	
	map = new L.Map("map_geotaker", {
		center: [-12.7187523,-67.3257076],
		zoom: 5,
		layers: [padrao, pontos, layerAreas],
		zoomControl: false,
		attributionControl: false
	});

  


  var geocoder = L.Control.Geocoder.nominatim();
    if (typeof URLSearchParams !== "undefined" && window.location.search) {
      // parse /?geocoder=nominatim from URL
      var params = new URLSearchParams(window.location.search);
      var geocoderString = params.get("geocoder");
      if (geocoderString && L.Control.Geocoder[geocoderString]) {
        geocoder = L.Control.Geocoder[geocoderString]();
      } else if (geocoderString) {
        console.warn("Geocoder não suportado", geocoderString);
      }
    }

    L.Control.geocoder({
      query: "",
      placeholder: "Digite o endereço e aperte Enter...",
      defaultMarkGeocode: false,
      geocoder
    })
      .on("markgeocode", function (e) {
        var latlng = e.geocode.center;
        L.marker(latlng, { icon })
          .addTo(map)
          .bindPopup(e.geocode.name)
          .openPopup();
        map.fitBounds(e.geocode.bbox);
      })
      .addTo(map);

    
      // The tree containing the layers      
       
    
    // var baseTree = {
    //     label: 'BaseLayers',
    //     noShow: true,
    //     children: [
    //         {
    //             label: 'Mapas',
    //             collapsed: true,
    //             children: [
    //                 {label: 'Padrão', layer: padrao, name: 'Padrão'},
    //                 {label: 'Satélite', layer: satelite, name: 'Satélite'},
    //                 {label: 'Hibrido', layer: hibrido, name: 'Hibrido'},
    //                 {label: 'Trânsito', layer: transito, name: 'Trânsito'},
    //                 {label: 'Bicicleta', layer: bicicleta, name: 'Bicicleta'},
    //                 {label: 'Transporte Público', layer: transpublico, name: 'Transporte Público'},
    //                 {label: 'Relevo', layer: relevo, name: 'Relevo'},
    //                 {label: 'Relevo+', layer: relevomais, name: 'Relevo+'},
    //                 {label: 'Cartográfico', layer: cartografico, name: 'Cartográfico'},
    //                 {label: 'Preto e Branco', layer: pretoebranco, name: 'Preto e Branco'},
    //                 {label: 'Escuro', layer: dark, name: 'Escuro'},
                    

    //             ]
    //         }
    //     ]
    // };

    // var ctl = L.control.layers.tree(baseTree, null,
    //     {
    //         namedToggle: false,
    //         collapsed: true,
    //     });

    // ctl.addTo(map).collapseTree().expandSelected();
    

    // var airportsEurope = arrayPontosObj

    // // console.log(airportsEurope.children[1].children)
    // /* ends aiports */
    // var makePopups = function(node) {
    //     if (node.layer) {
    //         node.layer.bindPopup(node.label);
    //     }
    //     if (node.children) {
    //         node.children.forEach(function(element) { makePopups(element); });
    //     }
    // };
    // makePopups(airportsEurope);

    // ctl.setOverlayTree(airportsEurope).collapseTree(true).expandSelected(true);

    
    // L.control.layers.tree(overlaysTree).addTo(map);
    var drawnItems = L.featureGroup().addTo(map);

		var overlays = {
      "Meus Pontos": pontos,
      "Minhas Áreas": layerAreas
	};

	var baseLayers = {
		"Padrão": padrao,
		"Satélite": satelite,
		"Hibrido": hibrido,
		"Trânsito": transito,
		"Bicicleta": bicicleta,
		"Transporte Público": transpublico,
		"Relevo": relevo,
		"Terreno": terrain,
		"Cartográfico": cartografico,
		"Preto e Branco": pretoebranco,
    "Cores": watercolor,
		"Escuro": dark
	};
	L.control.layers(baseLayers, overlays, {position: 'bottomright'}).addTo(map);



	new L.Control.Zoom({ position: 'bottomright' }).addTo(map);
	// map.setView(new L.LatLng(-15, -52),5);	

  var MarkerArea = L.Icon.extend({
    options: {
      shadowUrl: false,
      iconAnchor: new L.Point(22, 22),
      iconSize: new L.Point(41, 47),
      iconUrl: 'https://geotaker.com.br/images/pin_red.png'
    }
  });

  
  map.addControl(new L.Control.Draw({
    position: 'bottomright',
    edit: {
        featureGroup: drawnItems,
        poly : {
            allowIntersection : false
        }
    },
    draw: {
        marker: {
          icon: new MarkerArea()
        },
        polygon : {
            allowIntersection: false,
            showArea:true,
            shapeOptions: {
              // color: '#690096',
              weight: 5
            }
        }
    }
}));

// Truncate value based on number of decimals
var _round = function(num, len) {
  return Math.round(num*(Math.pow(10, len)))/(Math.pow(10, len));
};
// Helper method to format LatLng object (x.xxxxxx, y.yyyyyy)
var strLatLng = function(latlng) {
  return "("+_round(latlng.lat, 6)+", "+_round(latlng.lng, 6)+")";
};

// Generate popup content based on layer type
// - Returns HTML string, or null if unknown object
var getPopupContent = function(layer) {
  // Marker - add lat/long
  if (layer instanceof L.Marker || layer instanceof L.CircleMarker) {
      return strLatLng(layer.getLatLng());
  // Circle - lat/long, radius
  } else if (layer instanceof L.Circle) {
      var center = layer.getLatLng(),
          radius = layer.getRadius();
      return "Centro: "+strLatLng(center)+"<br />"
            +"Raio: "+_round(radius, 2)+" m";
  // Rectangle/Polygon - area
  } else if (layer instanceof L.Polygon) {
      var latlngs = layer._defaultShape ? layer._defaultShape() : layer.getLatLngs(),
          area = L.GeometryUtil.geodesicArea(latlngs);
      return "Área: "+L.GeometryUtil.readableArea(area, true);
  // Polyline - distance
  } else if (layer instanceof L.Polyline) {
      var latlngs = layer._defaultShape ? layer._defaultShape() : layer.getLatLngs(),
          distance = 0;
      if (latlngs.length < 2) {
          return "Distância: N/A";
      } else {
          for (var i = 0; i < latlngs.length-1; i++) {
              distance += latlngs[i].distanceTo(latlngs[i+1]);
          }
          return "Distância: "+_round(distance, 2)+" m";
      }
  }
  return null;
};

// Object created - bind popup to layer, add to feature group
map.on(L.Draw.Event.CREATED, function(event) {
  // alert("Teste") //solicitar nome da área, salvar pontos, nome, empresa e user criador no banco de dados.
  
  

  var layer = event.layer;
  console.log(layer)
  var type = event.layerType;
  setValueLayerNewArea(layer)
 
    if (type === 'circle') {      
        setVerifyTypeIsMarker(false)  
        var theCenterPt = layer.getLatLng();
        var center = `[${theCenterPt.lat},${theCenterPt.lng}]` 
        var theRadius = layer.getRadius();

        setValueTypeArea("circle")
        setValueCenterArea(center)
        setValueRadiusArea(theRadius)
        
    }

    else if (type === 'polygon') {     
        setVerifyTypeIsMarker(false)
        var shape = layer.toGeoJSON()
        const coordgeo = shape.geometry.coordinates  
        let arrayCordCorrigida = [];   

        for(let i = 0; i < coordgeo.length; i = i + 1 ) {
            for(let u = 0; u < coordgeo[i].length; u = u + 1 ) {
            arrayCordCorrigida.push([coordgeo[i][u][1], coordgeo[i][u][0]])
            }
        }
     
        var shape_for_db = JSON.stringify(arrayCordCorrigida);
        var coordenates = shape_for_db.slice(1, -1)
      
        setValueTypeArea("polygon")
        setValueCoordenadasArea(coordenates)
    }

    else if (type === 'rectangle') {    
        setVerifyTypeIsMarker(false)
        var shape = layer.toGeoJSON()
        var shape_for_db = JSON.stringify(shape.geometry.coordinates);
        var coordenates = shape_for_db.slice(2, -2)

        const corners = layer.getBounds();

        const northwest = corners.getNorthWest();
        const northeast = corners.getNorthEast();
        const southeast = corners.getSouthEast();
        const southwest = corners.getSouthWest();   
        
        setValueTypeArea("rectangle")
        setValueCoordenadasArea("[" + northwest.lat + ","+ northwest.lng + "]"+","+"[" + northeast.lat + ","+ northeast.lng + "]"+","+"[" + southeast.lat + ","+ southeast.lng + "]"+","+"[" + southwest.lat + ","+ southwest.lng + "]")
      
    }

    else if (type === 'polyline') { 
        setVerifyTypeIsMarker(false)
        var shape = layer.toGeoJSON()
        const coordgeo = shape.geometry.coordinates

        let arrayCordCorrigida = [];   

        for(let i = 0; i < coordgeo.length; i = i + 1 ) {   
          arrayCordCorrigida.push([coordgeo[i][1], coordgeo[i][0]])       
        }
        
        var shape_for_db = JSON.stringify(arrayCordCorrigida);
        var coordenates = shape_for_db.slice(1, -1)
        
        setValueTypeArea("polyline")
        setValueCoordenadasArea(coordenates)

    }

    else if (type === 'circlemarker') {    
        setVerifyTypeIsMarker(false)  
        var shape = layer.toGeoJSON()
        var shape_for_db = JSON.stringify(shape);
        var theCenterPt = layer.getLatLng();
        var center = `[${theCenterPt.lat},${theCenterPt.lng}]`
        var theRadius = layer.getRadius();

        setValueTypeArea("circlemarker")
        setValueCenterArea(center)
        setValueRadiusArea(theRadius) 
    }

    else if (type === 'marker') {      
        setVerifyTypeIsMarker(true)
        var shape = layer.toGeoJSON()
        var shape_for_db = JSON.stringify(shape);
        var theCenterPt = layer.getLatLng();
        var center = `[${theCenterPt.lat},${theCenterPt.lng}]`

        setValueTypeArea("marker")
        setValueCenterArea(center)
      
    }








  handleOpenModalMarcarArea(event)
  // console.log(layer)

  



  var content = getPopupContent(layer);
  if (content !== null) {
      layer.bindPopup("teste <br>"+ content);
  }
  drawnItems.addLayer(layer);
});

// Object(s) edited - update popups
map.on(L.Draw.Event.EDITED, function(event) {
  var layers = event.layers,
      content = null;
  layers.eachLayer(function(layer) {
      content = getPopupContent(layer);
      if (content !== null) {
          layer.setPopupContent(content);
      }
  });
});


	
	// setMapCriado(L.stamp(layer))
	setMapCriado(map)
	
	
};

useEffect(() => {
	initmap();
  }, []);


  
  // console.log(arrayPontosObj)
// console.log(map)
// useEffect(() => {console.log(mapCriado)}, [mapCriado])






function alterarLayerLight() {
	var layerControlElement = document.getElementsByClassName('leaflet-control-layers')[0];
		layerControlElement.getElementsByTagName('input')[0].click();
    
    //Alterar o MEUS PONTOS (checked/unchecked)
    // layerControlElement.getElementsByTagName('input')[11].checked = true;
}

function alterarLayerDark() {
	var layerControlElement = document.getElementsByClassName('leaflet-control-layers')[0];
		layerControlElement.getElementsByTagName('input')[10].click();
}

useEffect(()=> {
	if(map) {
	if(isDarkModeCont == true) {
		alterarLayerDark()
	}
	if(isDarkModeCont == false) {
		alterarLayerLight()
	}
}
}, [isDarkModeCont])





const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setStateMenu({ ...stateMenu, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <p>TESTE</p>
    </Box>
  );










function smalldecode(point){
	var prefix = '';
	switch(point.charAt(0)) {
		case '!': prefix = '-1'; break;
		case '@': prefix = '-2'; break;
		case '#': prefix = '-3'; break;
		case '$': prefix = '-4'; break;
		case '%': prefix = '-5'; break;
		case '?': prefix = '-6'; break;
		case '&': prefix = '-7'; break;
		case '*': prefix = '-8'; break;
		case '(': prefix = '-9'; break;
		default: prefix = ''; break;
	}
	
	var myCharCode = point.charCodeAt(0);
	
	if((myCharCode > 64) && (myCharCode <  91)){
		prefix = '-' + point.charAt(0).toLowerCase();
	}
	
	if (prefix.length > 0) {
		return prefix + point.substr(1) + ''; 
	}
	else {
		return point;
	}
}

var units = new Array();

function NewML() {

	//boundaries
	var fatherId;
	var west;
	var east;
	var north;
	var south;
	var ready;
	var exponent;
	var precisionNumber;
	var maxNumDigits;
	var obj = this;
	
	//array of items
	var units = new Array();
	
	//array of lengths
	var n = new Array();
	n['fatherIdLen']	= 	2;
	n['exponent']		= 	1;
	n['maxNumDigits']	= 	1;
	n['idlength'] 		=	2;
	n['situation'] 		=	1; 
	n['polycntlen']		= 	2;
	n['lonnumdigits']	=	1;
	n['latnumdigits']	=	1;
	n['polylenlen']		= 	2;
	
	this.getData = function(address) {
		var ajaxRequest;  // The variable that makes Ajax possible!
	
		var data = null;
		
		ready = false;
	
		try{
			// Opera 8.0+, Firefox, Safari
			ajaxRequest = new XMLHttpRequest();
		} catch (e){
			// Internet Explorer Browsers
			try{
				ajaxRequest = new ActiveXObject("Msxml2.XMLHTTP");
			} catch (e) {
				try {
					ajaxRequest = new ActiveXObject("Microsoft.XMLHTTP");
				} catch (e) {
					// Something went wrong
					alert("Your browser broke!");
					return false;
				}
			}
		}
		
		thisObj = this;
		
		// Create a function that will receive data sent from the server
		ajaxRequest.onreadystatechange = function(){
			if(ajaxRequest.readyState == 4){
				data = ajaxRequest.responseText;
				obj.parse(data);
				obj.ready = true;
			}
		}
		
		ajaxRequest.open("GET", address, false);
		ajaxRequest.send(null);
	}
	
	this.parse = function(data){
    // console.log(data)
		
		//index;
		var index = 0;
		
		var fatherIdLen = String(data).substr(index, n['fatherIdLen']) * 1;
		index += n['fatherIdLen'];
		
		fatherId = String(data).substr(index, fatherIdLen);
		index += fatherIdLen;
		
		exponent = String(data).substr(index, n['exponent']) * 1;
		index += n['exponent'];
		
		precisionNumber = Math.pow(10, exponent);
		
		maxNumDigits = String(data).substr(index, n['maxNumDigits']) * 1;
		index += n['maxNumDigits']; 
		
		west = parseInt(smalldecode(String(data).substr(index, maxNumDigits)), 36)/(precisionNumber * (-1));
		index += maxNumDigits;
		
		east = parseInt(smalldecode(String(data).substr(index, maxNumDigits)), 36)/(precisionNumber * (-1));
		index += maxNumDigits;
		
		north = parseInt(smalldecode(String(data).substr(index, maxNumDigits)), 36)/(precisionNumber * (-1));
		index += maxNumDigits;
		
		south = parseInt(smalldecode(String(data).substr(index, maxNumDigits)), 36)/(precisionNumber * (-1));
		index += maxNumDigits;
		
		while (index < String(data).length) {
			var idlength = String(data).substr(index, n['idlength']) * 1;
			index += n['idlength'];
			
			var id = String(data).substr(index, idlength);
			index += idlength;
			
			var situation = String(data).substr(index, n['situation']) * 1;
			index += n['situation'];
			
			var polycntlen = String(data).substr(index, n['polycntlen']) * 1;
			index += n['polycntlen'];			
			
			var polycnt = String(data).substr(index, polycntlen) * 1;
			index += polycntlen;
			
			var xcenter = String(data).substr(index, maxNumDigits);
			index += maxNumDigits;
			
			var ycenter = String(data).substr(index, maxNumDigits);
			index += maxNumDigits;
			
			var lonlen = String(data).substr(index, n['lonnumdigits']) * 1;
			index += n['lonnumdigits'];
			
			var latlen = String(data).substr(index, n['latnumdigits']) * 1;
			index += n['latnumdigits'];
			
			var latlonlen = lonlen + latlen;
			
			var u = new Unit(id, situation);
			
			//array of polygons
			var polygons = new Array();
			
			for (var i = 0; i < polycnt; i++) {
				var p = new Polygon(xcenter, ycenter, exponent, precisionNumber);
				
				var polylenlen = String(data).substr(index, n['polylenlen']) * 1;
				index += n['polylenlen'];
				
				var polylen = String(data).substr(index, polylenlen) * 1;
				index += polylenlen;
				
				var coordcnt = Math.round(polylen / latlonlen);
				
        var j
				for (j = 0; j < coordcnt; j++) {
					var lon = String(data).substr(index, lonlen);
					index += lonlen;
					
					var lat = String(data).substr(index, latlen);
					index += latlen;
					p.addPoint(lon, lat);
				}
				u.addPolygon(p);
			}
			
			units.push(u);
			// console.log(u)
		}
		ready = true;
		// console.log(units)
	}
	
	this.isReady = function() {
		return ready;
	}
	
	this.getId = function() {
		return fatherId;
	}
	
	this.getWest = function() {
		return west;
	}
	
	this.getEast = function() {
		return east;
	}
	
	this.getNorth = function() {
		return north;
	}
	
	this.getSouth = function() {
		return south;
	}
	
	this.getLatCenter = function() {
		return (north + south)/2;
	}
	
	this.getLonCenter = function() {
		return (west + east)/2;
	}
	
	this.isEmpty = function() {
		return (units.length == 0);
	}
	
	this.googleExport = function() {
		if (units.length > 0) {
			return units.shift();
		}
		else {
			return null;
		}
	}
}



function Unit(id, situation) {
	var _googleMultiPolygonPath = new Array();
	var _situation;
	var _id;
	
	this.setId = function(id) {
		_id = id;
	}
	
	this.setSituation = function(situation) {
		_situation = situation;
	}
	
	this.setId(id);
	this.setSituation( ( !situation ? "0" : situation ) );

	this.getId = function() {
		return _id;
	}	
	
	this.getSituation = function() {
		return _situation;
	}
	
	this.getMyPaths = function () {
		return _googleMultiPolygonPath;
	}
	
	this.addPolygon = function (p) {
    // console.log("veio aqui no ADDPOLYGON!!!")
		_googleMultiPolygonPath.push(p.getMyPath());
    // setPolygons(oldArray => [...oldArray, p.getMyPath()])
    // console.log(p.getMyPath())
	}
}
	
function Polygon(xcenter, ycenter, exponent, precisionNumber) {

	var _xcenter;
	var _ycenter;
	//var _googlePolygonPath = new google.maps.MVCArray();
	var _polyCoords = []
	
	this.setXCenter = function(xcenter) {
		_xcenter = parseInt(smalldecode(xcenter), 36)/(precisionNumber * (-1));
	}
	
	this.setYCenter = function(ycenter) {
		_ycenter = parseInt(smalldecode(ycenter), 36)/(precisionNumber * (-1));
	}
	
	this.setXCenter(xcenter);
	this.setYCenter(ycenter);

	var old_lon = "...";
	var old_lonpt;
	var old_lat = "...";
	var old_latpt;
	
	this.addPoint = function(lon, lat){
		if (lat == undefined || lon == undefined) {
			var x = 1;
		}
		
		var lonpt = (old_lon == lon ? old_lonpt : (_xcenter + parseInt(smalldecode(lon), 36)/precisionNumber).toFixed(exponent)) * 1;
		var latpt = (old_lat == lat ? old_latpt : (_ycenter + parseInt(smalldecode(lat), 36)/precisionNumber).toFixed(exponent)) * 1;
		
		old_lon = lon;
		old_lat = lat;
		old_lonpt = lonpt;
		old_latpt = latpt;
		
		
		//var myLatLng = new google.maps.LatLng(latpt, lonpt);

		var myLatLng = L.latLng(latpt, lonpt);

		//console.log(feature.getGeometry().getCoordinates());
		// _googlePolygonPath.push(myLatLng);

		_polyCoords.push(myLatLng);
		
	}
	
	this.getMyPath = function() {
		return _polyCoords;
	}
}

const [loadingGerarEstudo, setLoadingGerarEstudo] = useState(false)

function gerarEstudoSetor (codSetor, latlng, ponto)  {
  
  setLoadingGerarEstudo(true)

  //ponto = Se o estudo está sendo gerado através do Detalhes do Ponto
  if(ponto === false) {
    document.getElementById("buttonGerarEstudo").disabled = true;
    document.getElementById("buttonGerarEstudo").style.backgroundColor = "#E0E0E0";
    document.getElementById("buttonGerarEstudo").style.color = "#BDBDBD";
    document.getElementById("buttonGerarEstudo").style.boxShadow = "0px 0px 0px";
    document.getElementById("buttonGerarEstudo").innerHTML = '<img src="https://geotaker.com.br/images/loading.gif" style="position: absolute;left: 10px; top:3px;" width="25"> <span style="padding-left: 20px; font-size: 12px;">GERANDO...</span>'
    // alert("Setor: " + codSetor + " LatLng: "+ latlng)
  }

    const tokenUser = localStorage.getItem('token')
      api.post('/gerarestudo/setor', 
      {
        codSetor:codSetor, 
        coordenadas: latlng,
        tokenUser:tokenUser
      })
        .then(function (response) {
          // handle success
          // console.log("Resposta: ", response)
          
          const data = response.data
          const token = response.data.token
          // console.log(data)
          // window.open('https://localhost:3000/estudo/?PIEBkUYblOLjgtdYbnmhTDG='+token, '_blank');
          setTokenShare(token)
          // setSuccessoEstudo(true);
          // setLoadingEstudo(false);
          if(ponto === false) {
            document.getElementById("buttonGerarEstudo").disabled = false;
            document.getElementById("buttonGerarEstudo").style.backgroundColor = "#008006";
            document.getElementById("buttonGerarEstudo").style.color = "white";
            document.getElementById("buttonGerarEstudo").style.boxShadow = "1px 1px 5px grey";
            document.getElementById("buttonGerarEstudo").innerHTML = '<img src="https://geotaker.com.br/images/AssessmentTwoToneIcon20.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">GERADO!</span>'
              setTimeout(() => {           
                document.getElementById("buttonGerarEstudo").style.backgroundColor = "#2A5CF1";        
                document.getElementById("buttonGerarEstudo").innerHTML = '<img src="https://geotaker.com.br/images/AssessmentTwoToneIcon20.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">GERAR ESTUDO</span>'
            }, 2000);
          }

          handleOpenShare()
          setLoadingGerarEstudo(false)

          

        

        })
        .catch(function (error) {
          // handle error
            // setSuccessoEstudo(false);
            // setLoadingEstudo(false);
            if(ponto === false) {
              document.getElementById("buttonGerarEstudo").disabled = false;
              document.getElementById("buttonGerarEstudo").style.backgroundColor = "#2A5CF1";
              document.getElementById("buttonGerarEstudo").style.color = "white";
              document.getElementById("buttonGerarEstudo").style.boxShadow = "1px 1px 5px grey";
              document.getElementById("buttonGerarEstudo").innerHTML = '<img src="https://geotaker.com.br/images/AssessmentTwoToneIcon20.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">GERAR ESTUDO</span>'
            }
            setLoadingGerarEstudo(false)
            toast.error("Seu usuário está expirado! Saia e faça login novamente.");
            console.log(error);
        })
        .then(function () {
          // always executed
        
        });
  }


  function pesquisarNegocios (latlng, zoom)  {    
    handleOpenModalPesqNeg()    
    setValueLatLngPesqNeg({ latlng: latlng, zoom: zoom })
  }


  function marcarPonto (codSetor, latlng)  {    
    handleOpenModalMarcarPonto()    
    setValueCodSetorMarcarPonto(codSetor)
    setValueLatLngMarcarPonto(latlng)
  }

  const abrirGoogleMapsPesqNeg = () => {
    setLoadingPesqNeg(true)
    console.log(valueLatLngPesqNeg.zoom)
    window.open(`https://www.google.com/maps/search/${valuePesqNeg.toLowerCase()}/@${valueLatLngPesqNeg.latlng.lat},${valueLatLngPesqNeg.latlng.lng},${valueLatLngPesqNeg.zoom}z`, '_blank');
    setTimeout(() => setLoadingPesqNeg(false), 3000)
  }

function Cartograma()
{
	
	var marker = L.marker([], {icon: icon}).bindPopup("",{minWidth:'100%'});
	
	this.desenha = function(nml)
	{
		// console.log(nml)
    
		// setNmlPoly(nml)
		
    // console.log("PASSAMOS AQUIIII 1")
	// console.log(nml)
		var nml_polygon = nml.googleExport(); // retirar novo poligono. A fun��o retorna new Array(id, n.o do poligono, array de google points)
		// console.log(nml_polygon)
    var options = {
			weight:1,
			opacity:0.5,
			fill:true
		};
    // console.log("PASSAMOS AQUIIII 2")
    
		var group = L.featureGroup();
		while (nml_polygon != null) { // enquanto o poligono n�o � null
			// console.log(nml_polygon.getId)
			var id = nml_polygon.getId();
      // console.log(id)
			// console.log(nml_polygon.getId())
			var situacao = "0";
			var paths = nml_polygon.getMyPaths()
    //   console.log(polygonos)
			// console.log("PASSAMOS AQUIIII 3")
			options.id = id;
			
			options.setVisible = function (v)
			{
				this.visible = v;
				if (!v)
				{
					options.fillColor  = "#"+params.getCorBase();
					options.fillOpacity = 0;
					options.opacity  = 0;
				}
				else
				{
					options.fillOpacity = params.getOpContorno()
					options.opacity = params.getEspContorno();
				}
				dados.removeDado((params.getVariavel()[0]=='cidadesat'?this.id.substr(0,6):this.id));
			}

			var polyCoords = [];

      //incluir a area apenas se for o id desejado.
      // if(id == 150170940000004) {      
      //   for (var i in paths) {
      //     console.log(paths[i])
      //     polyCoords.push(paths[i]);
      //   }
      // }


    for (var i in paths) {
      // console.log(paths[i])
      polyCoords.push(paths[i]);
    }

    
			// console.log(polyCoords)
			
			var poly = new L.polygon(polyCoords,options);
			shapes[id] = poly;
			// console.log(shapes)
			// setShapesSaves(shapes)
			// console.log(poly)
			var geoJsonGroup = L.layerGroup([poly]) 
			geoJsonGroup.addTo(map);
			// console.log(L.stamp(geoJsonGroup))
			
			// groupLayers.push(L.stamp(geoJsonGroup))

			group.addLayer(poly);
			// console.log(L.stamp(group))
			// setIdLayer(group._leaflet_id)
			// console.log(group._leaflet_id)
			
			nml_polygon = nml.googleExport();
		}
		
		group.on('click',function(e){
			console.log(e)
      console.log(map.getZoom())
      
      //Para recuar o zoom no clique.
		 	// map.fitBounds(this.getBounds());
		 	
            //  var htmlPop = `
            //  <iframe id="infoWindowChrat" name="infoWindowChrat" style="width:620px;height:480px;border:0px;" src="https://geotaker.com.br/tooltips/tooltip.htm?codigo=${e.layer.options.id}" frameborder="0" scrolling="no"></iframe>
            //  <button id="gerarestudo" class="gerarestudo" type="button" onclick="gerarEstudoSetor()">Click Me!</button>
            //  `;
		 	// marker.setLatLng(e.latlng).setPopupContent(htmlPop).addTo(map).openPopup();
			

             var container = $('<div />');

            //  container.on('click', '.smallPolygonLink', function() {
            //     alert("test");
            // });

             container.html(`<iframe id="infoWindowChrat" name="infoWindowChrat" style="width:620px;height:480px;border:0px;" src="https://geotaker.com.br/tooltips/tooltip.htm?codigo=${e.layer.options.id}" frameborder="0" scrolling="no"></iframe>`)
            //  container.append($('<span class="bold">').text(" :)"))
            //  container.append("This is a link: <a href='#' class='smallPolygonLink'>Click me</a>.");

            container.append('<div style="float: left;">')

            container.append('<style>.buttonGerarEstudo:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
             container.append($('<button class="buttonGerarEstudo" id="buttonGerarEstudo" style="margin-left: 53px; background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 150px; padding: 8px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/chart-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">GERAR ESTUDO</span></button>').click(function() {
                
             gerarEstudoSetor(e.layer.options.id, e.latlng, false)
            })[0])


            container.append('<style>.buttonPesquisaNegocio:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
             container.append($('<button class="buttonPesquisaNegocio" id="buttonPesquisaNegocio" style="margin-left:20px; background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 190px; padding: 8px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/pesquisar2-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">PESQUISAR NEGÓCIOS</span></button>').click(function() {
                
              pesquisarNegocios(e.latlng, map.getZoom())
            })[0])           


            container.append('<style>.buttonMarcarPin:hover { background-color: #0D47A8!important; transition: .5s;}</style>')
             container.append($('<button class="buttonMarcarPin" id="buttonMarcarPin" style="margin-left: 20px; background-color: #2A5CF1; border: 0; font-weight: 500; color: white; width: 150px; padding: 8px; border-radius: 3px; text-align: center; cursor: pointer; box-shadow: 1px 1px 5px grey; position: relative; transition: .5s;"><img src="https://geotaker.com.br/images/pin2-23.png" style="position: absolute;left: 10px;" width="16"> <span style="padding-left: 20px; font-size: 12px; ">MARCAR PONTO</span></button>').click(function() {
                
             marcarPonto(e.layer.options.id, e.latlng)
            })[0])
            
            
            container.append('</div>')
             marker.setLatLng(e.latlng).bindPopup(container[0]).addTo(map).openPopup();


		 	// infowindow.open(map, marker);
            //  marker.bindPopup($(container[0]).click(function() {
            //     alert("test");
            // })[0]);

            // marker.bindPopup($(container[0]).click(function() {
            //     alert("test");
            // })[0]);
            // var link = $('<a href="#" class="speciallink">TestLink</a>').click(function() {
            //     alert("test");
            // })[0];
            // marker.bindPopup(link);
            
             
		 });
         
       
      

		// this.centraliza();
	}
	
	this.centraliza = function()
	{
		// var bounds = new google.maps.LatLngBounds();
		// for (var id in shapes)
		// {
		// 	if (shapes[id].visible == true)
		// 	{
		// 		bounds.union(shapes[id].getBounds());
		// 	}
		// }
		// map.fitBounds(bounds);
		
		// map.fitBounds(group.getBounds(), {padding:[20,20]});
	}
	
	this.getShapes = function()
	{
		// console.log(shapes)
		return shapes;
	}
}

var cartograma = new Cartograma();


function limparLayer() {
	map.eachLayer(function (layer) {	
		// console.log("LAYERS ID: " + layer._leaflet_id)
		// console.log(layer._leaflet_id)		
			// map.removeLayer(layer);
			
		if(groupLayers.includes(layer._leaflet_id)) {
			// console.log("EXISTE O LAYER NO MAP: "+layer._leaflet_id)
			map.removeLayer(layer)
		}
		// var indexRemover = groupLayers.indexOf(layer._leaflet_id)
		// if (indexRemover > -1) {
		// 	groupLayers.splice(indexRemover, 1);
		//   }
		// RESUMINDO O COMANDO ACIMA PARA BAIXO:
		groupLayers.splice(groupLayers.indexOf(layer._leaflet_id), 1);
	});
}

function limparArrayLayers() {
	var groupLayers = [];
}



function Dados()
{
	var bd = new Object();
	var titulo;
	var fonte;
	var unidade;
    
	this.getBd = function()
	{
		return bd;
	}
	this.getDado = function(id)
	{
		if (params.getVariavel()[0] == 'cidadesat')
		{
			return bd[id.substr(0,6)];
		}
		else
		{
			return bd[id];
		}
	}
	this.setDado = function(id, v)
	{
		bd[id] = v;
	}
	this.removeDado = function(id)
	{
		bd[id] = '';
	}
	this.clearBd = function()
	{
		for (var id in bd)
		{
			this.removeDado(id);
		}
	}
	this.getTitulo = function()
	{
		return titulo;
	}
	this.setTitulo = function(txt)
	{
		titulo = txt;
	}
	this.getFonte = function()
	{
		return fonte;
	}
	this.setFonte = function(txt)
	{
		fonte = txt;
	}
	this.getUnidade = function()
	{
		return unidade;
	}
	this.setUnidade = function(txt)
	{
		unidade = txt;
	}
}

var dados = new Dados();


function CargaDados()
{
	var filaAreas=0;
	var filaDados=0;
	
    this.loadData = function(url)
	{
        var headID = document.getElementsByTagName("head")[0];
        var newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.src = url;
        newScript.id = "carga_"+new Date().getTime();
        //newScript.class = "dados_carregados";
        headID.appendChild(newScript);
        
		newScript.dadosHandleExternalJSON = function()
		{
			//newScript.parentNode.removeChild(newScript);
		}
		
        newScript.onreadystatechange = function ()
        {
            if (this.readyState == 'complete' || this.readyState == 'loaded')
            {
                this.dadosHandleExternalJSON();
            }
        }
        newScript.onload = newScript.dadosHandleExternalJSON();
    }
	
	this.getUrlDados = function(areas, variavel, nivel)
    {
        var urls = new Array();
        switch (variavel[0])
        {
            case 'sinopseporsetores':
				var c=0;
                for (var id in areas)
                {
                	console.log(areas[id])
                	console.log(variavel[1])
                    urls.push("https://servicodados.ibge.gov.br/censo2010sinopsesetor.ashx?listaitens=1&ag="+nivel+"&cod="+areas[id]+"&v="+variavel[1]+"&js=objDataTmp["+c+"]=");
					c++;
                }
                break;
                
            case 'cidadesat':
                for (var id in areas)
                {
                    urls.push("https://www.ibge.gov.br/cidadesat/jSonp/jSonp_dados.php?tab="+variavel[1]+"&codigo="+areas[id]+"&var="+variavel[2]+"&inicode=objDataTmp.push(&endcode=);");
                }
                break;
        }
        return (urls);
    }

	this.getUrlPontos = function(id)
    {
		var strFolder = codCidade;
		var ufCod = codCidade.substring(0,2)
		var arrNiveis = new Array(2,7,9,11);
		if (id.length > 2)
		{
			var i = 0;
			while (arrNiveis[i] != id.length)
			{
				strFolder += id.substr(0,arrNiveis[i]) + "/";
				i++;
			}
		}
		return `https://servicodados.ibge.gov.br/Malha/${ufCod}/${strFolder}.js`
    }
	
	this.getFilaAreas = function()
	{
		return filaAreas;
	}
	this.addFilaAreas = function()
	{
		filaAreas++;
	}
	this.removeFilaAreas = function()
	{
		filaAreas--;
	}
	
	this.getFilaDados = function()
	{
		return filaDados;
	}
	this.addFilaDados = function()
	{
		filaDados++;
	}
	this.removeFilaDados = function()
	{
		filaDados--;
	}
}
var cargaDados = new CargaDados();

function Params()
{
	var nivel;
	var areas;
	var variavel;
	var filtro;
	var corBase;
	var corMin;
	var corMax;
	var corFill;
	var opFill;
	var corContorno;
	var espContorno;
	var opContorno;
	var showMenu;
	
	this.getPermaLink = function()
	{
		var myLink = window.location.toString().split("?")[0] + "?";
		myLink += "nivel="+nivel.toString();
		myLink += "&areas="+areas.toString();
		myLink += "&variavel="+variavel.toString();
		myLink += "&filtro="+filtro.toString();
		myLink += "&corBase="+corBase.toString();
		myLink += "&corMin="+corMin.toString();
		myLink += "&corMax="+corMax.toString();
		myLink += "&corFill="+corFill.toString();
		myLink += "&opFill="+opFill.toString();
		myLink += "&corContorno="+corContorno.toString();
		myLink += "&espContorno="+espContorno.toString();
		myLink += "&opContorno="+opContorno.toString();
		myLink += "&showMenu="+showMenu.toString();

		return myLink;
	}
	
	this.verMenu = function()
	{
		if (showMenu == 0 || showMenu == "no" || showMenu == false || showMenu == "false")
			return false;
		else
			return true;
	}
	
	// funções GET
	this.getNivel = function()
	{
		return nivel;
	}
	this.getAreas = function()
	{
		return areas;
	}
	this.setAreas = function(arr)
	{
		areas = new Array();
		areas = arr;
	}
	this.getVariavel = function()
	{
		return variavel;
	}
	this.setVariavel = function(arr)
	{
		variavel = new Array();
		variavel = arr;
	}
	this.getFiltro = function()
	{
		return filtro;
	}
	this.resetFiltro = function()
	{
		filtro = new Array();
	}
	this.getCorBase = function()
	{
		return corBase;
	}
	this.getCorMin = function()
	{
		return corMin;
	}
	this.getCorMax = function()
	{
		return corMax;
	}
	this.getCorFill = function()
	{
		return corFill;
	}
	this.getOpFill = function()
	{
		return opFill;
	}
	this.getCorContorno = function()
	{
		return corContorno;
	}
	this.getEspContorno = function()
	{
		return espContorno;
	}
	this.getOpContorno = function()
	{
		return opContorno;
	}
	
	// fuções de Cor
	this.setCor = function(id, cor)
	{
		if (corFill[id])
		{
			corFill[id] = cor;
		}
		else
		{
			alert("Erro: Esta posição não existe.");
		}
	}
	this.resetCorFill = function()
	{
		if (filtro.length != corFill.length)
		{
			corFill = this.getEscalaCores(corMin, corMax, filtro.length);
		}
	}
    this.getEscalaCores = function(corMin, corMax, n)
    {
        var cores = Array();
        if (n > 1)
        {
            var R = Array();
            var G = Array();
            var B = Array();
            var rIni = parseInt(corMin.substr(0,2), 16);
            var gIni = parseInt(corMin.substr(2,2), 16);
            var bIni = parseInt(corMin.substr(4,2), 16);
            var rFim = parseInt(corMax.substr(0,2), 16);
            var gFim = parseInt(corMax.substr(2,2), 16);
            var bFim = parseInt(corMax.substr(4,2), 16);
            var passoR = (rFim-rIni)/(n-1);
            var passoG = (gFim-gIni)/(n-1);
            var passoB = (bFim-bIni)/(n-1);
            for (var i=0; i<n; i++)
            {
                R[i] = Math.round(rIni + passoR*i);
                G[i] = Math.round(gIni + passoG*i);
                B[i] = Math.round(bIni + passoB*i);
                cores[i] = this.getCorHexaStr(R[i], G[i], B[i]);
            }
        }
        else
        {
            cores[0] = corMin;
        }
		corMin = cores[0];
		corMax = cores[cores.length-1];
        return cores;
    }
	this.setOpFill = function(op)
	{
		opFill = op;
	}
	
    this.getCorHexaStr = function(r, g, b)
    {
        var rHexaStr = r.toString(16);
        var gHexaStr = g.toString(16);
        var bHexaStr = b.toString(16);
        if (rHexaStr.length < 2) rHexaStr = "0"+rHexaStr;
        if (gHexaStr.length < 2) gHexaStr = "0"+gHexaStr;
        if (bHexaStr.length < 2) bHexaStr = "0"+bHexaStr;
        return (rHexaStr + gHexaStr + bHexaStr);
    }
	
	// funções do Filtro
	this.getFx = function(v)
    {
        var fx = '';
		if (v || v == 0 || v == "0")
		{
			var v = (parseFloat(v.toString())?parseFloat(v.toString()):'');
			if (v || v==0)
			{
				for (var i=0; i<filtro.length; i++)
				{
					if (v>=parseFloat(filtro[i][0]) && v<=parseFloat(filtro[i][1]))
					{
						fx = i;
					}
				}
			}
		}
        return fx;
    }
	this.getFaixa = function(i)
	{
		return filtro[i];
	}
	this.getPos = function(i,j)
	{
		return filtro[i][j];
	}
	this.setFiltro = function(matriz)//matriz
	{
		filtro = new Array();
		filtro = matriz;
		this.resetCorFill();
	}
	this.getMatrizArray = function(arr)
	{
		var m = new Array();
		var c=0;
		for (var i=0; i<arr.length; i+=2)
		{
			m[c] = new Array(arr[i], arr[i+1]);
			c++;
		}
		return m;
	}
	function sortNumber(a, b)
	{
		return a - b;
	}

	this.getQuantis = function(objBd, n)// quantis
	{
		var valores = new Array();
		var vTmp;
		for (var i in objBd)
		{
			vTmp = parseFloat(objBd[i].toString());
			if (!isNaN(vTmp))
			{
				valores.push(vTmp);
			}
		}
		var filtro = new Array();
		var vOrd = valores.sort(sortNumber);
		var vOrdUnique = Array();
		vOrdUnique[0] = "AAA";
		for (var i=0; i<vOrd.length; i++)
		{
			if (!isNaN(vOrd[i]) && vOrd[i] != undefined && vOrd[i] != null)
			{
				if (vOrd[i]+"Baca" != "Baca")
				{
					if (vOrd[i] != vOrdUnique[vOrdUnique.length-1])
					{
						vOrdUnique.push(vOrd[i]);
					}
				}
			}
		}
		var lixo = vOrdUnique.shift();
		if (vOrdUnique.length <= n)
		{
			if (vOrdUnique.length == 0)
			{
				alert("Sem regitro de valores para o cartograma.");
			}
			else if(vOrdUnique.length == 1)
			{
				alert("Quantidade de valores insufisciente para calcular os quantis solicitados.\nFoi calculado automaticamente 1 grupo.");
			}
			else
			{
				alert("Quantidade de valores insufisciente para calcular os quantis solicitados.\nForam calculados automaticamente "+vOrdUnique.length+" grupos.");
			}
			for (var i=0; i<vOrdUnique.length; i++)
			{
				filtro[i] = new Array();
				filtro[i][0] = vOrdUnique[i];
				filtro[i][1] = vOrdUnique[i];
			}
		}
		else
		{
			var passo = Math.round(vOrdUnique.length/n);
			var vSlice = Array();
			for (var i=0; i<n; i++)
			{
				if (i<n-1)
				{
					vSlice = vOrdUnique.splice(0, passo);
				}
				else
				{
					vSlice = vOrdUnique;
				}
				filtro[i] = new Array();
				if (vSlice.length > 1)
				{
					filtro[i][0] = vSlice.shift();
					filtro[i][1] = vSlice.pop();
				}
				else
				{
					filtro[i][0] = vSlice[0];
					filtro[i][1] = vSlice[0];
				}
			}
		}
		return filtro;
	}
	this.setFaixa = function(i, arr)
	{
		filtro[i] = arr;
	}
	this.setPos = function(i,j,v)
	{
		filtro[i][j] = v;
	}
	this.getShowMenu = function()
	{
		return showMenu;
	}
	
	// execução
	var myURL = window.location.toString();
	var get = new Object();
	if (myURL.split("?")[1])
	{
		var urlParams = myURL.split("?")[1].split("&");
		for (var i in urlParams)
		{
			get[urlParams[i].split("=")[0]] = urlParams[i].split("=")[1];
		}
	}
	nivel = (get.nivel ? get.nivel : 'mn');
	areas = (get.areas ? get.areas.split(",") : new Array());
	variavel = (get.variavel ? get.variavel.split(",") : new Array());
	filtro = (get.filtro ? this.getMatrizArray(get.filtro.split(",")) : new Array());
	corBase = (get.corBase ? get.corBase : "FFFFFF");
	corMin = (get.corMin ? get.corMin : "FFFF00");
	corMax = (get.corMax ? get.corMax : "660000");
	corFill = (get.corFill ? get.corFill.split(",") : (filtro.length ? this.getEscalaCores(corMin, corMax, filtro.length) : new Array()));
	opFill = (get.opFill ? parseFloat(get.opFill) : 0.5);
	corContorno = (get.corContorno ? get.corContorno : "000000");
	espContorno = (get.espContorno ? parseFloat(get.espContorno) : 0.5);
	opContorno = (get.opContorno ? parseFloat(get.opContorno) : 0.75);
	showMenu = (get.showMenu ? get.showMenu.toLowerCase() : true);
}
var params = new Params();

function containsArea(areas, id)
{
	for(var i in areas)
	{
		if (areas[i]==id)
		{
			return true;
		}
	}
	return false;
}

function resetAreas(areas)
{
	for (var i in areas)
	{
		if (!containsArea(params.getAreas(), areas[i]))
		{
			// $('#carregando').show();
			// cargaDados.loadData(cartoDecod);
		}
	}
	
	for (var i in params.getAreas())
	{
		if (!containsArea(areas, params.getAreas()[i]))
		{
			hideCarto(params.getAreas()[i]);
		}
	}

	hideCarto(codCidade);
	// console.log("resetandoooo...")
	// params.setAreas(areas);
	// resetVariaveis();
}

function hideCarto(idArea)
{
	for (var id in cartograma.getShapes())
	{
		// console.log("ID DO HIDECARTO: "+id)
    if(idArea) { 
		if (id.substring(0, idArea.length) == idArea)
		{
			
			cartograma.getShapes()[id].remove();
		}
  }
	}
}

function pintaCartograma()
{
	// console.log("PINTOU!!!!")
	var fx;
	var color;
	for (var id in cartograma.getShapes())
	{
		if (map.hasLayer(cartograma.getShapes()[id]))
		{      
			fx = params.getFx(dados.getDado(id));
			if (fx === ""){
				color = "#"+params.getCorBase();
			}else{
				color = "#"+params.getCorFill()[fx];
			}
			
			var highlightstyle = {
				fillColor:color,
				fillOpacity: params.getOpFill(),
				color: "#"+params.getCorContorno(), 
				opacity: params.getOpContorno(),
				weight: params.getEspContorno()
			};
			
			cartograma.getShapes()[id].setStyle(highlightstyle);
		}
	}
}








// useEffect(()=> {
//   var myHeaders = new Headers();
//   var myInit = { method: 'GET',
//          headers: myHeaders,
//          mode: 'cors',
//          cache: 'default' };
//   fetch(
//       `https://servicodados.ibge.gov.br/Malha/35/3549805.js`,
//       myInit
//   )
//     .then((res) => res.text())
//     .then((kmlText) => {
//       var kmltextCortada = kmlText.substring(17, (kmlText.length)-3)
//       // console.log(kmltextCortada)
//       var myNewml = [kmltextCortada].shift();     
//       var newml_decoder = new NewML();
//       newml_decoder.parse(myNewml)
//     //   console.log(newml_decoder);
//       setCartoDecod(newml_decoder)
//       // cartograma.desenha(newml_decoder);
//     })
  
// }, [])



if(razaoSexo == true) {
	var myHeaders = new Headers();
	var myInit = { method: 'GET',
		   headers: myHeaders,
		   mode: 'cors',
		   cache: 'default' };
	fetch(
		`https://servicodados.ibge.gov.br/censo2010sinopsesetor.ashx?listaitens=1&ag=st&cod=${codCidade}&v=case%20when%20coalesce(v016,0)%3E0%20then%20(round(coalesce(v015%20divide%20v016,0)%20multiplica%20100,2))%20else%200%20end&js=objDataTmp[0]=`,
		myInit
	)
	  .then((res) => res.text())
	  .then((kmlText) => {
		  console.log(kmlText)
		var kmltextCortada = kmlText.substring(14, (kmlText.length)-1)
		// console.log(kmlText)
		var myObjDataTmp = JSON.parse(kmltextCortada);
	
		console.log(myObjDataTmp)
		for (var codArea in myObjDataTmp)
		{
			// console.log(codArea)
			dados.setDado(codArea, myObjDataTmp[codArea]);
		}
		// cargaDados.removeFilaDados();
		
		if(objDataTmp.length==0)
		{
			console.log(dados.getBd())
			console.log(params.getQuantis(dados.getBd(),7))
			params.setFiltro(params.getQuantis(dados.getBd(),7));
			pintaCartograma();
			// resetLegenda();
		};

		// params.setFiltro(params.getQuantis(dados.getBd(),5));
		// 	pintaCartograma();


	  })
	
	}


const opcaoIndice = async (indice, tipo) => {
	
	await api.post('/malha/indice', {codCidade: codCidade, indice: indice, tipo: tipo})
	// .then((res) => console.log(res.data.data))
	.then((kmlText) => {
		// console.log(kmlText)
		var kmlusar = kmlText.data.data
		var kmltextCortada = kmlusar.substring(14, (kmlusar.length)-1)
		var myObjDataTmp = JSON.parse(kmltextCortada);	
		
		for (var codArea in myObjDataTmp)
		{
			// console.log(codArea)
			dados.setDado(codArea, myObjDataTmp[codArea]);
		}
		// cargaDados.removeFilaDados();
		
		if(objDataTmp.length==0)
		{			
			params.setFiltro(params.getQuantis(dados.getBd(),7));
			pintaCartograma();
			// resetLegenda();
		};

		// params.setFiltro(params.getQuantis(dados.getBd(),5));
		// 	pintaCartograma();


	  })
}

useEffect(()=> {
if(cartoDecod != null) {
	// console.log(cartoDecod)
  cartograma.desenha(cartoDecod)
}

}, [cartoDecod])


const voarPara = async (latlng, zoom, marker) => {
    if(idLayer != undefined) {
        // console.log("Excluindo")
        map.removeLayer(map._layers[idLayer]) 
        setIdLayer()
      }
    map.flyTo(latlng, zoom)

    if (marker == true) { 
    var marker = L.marker(latlng, {icon: icon})
    var geoJsonGroup = L.layerGroup([marker])                  
        geoJsonGroup.addTo(map);
        setIdLayer(L.stamp(geoJsonGroup))
    }
}

const voarParaInicio = async (latlng, zoom) => {
    if(idLayer != undefined) {
        // console.log("Excluindo")
        map.removeLayer(map._layers[idLayer]) 
        setIdLayer()
      }
    map.flyTo(latlng, zoom)
}

const voarParaLocal = async (latlng, zoom, geocode) => {
    
    map.flyTo(latlng, zoom)

    if(geocode) {
              
            await axios.get(`https://servicodados.ibge.gov.br/api/v2/malhas/${geocode}/?formato=application/vnd.geo+json`)
                .then(function (response) {
                  // handle success
                  console.log(response.data);
                  setMalha(response.data)
                  // L.geoJSON(response.data).addTo(map);
                  if(idLayer != undefined) {
                    // console.log("Excluindo")
                    map.removeLayer(map._layers[idLayer]) 
                  }
                  // console.log(idLayer)
                  var geojsonLayer = L.geoJSON(response.data, {
                    style: {
                      "color": "#9f27b8",
                      "weight": 1.5,
                      "opacity": 0.65
                  }
                  });

                  var marker = L.marker(latlng, {icon: icon})

                  var geoJsonGroup = L.layerGroup([geojsonLayer, marker])
                
                  // geoJsonGroup.clearLayers(map)      
                  geoJsonGroup.addTo(map);
                  // var x_id = L.stamp(geoJsonGroup)
                  setIdLayer(L.stamp(geoJsonGroup))
               
                 
                //   geoJsonGroup.eachLayer(function(layer) {
                //     layer.on('click', function(){
                //      alert(this._leaflet_id);
                //     //  map.removeLayer(this._leaflet_id)
                //     });
                //   });
          
                  // setTimeout(() => geoJsonGroup.clearLayers(map), 3000);
                  // setTimeout(() => map.removeLayer(x_id), 3000);
                  // map.removeLayer(geojsonLayer);
          
                })
                .catch(function (error) {
                  // handle error
                  // console.log(error);
                })
                .then(function () {
                  // always executed
                });
              } 
}
const verDetalhesCidade = () => {
  // setLiberaPinMapa(false)
    detalhesCidade()
    empresasDetalhesCidade()
    salarioMedioCidadeDetalhes()
    densidadeDemograficaCidadeDetalhes()
    pibCidadeDetalhes()
  }

  const detalhesCidade = async () => {
    setLoadingVerCidade(true)
	
    await api.post('/explorar/cidades/detalhes', {codCidade: codCidade})
        .then(function (response) {
          // handle success
        //   console.log(response.data)
          setMalhaIbge(response.data.malhaIbge)
          const datajson = response.data.data
          // console.log(datajson[0].latitude)
          // voarPara([datajson.latitude, datajson.longitude], 10)

          setNomeMunicDetalhes(response.data.data.nome_municipio)
          setNomeUfDetalhes(response.data.data.abrev_uf)
          setNomeEstDetalhes(response.data.data.nome_uf)
          setNomeMesorregDetalhes(response.data.data.nome_mesorregiao)
          setNomeMicrorregDetalhes(response.data.data.nome_microrregiao)

          setOpenTabs(false) 
          setOpenModal(false);
          setOpenDetalhes(true)
          setLoadingVerCidade(false)

		  setLoadingMalhaCidade(true)

			var ufCod = codCidade.substring(0,2)			

			var myHeaders = new Headers();
			var myInit = { method: 'GET',
					headers: myHeaders,
					mode: 'cors',
					cache: 'default' };
			fetch(
				`https://servicodados.ibge.gov.br/Malha/${ufCod}/${codCidade}.js`,
				myInit
			)
				.then((res) => res.text())
				.then((kmlText) => {
				var kmltextCortada = kmlText.substring(17, (kmlText.length)-3)
				// console.log(kmltextCortada)
				var myNewml = [kmltextCortada].shift();     
				var newml_decoder = new NewML();
				newml_decoder.parse(myNewml)
				//   console.log(newml_decoder);
				setCartoDecod(newml_decoder)
				// cartograma.desenha(newml_decoder);
				setLoadingMalhaCidade(false)
				})

         
        })
        .catch(function (error) {
          // handle error
          setLoadingVerCidade(false)
		  setLoadingMalhaCidade(false)
          console.log(error);
        })
        .then(function () {
          // always executed
         
        });

  }


  const empresasDetalhesCidade = async () => {
    setLoadingEmpresasCidade(true)
    await api.post('/consulta/cidade/empresas', {cidade:codCidade})
        .then(function (response) {
          // handle success
            const datajson = response.data
            const total_matriz = Number(response.data[0].ativa_matriz)
            const total_filial = Number(response.data[0].ativa_filial)
          
            const total_empresas = (total_matriz + total_filial).toLocaleString('pt-BR');

            setDadosEmpresasCidade(total_empresas)
            setLoadingEmpresasCidade(false)

        })
        .catch(function (error) {
          // handle error
          setLoadingEmpresasCidade(false)
          console.log(error);
        })
        .then(function () {
          // always executed
         
        });

  }


  const salarioMedioCidadeDetalhes = async () => {
    setLoadingSalarioMedio(true)
    await api.post('/consulta/cidade/salariosminimos', {cidade:codCidade})
          .then(function (response) {
            // handle success
            const ValorSalarioAtual = 1100;
            const datajson = response.data
            let data = datajson[1].V;
            let valorSalario = data * ValorSalarioAtual;
          
            setDadosSalarioMedio(valorSalario.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))
            setLoadingSalarioMedio(false)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          
          });

  }


const densidadeDemograficaCidadeDetalhes = async () => {
  setLoadingDensDemog(true)
  await api.post('/consulta/cidade/densidadedemografica', {cidade:codCidade})
  .then(function (response) {
    // handle success
    
    const datajson = response.data
  
    setDadosDensDemog(Number(datajson[1].V).toLocaleString('pt-BR'))
    setLoadingDensDemog(false)
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .then(function () {
    // always executed
  
  });

}

const pibCidadeDetalhes = async () => {
  setLoadingPib(true)
  await api.post('/consulta/cidade/pib', {cidade:codCidade})
          .then(function (response) {
            // handle success
           
            const datajson = response.data
            let data = datajson[1].V;
            let valorPib = data * 1000;
          
            setDadosPib(valorPib.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))
            setLoadingPib(false)
          })
          .catch(function (error) {
            // handle error
            setLoadingPib(false)
            console.log(error);
          })
          .then(function () {
            // always executed
          
          });

}

const handleOpenModal = () => {   
    setOpenModal(true);
  };

  const handleCloseModal = (event, reason) => {
    if(reason !== 'backdropClick') {
      hideCarto(codCidade)
      setOpenModal(false);
      setOpenTabs(true)
    }
    
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeTabs = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

const handleCloseDetalhes = () => {
  setOpenDetalhes(false)
  setOpenModal(true);
};

const handleChangeSexo = (event) => {
  const name = event.target.name;
  setStateSexo(event.target.value);
};



const indices = [
	{ value:"case%20when%20coalesce(v016,0)>0%20then%20(round(coalesce(v015%20divide%20v016,0)%20multiplica%20100,2))%20else%200%20end", label: "Razão de Sexo", type: 1 },
{ value:"case%20when%20coalesce(AREA,0)>0%20then%20(round(coalesce((V014%20multiplica%201000000)%20divide AREA,%200),%202))%20else%200%20end", label: "Densidade Demográfica Preliminar (Habitantes/Km2)", type: 1 },
{ value:"4324", label: "Rendimento", type: 2 },
{ value:"case when coalesce(v010,0)>0 then (round(coalesce(V014 divide V010, 0), 2)) else 0 end", label: "Média de Moradores por Domicílio Ocupado", type: 1 },
{ value:"case when coalesce(v010,0)>0 then (round(coalesce(V015 divide V010, 0), 2)) else 0 end", label: "Média de Homens por Domicílio Ocupado", type: 1 },
{ value:"case when coalesce(v010,0)>0 then (round(coalesce(V016 divide V010, 0), 2)) else 0 end", label: "Média de Mulheres por Domicílio Ocupado", type: 1 },
{ value:"coalesce(V032 soma V033 soma V034 soma V035 soma V036 soma V037, 0)", label: "Pessoas Residentes - 0 a 5 anos de idade", type: 1 },
{ value:"coalesce(V038 soma V039 soma V040 soma V041 soma V042 soma V043 soma V044 soma V045 soma V046 soma V047, 0)", label: "Pessoas Residentes - 6 a 15 anos de idade", type: 1 },
{ value:"coalesce(V048 soma V049 soma V050 soma V051, 0)", label: "Pessoas Residentes - 16 a 19 anos de idade", type: 1 },
{ value:"coalesce(V052 soma V053 soma V054 soma V055 soma V056, 0)", label: "Pessoas Residentes - 20 a 24 anos de idade", type: 1 },
{ value:"coalesce(V064 soma V065 soma V066 soma V067 soma V068 soma V069 soma V070 soma V071 soma V072, 0)", label: "Pessoas Residentes - 60 anos ou mais", type: 1 },
{ value:"coalesce(V068 soma V069 soma V070 soma V071 soma V072, 0)", label: "Pessoas Residentes - 80 anos ou mais", type: 1 },
{ value:"coalesce(V064 soma V065 soma V066 soma V067, 0)", label: "Pessoas Residentes - 60 a 79 anos de idade", type: 1 },
{ value:"V001", label: "Domicílios Particulares e Coletivos", type: 1 },
{ value:"V002", label: "Domicílios Particulares Permanentes", type: 1 },
{ value:"V003", label: "Domicílios Particulares Permanentes Ocupados", type: 1 },
{ value:"V004", label: "Domicílios Particulares Permanentes Ocupados - com entrevista realizada", type: 1 },
{ value:"V005", label: "Domicílios Particulares Permanentes Ocupados - sem entrevista realizada", type: 1 },
{ value:"V006", label: "Domicílios Particulares Permanentes não Ocupados", type: 1 },
{ value:"V007", label: "Domicílios Particulares Permanentes não Ocupados - uso ocasional", type: 1 },
{ value:"V008", label: "Domicílios Particulares Permanentes não Ocupados - vago", type: 1 },
{ value:"V009", label: "Domicílios Particulares Improvisados Ocupados", type: 1 },
{ value:"V010", label: "Domicílios Particulares Ocupados", type: 1 },
{ value:"V011", label: "Domicílios Coletivos", type: 1 },
{ value:"V012", label: "Domicílios Coletivos - com morador", type: 1 },
{ value:"V013", label: "Domicílios Coletivos - sem morador", type: 1 },
{ value:"V014", label: "Pessoas residentes", type: 1 },
{ value:"V015", label: "Homens residentes", type: 1 },
{ value:"V016", label: "Mulheres residentes", type: 1 },
{ value:"V017", label: "Pessoas residentes - Domicílios  Particulares ocupados", type: 1 },
{ value:"V018", label: "Pessoas residentes - Domicílios  particulares permanentes ocupados - com entrevista realizada", type: 1 },
{ value:"V019", label: "Pessoas residentes - Domicílios  particulares permanentes ocupados - sem entrevista realizada", type: 1 },
{ value:"V020", label: "Pessoas residentes – Domicílios particulares improvisados ocupados", type: 1 },
{ value:"V021", label: "Pessoas residentes - Domicílios  coletivos - com morador", type: 1 },
{ value:"V022", label: "Homens residentes - Domicílios  particulares ocupados", type: 1 },
{ value:"V023", label: "Homens residentes - Domicílios  particulares permanentes ocupados - com entrevista realizada", type: 1 },
{ value:"V024", label: "Homens residentes - Domicílios  particulares permanentes ocupados - sem entrevista realizada", type: 1 },
{ value:"V025", label: "Homens residentes – Domicílios particulares improvisados ocupados", type: 1 },
{ value:"V026", label: "Homens residentes - Domicílios  coletivos - com morador", type: 1 },
{ value:"V027", label: "Mulheres residentes - Domicílios  particulares ocupados", type: 1 },
{ value:"V028", label: "Mulheres residentes - Domicílios  particulares permanentes ocupados – com entrevista realizada", type: 1 },
{ value:"V029", label: "Mulheres residentes - Domicílios  particulares permanentes ocupados – sem entrevista realizada", type: 1 },
{ value:"V030", label: "Mulheres residentes – Domicílios particulares improvisados ocupados", type: 1 },
{ value:"V031", label: "Mulheres residentes - Domicílios  coletivos - com morador", type: 1 },
{ value:"V032", label: "Pessoas residentes - 0 anos de idade", type: 1 },
{ value:"V033", label: "Pessoas residentes - 1 anos de idade", type: 1 },
{ value:"V034", label: "Pessoas residentes - 2 anos de idade", type: 1 },
{ value:"V035", label: "Pessoas residentes - 3 anos de idade", type: 1 },
{ value:"V036", label: "Pessoas residentes - 4 anos de idade", type: 1 },
{ value:"V037", label: "Pessoas residentes - 5 anos de idade", type: 1 },
{ value:"V038", label: "Pessoas residentes - 6 anos de idade", type: 1 },
{ value:"V039", label: "Pessoas residentes - 7 anos de idade", type: 1 },
{ value:"V040", label: "Pessoas residentes - 8 anos de idade", type: 1 },
{ value:"V041", label: "Pessoas residentes - 9 anos de idade", type: 1 },
{ value:"V042", label: "Pessoas residentes - 10 anos de idade", type: 1 },
{ value:"V043", label: "Pessoas residentes - 11 anos de idade", type: 1 },
{ value:"V044", label: "Pessoas residentes - 12 anos de idade", type: 1 },
{ value:"V045", label: "Pessoas residentes - 13 anos de idade", type: 1 },
{ value:"V046", label: "Pessoas residentes - 14 anos de idade", type: 1 },
{ value:"V047", label: "Pessoas residentes - 15 anos de idade", type: 1 },
{ value:"V048", label: "Pessoas residentes - 16 anos de idade", type: 1 },
{ value:"V049", label: "Pessoas residentes - 17 anos de idade", type: 1 },
{ value:"V050", label: "Pessoas residentes - 18 anos de idade", type: 1 },
{ value:"V051", label: "Pessoas residentes - 19 anos de idade", type: 1 },
{ value:"V052", label: "Pessoas residentes - 20 anos de idade", type: 1 },
{ value:"V053", label: "Pessoas residentes - 21 anos de idade", type: 1 },
{ value:"V054", label: "Pessoas residentes - 22 anos de idade", type: 1 },
{ value:"V055", label: "Pessoas residentes - 23 anos de idade", type: 1 },
{ value:"V056", label: "Pessoas residentes - 24 anos de idade", type: 1 },
{ value:"V057", label: "Pessoas residentes - 25 a 29 anos de idade", type: 1 },
{ value:"V058", label: "Pessoas residentes - 30 a 34 anos de idade", type: 1 },
{ value:"V059", label: "Pessoas residentes - 35 a 39 anos de idade", type: 1 },
{ value:"V060", label: "Pessoas residentes - 40 a 44 anos de idade", type: 1 },
{ value:"V061", label: "Pessoas residentes - 45 a 49 anos de idade", type: 1 },
{ value:"V062", label: "Pessoas residentes - 50 a 54 anos de idade", type: 1 },
{ value:"V063", label: "Pessoas residentes - 55 a 59 anos de idade", type: 1 },
{ value:"V064", label: "Pessoas residentes - 60 a 64 anos de idade", type: 1 },
{ value:"V065", label: "Pessoas residentes - 65 a 69 anos de idade", type: 1 },
{ value:"V066", label: "Pessoas residentes - 70 a 74 anos de idade", type: 1 },
{ value:"V067", label: "Pessoas residentes - 75 a 79 anos de idade", type: 1 },
{ value:"V068", label: "Pessoas residentes - 80 a 84 anos de idade", type: 1 },
{ value:"V069", label: "Pessoas residentes - 85 a 89 anos de idade", type: 1 },
{ value:"V070", label: "Pessoas residentes - 90 a 94 anos de idade", type: 1 },
{ value:"V071", label: "Pessoas residentes - 95 a 99 anos de idade", type: 1 },
{ value:"V072", label: "Pessoas residentes - 100 anos ou mais de idade ", type: 1 },
{ value:"V073", label: "Homens residentes - 0 anos de idade", type: 1 },
{ value:"V074", label: "Homens residentes - 1 anos de idade", type: 1 },
{ value:"V075", label: "Homens residentes - 2 anos de idade", type: 1 },
{ value:"V076", label: "Homens residentes - 3 anos de idade", type: 1 },
{ value:"V077", label: "Homens residentes - 4 anos de idade", type: 1 },
{ value:"V078", label: "Homens residentes - 5 anos de idade", type: 1 },
{ value:"V079", label: "Homens residentes - 6 anos de idade", type: 1 },
{ value:"V080", label: "Homens residentes - 7 anos de idade", type: 1 },
{ value:"V081", label: "Homens residentes - 8 anos de idade", type: 1 },
{ value:"V082", label: "Homens residentes - 9 anos de idade", type: 1 },
{ value:"V083", label: "Homens residentes - 10 anos de idade", type: 1 },
{ value:"V084", label: "Homens residentes - 11 anos de idade", type: 1 },
{ value:"V085", label: "Homens residentes - 12 anos de idade", type: 1 },
{ value:"V086", label: "Homens residentes - 13 anos de idade", type: 1 },
{ value:"V087", label: "Homens residentes - 14 anos de idade", type: 1 },
{ value:"V088", label: "Homens residentes - 15 anos de idade", type: 1 },
{ value:"V089", label: "Homens residentes - 16 anos de idade", type: 1 },
{ value:"V090", label: "Homens residentes - 17 anos de idade", type: 1 },
{ value:"V091", label: "Homens residentes - 18 anos de idade", type: 1 },
{ value:"V092", label: "Homens residentes - 19 anos de idade", type: 1 },
{ value:"V093", label: "Homens residentes - 20 anos de idade", type: 1 },
{ value:"V094", label: "Homens residentes - 21 anos de idade", type: 1 },
{ value:"V095", label: "Homens residentes - 22 anos de idade", type: 1 },
{ value:"V096", label: "Homens residentes - 23 anos de idade", type: 1 },
{ value:"V097", label: "Homens residentes - 24 anos de idade", type: 1 },
{ value:"V098", label: "Homens residentes - 25 a 29 anos de idade", type: 1 },
{ value:"V099", label: "Homens residentes - 30 a 34 anos de idade", type: 1 },
{ value:"V100", label: "Homens residentes - 35 a 39 anos de idade", type: 1 },
{ value:"V101", label: "Homens residentes - 40 a 44 anos de idade", type: 1 },
{ value:"V102", label: "Homens residentes - 45 a 49 anos de idade", type: 1 },
{ value:"V103", label: "Homens residentes - 50 a 54 anos de idade", type: 1 },
{ value:"V104", label: "Homens residentes - 55 a 59 anos de idade", type: 1 },
{ value:"V105", label: "Homens residentes - 60 a 64 anos de idade", type: 1 },
{ value:"V106", label: "Homens residentes - 65 a 69 anos de idade", type: 1 },
{ value:"V107", label: "Homens residentes - 70 a 74 anos de idade", type: 1 },
{ value:"V108", label: "Homens residentes - 75 a 79 anos de idade", type: 1 },
{ value:"V109", label: "Homens residentes - 80 a 84 anos de idade", type: 1 },
{ value:"V110", label: "Homens residentes - 85 a 89 anos de idade", type: 1 },
{ value:"V111", label: "Homens residentes - 90 a 94 anos de idade", type: 1 },
{ value:"V112", label: "Homens residentes - 95 a 99 anos de idade", type: 1 },
{ value:"V113", label: "Homens residentes - 100 anos ou mais de idade", type: 1 },
{ value:"V114", label: "Mulheres residentes - 0 anos de idade", type: 1 },
{ value:"V115", label: "Mulheres residentes - 1 anos de idade", type: 1 },
{ value:"V116", label: "Mulheres residentes - 2 anos de idade", type: 1 },
{ value:"V117", label: "Mulheres residentes - 3 anos de idade", type: 1 },
{ value:"V118", label: "Mulheres residentes - 4 anos de idade", type: 1 },
{ value:"V119", label: "Mulheres residentes - 5 anos de idade", type: 1},
{ value:"V120", label: "Mulheres residentes - 6 anos de idade", type: 1 },
{ value:"V121", label: "Mulheres residentes - 7 anos de idade", type: 1 },
{ value:"V122", label: "Mulheres residentes - 8 anos de idade", type: 1 },
{ value:"V123", label: "Mulheres residentes - 9 anos de idade", type: 1 },
{ value:"V124", label: "Mulheres residentes - 10 anos de idade", type: 1 },
{ value:"V125", label: "Mulheres residentes - 11 anos de idade", type: 1 },
{ value:"V126", label: "Mulheres residentes - 12 anos de idade", type: 1 },
{ value:"V127", label: "Mulheres residentes - 13 anos de idade", type: 1 },
{ value:"V128", label: "Mulheres residentes - 14 anos de idade", type: 1 },
{ value:"V129", label: "Mulheres residentes - 15 anos de idade", type: 1 },
{ value:"V130", label: "Mulheres residentes - 16 anos de idade", type: 1 },
{ value:"V131", label: "Mulheres residentes - 17 anos de idade", type: 1 },
{ value:"V132", label: "Mulheres residentes - 18 anos de idade", type: 1 },
{ value:"V133", label: "Mulheres residentes - 19 anos de idade", type: 1 },
{ value:"V134", label: "Mulheres residentes - 20 anos de idade", type: 1 },
{ value:"V135", label: "Mulheres residentes - 21 anos de idade", type: 1 },
{ value:"V136", label: "Mulheres residentes - 22 anos de idade", type: 1 },
{ value:"V137", label: "Mulheres residentes - 23 anos de idade", type: 1 },
{ value:"V138", label: "Mulheres residentes - 24 anos de idade", type: 1 },
{ value:"V139", label: "Mulheres residentes - 25 a 29 anos de idade", type: 1 },
{ value:"V140", label: "Mulheres residentes - 30 a 34 anos de idade", type: 1 },
{ value:"V141", label: "Mulheres residentes - 35 a 39 anos de idade", type: 1 },
{ value:"V142", label: "Mulheres residentes - 40 a 44 anos de idade", type: 1 },
{ value:"V143", label: "Mulheres residentes - 45 a 49 anos de idade", type: 1 },
{ value:"V144", label: "Mulheres residentes - 50 a 54 anos de idade", type: 1 },
{ value:"V145", label: "Mulheres residentes - 55 a 59 anos de idade", type: 1 },
{ value:"V146", label: "Mulheres residentes - 60 a 64 anos de idade", type: 1 },
{ value:"V147", label: "Mulheres residentes - 65 a 69 anos de idade", type: 1 },
{ value:"V148", label: "Mulheres residentes - 70 a 74 anos de idade", type: 1 },
{ value:"V149", label: "Mulheres residentes - 75 a 79 anos de idade", type: 1 },
{ value:"V150", label: "Mulheres residentes - 80 a 84 anos de idade", type: 1 },
{ value:"V151", label: "Mulheres residentes - 85 a 89 anos de idade", type: 1 },
{ value:"V152", label: "Mulheres residentes - 90 a 94 anos de idade", type: 1 },
{ value:"V153", label: "Mulheres residentes - 95 a 99 anos de idade", type: 1 },
{ value:"V154", label: "Mulheres residentes - 100 anos ou mais de idade", type: 1 }
]


const tiposPontos = [
  { value:1, label: "Clientes" },
  { value:1, label: "Concorrentes" },
  { value:1, label: "Estudos" },
  { value:1, label: "Fornecedores" },
  { value:1, label: "Unidades Franquedas" },
  { value:1, label: "Minhas Unidades" },
  { value:1, label: "Parceiros" },
  { value:1, label: "Geral" }
]


const gerarEstudoEstado = (dados) => {
    if (!loadingEstudo) {
      setSuccessoEstudo(false);
      setLoadingEstudo(true);
      const tokenUser = localStorage.getItem('token')
      api.post('/gerarestudo/estado', 
      {
        estado:codEstadoBox, 
        coordenadas: latLngEstudo,
        tokenUser:tokenUser
      })
        .then(function (response) {
          // handle success
          // console.log("Resposta: ", response)
          
          const data = response.data
          const token = response.data.token
          console.log(data)
          // history.push('/estudo/?PIEBkUYblOLjgtdYbnmhTDG='+token);          
          setTokenShare(token)
          setSuccessoEstudo(true);
          setLoadingEstudo(false);
          handleOpenShare()
          setTimeout(() => {
            setSuccessoEstudo(false);
        }, 2000);

        })
        .catch(function (error) {
          // handle error
            setSuccessoEstudo(false);
            setLoadingEstudo(false);
            toast.error("Seu usuário está expirado! Saia e faça login novamente.");
          console.log(error);
        })
        .then(function () {
          // always executed
        
        });

    
      
    }
  }

  const handleChangeCheck = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };


  const gerarEstudoCidade = (dados) => {
    if (!loadingEstudoCidade) {
      setSuccessoEstudoCidade(false);
      setLoadingEstudoCidade(true);
      const tokenUser = localStorage.getItem('token')
      api.post('/gerarestudo/cidade', 
      {
        cidade:codCidadeBox, 
        estado:state.checkedCidade,
        codestado: codCidadeBox.substr(0, 2),
        coordenadas: latLngEstudo,
        tokenUser:tokenUser
      })
        .then(function (response) {
          // handle success
          // console.log("Resposta: ", response)
          
          const data = response.data
          const token = response.data.token
          // console.log(data)
          // history.push('/estudo/?PIEBkUYblOLjgtdYbnmhTDG='+token);
          setTokenShare(token)
          setSuccessoEstudoCidade(true);
          setLoadingEstudoCidade(false);
          handleOpenShare()
          setTimeout(() => {
            setSuccessoEstudoCidade(false);
        }, 2000);

        })
        .catch(function (error) {
          // handle error
            setSuccessoEstudoCidade(false);
            setLoadingEstudoCidade(false);
            toast.error("Seu usuário está expirado! Saia e faça login novamente.");
          console.log(error);
        })
        .then(function () {
          // always executed
        
        });
    }
  }


  const gerarEstudoBairro = (dados) => {
    if (!loadingEstudoBairro) {
      setSuccessoEstudoBairro(false);
      setLoadingEstudoBairro(true);
      const tokenUser = localStorage.getItem('token')
      api.post('/gerarestudo/bairro', 
      {
        estado:state.checkedBairroEstado,
        codestado: codBairroBox.substr(0, 2),
        cidade: state.checkedBairroCidade,
        codcidade:codBairroBox.substr(0, 7), 
        codbairro: codBairroBox,
        distrito: bairroDistrito,
        coordenadas: latLngEstudo,
        tokenUser:tokenUser
      })
        .then(function (response) {
          // handle success
          // console.log("Resposta: ", response)
          
          const data = response.data
          const token = response.data.token
          // console.log(data)
          // history.push('/estudo/?PIEBkUYblOLjgtdYbnmhTDG='+token);
          setTokenShare(token)
          setSuccessoEstudoBairro(true);
          setLoadingEstudoBairro(false);
          handleOpenShare()
          setTimeout(() => {
            setSuccessoEstudoBairro(false);
        }, 2000);

        })
        .catch(function (error) {
          // handle error
            setSuccessoEstudoBairro(false);
            setLoadingEstudoBairro(false);
            toast.error("Seu usuário está expirado! Saia e faça login novamente.");
          console.log(error);
        })
        .then(function () {
          // always executed
        
        });
    }
  }



  const gerarEstudoShopping = (dados) => {
    console.log(codShoppingBox)
    if (!loadingEstudoShopping) {
      setSuccessoEstudoShopping(false);
      setLoadingEstudoShopping(true);
      const tokenUser = localStorage.getItem('token')
      api.post('/gerarestudo/shopping', 
      {
        estado:state.checkedShoppingEstado,
        codestado: (codShoppingBox.cod_ibge).substr(0, 2),
        cidade: state.checkedShoppingCidade,
        codcidade: (codShoppingBox.cod_ibge).substr(0, 7), 
        bairro: state.checkedShoppingBairro,
        codbairro: codShoppingBox.cod_ibge_bairro,
        // distrito: bairroDistrito,
        codshopping: codShoppingBox.id,
        coordenadas: latLngEstudo,
        tokenUser:tokenUser
      })
        .then(function (response) {
          // handle success
          // console.log("Resposta: ", response)
          
          const data = response.data
          // console.log(data)
          const token = response.data.token
          // console.log(data)
          // history.push('/estudo/?PIEBkUYblOLjgtdYbnmhTDG='+token);
          setTokenShare(token)
          setSuccessoEstudoShopping(true);
          setLoadingEstudoShopping(false);
          handleOpenShare()
          setTimeout(() => {
            setSuccessoEstudoShopping(false);
        }, 2000);

        })
        .catch(function (error) {
          // handle error
            setSuccessoEstudoShopping(false);
            setLoadingEstudoShopping(false);
            toast.error("Seu usuário está expirado! Saia e faça login novamente.");
          console.log(error);
        })
        .then(function () {
          // always executed
        
        });
    }
  }

  
  
    // if(liberaPinMapa === true && mapCriado) {
    //     mapCriado.on('click', function(e) { 
    //       if (liberaPinMapa === true) {     
    //         console.log(liberaPinMapa)  
    //       console.log('PERMITIDO!')
    //     var popLocation= e.latlng;
    //     var popup = L.popup()
    //     .setLatLng(popLocation)
    //     .setContent('<p>Hello world!<br />This is a nice popup.</p>')
    //     .openOn(map);   
    //       }
    //   })
    // }


return (
    <div>
  <div id="map_geotaker" style={{width:'100%', height:'100%',  position:'absolute'}}></div>
  {loadingMalhaCidade ? <CircularProgress size={60} className={classes.carregandoMalha} /> : null }


   {/* <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false} style={{ height: "100vh" }}>
   
  <TileLayer
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
  <Marker position={[51.505, -0.09]} icon={icon}>
    <Popup>
      A pretty CSS3 popup. <br /> Easily customizable.
    </Popup>
  </Marker>
</MapContainer> */}

<Grow
       
      in={openTabs}
      style={{ transformOrigin: '0 0 0' }}
      {...(openTabs ? { timeout: 300 } : {})}
    >
        
<Box  className={classes.root} sx={{ bgcolor: 'background.paper', width: 350 }} style={{position: 'absolute', top: '50px', maxWidth: '95%', borderRadius: '20px 20px 20px 20px'}}>
      <AppBar position="static" style={{borderRadius: '20px 20px 0 0'}}>
        <Tabs
          value={valueTab}
          onChange={handleChangeTab}
        //   indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="Selecione"
          classes={{ indicator: classes.indicator }}
          style={{borderRadius: '20px 20px 0 0'}}
        >
          <Tab icon={<ExploreIcon />} label="Explorar" {...a11yProps(0)} />
          <Tab icon={<AddCircleRoundedIcon />} label="Estudo" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
     
        <TabPanel value={valueTab} index={0} className={classes.panelEstudo} >
        <div className={classes.panelInside} style={{padding: '0px'}}>

          <h5 style={{marginBlockStart: '0px', marginBlockEnd: '5px'}}>Filtro de busca</h5>
          
            <Accordion expanded={expanded === 'panel01'} onChange={handleChangeTabs('panel01')}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                marginBlockStart: '0px', 
                                marginBlockEnd: '0px'
                            }}>
                                <GroupIcon color="secondary"/>                           
                                <h5 style={{marginLeft: '5px', marginBlockStart: '0px', marginBlockEnd: '0px'}}>Habitantes</h5>
                            </div>
            </AccordionSummary>
            <AccordionDetails>
            <div className={classes.sliders}>
            <small>Selecione a faixa de habitantes que deseja.</small>
                    <Slider                      
                        value={value}
                        min={0}
                        step={1}
                        max={200}
                        valueLabelFormat={numFormatter}
                        marks={followersMarks}
                        scale={scaleValues}
                        onChange={handleChange}
                        valueLabelDisplay="off"
                        aria-labelledby="Habitantes"
                    />
                    <h5 style={{marginBlockStart: '0px', marginBlockEnd: '0px'}}>Mín: {Number(JSON.stringify(scaleValues(value)[0])).toLocaleString('pt-BR')} e Máx: {Number(JSON.stringify(scaleValues(value)[1])).toLocaleString('pt-BR')}</h5>
            </div>
            </AccordionDetails>
            </Accordion>
            
            <Accordion expanded={expanded === 'panel02'} onChange={handleChangeTabs('panel02')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    marginBlockStart: '0px', 
                                    marginBlockEnd: '0px'
                                }}>
                                    <WcIcon color="secondary" />                           
                                    <h5 style={{marginLeft: '5px', marginBlockStart: '0px', marginBlockEnd: '0px'}}>Habitantes por sexo</h5>
                                </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        
                    </div>
                    <br />
                    <br />
                    <div className={classes.formControl}>
                        
                        <FormControl>
                        <small style={{marginBottom: '10px'}}>Selecione o sexo predominante desejado</small>
                        <NativeSelect
                            value={stateSexo}
                            onChange={handleChangeSexo}
                            name="age"
                            // className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'age' }}
                        >                      
                            <option value={0}>Indiferente</option>
                            <option value={1}>Masculino</option>
                            <option value={2}>Feminino</option>
                            
                        </NativeSelect>				
                        </FormControl>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel03'} onChange={handleChangeTabs('panel03')}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    marginBlockStart: '0px', 
                                    marginBlockEnd: '0px'
                                }}>
                                    <MonetizationOnIcon color="secondary"/>                           
                                    <h5 style={{marginLeft: '5px', marginBlockStart: '0px', marginBlockEnd: '0px'}}>Salários mínimos</h5>
                                </div>
                </AccordionSummary>
                <AccordionDetails>
                <div className={classes.sliders}>
                <small>Selecione a faixa de salários mínimos</small><br/>
                        
                        <Slider                      
                            value={valueSliderSalario}
                            min={0}
                            step={1}
                            max={10}
                            // valueLabelFormat={numFormatter}
                            marks={followersMarksSalario}                    
                            onChange={handleChangeSliderSalario}
                            valueLabelDisplay="auto"
                            aria-labelledby="Salário"
                        />
                        <h5 style={{marginBlockStart: '0px', marginBlockEnd: '0px'}}>De {valueSliderSalario[0]} a {valueSliderSalario[1]} salários mínimos</h5>
                </div>
                </AccordionDetails>
            </Accordion>

            <div className={classes.buttonWrapper}>
                <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={verificarCidades}
                // className={successoEstudoCEP ? classes.buttonSuccess : classes.button}
                startIcon={loadingBusca ? <CircularProgress size={24} className={classes.buttonProgress} /> : <SearchIcon />}                        
                disabled={loadingBusca}             
                
                >
                {loadingBusca ?  "Buscando..." : "Buscar Cidades"}
                </Button>
            
            </div>

        </div>
        </TabPanel>
        
        
        
        <TabPanel value={valueTab} index={1} className={classes.panelEstudo}>
        <div className={classes.panelInside} style={{padding: '0px'}}>
            
            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeTabs('panel1')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    >
                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        marginBlockStart: '0px', 
                                        marginBlockEnd: '0px'
                                    }}>
                                        <MapIcon color="secondary"/>                           
                                        <h5 style={{marginLeft: '5px', marginBlockStart: '0px', marginBlockEnd: '0px'}}>Estado</h5>
                                    </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                        <div style={{flexDirection: 'column', width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <ComboBoxEstados style={{zIndex: 100}} setCodEstadoBox={setCodEstadoBox} setLatLngEstudo={setLatLngEstudo} voarPara={voarPara} voarParaLocal={voarParaLocal} />
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                                <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={gerarEstudoEstado}
                                className={successoEstudo ? classes.buttonSuccess : classes.button}
                                startIcon={loadingEstudo ? <CircularProgress size={22} className={classes.buttonProgress} /> : <AssessmentTwoToneIcon />}
                                // style={{marginLeft: '40px'}}
                                disabled={loadingEstudo || codEstadoBox == undefined}             
                                
                                >                              
                                {successoEstudo ? "Gerado!" : "Gerar Estudo"}
                                </Button>
                                
                            </div>
                        </div>

                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeTabs('panel2')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    >
                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        marginBlockStart: '0px', 
                                        marginBlockEnd: '0px'
                                    }}>
                                        <LocationCityIcon color="secondary"/>                           
                                        <h5 style={{marginLeft: '5px', marginBlockStart: '0px', marginBlockEnd: '0px'}}>Cidade</h5>
                                    </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{flexDirection: 'column', width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <ComboBoxCidades style={{zIndex: 100}} setCodCidadeBox={setCodCidadeBox} setLatLngEstudo={setLatLngEstudo} voarPara={voarPara} voarParaLocal={voarParaLocal} />    
                            <div>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={state.checkedCidade}
                                    onChange={handleChangeCheck('checkedCidade')}
                                    value="checkedCidade"
                                    color="primary"
                                    size="small"
                                    />
                                }
                                label={<Typography className={classes.formControlLabel}>Estado</Typography>}
                                />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                                <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={gerarEstudoCidade}
                                className={successoEstudoCidade ? classes.buttonSuccess : classes.button}
                                startIcon={loadingEstudoCidade ? <CircularProgress size={22} className={classes.buttonProgress} /> : <AssessmentTwoToneIcon />}
                                // style={{marginLeft: '40px'}}
                                disabled={loadingEstudoCidade || codCidadeBox == undefined}
                                
                                >
                                  {successoEstudoCidade ? "Gerado!" : "Gerar Estudo"}                                
                                </Button>
                            </div>
                            
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeTabs('panel3')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    >
                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        marginBlockStart: '0px', 
                                        marginBlockEnd: '0px'
                                    }}>
                                        <StreetviewIcon color="secondary"/>                           
                                        <h5 style={{marginLeft: '5px', marginBlockStart: '0px', marginBlockEnd: '0px'}}>Bairro</h5>
                                    </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{flexDirection: 'column', width: '100%', display: 'flex', justifyContent: 'center'}}> 
                            <ComboBoxBairros style={{zIndex: 100}} setCodBairroBox={setCodBairroBox} setBairroDistrito={setBairroDistrito} setLatLngEstudo={setLatLngEstudo} voarPara={voarPara} />    
                            <div>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={state.checkedBairroEstado}
                                    onChange={handleChangeCheck('checkedBairroEstado')}
                                    value="checkedBairroEstado"
                                    color="primary"
                                    size="small"
                                    />
                                }
                                label={<Typography className={classes.formControlLabel}>Estado</Typography>}
                                />
                                
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={state.checkedBairroCidade}
                                    onChange={handleChangeCheck('checkedBairroCidade')}
                                    value="checkedBairroCidade"
                                    color="primary"
                                    size="small"
                                    />
                                }
                                label={<Typography className={classes.formControlLabel}>Cidade</Typography>}
                                />
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                                <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={gerarEstudoBairro}
                                className={successoEstudoBairro ? classes.buttonSuccess : classes.button}
                                startIcon={loadingEstudoBairro ? <CircularProgress size={22} className={classes.buttonProgress} /> : <AssessmentTwoToneIcon />}
                                // style={{marginLeft: '40px'}}
                                disabled={loadingEstudoBairro || codBairroBox == undefined}
                                
                                >
                                {successoEstudoBairro ? "Gerado!" : "Gerar Estudo"}
                                </Button>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChangeTabs('panel4')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    >
                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        marginBlockStart: '0px', 
                                        marginBlockEnd: '0px'
                                    }}>
                                        <LocalMallIcon color="secondary"/>                           
                                        <h5 style={{marginLeft: '5px', marginBlockStart: '0px', marginBlockEnd: '0px'}}>Shopping Center</h5>
                                    </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{flexDirection: 'column', width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <ComboBoxShoppings style={{zIndex: 100}} setCodShoppingBox={setCodShoppingBox} setButtonCheckShoppingBairro={setButtonCheckShoppingBairro} setStateBairroShop={setStateBairroShop} stateBairroShop={stateBairroShop} stateBairroShopAtual={stateBairroShopAtual} setLatLngEstudo={setLatLngEstudo} voarPara={voarPara} />
                            <div>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={state.checkedShoppingEstado}
                                    onChange={handleChangeCheck('checkedShoppingEstado')}
                                    value="checkedShoppingEstado"
                                    color="primary"
                                    size="small"
                                    />
                                }
                                label={<Typography className={classes.formControlLabel}>Estado</Typography>}
                                />
                                
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={state.checkedShoppingCidade}
                                    onChange={handleChangeCheck('checkedShoppingCidade')}
                                    value="checkedShoppingCidade"
                                    color="primary"
                                    size="small"
                                    />
                                }
                                label={<Typography className={classes.formControlLabel}>Cidade</Typography>}
                                />

                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={state.checkedShoppingBairro}
                                    onChange={handleChangeCheck('checkedShoppingBairro')}
                                    value="checkedShoppingBairro"
                                    color="primary"
                                    disabled={buttonCheckShoppingBairro}
                                    size="small"
                                    />
                                }
                                label={<Typography className={classes.formControlLabel}>Bairro</Typography>}
                                />
                            </div>
                            <div className={classes.wrapper}>
                                <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={gerarEstudoShopping}
                                className={successoEstudoShopping ? classes.buttonSuccess : classes.button}
                                startIcon={loadingEstudoShopping ? <CircularProgress size={22} className={classes.buttonProgress} /> : <AssessmentTwoToneIcon />}
                                // style={{marginLeft: '40px'}}
                                disabled={loadingEstudoShopping || codShoppingBox == undefined}
                                
                                >
                                {successoEstudoShopping ? "Gerado!" : "Gerar Estudo"}
                                </Button>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>
        </TabPanel>
      
     
    </Box>

</Grow>

		<Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        disableEscapeKeyDown={true}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
        
            <Paper className={classes.paper} elevation={10} style={{width: '600px'}}>
            
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"                   
                    >   

                    <div style={{ width: '100%', margin: 0 }}>
                        <Box display="flex" p={1}>
                            <Box flexGrow={1} >
                            <div  style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        margin: '0px'
                                    }}
                                    className={classes.noMargin}
                                    > 
                        
                                        <SearchIcon/>
                                        <h2 style={{marginLeft: '5px', marginBlockStart: 0, marginBlockEnd: 0}} className={classes.noMargin}>Cidades</h2>
                                    
                            </div>
                            </Box>
                            <Box>
                                <ReplyIcon onClick={handleCloseModal} style={{cursor: 'pointer'}}/>
                            </Box>
                            
                        </Box>
                        <Divider />
                    </div>

                
                </Grid>
                <Grid>
                    <div style={{
                      paddingTop: '20px', 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      }}>
                        <p>Encontramos <span style={{fontWeight: '800'}}>{qtdadeCidadesReceb}</span> cidades com faixa de habitantes entre mín: <span style={{fontWeight: '800'}}>{Number(JSON.stringify(scaleValues(value)[0])).toLocaleString('pt-BR')}</span> e máx: <span style={{fontWeight: '800'}}>{Number(JSON.stringify(scaleValues(value)[1])).toLocaleString('pt-BR')}</span>. Selecione para encontrar informações detalhadas.</p>
                    </div>

                  <div className={classes.wrapper2}>
                    <Autocomplete                    
                      id="combo-box-cidades"
                      options={cidadesReceb}
                      size="small"
                      getOptionLabel={(option) => option.nome_municipio + " - " + option.abrev_uf + " (" + (Number(option.habitantes)).toLocaleString('pt-BR') +" hab)" }
					  // renderOption={(option) => (
						// <React.Fragment>
						// 	<div>
						// 	<h5 style={{marginBlockStart: '0px', marginBlockEnd: '0px', color: '#1266F1'}}>{option.nome_municipio + " - " + option.abrev_uf}</h5>
						// 	<small style={{marginTop: '0px', marginBottom: '0px', fontSize: '10px'}}><span style={{fontWeight: 'bold'}}>Hab:</span> {(Number(option.habitantes)).toLocaleString('pt-BR')}, <span style={{fontWeight: 'bold'}}>Homem:</span> {option.urb_homem}%, <span style={{fontWeight: 'bold'}}>Mulher:</span> {option.urb_mulher}%, <span style={{fontWeight: 'bold'}}>Salários Mínimos:</span> {option.salario} </small>
						// 	</div>
						  
						// </React.Fragment>
					  // )} 
					  style={{ width: "90%" }}
                      renderInput={(params) => <TextField {...params} label="Cidade" variant="outlined" />}
                      onChange={(e, value) => {
                        if(value !== null) {							
							              hideCarto(codCidade)
                            map.flyTo([value.latitude, value.longitude], 10)
                            setCodCidade(value.codigo_municipio_completo)
                            setPopCidade((Number(value.habitantes)).toLocaleString('pt-BR'))
                        } else {
                            setCodCidade()
							              map.flyTo([-15.2703821, -55.3690471], 5)
                        }
                        
                      }}
                    />
                  </div>

                  <div className={classes.wrapper}>
                    <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={verDetalhesCidade}
                    // className={successoEstudoCEP ? classes.buttonSuccess : classes.button}
                    startIcon={loadingVerCidade ? <CircularProgress size={24} className={classes.buttonProgress} /> : <SearchIcon />}                        
                    disabled={loadingVerCidade || codCidade == undefined}             
                    
                    >
                    {loadingVerCidade ?  "Abrindo..." : "Carregar cidade"}
                    </Button>
                   
                </div>



                </Grid>
                
              
                
            </Paper>
         
        </Fade>
      </Modal>
      
      <Grow
       
      in={openDetalhes}
      style={{ transformOrigin: '0 0 0' }}
      {...(openDetalhes ? { timeout: 300 } : {})}
    >
        <div className={classes.root} style={{position: 'absolute', top: '50px'}}> 
		<AppBar position="relative" style={{borderRadius: '20px 20px 0 0'}}>
		  <Toolbar className={classes.toolbarCidade}>
		  <div style={{ width: '100%', padding: 0 }}>
			<Box sx={{ display: 'flex'}}>
				<Box sx={{p: 1, width: '100%'}}>
					<Typography variant="h6" component="div">
						Informações
					</Typography>
				</Box>
				<Box sx={{ flexShrink: 1 }}>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						color="inherit"
						onClick={() => handleChangeMinMaxInfo()}
					>
						{minMaxTabInfo ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
						
					</IconButton>
				</Box>
				<Box sx={{ pl: 1, flexShrink: 0 }}>
				<IconButton 
					className={classes.customizedButtonDetalhes}
					color="inherit"
					onClick={() => handleCloseDetalhes()}
					>
					<ReplyIcon />
				</IconButton>
				</Box>
				</Box>
			</div>
		  </Toolbar>
          </AppBar> 
		  <Collapse in={minMaxTabInfo}>
          <Paper className={classes.paper} elevation={10} style={{width: '300px', borderRadius: '0 0 20px 20px'}}>
            <Box component="div"  overflow="auto">
            
            <div className={classes.wrapper}>
              <Chip size="small" color="secondary" label={nomeMunicDetalhes + ' - ' + nomeUfDetalhes} style={{marginTop: '20px', marginBottom:'20px', padding:'10px', fontWeight: 700, boxShadow: '0px 2px 10px #888888'}} />
            </div>

              <p style={{marginBlockStart:'0px', marginBlockEnd:'0px', fontSize: '11px'}}><span style={{fontWeight: 700}}>Estado:</span> {nomeEstDetalhes}</p>
              <p style={{marginBlockStart:'0px', marginBlockEnd:'0px', fontSize: '11px'}}><span style={{fontWeight: 700}}>Mesorregião:</span> {nomeMesorregDetalhes}</p>
              <p style={{marginBlockStart:'0px', marginBlockEnd:'0px', fontSize: '11px'}}><span style={{fontWeight: 700}}>Microrregião:</span> {nomeMicrorregDetalhes}</p>
              <p style={{marginBlockStart:'0px', marginBlockEnd:'0px', fontSize: '11px'}}><span style={{fontWeight: 700}}>População:</span> {popCidade} habitantes</p>
              <p style={{marginBlockStart:'0px', marginBlockEnd:'0px', fontSize: '11px'}}><span style={{fontWeight: 700}}>Dens. Demográfica:</span> {loadingDensDemog ? <CircularProgress size={13} className={classes.buttonProgress} /> : dadosDensDemog + " hab/km2"}</p>
              <p style={{marginBlockStart:'0px', marginBlockEnd:'0px', fontSize: '11px'}}><span style={{fontWeight: 700}}>Empresas:</span> {loadingEmpresasCidade ? <CircularProgress size={13} className={classes.buttonProgress} /> : dadosEmpresasCidade + " empresas"}</p>
              <p style={{marginBlockStart:'0px', marginBlockEnd:'0px', fontSize: '11px'}}><span style={{fontWeight: 700}}>Salário Médio Mensal:</span> {loadingSalarioMedio ? <CircularProgress size={13} className={classes.buttonProgress} /> : dadosSalarioMedio}</p>
              <p style={{marginBlockStart:'0px', marginBlockEnd:'0px', fontSize: '11px'}}><span style={{fontWeight: 700}}>PIB:</span> {loadingPib ? <CircularProgress size={13} className={classes.buttonProgress} /> : dadosPib}</p>


            </Box>
			<div className={classes.wrapper4}>
				<small>Selecione o indice:</small>
			</div>
			<div className={classes.wrapper3}>				
				<Autocomplete                    
					id="combo-box-indices"
					size="small"
					options={indices}
					getOptionLabel={(option) => option.label }
					style={{ width: "90%" }}
					renderInput={(params) => <TextField {...params} label="Indices" variant="outlined" />}
					onChange={(e, value) => {
					if(value !== null) {			
						opcaoIndice(value.value, value.type)
					}
					
					}}
				/>
			</div>
			
			<small>Legenda:</small>

			
		
			<div className={classes.legenda} style={{display: 'flex', justifyContent: "space-between"}}>
				<small style={{justifyContent: "space-between", padding: '1px', marginLeft: '10px'}}>menor</small>
				<small style={{justifyContent: "space-between", color: 'white', padding: '1px', marginRight: '10px'}}>maior</small>
			</div>
			
			
			{/* <p onClick={()=>limparLayer()}>LIMPAR LAYERS</p>
			<p onClick={()=>console.log(groupLayers)}>VER ARRAY LAYERS</p> */}

{/* <p onClick={()=>setRazaoSexo(true)}>Ligar Razão de Sexo</p>
<p onClick={()=>resetAreas(codCidade)}>RESETAR AREAS</p>
<p onClick={()=>opcaoIndice()}>LIGAR INDICE</p> */}


          </Paper>
		  </Collapse>
          
        </div>

      </Grow>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalPesqNeg}
        disableEscapeKeyDown={true}
        onClose={handleCloseModalPesqNeg}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalPesqNeg}>
          <Paper className={classes.paper} elevation={10} style={{width: '600px'}}>
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"                   
                    >   

                    <div style={{ width: '100%', margin: 0 }}>
                        <Box display="flex" p={1}>
                            <Box flexGrow={1} >
                            <div  style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        margin: '0px'
                                    }}
                                    className={classes.noMargin}
                                    > 
                        
                                        <SearchIcon/>
                                        <h2 style={{marginLeft: '5px', marginBlockStart: 0, marginBlockEnd: 0}} className={classes.noMargin}>Pesquisar Negócios</h2>
                                    
                            </div>
                            </Box>
                            <Box>
                                <CloseIcon onClick={handleCloseModalPesqNeg} style={{cursor: 'pointer'}}/>
                            </Box>
                            
                        </Box>
                        <Divider />
                    </div>

                
                </Grid>
                <Grid>
                    <div style={{
                      paddingTop: '20px', 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      }}>
                        <p>Digite o(s) negócio(s) que deseja encontrar</p>
                    </div>

                  <div className={classes.wrapper2}>
                  <TextField 
                    id="outlined-basic" 
                    label="Negócios" 
                    variant="outlined" 
                    onChange={(e) => setValuePesqNeg(e.target.value)} 
                    size="small" 
                    style={{width: '70%'}} 
                    helperText="Ex: Restaurantes, Lavanderias, Soverterias, ..."
                    />
                  </div>

                  <div className={classes.buttonsEspacados}>
                    <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={abrirGoogleMapsPesqNeg}
                    // className={successoEstudoCEP ? classes.buttonSuccess : classes.button}
                    startIcon={loadingPesqNeg ? <CircularProgress size={24} className={classes.buttonProgress} /> : <SearchIcon />}                        
                    disabled={loadingPesqNeg || valuePesqNeg == undefined}             
                    
                    >
                    {loadingPesqNeg ?  "Abrindo..." : "Encontrar Negócios"}
                    </Button>
                   
                </div>



                </Grid>
          </Paper>
        </Fade>
      </Modal>




      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalMarcarPonto}
        disableEscapeKeyDown={true}
        onClose={handleCloseModalMarcarPonto}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalMarcarPonto}>
          <Paper className={classes.paper} elevation={10} style={{width: '600px'}}>
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"                   
                    >   

                    <div style={{ width: '100%', margin: 0 }}>
                        <Box display="flex" p={1}>
                            <Box flexGrow={1} >
                            <div  style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        margin: '0px'
                                    }}
                                    className={classes.noMargin}
                                    > 
                        
                                        <SearchIcon/>
                                        <h2 style={{marginLeft: '5px', marginBlockStart: 0, marginBlockEnd: 0}} className={classes.noMargin}>Marcar Ponto</h2>
                                    
                            </div>
                            </Box>
                            <Box>
                                <CloseIcon onClick={handleCloseModalMarcarPonto} style={{cursor: 'pointer'}}/>
                            </Box>
                            
                        </Box>
                        <Divider />
                    </div>

                
                </Grid>
                <Grid>
                    <div style={{
                      paddingTop: '20px', 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      }}>
                        <p>Criação de novo ponto</p>
                    </div>

                    <div className={classes.wrapper5}>
                    <TextField 
                      id="outlined-basic" 
                      label="Nome do Ponto" 
                      variant="outlined" 
                      onChange={(e) => setValueNomePonto(e.target.value)}  
                      size="small" 
                      style={{width: '70%'}} 
                      helperText="Ex: Unidade Piloto"
                      inputProps={{ maxLength: 100 }} 
                      />
                    </div>

                    <div className={classes.wrapper5}>				
                    <Autocomplete    
                        PopperComponent={PopperCategoriasPonto}
                        id="async-categorias"
                        style={{ zIndex: 1000000000000000000000000000000000000, width: '70%' }}
                        size="small"
                        open={openCategoriasPonto}
                        onOpen={() => {
                          setOpenCategoriasPonto(true);
                        }}
                        onClose={() => {
                          setOpenCategoriasPonto(false);
                        }}
                      // getOptionSelected={(option, value) => console.log( value.id)}
                        getOptionLabel={(option) => option.categoria}
                        options={optionsCategoriasPonto}
                        loading={loadingCategoriasPonto}
                        onChange={(e, value) => {
                            if(value !== null){
                              console.log(value);
                              setValueCategoriaPonto(value.id)
                              
                            } else {
                              
                            }
                        
                      
                        }}
                        renderInput={(params) => (
                          <TextField
                          style={{zIndex: 1000000000000000000000000000000000}}
                         
                            {...params}
                            label="Categoria"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment style={{backgroundColor: 'red'}}>
                                  {loadingCategoriasPonto ? <CircularProgress size={20} style={{color: 'green'}} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className={classes.wrapper5}>
                    <TextField
                      id="ponto-observacoes"
                      style={{width: '70%'}} 
                      size="small"
                      onChange={(e) => setValueObsPonto(e.target.value)} 
                      label="Observações"
                      variant="outlined"
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 1000 }}   
                           
                    />
                    </div>

                  <div className={classes.buttonsEspacados}>
                    <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={salvarNovoPonto}  
                    startIcon={<SaveIcon/>}                  
                    >
                    Salvar
                    </Button>

                    <Button
                    variant="contained"
                    size="small"
                    onClick={handleCloseModalMarcarPonto}
                    style={{marginLeft: 20}}
                    >
                    Cancelar
                    </Button>
                   
                </div>



                </Grid>
          </Paper>
        </Fade>
      </Modal>



      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalEditarPonto}
        disableEscapeKeyDown={true}
        onClose={handleCloseModalEditarPonto}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalEditarPonto}>
          <Paper className={classes.paper} elevation={10} style={{width: '600px'}}>
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"                   
                    >   

                    <div style={{ width: '100%', margin: 0 }}>
                        <Box display="flex" p={1}>
                            <Box flexGrow={1} >
                            <div  style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        margin: '0px'
                                    }}
                                    className={classes.noMargin}
                                    > 
                        
                                        <SearchIcon/>
                                        <h2 style={{marginLeft: '5px', marginBlockStart: 0, marginBlockEnd: 0}} className={classes.noMargin}>Detalhes do Ponto</h2>
                                    
                            </div>
                            </Box>
                            <Box>
                                <CloseIcon onClick={handleCloseModalEditarPonto} style={{cursor: 'pointer'}}/>
                            </Box>
                            
                        </Box>
                        <Divider />
                    </div>

                
                </Grid>
                <Grid>
                    <div style={{
                      paddingTop: '20px', 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      }}>
                        <p>Detalhes do ponto</p>
                    </div>

                    <div className={classes.wrapper5}>
                    <TextField 
                      id="outlined-basic" 
                      label="Nome do Ponto" 
                      variant="outlined" 
                      value={valueEditNomePonto ? valueEditNomePonto : null}
                      onChange={(e) => setValueEditNomePonto(e.target.value)}  
                      size="small" 
                      style={{width: '90%'}}                       
                      inputProps={{ maxLength: 100 }} 
                      disabled={loadingDadosPonto ? true : false} 
                      InputLabelProps={{
                        shrink: true,
                      }}  
                      />
                    </div>

                    <div className={classes.wrapper5}>				
                    <Autocomplete    
                        PopperComponent={PopperCategoriasPonto}
                        id="async-categorias"
                        style={{ zIndex: 1000000000000000000000000000000000000, width: '90%' }}
                        size="small"
                        disabled={loadingDadosPonto ? true : false} 
                        // open={openCategoriasPonto}
                        // onOpen={() => {
                        //   setOpenCategoriasPonto(true);
                        // }}
                        // onClose={() => {
                        //   setOpenCategoriasPonto(false);
                        // }}
                      // getOptionSelected={(option, value) => console.log( value.id)}
                        getOptionLabel={(option) => option.categoria}
                        options={optionsCategoriasPonto}
                        value={valueEditCategoriaPonto ? optionsCategoriasPonto.find(v => v.id === valueEditCategoriaPonto) : null}
                        loading={loadingCategoriasPonto}
                        onChange={(e, value) => {
                            if(value !== null){
                              console.log(value);
                              setValueEditCategoriaPonto(value.id)
                              
                            } else {
                              
                            }
                        
                      
                        }}
                        renderInput={(params) => (
                          <TextField
                          style={{zIndex: 1000000000000000000000000000000000}}
                         
                            {...params}
                            label="Categoria"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingCategoriasPonto ? <CircularProgress size={20} style={{color: 'green'}} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />


                  
                    </div>
                    <div className={classes.wrapper5}>
                    <TextField
                      id="ponto-observacoes"
                      style={{width: '90%'}} 
                      size="small"
                      value={valueEditObsPonto ? valueEditObsPonto : null}
                      onChange={(e) => setValueEditObsPonto(e.target.value)} 
                      label="Observações"
                      variant="outlined"
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 1000 }}   
                      disabled={loadingDadosPonto ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    </div>

                  <div className={classes.buttonsEspacados}>
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.buttonExcluir}
                    onClick={() => removerPonto(valueEditIdPonto)}  
                    disabled={loadingDadosPonto ? true : false}
                    startIcon={loadingDadosPonto ? <CircularProgress disableShrink style={{color: 'green'}} size={20} /> : <DeleteIcon/>}                  
                    >
                    Excluir
                    </Button>

                    <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => gerarEstudoSetor(valueCodSetorEditPonto, valueLatLngEditPonto, true)}  
                    disabled={loadingDadosPonto || loadingGerarEstudo ? true : false}
                    style={{marginLeft: 108}}
                    startIcon={loadingDadosPonto || loadingGerarEstudo ? <CircularProgress disableShrink style={{color: 'green'}} size={20} /> : <AssessmentIcon/>}                  
                    >
                    Gerar Estudo
                    </Button>

                    <Button
                    variant="contained"
                    className={classes.buttonSalvar}
                    size="small"
                    onClick={() => salvarEditarPonto(valueEditIdPonto)}  
                    disabled={loadingDadosPonto || salvandoEditarPonto ? true : false}
                    style={{marginLeft: 10}}
                    startIcon={loadingDadosPonto || salvandoEditarPonto ? <CircularProgress disableShrink style={{color: 'green'}} size={20} /> : <SaveIcon/>}                  
                    >
                    Salvar
                    </Button>

                    <Button
                    variant="contained"
                    size="small"
                    onClick={handleCloseModalEditarPonto}
                    style={{marginLeft: 10}}
                    >
                    Fechar
                    </Button>
                   
                </div>



                </Grid>
          </Paper>
        </Fade>
      </Modal>




      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalEditarArea}
        disableEscapeKeyDown={true}
        onClose={handleCloseModalEditarArea}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalEditarArea}>
          <Paper className={classes.paper} elevation={10} style={{width: '600px'}}>
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"                   
                    >   

                    <div style={{ width: '100%', margin: 0 }}>
                        <Box display="flex" p={1}>
                            <Box flexGrow={1} >
                            <div  style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        margin: '0px'
                                    }}
                                    className={classes.noMargin}
                                    > 
                        
                                        <SearchIcon/>
                                        <h2 style={{marginLeft: '5px', marginBlockStart: 0, marginBlockEnd: 0}} className={classes.noMargin}>Detalhes da Área</h2>
                                    
                            </div>
                            </Box>
                            <Box>
                                <CloseIcon onClick={handleCloseModalEditarArea} style={{cursor: 'pointer'}}/>
                            </Box>
                            
                        </Box>
                        <Divider />
                    </div>

                
                </Grid>
                <Grid>
                    <div style={{
                      paddingTop: '20px', 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      }}>
                        <p>Detalhes da Área</p>
                    </div>

                    <div className={classes.wrapper5}>
                    <TextField 
                      id="outlined-basic" 
                      label="Nome da Área" 
                      variant="outlined" 
                      value={valueEditNomeArea ? valueEditNomeArea : null}
                      onChange={(e) => setValueEditNomeArea(e.target.value)}  
                      size="small" 
                      style={{width: '90%'}}                       
                      inputProps={{ maxLength: 100 }} 
                      disabled={loadingDadosArea ? true : false} 
                      InputLabelProps={{
                        shrink: true,
                      }}  
                      />
                    </div>

                    <div className={classes.wrapper5}>				
                    <Autocomplete    
                        PopperComponent={PopperCategoriasPonto}
                        id="async-categorias"
                        style={{ zIndex: 1000000000000000000000000000000000000, width: '90%' }}
                        size="small"
                        disabled={loadingDadosArea ? true : false} 
                        // open={openCategoriasPonto}
                        // onOpen={() => {
                        //   setOpenCategoriasPonto(true);
                        // }}
                        // onClose={() => {
                        //   setOpenCategoriasPonto(false);
                        // }}
                      // getOptionSelected={(option, value) => console.log( value.id)}
                        getOptionLabel={(option) => option.categoria}
                        options={optionsCategoriasPonto}
                        value={valueEditCategoriaArea ? optionsCategoriasPonto.find(v => v.id === valueEditCategoriaArea) : null}
                        loading={loadingCategoriasPonto}
                        onChange={(e, value) => {
                            if(value !== null){
                              console.log(value);
                              setValueEditCategoriaArea(value.id)
                              
                            } else {
                              
                            }
                        
                      
                        }}
                        renderInput={(params) => (
                          <TextField
                          style={{zIndex: 1000000000000000000000000000000000}}
                         
                            {...params}
                            label="Categoria"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingCategoriasPonto ? <CircularProgress size={20} style={{color: 'green'}} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />


                  
                    </div>
                    <div className={classes.wrapper5}>
                    <TextField
                      id="ponto-observacoes"
                      style={{width: '90%'}} 
                      size="small"
                      value={valueEditObsArea ? valueEditObsArea : null}
                      onChange={(e) => setValueEditObsArea(e.target.value)} 
                      label="Observações"
                      variant="outlined"
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 1000 }}   
                      disabled={loadingDadosArea ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    </div>

                    { !verifyTypeIsMarker ? (
                    <div style={{marginLeft: '5%', marginRight: '5%', borderRadius: '5px', border: '2px solid '+colorEditArea}}>
                      <div className={classes.wrapper5}>
                      <p>Selecione a cor</p>
                      </div>
                      <div className={classes.wrapper5}> 
                      {loadingDadosArea ? <Skeleton variant="text" width="90%" height={26} /> : <HuePicker width="90%" color={colorEditArea} onChange={ handleChangeColorEditArea } /> }                      
                      </div>
                    </div>
                    ) : null }


                  <div className={classes.buttonsEspacados} style={{marginTop: 50}}>
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.buttonExcluir}
                    onClick={() => removerArea(valueEditIdArea)}  
                    disabled={loadingDadosArea ? true : false}
                    startIcon={loadingDadosArea ? <CircularProgress disableShrink style={{color: 'green'}} size={20} /> : <DeleteIcon/>}                  
                    style={{marginRight: 240}}
                    >
                    Excluir
                    </Button>
                   
                    <Button
                    variant="contained"
                    className={classes.buttonSalvar}
                    size="small"
                    onClick={() => salvarEditarArea(valueEditIdArea)}  
                    disabled={loadingDadosArea || salvandoEditarArea ? true : false}
                    style={{marginLeft: 10}}
                    startIcon={loadingDadosArea || salvandoEditarArea ? <CircularProgress disableShrink style={{color: 'green'}} size={20} /> : <SaveIcon/>}                  
                    >
                    Salvar
                    </Button>

                    <Button
                    variant="contained"
                    size="small"
                    onClick={handleCloseModalEditarArea}
                    style={{marginLeft: 10}}
                    >
                    Fechar
                    </Button>
                   
                </div>



                </Grid>
          </Paper>
        </Fade>
      </Modal>






      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalMarcarArea}
        disableEscapeKeyDown={true}
        onClose={handleCloseModalMarcarPonto}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalMarcarArea}>
          <Paper className={classes.paper} elevation={10} style={{width: '600px'}}>
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"                   
                    >   

                    <div style={{ width: '100%', margin: 0 }}>
                        <Box display="flex" p={1}>
                            <Box flexGrow={1} >
                            <div  style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        margin: '0px'
                                    }}
                                    className={classes.noMargin}
                                    > 
                        
                                        <CropIcon/>
                                        <h2 style={{marginLeft: '5px', marginBlockStart: 0, marginBlockEnd: 0}} className={classes.noMargin}>Marcar Área</h2>
                                    
                            </div>
                            </Box>
                            <Box>
                                <CloseIcon onClick={handleCloseModalMarcarArea} style={{cursor: 'pointer'}}/>
                            </Box>
                            
                        </Box>
                        <Divider />
                    </div>

                
                </Grid>

                <div style={{
                      paddingTop: '20px', 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      }}>
                        <p>Criação de nova área</p>
                    </div>


               
                <Grid item md={12} xs={12}>
                    
                   
                    <div className={classes.wrapper5}>
                    <TextField 
                      id="outlined-basic" 
                      label="Nome da Área" 
                      variant="outlined" 
                      onChange={(e) => setValueNomeArea(e.target.value)}  
                      size="small" 
                      style={{width: '70%'}} 
                      helperText="Ex: Área da Unidade Piloto"
                      inputProps={{ maxLength: 100 }} 
                      />
                    </div>

                    

                    <div className={classes.wrapper5}>				
                    <Autocomplete    
                        PopperComponent={PopperCategoriasPonto}
                        id="async-categorias"
                        style={{ zIndex: 1000000000000000000000000000000000000, width: '70%' }}
                        size="small"
                        open={openCategoriasPonto}
                        onOpen={() => {
                          setOpenCategoriasPonto(true);
                        }}
                        onClose={() => {
                          setOpenCategoriasPonto(false);
                        }}
                      // getOptionSelected={(option, value) => console.log( value.id)}
                        getOptionLabel={(option) => option.categoria}
                        options={optionsCategoriasPonto}
                        loading={loadingCategoriasPonto}
                        onChange={(e, value) => {
                            if(value !== null){
                              // console.log(value);
                              setValueCategoriaArea(value.id)
                              
                            } else {
                              
                            }
                        
                      
                        }}
                        renderInput={(params) => (
                          <TextField
                          style={{zIndex: 1000000000000000000000000000000000}}
                         
                            {...params}
                            label="Categoria"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment style={{backgroundColor: 'red'}}>
                                  {loadingCategoriasPonto ? <CircularProgress size={20} style={{color: 'green'}} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>

                    


                    <div className={classes.wrapper5}>
                    <TextField
                      id="ponto-observacoes"
                      style={{width: '70%'}} 
                      size="small"
                      onChange={(e) => setValueObsArea(e.target.value)} 
                      label="Observações"
                      variant="outlined"
                      multiline
                      rows={4}
                      inputProps={{ maxLength: 1000 }}   
                           
                    />
                    </div>

                    { !verifyTypeIsMarker ? (
                    <div style={{marginLeft: '15%', marginRight: '15%', borderRadius: '5px', border: '2px solid '+colorNewArea}}>
                      <div className={classes.wrapper5}>
                      <p>Selecione a cor</p>
                      </div>
                      <div className={classes.wrapper5}>
                        <HuePicker width="90%" color={colorNewArea} onChange={ handleChangeColorNewArea } />                      
                      </div>
                    </div>
                    ) : null }
                    


                </Grid>
              

              <div style={{
                      paddingTop: '30px', 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      }}>
                        <div className={classes.buttonsEspacados}>
                    <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={salvarNovaArea}  
                    startIcon={<SaveIcon/>}          
                    >
                    Salvar
                    </Button>

                    <Button
                    variant="contained"
                    size="small"
                    onClick={handleCloseModalMarcarArea}
                    style={{marginLeft: 20}}
                    >
                    Cancelar
                    </Button>
                   
                </div>
                    </div>

          </Paper>
        </Fade>
      </Modal>
    

    <EstudoShare openShare={openShare} handleCloseShare={handleCloseShare} tokenShare={tokenShare} />
 
    </div>
)

}