import React, { useState, useEffect, useContext } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

import api from '../../../api';

const useStyles = makeStyles((theme) => ({
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
}))

export default function ReceitaOrcamentaria(props) {
    const classes = useStyles();
    const [dados, setDados] = useState();
    const codCidade = props.codCidade   

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
        
          await api.post('/consulta/cidade/receitaorcamentaria', {cidade:codCidade})
          .then(function (response) {
            // handle success
            //console.log(response.data[0].idh)
            const datajson = Number(response.data[0].receita_orcamentaria).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
          
            setDados(datajson)
            setLoading(false)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          
          });
    
    
        })();
      }, []);

      return (
        <>
        {loading ? (
                        <Skeleton style={{ width: '60%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                      ) : (
            <p style={{marginLeft: '5px'}} className={classes.textSecond}>Receita Orçamentária: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{dados}</span></p>
                      )}
        </>
      );

}