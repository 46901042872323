import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../Context/DarkContext';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Sumario from './Sumario/Sumario';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Chart from "react-apexcharts";
import CircularProgress from '@material-ui/core/CircularProgress';

import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import AssessmentIcon from '@material-ui/icons/Assessment';

import api from '../../api';

import purple from '@material-ui/core/colors/purple';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '5%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  paperNiveis: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: purple[900],
  },
  paperLeft: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  
  imgresponsive: {
    width: '100px',
    height: 'auto',
  },
  mapresponsive: {
    width: '80%',
    height: 'auto',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fontBold: {
    fontWeight: 900,
  },
  noMarginSecond: {
    color: theme.palette.text.secondary,
    marginBlockStart: 0,
    marginBlockEnd: 0,    
  },
  noMargin: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    marginBlockStart: 0,
    marginBlockEnd: 0,    
  },
  marginJustLeft: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginLeft: '15px',
  },
  marginDiv: {
    marginBlockEnd: '0.5em'
  },
  marginTitle: {
    marginBlockEnd: '1em'
  },
  inCenter: {
    padding: theme.spacing(2),
    textAlign: 'center',
  }
}));


export default function Shopping(props) {
  const classes = useStyles();
  const { isDarkModeCont } = useContext(DarkContext);
    
  const codShopping = props.cod;
  const tokenEstudo = props.tokenEstudo;

  const [loading, setLoading] = useState(true)

  const [nomeBairro, setNomeBairro] = useState();
  const [nomeCidade, setNomeCidade] = useState();
  const [nomeEstado, setNomeEstado] = useState();
  const [imagem, setImagem] = useState();
  const [nomeShopping, setNomeShopping] = useState();
  const [endereco, setEndereco] = useState();
  const [numero, setNumero] = useState();
  const [bairro, setBairro] = useState();
  const [cep, setCep] = useState();
  const [cidade, setCidade] = useState();
  const [uf, setUf] = useState();
  const [regiao, setRegiao] = useState();
  const [telefone, setTelefone] = useState();
  const [website, setWebsite] = useState();

  const [dataInauguracao, setDataInauguracao] = useState();
  const [tipoShopping, setTipoShopping] = useState();
  const [pisosShopping, setPisosShopping] = useState();
  const [areaBrutaLoc, setAreaBrutaLoc] = useState();
  const [areaConstruida, setAreaConstruida] = useState();
  const [areaTerreno, setAreaTerreno] = useState();
  const [estacionamento, setEstacionamento] = useState();
  const [administracao, setAdministracao] = useState();

  const [totalLojas, setTotalLojas] = useState();
  const [lojasAncoras, setLojasAncoras] = useState();
  const [lojasMegalojas, setLojasMegalojas] = useState();
  const [lojasSatelites, setLojasSatelites] = useState();
  const [lojasConvServ, setLojasConvServ] = useState();
  const [lojasAlimentacao, setLojasAlimentacao] = useState();
  const [lojasEntretenimento, setLojasEntretenimento] = useState();
  const [lojasCinema, setLojasCinema] = useState();

  const [classeA, setClasseA] = useState();
  const [classeB, setClasseB] = useState();
  const [classeC, setClasseC] = useState();
  const [classeD, setClasseD] = useState();

  const [dadosClassesPerfil, setDadosClassesPerfil] = useState([0, 0, 0, 0]);



  const options = {
    chart: { 
        background: 'transparent',           
    defaultLocale: 'pt-br',
    locales: [{
        name: 'pt-br',
        options: {
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
        shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        toolbar: {
            download: 'Download SVG',
            selection: 'Seleção',
            selectionZoom: 'Selecionar Zoom',
            zoomIn: 'Mais Zoom',
            zoomOut: 'Menos Zoom',
            pan: 'Mover',
            reset: 'Reiniciar Zoom',
        }
        }
    }],
    type: 'donut',     
    // dropShadow: {
    // enabled: true,
    // color: '#000',
    // top: 3,
    // left: 3,
    // blur: 3,
    // opacity: 0.2
    // },
    // height: 150,
    stacked: true,
    
    },
    theme: {
        mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
    },
    // colors: ['#264aff', '#ff33cf'],
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "dark",
    //     type: "horizontal",
    //     shadeIntensity: 0.5,
    //     inverseColors: true,
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 100]
    //   }
    // },
    dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      
      legend: {
        show: true,
        position: "bottom",
        // offsetX: -30,
        // offsetY: -10,
        formatter: function (val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
        }
      },
      labels: ['Classe A', 'Classe B', 'Classe C', 'Classe D'],
  }
 
  const series = dadosClassesPerfil

  useEffect(() => {
    (async () => {
      
      await api.post('/consulta/shopping', {codShopping:codShopping, tokenEstudo})
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        // console.log(datajson)

        setNomeBairro(datajson[0].bairro)
        setNomeCidade(datajson[0].cidade)
        setNomeEstado(datajson[0].uf)
        setImagem(datajson[0].imagem)
        setNomeShopping(datajson[0].nome)
        setEndereco(datajson[0].endereco)
        setNumero(datajson[0].numero)
        setBairro(datajson[0].bairro)
        setCep(datajson[0].cep)
        setCidade(datajson[0].cidade)
        setUf(datajson[0].uf)
        setRegiao(datajson[0].regiao)
        setTelefone(datajson[0].telefone)
        setWebsite(datajson[0].website)

        setDataInauguracao(datajson[0].data_inauguracao)
        setTipoShopping(datajson[0].tipo_shopping)
        setPisosShopping(datajson[0].pisos_shopping)
        setAreaBrutaLoc(datajson[0].abl)
        setAreaConstruida(datajson[0].area_construida)
        setAreaTerreno(datajson[0].area_terreno)
        setEstacionamento(datajson[0].estacionamento)
        setAdministracao(datajson[0].administracao)

        setTotalLojas(datajson[0].total_lojas)
        setLojasAncoras(datajson[0].lojas_ancoras)
        setLojasMegalojas(datajson[0].lojas_megalojas)
        setLojasSatelites(datajson[0].lojas_satelites)
        setLojasConvServ(datajson[0].lojas_conveniencia_servicos)
        setLojasAlimentacao(datajson[0].lojas_alimentacao)
        setLojasEntretenimento(datajson[0].entretenimento)
        setLojasCinema(datajson[0].cinema)

        setClasseA(datajson[0].perfil_frequentadores_a)
        setClasseB(datajson[0].perfil_frequentadores_b)
        setClasseC(datajson[0].perfil_frequentadores_c)
        setClasseD(datajson[0].perfil_frequentadores_d)

        setDadosClassesPerfil([Number(datajson[0].perfil_frequentadores_a), Number(datajson[0].perfil_frequentadores_b), Number(datajson[0].perfil_frequentadores_c), Number(datajson[0].perfil_frequentadores_d)])
        
        setLoading(false)
      })
      .catch(function (error) {
        // handle error
        setLoading(false)
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });


    })();
  }, []);

  return (
    <>
    <Accordion style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <Typography className={classes.heading}>Shopping Center: {nomeShopping ? nomeShopping : <CircularProgress style={{color: 'green'}} size={15} />}</Typography>
                </AccordionSummary>
                <AccordionDetails>

    <div className={classes.root}>
      
      <Grid container spacing={3}>
     
        <Grid item xs={12}>       
          <Paper className={classes.paperNiveis} elevation={16}>
            <h1>Estudo Geográfico, Social e Econômico</h1>
            <h3>{nomeShopping}</h3>              
            <h3>{nomeCidade} - {nomeEstado} - {nomeBairro}</h3>
          </Paper>
        </Grid>
      

        <Sumario estado={null} cidade={null} bairro={null} shopping={codShopping} />
        

        <Grid item xs={12} style={{marginTop: '20px'}}>
        
            <div className={classes.paper}>
            
                <div className={classes.logo} >       
                    {loading ? (
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={118} /> 
                    ) : (       
                        <img src={`https://api.geotaker.com.br/shoppings/${imagem}`}  style={{maxHeight: '350px', maxWidth: '80%', borderRadius: '20px 60px 20px 60px'}}></img>
                    )}
                </div>  
                <br /><br />
                <h1>{nomeShopping}</h1><br />              
            </div>           
         
        </Grid>

      </Grid>
      <Paper className={classes.paperLeft} elevation={16}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} id="dadoscadastraisshopping">
          
            <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <BusinessIcon color="secondary"/>
                    <h2>Cadastro</h2>
            </div>
            <Divider className={classes.marginTitle} />
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Endereço</p>
              <p className={classes.noMargin}>{endereco}</p>
            </div>
            <div className={classes.marginDiv}>   
              <p className={classes.noMarginSecond}>Número</p>
              <p className={classes.noMargin}>{numero}</p>
            </div>
            <div className={classes.marginDiv}> 
              <p className={classes.noMarginSecond}>Bairro</p>
              <p className={classes.noMargin}>{bairro}</p>
            </div>
            <div className={classes.marginDiv}> 
              <p className={classes.noMarginSecond}>CEP</p>
              <p className={classes.noMargin}>{cep}</p>
            </div>
            <div className={classes.marginDiv}> 
              <p className={classes.noMarginSecond}>Cidade</p>
              <p className={classes.noMargin}>{cidade}</p>
            </div>
            <div className={classes.marginDiv}> 
              <p className={classes.noMarginSecond}>Estado</p>
              <p className={classes.noMargin}>{uf}</p>
            </div>
            <div className={classes.marginDiv}> 
              <p className={classes.noMarginSecond}>Região</p>
              <p className={classes.noMargin}>{regiao}</p>
            </div>
            <div className={classes.marginDiv}> 
              <p className={classes.noMarginSecond}>Telefone</p>
              <p className={classes.noMargin}>{telefone}</p>
            </div>
            <div className={classes.marginDiv}> 
              <p className={classes.noMarginSecond}>Website</p>
              <p className={classes.noMargin}>{website}</p>
            </div>
          
        </Grid>
        <Grid item xs={12} md={4} id="informacoesshopping">
         
            <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <AssignmentIcon color="secondary"/>
                    <h2>Informações</h2>
            </div>
            <Divider className={classes.marginTitle} />
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Data de Inauguração</p>
              <p className={classes.noMargin}>{dataInauguracao}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Tipo de Shopping</p>
              <p className={classes.noMargin}>{tipoShopping}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Pisos do Shopping</p>
              <p className={classes.noMargin}>{pisosShopping}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Área Bruta Locável</p>
              <p className={classes.noMargin}>{areaBrutaLoc}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Área Construída</p>
              <p className={classes.noMargin}>{areaConstruida}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Área do Terreno</p>
              <p className={classes.noMargin}>{areaTerreno}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Estacionamento</p>
              <p className={classes.noMargin}>{estacionamento}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Administracação</p>
              <p className={classes.noMargin}>{administracao}</p>
            </div>

          
        </Grid>
        <Grid item xs={12} md={4} id="lojasshopping">
            <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <LocalMallIcon color="secondary"/>
                    <h2>Lojas</h2>
            </div>
            <Divider className={classes.marginTitle} />
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Total de Lojas</p>
              <p className={classes.noMargin}>{totalLojas}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Lojas Âncoras</p>
              <p className={classes.noMargin}>{lojasAncoras}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Megalojas</p>
              <p className={classes.noMargin}>{lojasMegalojas}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Satélites</p>
              <p className={classes.noMargin}>{lojasSatelites}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Conveniência e Serviços</p>
              <p className={classes.noMargin}>{lojasConvServ}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Alimentação</p>
              <p className={classes.noMargin}>{lojasAlimentacao}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Entretenimento</p>
              <p className={classes.noMargin}>{lojasEntretenimento}</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Cinema</p>
              <p className={classes.noMargin}>{lojasCinema}</p>
            </div>

        </Grid>
        
        <Grid container spacing={3} className={classes.inCenter}>
          <Grid item xs={12} md={12} id="perfilfrequentadoresshopping">
            <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <AssessmentIcon color="secondary"/>
                    <h2>Perfil dos Frequentadores</h2>
            </div>
            <Divider />
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Classe A</p>
              <p className={classes.noMargin}>{classeA}%</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Classe B</p>
              <p className={classes.noMargin}>{classeB}%</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Classe C</p>
              <p className={classes.noMargin}>{classeC}%</p>
            </div>
            <div className={classes.marginDiv}>          
              <p className={classes.noMarginSecond}>Classe D</p>
              <p className={classes.noMargin}>{classeD}%</p>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
          <div className="app">
            <div className="row">
              <div className="mixed-chart">
                <Chart
                  options={options}
                  series={series}
                  type="pie"
                  width="100%"
                  height="250vh"
                />
              </div>
            </div>
          </div>
          </Grid>

        </Grid>



      </Grid>
      </Paper>
            
    </div>

    </AccordionDetails>
            </Accordion>
                </>

  );
}
