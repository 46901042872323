import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import jwtdecode from "jwt-decode";

import ScrollTotop from '../../Components/ScrollToTop';


import LogoCliente from '../../Components/LogoCliente'
import Estado from './Estado';
import Cidade from './Cidade';
import Bairro from './Bairro';
import Shopping from './Shopping';
import Setor from './Setor';
import Sumario from './Sumario/Sumario';
import MapaEstudo from '../../Components/Mapas/MapaEstudo'


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '5%',
      },
    rootAbas: {
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: '100px'
        },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  
        margin: theme.spacing(3),
        padding: theme.spacing(3),    
      },
      iconMargin: {
          margin: '5px',
      },
      noMargin: {
          margin: '0px',
          marginBlockStart: 0,
          marginBlockEnd: 0,
      },
      titulo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  
      },
      tabsEstudo: {
          marginTop: '2px',
      },
      tabsGeral: {
          paddingBottom: '30px',
      }
      
}));

export default function Estudo() {
const classes = useStyles();
// const params = useParams();
// console.log("veio aqui!")
const tokenEstudo = (sessionStorage.getItem('client')).slice(1, -1);
const tokenEstudoLogo = sessionStorage.getItem('client');
// localStorage.setItem('client', JSON.stringify(tokenEstudo));

// const tokenEstudo = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjIsImNvbXBhbnlJZCI6IjEyMzQ1NiIsImVzdGFkbyI6dHJ1ZSwiY29kRXN0YWRvIjoiMjMiLCJjaWRhZGUiOnRydWUsImNvZENpZGFkZSI6IjIzMDQ0MDAiLCJiYWlycm8iOnRydWUsImRpc3RyaXRvIjowLCJjb2RCYWlycm8iOiIyMzA0NDAwMDU3Iiwic2hvcHBpbmciOnRydWUsImNvZFNob3BwaW5nIjozLCJpYXQiOjE2MjgwMDY4NDh9.1FzsdsRNzgO4tu0a7Fk4fY9mszYKuSgpd-qy_epxix0"
// console.log(tokenEstudo)

const setor = jwtdecode(tokenEstudo).setor
const codSetor = jwtdecode(tokenEstudo).codSetor
const estado = jwtdecode(tokenEstudo).estado
const codEstado = jwtdecode(tokenEstudo).codEstado
const cidade = jwtdecode(tokenEstudo).cidade
const codCidade = jwtdecode(tokenEstudo).codCidade
const bairro = jwtdecode(tokenEstudo).bairro
const codBairro = jwtdecode(tokenEstudo).codBairro
const distrito = jwtdecode(tokenEstudo).distrito
const shopping = jwtdecode(tokenEstudo).shopping
const codShopping =jwtdecode(tokenEstudo).codShopping
const coordenadas = jwtdecode(tokenEstudo).coordenadas
const coordZoom = jwtdecode(tokenEstudo).zoom

return (
  
    <div className={classes.tabsGeral}>        
        <ScrollTotop />
        <LogoCliente tokenEstudo={tokenEstudo} />
        {/* <Sumario estado={estado} cidade={cidade} bairro={bairro} shopping={shopping} /> */}



        <div className={classes.root}>
        <MapaEstudo cordenadas={{'coord':coordenadas, 'zoom':coordZoom}} setor={setor} codSetor={codSetor} />
        </div>
        <div className={classes.titulo}>
        <h2 style={{marginBlockStart: 0, marginBlockEnd: 0}}>Estudo</h2>        
        </div>
        <div className={classes.titulo}>
        <p style={{marginBlockStart: 0, fontSize: '12px'}}>Clique na aba abaixo desejada para abrir o estudo</p>
        </div>
        <div className={classes.rootAbas}>            
        
            {estado ? (        
                <Estado cod={codEstado}/>        
            ) : (        
                null 
            ) }

            {cidade ? (        
                <div className={classes.tabsEstudo}>
                    <Cidade cod={codCidade} />
                </div>
            ) : (        
                null        
            ) }

            {bairro ? (        
                <div className={classes.tabsEstudo}>
                    <Bairro cod={codBairro} distrito={distrito}/>
                </div>
            ) : (        
                null      
            ) }

            {setor ? (             
                <div className={classes.tabsEstudo}>
                    {/* <Sumario estado={null} cidade={null} bairro={null} shopping={shopping} /> */}
                    <Setor cod={codSetor} tokenEstudo={tokenEstudo} />
                </div>
            ) : (        
                null       
            ) }

            {shopping ? (        
                <div className={classes.tabsEstudo}>
                    <Shopping cod={codShopping} tokenEstudo={tokenEstudo} />
                </div>
            ) : (        
                null      
            ) }
        </div>
    </div>
 
)
}