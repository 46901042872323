import { useState, useEffect, useContext } from 'react';
import { AdminContext } from '../../Context/AdminContext';

import api from '../../api';
import apilogin from '../../apilogin';
import history from '../../history';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const { isAdmin, setIsAdmin } = useContext(AdminContext);
  const [userActived, setUserActived] = useState(true)
  
  // const Verificacao = () => { 
      
  //   api.post('/verificacao/usuario/ativo', {id:2})
  //         .then(function (response) {
  //           // handle success
  //           const datajson = response.data
  //           console.log(datajson)
  //           setUserActived(datajson.actived);
  //         })
  //         .catch(function (error) {
  //           // handle error
         
  //           console.log(error);
  //         })
  //         .then(function () {
  //           // always executed
           
  //         });

  //       }
  //       Verificacao()

    
     
  useEffect(() => {

      
    // const client = localStorage.getItem('client');
    // if (client) {
    //   console.log("Tem CLIENT!!!")
    //   api.defaults.headers.Authorization = `Bearer ${JSON.parse(client)}`;
    // }


    
    
    const token = localStorage.getItem('token');
    
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
      setAuthenticated(true); 
     

      const decoded = jwt_decode(token);
      setDataUser(decoded)
      setIsAdmin(decoded.admin) 
      
      api.post('/confirmar/token', {token: token})
          .then(function (response) {
            // handle success
          //  console.log("CONFIRMANDO TOKEN...")
            const respjson = response.data
            // console.log(respjson)

            if(respjson.message === "authorized") {
              
              const decoded = jwt_decode(token);
              setIsAdmin(decoded.admin)  
              
            }

            else {
              toast.error("Seu token não é válido! Faça login novamente."); 
              setAuthenticated(false);
              localStorage.removeItem('token');
              api.defaults.headers.Authorization = undefined;
              history.push('/login');
            }
            
                
          })
          .catch(function (error) {
            // handle error
            console.log(error);
            toast.error("Seu token não é válido! Faça login novamente."); 
            setAuthenticated(false);
            localStorage.removeItem('token');
            api.defaults.headers.Authorization = undefined;
            history.push('/login');
          })

      
      // 
      // console.log(decoded);   

          //Verificar se está Ativo

          api.post('/verificacao/usuario/ativo', {id:decoded.id})
          .then(function (response) {
            // handle success
            const datajson = response.data
            // console.log(datajson)
            setUserActived(datajson.actived);
          })
          .catch(function (error) {
            // handle error
         
            console.log(error);
          })
          .then(function () {
            // always executed
           
          });


    }

    setLoading(false);
  }, []);

  useEffect(()=> {
    // console.log("O usuário está ativo: "+userActived)
    if(userActived === false) { 
            toast.error("O SISTEMA ESTÁ BLOQUEADO POR FALTA DE PAGAMENTO!"); 
            setAuthenticated(false);
            localStorage.removeItem('token');
            api.defaults.headers.Authorization = undefined;
            history.push('/login');
    }
  },[userActived])
    
  function handleLogin(userData) { 
    setLoadingUser(true);   
   
    api.post('/authenticate', userData)
    .then(function (response) {    

      // console.log(JSON.stringify(response))
       setDataUser(response.data.user);
      
      if(response.request.status === 200 && response.data.message === "authorized") {
        
        const token = response.data.token;
        
        localStorage.setItem('token', JSON.stringify(token));
        apilogin.defaults.headers.Authorization = `Bearer ${token}`;
        setAuthenticated(true);
        toast.success("Login realizado com sucesso!");

        const decoded = jwt_decode(token);
        setIsAdmin(decoded.admin)

        history.push('/dashboard');
        setLoadingUser(false);
        
      } 

      if(response.request.status === 200 && response.data.message === "payment") {
        toast.error("O acesso está bloqueado por falta de pagamento.")
        setLoadingUser(false);
        Swal.fire({
          title: 'Sistema bloqueado!',
          text: "O acesso está bloqueado por falta de pagamento. Realize o pagamento clicando no botão abaixo.",
          icon: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Realizar Pagamento'
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Encaminhando...',
              text: 'Você está sendo redirecionado á página de pagamento...',
              icon: 'info',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              didOpen: () => {
                Swal.showLoading()
            }
          })
          } else {
            Swal.fire({
              title: 'Sistema bloqueado!',
              text: 'O acesso continuará bloqueado até a realização do pagamento.',
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
            })
          }
        })
       
      }
      
      if(response.request.status === 200 && response.data.message === "userpass") {       
        toast.error("Email ou senha incorreto!");        
        setLoadingUser(false);
      }

      if(response.request.status === 200 && response.data.message === "unauthorized") {       
        toast.error("Acesso não autorizado!");        
        setLoadingUser(false);
      }
      

    })
    .catch(function (error) {
      toast.error("Algo está errado! Informe o suporte."); 
      console.log(error);
      setLoadingUser(false);
    });
    
  
    
  }

  function handleLogout() {
    setAuthenticated(false);
    localStorage.removeItem('token');
    api.defaults.headers.Authorization = undefined;
    history.push('/login');
  }
  
  return { authenticated, userActived, loading, loadingUser, dataUser, setDataUser, handleLogin, handleLogout };
}