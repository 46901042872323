import React, { useState, useEffect, useContext } from 'react';
import api from '../../../api';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      margin: '5%',
      position: 'relative',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    paperNiveis: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: 'white',
      backgroundColor: purple[900],
    },
    textPrimary: { 
      color: theme.palette.text.primary,
    },
    textSecond: { 
      color: theme.palette.text.secondary,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    
    imgresponsive: {
      width: '100px',
      height: 'auto',
    },
    mapresponsive: {
      width: '80%',
      height: 'auto',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    fontBold: {
      fontWeight: 900,
    },
  }));

export default function ImoveisSetor(props) {
    const classes = useStyles();
    const [casa, setCasa] = useState();
    const [condominio, setCondominio] = useState();
    const [apartamento, setApartamento] = useState();
    const [proprioQuitado, setProprioQuitado] = useState();
    const [proprioAquisicao, setProprioAquisicao] = useState();
    const [alugado, setAlugado] = useState();
    const [cedidoEmpregador, setCedidoEmpregador] = useState();
    const [cedidoOutraForma, setCedidoOutraForma] = useState();
    const [outraCondicao, setOutraCondicao] = useState();
    const [moradores, setMoradores] = useState([])

    const codSetor = props.codSetor
  

    useEffect(() => {
        (async () => {
          
          await api.post('/consulta/setor/imoveis', {setor:codSetor})
          .then(function (response) {
            // handle success
          
            const datajson = response;         
            var chavesAscendentes = Object.keys(datajson.data).sort( function(keyA, keyB) {
                return Number(datajson.data[keyA].VAR) - Number(datajson.data[keyB].VAR);
              });    

            function alterarZero(valor) {
                if(valor == 0) {
                    return "0"
                } else {
                    return valor
                }
            }

            setCasa(alterarZero(Number(datajson.data[chavesAscendentes[0]].V)))
            setCondominio(alterarZero(Number(datajson.data[chavesAscendentes[1]].V)))
            setApartamento(alterarZero(Number(datajson.data[chavesAscendentes[2]].V)))
            setProprioQuitado(alterarZero(Number(datajson.data[chavesAscendentes[3]].V)))
            setProprioAquisicao(alterarZero(Number(datajson.data[chavesAscendentes[4]].V)))
            setAlugado(alterarZero(Number(datajson.data[chavesAscendentes[5]].V)))
            setCedidoEmpregador(alterarZero(Number(datajson.data[chavesAscendentes[6]].V)))
            setCedidoOutraForma(alterarZero(Number(datajson.data[chavesAscendentes[7]].V)))
            setOutraCondicao(alterarZero(Number(datajson.data[chavesAscendentes[8]].V)))
            setMoradores([alterarZero(Number(datajson.data[chavesAscendentes[9]].V)),alterarZero(Number(datajson.data[chavesAscendentes[10]].V)),alterarZero(Number(datajson.data[chavesAscendentes[11]].V)),alterarZero(Number(datajson.data[chavesAscendentes[12]].V)),alterarZero(Number(datajson.data[chavesAscendentes[13]].V)),alterarZero(Number(datajson.data[chavesAscendentes[14]].V)),alterarZero(Number(datajson.data[chavesAscendentes[15]].V)),alterarZero(Number(datajson.data[chavesAscendentes[16]].V)),alterarZero(Number(datajson.data[chavesAscendentes[17]].V)),alterarZero(Number(datajson.data[chavesAscendentes[18]].V))])
            console.log(moradores)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
           
          });
    
    
        })();
      }, []);

    return (
        <>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <p style={{textAlign: 'left', fontWeight: 600}}>Tipo de Imóvel</p>
                    <Divider style={{marginBottom: '30px'}} />
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>Casa: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{casa ? casa + " unidades" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>Casa de vila ou em condomínio: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{condominio ? condominio + " unidades" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>Apartamento: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{apartamento ? apartamento + " unidades" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
            
                <p style={{textAlign: 'left', marginTop: '50px', fontWeight: 600}}>Condição de Ocupação</p>
                    <Divider style={{marginBottom: '30px'}} />
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>Imóvel próprio já quitado: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{proprioQuitado ? proprioQuitado + " unidades" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>Imóvel próprio em aquisição: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{proprioAquisicao ? proprioAquisicao + " unidades" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>Imóvel alugado: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{alugado ? alugado + " unidades" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />   
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>Imóvel cedido por empregador: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{cedidoEmpregador ? cedidoEmpregador + " unidades" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>Imóvel cedido de outra forma: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{cedidoOutraForma ? cedidoOutraForma + " unidades" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>Imóvel de outra condição: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{outraCondicao ? outraCondicao + " unidades" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
            <p style={{textAlign: 'left', fontWeight: 600}}>Moradores por Domicílio</p>
                    <Divider style={{marginBottom: '30px'}} />
                    <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>1 morador: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[0] ? moradores[0] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>2 moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[1] ? moradores[1] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>3 moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[2] ? moradores[2] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />   
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>4 moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[3] ? moradores[3] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>5 moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[4] ? moradores[4] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>6 moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[5] ? moradores[5] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>7 moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[6] ? moradores[6] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>8 moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[7] ? moradores[7] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>9 moradores: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[8] ? moradores[8] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
                    <Divider />    
                <div style={{textAlign: 'left'}}>
                    <p style={{marginLeft: '15px'}} className={classes.textSecond}>10 moradores ou mais: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{moradores[9] ? moradores[9] + " domicílios" : <CircularProgress style={{color: 'green'}} size={15} /> }</span></p>
                </div>
            </Grid>
        </Grid>
        </>
    )
}