import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import jwtdecode from "jwt-decode";
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import ScrollTotop from '../../Components/ScrollToTop';
import Divider from '@material-ui/core/Divider';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ShareIcon from '@material-ui/icons/Share';
import Box from '@material-ui/core/Box';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import LinkIcon from '@material-ui/icons/Link';

import Logo from '../../Components/Logo'
import Estado from './Estado';
import Cidade from './Cidade';
import Bairro from './Bairro';
import Shopping from './Shopping';
import Setor from './Setor';
import Sumario from './Sumario/Sumario';
import MapaEstudo from '../../Components/Mapas/MapaEstudo'

import {
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";

  
  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '5%',
      },
    rootAbas: {
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '100px'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  
        margin: theme.spacing(3),
        padding: theme.spacing(3),    
      },
      iconMargin: {
          marginRight: '5px',
          marginLeft: '5px'
      },
      noMargin: {
          margin: '0px',
          marginBlockStart: 0,
          marginBlockEnd: 0,
      },
      btnLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        backgroundColor: '#2756A3',  
        float: 'left',     
        marginLeft: '5px',             
        marginRight: '5px',
        marginBlockStart: 0,
        marginBlockEnd: 0,
        cursor: 'pointer',
      },
      titulo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  
      },
      tabsEstudo: {
          marginTop: '2px',
      },
      tabsGeral: {
          paddingBottom: '30px',
      }
      
}));

export default function Estudo() {
const classes = useStyles();

const { search } = useLocation();
const { PIEBkUYblOLjgtdYbnmhTDG } = queryString.parse(search)
// const params = useParams();

const tokenEstudo = PIEBkUYblOLjgtdYbnmhTDG

const setor = jwtdecode(tokenEstudo).setor
const codSetor = jwtdecode(tokenEstudo).codSetor
const estado = jwtdecode(tokenEstudo).estado
const codEstado = jwtdecode(tokenEstudo).codEstado
const cidade = jwtdecode(tokenEstudo).cidade
const codCidade = jwtdecode(tokenEstudo).codCidade
const bairro = jwtdecode(tokenEstudo).bairro
const codBairro = jwtdecode(tokenEstudo).codBairro
const distrito = jwtdecode(tokenEstudo).distrito
const shopping = jwtdecode(tokenEstudo).shopping
const codShopping = jwtdecode(tokenEstudo).codShopping
const coordenadas = jwtdecode(tokenEstudo).coordenadas
const coordZoom = jwtdecode(tokenEstudo).zoom

const urlExterno = 'https://sistema.geotaker.com.br/cliente/'+"?HdsfgFdfgaseFoiUudtgfdHdsfgFdfgaseFoiUudtgfd="+tokenEstudo;

const [open, setOpen] = useState(false);

const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

const copyLink = () => {
    navigator.clipboard.writeText(urlExterno)
    toast.success("🔗 Link copiado!");
}  


return (
    <div className={classes.tabsGeral}>
        <ScrollTotop />
        <Logo />       

        <div className={classes.root}>
        <MapaEstudo cordenadas={{'coord':coordenadas, 'zoom':coordZoom}} setor={setor} codSetor={codSetor} />
        </div>
        <div className={classes.titulo}>
        <h2 style={{marginBlockStart: 0, marginBlockEnd: 0}}>Estudo</h2>        
        </div>
        <div className={classes.titulo}>
        <p style={{marginBlockStart: 0, fontSize: '12px'}}>Clique na aba abaixo desejada para abrir o estudo</p>
        </div>
        <div className={classes.rootAbas}>
            {estado ? (
                <div>     
                    {/* <Sumario estado={estado} cidade={null} bairro={null} shopping={null} /> */}
                    <Estado cod={codEstado}/>        
                </div>
            ) : (        
                null         
            ) }

            {cidade ? (
                <div className={classes.tabsEstudo}>
                    {/* <Sumario estado={null} cidade={cidade} bairro={null} shopping={null} /> */}
                    <Cidade cod={codCidade} />
                </div>
            ) : (        
                null        
            ) }

            {bairro ? (            
                <div className={classes.tabsEstudo}>
                    {/* <Sumario estado={null} cidade={null} bairro={bairro} shopping={null} /> */}
                    <Bairro cod={codBairro} distrito={distrito} />
                </div>               
            ) : (        
            null      
            ) }

            {setor ? (             
                <div className={classes.tabsEstudo}>
                    {/* <Sumario estado={null} cidade={null} bairro={null} shopping={shopping} /> */}
                    <Setor cod={codSetor} tokenEstudo={tokenEstudo} />
                </div>
            ) : (        
                null       
            ) }
            
            {shopping ? (             
                <div className={classes.tabsEstudo}>
                    {/* <Sumario estado={null} cidade={null} bairro={null} shopping={shopping} /> */}
                    <Shopping cod={codShopping} tokenEstudo={tokenEstudo} />
                </div>
            ) : (        
                null       
            ) }

    </div>

              
        

        
        
        {/* <p>Params: {JSON.stringify(params)}</p>
        <p>Params1: {params.id}</p>
        <p>Token: {JSON.stringify(jwtdecode(tokenEstudo))}</p>
        <p>Token: {jwtdecode(tokenEstudo).codEstado}</p> */}

        <Fab aria-label="Opções" className={classes.fab} color="primary" onClick={()=> handleOpen()}>
          <ShareIcon />
        </Fab>



        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        
            <Paper className={classes.paper} elevation={10}>
            
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    >   

                    <div style={{ width: '100%', margin: 0 }}>
                        <Box display="flex" p={1}>
                            <Box flexGrow={1} >
                            <div  style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        margin: '0px'
                                    }}
                                    className={classes.noMargin}
                                    > 
                        
                                        <ShareIcon/>
                                        <h2 style={{marginLeft: '5px'}} className={classes.noMargin}>Compartilhar</h2>
                                    
                            </div>
                            </Box>
                            <Box>
                                <CloseRoundedIcon onClick={handleClose} style={{cursor: 'pointer'}}/>
                            </Box>
                            
                        </Box>
                        <Divider />
                    </div>

                
                </Grid>
                <Grid>
                    <div className={classes.btnLink}>
                        <LinkIcon 
                            onClick={() => copyLink()} 
                            style={{color: 'white', transform: 'rotate(135deg)'}} 
                        />
                    </div>
                    
                    <WhatsappShareButton url={urlExterno} className={classes.iconMargin}>
                        <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>
                    <TelegramShareButton url={urlExterno} className={classes.iconMargin}>
                        <TelegramIcon size={40} round={true} />
                    </TelegramShareButton>
                    <EmailShareButton url={urlExterno} className={classes.iconMargin}>
                        <EmailIcon size={40} round={true} />
                    </EmailShareButton>
                    <FacebookShareButton url={urlExterno} className={classes.iconMargin}>
                        <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>
                    <FacebookMessengerShareButton url={urlExterno} className={classes.iconMargin}>
                        <FacebookMessengerIcon size={40} round={true} />
                    </FacebookMessengerShareButton>
                    <LinkedinShareButton url={urlExterno} className={classes.iconMargin}>
                        <LinkedinIcon size={40} round={true} />
                    </LinkedinShareButton>
                    <TwitterShareButton url={urlExterno} className={classes.iconMargin}>
                        <TwitterIcon size={40} round={true} />
                    </TwitterShareButton>
                    <PinterestShareButton url={urlExterno} className={classes.iconMargin}>
                        <PinterestIcon size={40} round={true} />
                    </PinterestShareButton>
                </Grid>
                
              
                
            </Paper>
         
        </Fade>
      </Modal>


    </div>
    
)
}