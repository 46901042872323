import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-scroll";
import Divider from '@material-ui/core/Divider';

import Estado from './Estado';
import Cidade from './Cidade';
import Bairro from './Bairro';
import Setor from './Setor';
import Shopping from './Shopping';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '5%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  sumario: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  sumarioTopico: {
    fontSize: '20px',
    textAlign: 'Left',
  },
  sumarioSubTopico: {
    paddingLeft: '20px',
    textAlign: 'Left',
  },
}))

export default function Sumario(props) {
    const classes = useStyles();
   
    const estado = props.estado;
    const cidade = props.cidade;
    const bairro = props.bairro;
    const setor = props.setor
    const shopping = props.shopping
     
 
     

    

      return (
      <div className={classes.root}>
        <Grid item xs={12} style={{marginTop: '20px'}}>

          <div className={classes.sumario}>
            <h1>Sumário</h1>
          </div>

          <Paper className={classes.paper} elevation={16}>
            
            {estado ? (
              <div>
                <Estado />
              </div>    
            ) : ( <div /> )}


            {cidade ? (
              <div style={{marginTop: '50px'}}>
                <Cidade />
              </div>
            ) : ( <div /> )}


            {bairro ? (
            <div style={{marginTop: '50px'}}>
              <Bairro />
            </div>
            ) : ( <div /> )}

            {setor ? (
            <div style={{marginTop: '50px'}}>
              <Setor />
            </div>
            ) : ( <div /> )}

            {shopping ? (
            <div style={{marginTop: '50px'}}>
              <Shopping />
            </div>
            ) : ( <div /> )}

            </Paper>
        </Grid>
      </div>
      );

}