import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Sumario from './Sumario/Sumario';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import api from '../../api';
import CircularProgress from '@material-ui/core/CircularProgress';
import PopulacaoEstimada from '../../Components/Ibge/Cidade/PopEstimada'
import AreaTerritorial from '../../Components/Ibge/Cidade/AreaTerritorial'
import DensidadeDemografica from '../../Components/Ibge/Cidade/DensidadeDemografica'
import IDH from '../../Components/Ibge/Cidade/IDH'
import ReceitaOrcamentaria from '../../Components/Ibge/Cidade/ReceitaOrcamentaria'
import Empresas from '../../Components/Ibge/Cidade/Empresas'
import SalariosMinimos from '../../Components/Ibge/Cidade/SalariosMinimos'
import PIB from '../../Components/Ibge/Cidade/PIB'
import PopCrescAnos from '../../Components/Ibge/Cidade/PopCrescAnos'
import { PopCidadeEstado, PopUrbanoEstado, PopRuralEstado, PopSexoEstado, PopSexoEstadoUrbano, PopSexoEstadoRural } from '../../Components/Ibge/Cidade/PopDadosEstado'
import PopIdade from '../../Components/Ibge/Cidade/PopIdade'
import PopRendimento from '../../Components/Ibge/Cidade/PopRendimento'
import Consumo from '../../Components/Ibge/Cidade/Consumo'
import ImoveisTipos from '../../Components/Ibge/Cidade/ImoveisTipos'
import EmpresasDetalhadas from '../../Components/Ibge/Cidade/EmpresasDetalhadas'
import MoradoresDomicilio from '../../Components/Ibge/Cidade/MoradoresDomicilio'
import PessoalOcupadoTotal from '../../Components/Ibge/Cidade/PessoalOcupadoTotal'
import PessoalOcupadoAssalariado from '../../Components/Ibge/Cidade/PessoalOcupadoAssalariado'
import NiveisInstrucaoEscolar from '../../Components/Ibge/Cidade/NiveisInstrucaoEscolar'
import Alfabetizacao from '../../Components/Ibge/Cidade/Alfabetizacao'
import Analfabetizacao from '../../Components/Ibge/Cidade/Analfabetizacao'
import Veiculos from '../../Components/Ibge/Cidade/Veiculos'
import TesouroReceita from '../../Components/Ibge/Cidade/TesouroReceita'

import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import MapIcon from '@material-ui/icons/Map';
import PersonPinRoundedIcon from '@material-ui/icons/PersonPinRounded';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import NaturePeopleRoundedIcon from '@material-ui/icons/NaturePeopleRounded';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import SendIcon from '@material-ui/icons/Send';

import purple from '@material-ui/core/colors/purple';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '5%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  paperNiveis: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: purple[900],
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  
  imgresponsive: {
    width: '100px',
    height: 'auto',
  },
  mapresponsive: {
    width: '80%',
    height: 'auto',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  fontBold: {
    fontWeight: 900,
  },
}));


export default function Cidade(props) {
  const classes = useStyles();
  
  const codEstado = props.cod.substr(0, 2);
  const codCidade = props.cod;
  
  const [nomeCidade, setNomeCidade] = useState();
  const [nomeEstado, setNomeEstado] = useState();
  
  useEffect(() => {
    (async () => {
      
      await api.post('/consulta/cidade/nomecidade', {cidade:codCidade})
      .then(function (response) {
        // handle success
        
        const datajson = response.data
        // console.log(datajson)
        setNomeCidade(datajson[0].nome_municipio)
        setNomeEstado(datajson[0].nome_uf)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });


    })();
  }, []);

  return (

    <>
    <Accordion style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className={classes.heading}>Cidade: {nomeCidade ? nomeCidade : <CircularProgress style={{color: 'green'}} size={15} />}</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  
    <div className={classes.root}>
      
      <Grid container spacing={3}>
     
        <Grid item xs={12}>
       
         
          <Paper className={classes.paperNiveis} elevation={16}>
              <h1>Estudo Geográfico, Social e Econômico</h1>
              <h2>Cidade</h2>
              <h1 className={classes.fontBold}>{nomeCidade}</h1>
              <h3>{nomeEstado} </h3>
          </Paper>
              
       
        </Grid>
       
        <Sumario estado={null} cidade={codCidade} bairro={null} shopping={null} />
        

        <Grid item xs={12} style={{marginTop: '20px'}} id="sectioninformacoescidade">
          <Paper className={classes.paper} elevation={16}>

            <h2>Informações</h2>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <div style={{textAlign: 'left'}}>
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      paddingBottom: '0px',
                      marginBottom: '0px',
                  }}>
                      <PeopleAltRoundedIcon color="secondary"/>
                      <PopulacaoEstimada codCidade={codCidade} />
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <MapIcon color="secondary"/>
                      <AreaTerritorial codCidade={codCidade} />
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <PersonPinRoundedIcon color="secondary"/>
                      <DensidadeDemografica codCidade={codCidade} />
                  </div>
                  <Divider />                  
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}>
                      <AccountBalanceWalletRoundedIcon color="secondary"/>
                      <PessoalOcupadoTotal codCidade={codCidade} />
                  </div>
                  <Divider />
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <NaturePeopleRoundedIcon color="secondary"/>
                      <PessoalOcupadoAssalariado codCidade={codCidade} />
                  </div>
                  <Divider />                
               
                  <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                  }}>
                      <MonetizationOnRoundedIcon color="secondary"/>
                      <SalariosMinimos codCidade={codCidade} />
                  </div>
                </div>
                
              </Grid>
              <Grid item xs={12} md={6}>
              <div style={{textAlign: 'left'}}>

              <IDH codCidade={codCidade} />

              <Divider />                
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <BusinessRoundedIcon color="secondary"/>
                    <Empresas codCidade={codCidade} />
                </div>
                <Divider />
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <AccountBalanceTwoToneIcon color="secondary"/>
                    <PIB codCidade={codCidade} />
                </div>

                
                
              </div>
            </Grid>

          </Grid>           

        </Paper>
      </Grid>





        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionreceitaanualcidade">
          <Paper className={classes.paper} elevation={16}>

            <h2>Receita Anual</h2>

            <TesouroReceita codCidade={codCidade} />          

          </Paper>
        </Grid>




        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionpopulacaocidade">
          <Paper className={classes.paper} elevation={16}>

            <h2>População</h2>

            <Grid container spacing={3}>            
            <Grid item xs={12} md={12}>
                <p style={{textAlign: 'left'}}>Crescimento Populacional</p>
                <Divider />
                <PopCrescAnos codCidade={codCidade} />
                
              </Grid>

              </Grid>

            <Grid container spacing={3}>            
            
              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Cidade x Estado</p>
                <Divider />
                <PopCidadeEstado codCidade={codCidade} codEstado={codEstado} />
                
              </Grid>
              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Urbana</p>
                <Divider />
                <PopUrbanoEstado codCidade={codCidade} />
              </Grid>

              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Rural</p>
                <Divider />
                <PopRuralEstado codCidade={codCidade} />
              </Grid>

            </Grid>

            <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Total - Sexo</p>
                <Divider />
                <PopSexoEstado codCidade={codCidade} />
              </Grid>
              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Urbana - Sexo</p>
                <Divider />
                <PopSexoEstadoUrbano codCidade={codCidade} />
              </Grid>
              <Grid item xs={12} md={4}>
                <p style={{textAlign: 'left'}}>População Rural - Sexo</p>
                <Divider />
                <PopSexoEstadoRural codCidade={codCidade} />
              </Grid>

            </Grid>

          </Paper>
        </Grid>



        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionidadecidade">
          <Paper className={classes.paper} elevation={16}>
            <h2>Idade</h2>
            <PopIdade codCidade={codCidade} />
          </Paper>
        </Grid>



        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionrendimentocidade">
          <Paper className={classes.paper} elevation={16}>
            <h2>Rendimento</h2>
            <PopRendimento codCidade={codCidade} />
          </Paper>
        </Grid>

        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionconsumocidade">
          <Paper className={classes.paper} elevation={16}>
            <h2>Consumo</h2>
            <Consumo codCidade={codCidade} />
          </Paper>
        </Grid>


        <Grid item xs={12} style={{marginTop: '20px'}} id="sectioneducacaocidade">
          <Paper className={classes.paper} elevation={16}>

            <h2>Educação</h2>
            
            <Grid container spacing={3}>
              
              <Grid item xs={12} md={6}>
                <p style={{textAlign: 'left'}}>Taxa de Alfabetização</p>
                <Divider />
                <Alfabetizacao codCidade={codCidade} />
              </Grid>
              <Grid item xs={12} md={6}>
                <p style={{textAlign: 'left'}}>Taxa de Analfabetismo</p>
                <Divider />
                <Analfabetizacao codCidade={codCidade} />
              </Grid>

            </Grid>

             

            <NiveisInstrucaoEscolar codCidade={codCidade} />         

          </Paper>
        </Grid>


        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionempresascidade">
          <Paper className={classes.paper} elevation={16}>

            <h2>Empresas</h2>

            <EmpresasDetalhadas codCidade={codCidade} />

          </Paper>
        </Grid>


        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionimoveiscidade">
          <Paper className={classes.paper} elevation={16}>

            <h2>Imóveis</h2>

            <ImoveisTipos codCidade={codCidade} />

          </Paper>
        </Grid>



        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionmoradorespordomiciliocidade">
          <Paper className={classes.paper} elevation={16}>

            <h2>Moradores por domicílio</h2>

            <MoradoresDomicilio codCidade={codCidade} />        

          </Paper>
        </Grid>



        

        
        <Grid item xs={12} style={{marginTop: '20px'}} id="sectionveiculoscidade">
          <Paper className={classes.paper} elevation={16}>

            <h2>Veículos</h2>

            <Veiculos codCidade={codCidade} />           

          </Paper>
        </Grid>


        


      </Grid>
    
      
        
    </div>
    </AccordionDetails>
            </Accordion>
            </>
  );
}
