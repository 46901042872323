import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

import Button from '@material-ui/core/Button';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';


import StyledDropzone from '../Dropzone'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', 
      overflow:'scroll', 
      margin: theme.spacing(3),
      padding: theme.spacing(3), 
         
    },
    modalStyle:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position:'absolute',
      top:'10%',
      left:'10%',
      overflow:'auto',
      // height:'100%',
      // display:'block',
      margin: theme.spacing(3),
      // padding: theme.spacing(3), 
      
    }
  }));

  

export default function ModalNovaLogo({ openLogo, setOpenLogo }) {
const classes = useStyles();



const handleClose = () => {
    setOpenLogo(false);
    };

return (
    <>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle}
        open={openLogo}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openLogo}>
        
            <Paper className={classes.paper} elevation={10}>
            
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    >    
                <div  m={1} style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}> 
               
                            <ImageSearchIcon/>
                            <h2 style={{marginLeft: '5px' }}>Adicionar/Alterar Logo</h2>
                </div>
                <br/>
                
                </Grid>  
                <p style={{fontStyle: 'italic'}}>Altura Máx: 500px. Largura Máx: 500px. Tamanho Máx: 2 MB.</p>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                        <StyledDropzone setOpenLogo={setOpenLogo} />

                        <Button        
                            variant="contained"                            
                            color="secondary"
                            size="small"  
                            style={{marginRight: '10px'}} 
                            onClick={() => handleClose()}                                                                   
                        >
                            Cancelar
                        </Button>

                        


                    
                        
                    </Grid>
                </Grid>

                                
            </Paper>
         
        </Fade>
      </Modal>
    </>
    )
}