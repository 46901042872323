import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';

import Chart from "react-apexcharts";

import api from '../../../api';


export default function PopIdadeSetor(props) {
    const [dadosLabel, setDadosLabel] = useState([]);
    const [dadosSeries, setDadosSeries] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [dadosLabelHomens, setDadosLabelHomens] = useState([]);
    const [dadosSeriesHomens, setDadosSeriesHomens] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [dadosLabelMulheres, setDadosLabelMulheres] = useState([]);
    const [dadosSeriesMulheres, setDadosSeriesMulheres] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const codSetor = props.codSetor
    const { isDarkModeCont } = useContext(DarkContext);


    const options = {
        chart: { 
            background: 'transparent',           
        defaultLocale: 'pt-br',
        locales: [{
            name: 'pt-br',
            options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            toolbar: {
                download: 'Download SVG',
                selection: 'Seleção',
                selectionZoom: 'Selecionar Zoom',
                zoomIn: 'Mais Zoom',
                zoomOut: 'Menos Zoom',
                pan: 'Mover',
                reset: 'Reiniciar Zoom',
            }
            }
        }],
        type: 'area',     
        // dropShadow: {
        // enabled: true,
        // color: '#000',
        // top: 3,
        // left: 3,
        // blur: 3,
        // opacity: 0.2
        // },
        // height: 150,
        stacked: false,
        toolbar: {
            show: false
          },
        },
        theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
        colors: ['#1fff80', '#4d8eff', '#ff78f6'], 
        // fill: {
        //   type: "gradient",
        //   gradient: {
        //     shadeIntensity: 1,
        //     inverseColors: true,
        //     gradientToColors: ["#DB162F"],
        //     opacityFrom: 1,
        //     opacityTo: 1,
        //     type: "vertical",
        //     stops: [0, 30]
        //   }
        // },
        dataLabels: {
            enabled: false
          },
          stroke: {
            width: 2,
            curve: 'smooth'
          },
          xaxis: {
            categories: ["0 a 4 anos", "5 a 9 anos", "10 a 14 anos", "15 a 19 anos", "20 a 24 anos", "25 a 29 anos", "30 a 34 anos", "35 a 39 anos", "40 a 44 anos", "45 a 49 anos", "50 a 54 anos", "55 a 59 anos", "60 a 64 anos", "65 a 69 anos", "70 a 74 anos", "75 a 79 anos", "80 a 84 anos", "85 a 89 anos", "90 a 94 anos", "95 a 99 anos"],
            title: {
              text: 'Idade'
            }
          },
          yaxis: {
            title: {
              text: 'População',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('pt-BR');
              }
            },
          },
          
        
        
      }
    
      const series = [{
        name: 'População Total',
        data: dadosSeries
      },{
        name: 'População Homens',
        data: dadosSeriesHomens
      },{
        name: 'População Mulheres',
        data: dadosSeriesMulheres
      }, ]


       
   useEffect(() => {
    (async () => {
      
      api.post('/consulta/setor/populacaoidade', {setor:codSetor})
      .then(function (response) {
        // handle success
                
        const datajson = response.data.total      
        var chavesAscendentes = Object.keys(datajson).sort( function(keyA, keyB) {
          return Number(datajson[keyA].VAR) - Number(datajson[keyB].VAR);
        });           
        setDadosSeries([Number(datajson[chavesAscendentes[0]].V), Number(datajson[chavesAscendentes[1]].V), Number(datajson[chavesAscendentes[2]].V), Number(datajson[chavesAscendentes[3]].V), Number(datajson[chavesAscendentes[4]].V), Number(datajson[chavesAscendentes[5]].V), Number(datajson[chavesAscendentes[6]].V), Number(datajson[chavesAscendentes[7]].V), Number(datajson[chavesAscendentes[8]].V), Number(datajson[chavesAscendentes[9]].V), Number(datajson[chavesAscendentes[10]].V), Number(datajson[chavesAscendentes[11]].V), Number(datajson[chavesAscendentes[12]].V), Number(datajson[chavesAscendentes[13]].V), Number(datajson[chavesAscendentes[14]].V), Number(datajson[chavesAscendentes[15]].V), Number(datajson[chavesAscendentes[16]].V), Number(datajson[chavesAscendentes[17]].V), Number(datajson[chavesAscendentes[18]].V), Number(datajson[chavesAscendentes[19]].V)])
        

        const datajsonhomem = response.data.homem      
        var chavesAscendenteshomem = Object.keys(datajsonhomem).sort( function(keyA, keyB) {
          return Number(datajsonhomem[keyA].VAR) - Number(datajsonhomem[keyB].VAR);
        });       
        setDadosSeriesHomens([Number(datajsonhomem[chavesAscendenteshomem[0]].V), Number(datajsonhomem[chavesAscendenteshomem[1]].V), Number(datajsonhomem[chavesAscendenteshomem[2]].V), Number(datajsonhomem[chavesAscendenteshomem[3]].V), Number(datajsonhomem[chavesAscendenteshomem[4]].V), Number(datajsonhomem[chavesAscendenteshomem[5]].V), Number(datajsonhomem[chavesAscendenteshomem[6]].V), Number(datajsonhomem[chavesAscendenteshomem[7]].V), Number(datajsonhomem[chavesAscendenteshomem[8]].V), Number(datajsonhomem[chavesAscendenteshomem[9]].V), Number(datajsonhomem[chavesAscendenteshomem[10]].V), Number(datajsonhomem[chavesAscendenteshomem[11]].V), Number(datajsonhomem[chavesAscendenteshomem[12]].V), Number(datajsonhomem[chavesAscendenteshomem[13]].V), Number(datajsonhomem[chavesAscendenteshomem[14]].V), Number(datajsonhomem[chavesAscendenteshomem[15]].V), Number(datajsonhomem[chavesAscendenteshomem[16]].V), Number(datajsonhomem[chavesAscendenteshomem[17]].V), Number(datajsonhomem[chavesAscendenteshomem[18]].V), Number(datajsonhomem[chavesAscendenteshomem[19]].V)])


        const datajsonmulher = response.data.mulher      
        var chavesAscendentesmulher = Object.keys(datajsonmulher).sort( function(keyA, keyB) {
          return Number(datajsonmulher[keyA].VAR) - Number(datajsonmulher[keyB].VAR);
        });       
        setDadosSeriesMulheres([Number(datajsonmulher[chavesAscendentesmulher[0]].V), Number(datajsonmulher[chavesAscendentesmulher[1]].V), Number(datajsonmulher[chavesAscendentesmulher[2]].V), Number(datajsonmulher[chavesAscendentesmulher[3]].V), Number(datajsonmulher[chavesAscendentesmulher[4]].V), Number(datajsonmulher[chavesAscendentesmulher[5]].V), Number(datajsonmulher[chavesAscendentesmulher[6]].V), Number(datajsonmulher[chavesAscendentesmulher[7]].V), Number(datajsonmulher[chavesAscendentesmulher[8]].V), Number(datajsonmulher[chavesAscendentesmulher[9]].V), Number(datajsonmulher[chavesAscendentesmulher[10]].V), Number(datajsonmulher[chavesAscendentesmulher[11]].V), Number(datajsonmulher[chavesAscendentesmulher[12]].V), Number(datajsonmulher[chavesAscendentesmulher[13]].V), Number(datajsonmulher[chavesAscendentesmulher[14]].V), Number(datajsonmulher[chavesAscendentesmulher[15]].V), Number(datajsonmulher[chavesAscendentesmulher[16]].V), Number(datajsonmulher[chavesAscendentesmulher[17]].V), Number(datajsonmulher[chavesAscendentesmulher[18]].V), Number(datajsonmulher[chavesAscendentesmulher[19]].V)])

          
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
       
      });


      // const { data } = console.log(await api.get('/consulta/ibge/teste'));
      // console.log(data)
      // setDados(data);
    })();
  }, []);

  // useEffect(() => {console.log(dados)}, [dados])


  
  return (
    <div className="app">
         <div className="row">
           <div className="mixed-chart">
             <Chart
               options={options}
               series={series}
               type="area"
               //width="100%"
               height="400vh"
             />
           </div>
         </div>
       </div>
  );
}
