import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import jwtdecode from "jwt-decode";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import InputIcon from '@material-ui/icons/Input';

import ScrollTotop from '../../Components/ScrollToTop';


import LogoCliente from '../../Components/LogoCliente'
import Estado from '../../Components/Cliente/Estado';
import Cidade from '../../Components/Cliente/Cidade';
import Bairro from '../../Components/Cliente/Bairro';
import Setor from '../../Components/Cliente/Setor';
import Shopping from '../../Components/Cliente/Shopping';
import MapaEstudo from '../../Components/Mapas/MapaEstudo'

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        minWidth: '50%', 
        // maxWidth: '25%',
        // position: 'absolute',
        marginTop: '40px'
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',  
        margin: theme.spacing(3),
        padding: theme.spacing(3),    
      },
      alignCenter: {
        padding: theme.spacing(2),
        textAlign: 'center',
        justifyContent: 'center', 
      },
      marginItens: {
          marginTop: '60px',
      },
      marginItemSimples: {
        marginTop: '40px',
      },
      noMargins: {
          margin: '0px',
          padding: '0px',
          marginBlockStart: 0,
            marginBlockEnd: 0,
      },
      textSecondary: {
        color: theme.palette.text.secondary,
        textDecoration: 'underline',
        marginBlockStart: 0,
        marginBlockEnd: 0,
      },
      button: {
        margin: theme.spacing(1),
      },

      
}));

export default function Estudo() {
const classes = useStyles();

const { search } = useLocation();
const { HdsfgFdfgaseFoiUudtgfdHdsfgFdfgaseFoiUudtgfd } = queryString.parse(search)
// console.log(HdsfgFdfgaseFoiUudtgfdHdsfgFdfgaseFoiUudtgfd)

// const params = useParams();

const tokenEstudo = HdsfgFdfgaseFoiUudtgfdHdsfgFdfgaseFoiUudtgfd
// localStorage.setItem('client', JSON.stringify(tokenEstudo));
sessionStorage.setItem('client', JSON.stringify(tokenEstudo));

const setor = jwtdecode(tokenEstudo).setor
const codSetor = jwtdecode(tokenEstudo).codSetor
const estado = jwtdecode(tokenEstudo).estado
const codEstado = jwtdecode(tokenEstudo).codEstado
const cidade = jwtdecode(tokenEstudo).cidade
const codCidade = jwtdecode(tokenEstudo).codCidade
const bairro = jwtdecode(tokenEstudo).bairro
const codBairro = jwtdecode(tokenEstudo).codBairro
const shopping = jwtdecode(tokenEstudo).shopping
const codShopping = jwtdecode(tokenEstudo).codShopping
const coordenadas = jwtdecode(tokenEstudo).coordenadas
const coordZoom = jwtdecode(tokenEstudo).zoom

return (
  
    <div className={classes.alignCenter}>        
        <ScrollTotop />
        <LogoCliente tokenEstudo={tokenEstudo} />

        <div className={classes.marginItemSimples}>
            
            <h1>Estudo Geográfico, Social e Econômico.</h1>
            
        </div>
        <Box display="flex" justifyContent="center">
        <Paper className={classes.paper} elevation={13}>

        <MapaEstudo cordenadas={{'coord':coordenadas, 'zoom':coordZoom}} setor={setor} codSetor={codSetor} />
        
        <div>
            {estado ? (  
                <div>  
                    <h3 className={classes.textSecondary}>Estado</h3>
                    <h2 className={classes.noMargins}><Estado cod={codEstado} tokenEstudo={tokenEstudo} /></h2>  
                </div>
            ) : (        
                <div />   
            ) }

            {cidade ? (        
                <div className={classes.marginItemSimples}>
                    <h3 className={classes.textSecondary}>Cidade</h3>
                    <h2 className={classes.noMargins}><Cidade cod={codCidade} tokenEstudo={tokenEstudo} /></h2>
                </div>
            ) : (        
                <div />        
            ) }

            {bairro ? (        
                <div className={classes.marginItemSimples}>
                    <h3 className={classes.textSecondary}>Bairro</h3>
                    <h2 className={classes.noMargins}><Bairro cod={codBairro} tokenEstudo={tokenEstudo}/></h2>
                </div>
            ) : (        
                <div />        
            ) }

            {setor ? (        
                <div className={classes.marginItemSimples}>
                    <h3 className={classes.textSecondary}>Setor localizado em:</h3>
                    <h2 className={classes.noMargins}><Setor cod={codSetor} tokenEstudo={tokenEstudo}/></h2>
                </div>
            ) : (        
                <div />        
            ) }

            {shopping ? (        
                <div className={classes.marginItemSimples}>
                    <h3 className={classes.textSecondary}>Shopping</h3>
                    <h2 className={classes.noMargins}><Shopping cod={codShopping} tokenEstudo={tokenEstudo}/></h2>
                </div>
            ) : (        
                <div />        
            ) }
        </div>
        <div className={classes.marginItens}>
        <Link href={"https://sistema.geotaker.com.br/cliente/estudo/"} >
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<InputIcon />}
            >
                Acessar Estudo
            </Button>
        </Link>
        </div>
        </Paper>
        </Box>
    </div>
 
)
}