import React from 'react';



export default function Teste() {

return (
    <>
<iframe width="520" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://www.google.com/maps/d/u/0/embed?mid=1QE-RXSD2SS1RO7tkE23rRlqRYMY&ie=UTF8&msa=0&ll=40.71132100000002%2C-73.957686&spn=0.007807%2C0.009871&z=15&output=embed"></iframe>
</>
)
}