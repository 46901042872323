import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-scroll";
import Divider from '@material-ui/core/Divider';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '5%',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  textPrimary: { 
    color: theme.palette.text.primary,
  },
  textSecond: { 
    color: theme.palette.text.secondary,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  sumarioTopico: {
    fontSize: '20px',
    textAlign: 'Left',
  },
  sumarioSubTopico: {
    paddingLeft: '20px',
    textAlign: 'Left',
  },
}))

export default function SumarioBairro(props) {
    const classes = useStyles();
   

    

      return (

    <>

        <div style={{textAlign: 'Left'}}>
            <h2>Sumário Bairro</h2>
        </div>

        <Divider />
      
        <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
            
               <p className={classes.sumarioTopico}><Link
                activeClass="active"
                to="sectionsobresetor"              
                smooth={true}              
                offset={-50}
                duration={500} 
                className={classes.cursorPointer}     
                >
                  Sobre
                </Link></p>
                
                <p className={classes.sumarioTopico}><Link
                activeClass="active"
                to="sectionpopulacaosetor"              
                smooth={true}              
                offset={-50}
                duration={500} 
                className={classes.cursorPointer}     
                >
                  População
                </Link></p>

                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionpopulacaosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      População Total
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionpopulacaosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      População Homem
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionpopulacaosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      População Mulher
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionpopulacaosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Estudantes
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionpopulacaosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Densidade Demográfica
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionpopulacaosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      População por Cor ou Raça
                    </Link></p>
                    

                <p className={classes.sumarioTopico}><Link
                activeClass="active"
                to="sectionidadesetor"              
                smooth={true}              
                offset={-50}
                duration={500} 
                className={classes.cursorPointer}     
                >
                  Idade
                </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionidadesetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      População Total
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionidadesetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      População Homens
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionidadesetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      População Mulheres
                    </Link></p>


            </Grid>
            <Grid item xs={12} md={3}>

                
            <p className={classes.sumarioTopico}><Link
                activeClass="active"
                to="sectionrendimentosetor"              
                smooth={true}              
                offset={-50}
                duration={500} 
                className={classes.cursorPointer}     
                >
                  Rendimento
                </Link></p>                   
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionrendimentosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Sem rendimento
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionrendimentosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Até 1/4 de salário mínimo
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionrendimentosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Mais de 1/4 a 1/2 salário mínimo
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionrendimentosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Mais de 1/2 a 1 salário mínimo
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionrendimentosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Mais de 1 a 2 salários mínimos
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionrendimentosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Mais de 2 a 3 salários mínimos
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionrendimentosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Mais de 3 a 5 salários mínimos
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionrendimentosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Mais de 5 salários mínimos
                    </Link></p>

              </Grid>


              <Grid item xs={12} md={3}>

                
                  <p className={classes.sumarioTopico}><Link
                      activeClass="active"
                      to="sectionconsumosetor"              
                      smooth={true}              
                      offset={-50}
                      duration={500} 
                      className={classes.cursorPointer}     
                      >
                        Consumo
                      </Link></p>                   
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Alimentação
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Habitação
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Vestuário
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Transporte
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Higiene e Cuidados Pessoais
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Assistência a Saúde
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Educação
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Recreação e Cultura
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Serviços Pessoais
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Despesas Diversas
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Outras Despesas Correntes
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Aumento do Ativo
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionconsumosetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Diminuição do Passivo
                    </Link></p>

              </Grid>



              <Grid item xs={12} md={3}>

                
                  <p className={classes.sumarioTopico}><Link
                      activeClass="active"
                      to="sectionimoveissetor"              
                      smooth={true}              
                      offset={-50}
                      duration={500} 
                      className={classes.cursorPointer}     
                      >
                        Imóveis
                      </Link></p>  
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Casa
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Casa de vila ou em condomínio
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Apartamento
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Imóvel próprio já quitado
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Imóvel próprio em aquisição
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Imóvel alugado
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Imóvel cedido por empregador
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Imóvel cedido de outra forma
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Imóvel de outra condição
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Outras Despesas Correntes
                    </Link></p>
                    <p className={classes.sumarioSubTopico}><Link
                    activeClass="active"
                    to="sectionimoveissetor"              
                    smooth={true}              
                    offset={-50}
                    duration={500} 
                    className={classes.cursorPointer}     
                    >
                      Moradores por Domicílio (1 a 10 ou mais)
                    </Link></p>
                    

              </Grid>





            </Grid>

        </>   
      );

}