import React, { useState, useEffect, createContext } from 'react';


const LogoContext = createContext();

function LogoProvider({ children }) {
    const [LogoCompany, setLogoCompany] = useState();

    // useEffect(() => {
    //     console.log(flyToMap)
    // }, [flyToMap])

    return (
        <LogoContext.Provider value={{ LogoCompany, setLogoCompany }}>
          {children}
        </LogoContext.Provider>
      );
}

export { LogoContext, LogoProvider };
