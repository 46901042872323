import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AnnouncementIcon from '@material-ui/icons/Announcement';


export default function Atualizacoes() {


    return (
        <div>       
            <List>
              
                <ListItem>
                  <ListItemIcon>
                    <AnnouncementIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Inclusões de novos Shopping Centers"
                    secondary="Novos shopping centers disponibilizados para estudo"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <AnnouncementIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Inclusões de novos Bairros"
                    secondary="Novos bairros disponibilizados para estudo"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <AnnouncementIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Inclusões de novos Bairros"
                    secondary="Novos bairros disponibilizados para estudo"
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <AnnouncementIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Inclusões de novos Bairros"
                    secondary="Novos bairros disponibilizados para estudo"
                  />
                </ListItem>
              
            </List>
        </div>
    )

}