import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../../api';

import Swal from 'sweetalert2';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { toast } from 'react-toastify';

import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      marginRight: '10px',
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',  
      margin: theme.spacing(3),
      padding: theme.spacing(3),    
    },
    removeListsDots: {
      ul: {
        listStyleType: 'none !important'
      },
    }
  }));

export default function AdminUsuarios({ open, setOpen } ) {
    const classes = useStyles();
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(true);
    const [dados, setDados] = useState([]);
    
    const [firstName, setFirstName] = useState();
    const [secondName, setSecondName] = useState();
    const [emailUser, setEmailUser] = useState();
    const [passUser, setPassUser] = useState();
    const [rePassUser, setRePassUser] = useState();

    
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleChangePassUser = (event) => {
      setPassUser(event.target.value);
    };
  
    const handleChangeRePassUser = (event) => {
      setRePassUser(event.target.value);
    };

    const handleChangeFirstName = (event) => {
      setFirstName(event.target.value);
    };
  
    const handleChangeSecondName = (event) => {
      setSecondName(event.target.value);
    };
  
    const handleChangeEmailUser = (event) => {
      setEmailUser(event.target.value);
    };

    useEffect(() => {
        (async () => {
        
          await api.post('/administracao/usuarios', {tokenUser:token})
          .then(function (response) {
            // handle success
            //console.log(response.data[0].idh)
            const datajson = response.data
            // console.log(JSON.stringify(datajson))
            
            setDados(datajson)
            setLoading(false)
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .then(function () {
            // always executed
          
          });
    
    
        })();
      }, []);

      

      function deletarUsuario(id, nome) {
        Swal.fire({
            title: 'Você tem certeza?',
            html: `Você está excluindo o usuário ${nome.bold()}. Essa ação é irreversível.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, pode excluir!',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              
            Swal.fire({ 
                title: 'Excluindo, aguarde...',
                html: 'A ação está sendo executada!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            api.post('/administracao/usuarios/excluir', {tokenUser:token, idUserExcluir:id})
            .then(function (response) {
                // handle success
                //console.log(response.data[0].idh)
                const datajson = response.data
                // console.log(JSON.stringify(datajson))
                if(datajson === 1) {
                    Swal.fire(
                        'Excluído!',
                        `O usuário ${nome.bold()} foi excluído com sucesso!`,
                        'success'
                      )
                      const newList = dados.filter((item) => item.id !== id); 
                      setDados(newList);
                } else {
                    Swal.fire(
                        'Erro!',
                        `Houve algum problema e o usuário ${nome.bold()} não foi excluído! Informe o suporte.`,
                        'error'
                      )
                }
                                
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            
            });


            

            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelado',
                `O usuário ${nome.bold()} não foi excluído.`,
                'error'
              )
            }
          })
      }




      async function handleSaveNewUser() {
        if(firstName === undefined || secondName === undefined || emailUser === undefined || passUser === undefined) {
          toast.error("Favor preencher todos os campos.");
        } else { 

          if(passUser !== rePassUser) {
            toast.error("Os campos de senhas devem ser iguais.");
          } else {
            
          const datasNewUser = { tokenUser:token, firstName:firstName, secondName:secondName, emailUser:emailUser, passUser:passUser }    
          await api.post('/administracao/usuarios/incluir', datasNewUser)
          .then(function (response) {
      
            // console.log(response)
            const dadosUser = JSON.stringify(response.data)
            // toast.success("Atualização salva com sucesso!");
            handleClose()
            // console.log(dadosUser)
            // dados.push(response.data); 
           
            // console.log(dados)
            // setDados(dados)
            // const novosDados = dados => [...dados, response.data]
            // console.log(dados)
          //   novosDados.sort(function(a,b) {
          //     return a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0;
          // });
            setDados(dados => [...dados, response.data]);

            if(response.status === 200) {
              Swal.fire(
                'Adicionado',
                `Usuário ${(firstName +' '+secondName).bold()} adicionado com sucesso!`,
                'success'
              )
            }
            
          })
          .catch(function (error) {
            console.log(error);
            handleClose()
            Swal.fire(
              'Erro!',
              `O usuário ${(firstName +' '+secondName).bold()} não foi adicionado! Informe o suporte.`,
              'error'
            )
          });

          }

        }
    
      }

   

return (
  <>
  { loading ? (
    <div>
      <List>
        <ListItem key="itemLoading">
            <ListItemAvatar>
             <Skeleton animation="wave" variant="circle" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText >
               <Skeleton animation="wave" variant="rect" width={210} height={40} />
            </ListItemText>
            <ListItemSecondaryAction>
               <Skeleton animation="wave" variant="circle" width={40} height={40} />
            </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  ) : (
    <List>
      {dados.map((item) => {
        let userOwner = item.owner
     return (
        
        

        <ListItem button key={item.id}>
            <ListItemAvatar>
                <Avatar className={classes.orange}>
                    {item.firstName.substr(0, 1)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
            primary={item.firstName+' '+item.secondName}
            secondary={item.email}
            />
            {userOwner ? (
              <VerifiedUserIcon />
            ) : (
            <ListItemSecondaryAction>              
                <IconButton edge="end" aria-label="delete" onClick={() => deletarUsuario(item.id, item.firstName+' '+item.secondName)}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
            )}
           
        </ListItem>
        
    
    );
  })}
    </List>
  )}
    
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        
            <Paper className={classes.paper} elevation={10}>
            
            <Grid item md={12} xs={12}
                    container        
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginBottom: '20px'}}
                    >    
                <div  m={1} style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}> 
               
                            <PersonAddRoundedIcon/>
                            <h2 style={{marginLeft: '5px'}}>Adicionar Usuário</h2>
                        
                </div>
                </Grid>
                
                <form  autoComplete="off"> 
                <Grid container spacing={3}> 
                  <Grid item md={4} xs={12}>
                    <TextField fullWidth id="newFirstName" label="Nome" variant="outlined" type="text" required onChange={handleChangeFirstName} />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <TextField fullWidth id="newSecondName" label="Sobrenome" variant="outlined" type="text" required onChange={handleChangeSecondName} />
                  </Grid>      
                  <Grid item md={6} xs={12}>
                    <TextField fullWidth id="newEmail" label="Email" variant="outlined" type="email" required onChange={handleChangeEmailUser} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField fullWidth id="newPass" label="Senha" variant="outlined" type="password" required autoComplete="false" onChange={handleChangePassUser} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField fullWidth id="reNewPass" label="Confirmar Senha" variant="outlined" type="password" required autoComplete="false" onChange={handleChangeRePassUser} />
                  </Grid>
                        
                        
                        

                        
                   
                    <Grid
                    container   
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginTop: '20px', marginBottom: '20px'}}
                    >    
                        <Button        
                            variant="contained"                            
                            color="primary"                            
                            startIcon={<SaveRoundedIcon />}
                            size="small"  
                            style={{marginRight: '10px'}} 
                            onClick={() => handleSaveNewUser()}                                                                   
                        >
                            Salvar
                        </Button>
                        
                        <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                          Cancelar
                        </Button>
                    </Grid>
                    </Grid>
                </form>
                
            </Paper>
         
        </Fade>
      </Modal>
    
    </>
    

    )
}