import React, { useState, useEffect, useContext } from 'react';
import { DarkContext } from '../../../Context/DarkContext';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import PersonIcon from '@material-ui/icons/Person';

import Chart from "react-apexcharts";

import api from '../../../api';

const useStyles = makeStyles((theme) => ({
    textPrimary: { 
      color: theme.palette.text.primary,
    },
    textSecond: { 
      color: theme.palette.text.secondary,
    },
  }))

export default function NiveisInstrucaoEscolar(props) {
    const classes = useStyles();
    const [dadosLabel, setDadosLabel] = useState([]);
    const [dadosSeries, setDadosSeries] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const codCidade = props.codCidade
    const { isDarkModeCont } = useContext(DarkContext);

    const [loading, setLoading] = useState(true)

    const [opcaoUm, setOpcaoUm] = useState();
    const [opcaoDois, setOpcaoDois] = useState();
    const [opcaoTres, setOpcaoTres] = useState();
    const [opcaoQuatro, setOpcaoQuatro] = useState();
    

    function vezesMil(value) {
        return value*1000
    }

    useEffect(() => {
      (async () => {
      
        await api.post('/consulta/cidade/niveisinstrucaoescolar', {cidade:codCidade})
        .then(function (response) {
          // handle success
          
          const datajson = response.data
          setDadosLabel([datajson[3].D4N, datajson[4].D4N, datajson[5].D4N, datajson[6].D4N])
          setDadosSeries([Number(datajson[3].V), Number(datajson[4].V), Number(datajson[5].V), Number(datajson[6].V)])
          
          setOpcaoUm(Number(datajson[2].V).toLocaleString('pt-BR'));
          setOpcaoDois(Number(datajson[3].V).toLocaleString('pt-BR'));
          setOpcaoTres(Number(datajson[4].V).toLocaleString('pt-BR'));
          setOpcaoQuatro(Number(datajson[5].V).toLocaleString('pt-BR'));
          

       
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        
        });
  
  
      })();
    }, []);
  

    const options = {
        chart: {
            background: 'transparent', 
            height: 200,
            type: 'bar',
            events: {
              click: function(chart, w, e) {
                // console.log(chart, w, e)
              }
            },
            toolbar: {
                show: false
              },
          },
          //colors: ['#264aff', '#ff33cf'],
          theme: {
            mode: isDarkModeCont ? 'dark' : 'light' // Start with either dark or light, doesnt matter, only BG changes upon dynamic update.
        },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: dadosLabel,
            labels: {
              style: {
              // colors: ['#264aff', '#ff33cf'],
                fontSize: '8px'
              }
            }
          },       
          yaxis: {
            title: {
              text: 'População',
            },
            labels: {
              formatter: function (value) {
                return value.toLocaleString('pt-BR');
              }
            },
          },
    }

    const series = [{
      name: 'População',
        data: dadosSeries
      }]

      return (
        <>
  <p style={{textAlign: 'left', marginTop: '30px'}}>População (15 anos ou mais) por Níveis de Instrução</p>
    <Divider style={{marginBottom: '20px'}} />
  <Grid container spacing={3}>
    
                <Grid item xs={12} md={7}>
                  <div style={{textAlign: 'left'}}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        paddingBottom: '0px',
                        marginBottom: '0px',
                    }}>
                        <PersonIcon color="secondary"/>
                        {loading ? (
                          <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                        ) : (
                        <p style={{marginLeft: '5px'}} className={classes.textSecond}>Fundamental completo e médio incompleto: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{opcaoUm}</span> pessoas</p>                      
                        )}
                    </div>
                    <Divider />
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <PersonIcon color="secondary"/>
                        {loading ? (
                          <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                        ) : (
                        <p style={{marginLeft: '5px'}} className={classes.textSecond}>Médio completo e superior incompleto: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{opcaoDois}</span> pessoas</p>
                        )}
                    </div>
                    <Divider />
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <PersonIcon color="secondary"/>
                        {loading ? (
                          <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                        ) : (
                        <p style={{marginLeft: '5px'}} className={classes.textSecond}>Superior completo: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{opcaoTres}</span> pessoas</p>
                        )}
                    </div>
                    <Divider />
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}>
                        <PersonIcon color="secondary"/>
                        {loading ? (
                          <Skeleton style={{ width: '80%', height: '2.5em', marginLeft: '5px', marginBlockStart: '1em', marginBlockEnd: '1em' }} animation="wave" /> 
                        ) : (
                        <p style={{marginLeft: '5px'}} className={classes.textSecond}>Não determinado: <span style={{fontSize: '20px'}} className={classes.textPrimary}>{opcaoQuatro}</span> pessoas</p>
                        )}
                    </div>
                    
                    
                    
                   
                  </div>
                  
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className="app">
                    <div className="row">
                      <div className="mixed-chart">
                        <Chart
                          options={options}
                          series={series}
                          type="bar"
                          width="100%"
                          height="300vh"
                        />
                      </div>
                    </div>
                  </div>                
                </Grid>
  
              </Grid> 
  
          
        </>     
        );

}